import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        
        background: 'linear-gradient(to bottom, rgba(135, 201, 229, 0.5) 1%, rgba(135, 201, 229, 0) 74%), url(icons/login-background.svg) no-repeat 3% -40px',
        height: '100%'
    },
    logoImg: {
        maxHeight: '520px',
        marginTop: '30px'
    },
    countdownStyles: {
        textAlign: 'center',
        width: '275px',
        height: '46px',
        margin: '46px 105px 25px 72px',
        padding: '11px 51px 11px 50px',
        borderRadius: '5px',
        boxShadow:' 0 2px 2px 0 rgba(0, 0, 0, 0.4)',
        border: 'solid 2px rgb(114, 114, 118)',
        backgroundImage: 'linear-gradient(to bottom, rgb(65, 65, 65) 2%, rgb(0, 0, 0))'
    },
    daysLeftStyles: {
        width: '370px',
        height: '24px',
        fontFamily: 'Roboto',
        fontSize: '21px',
        fontWeight: 'bold',
        position: 'absolute',
        marginTop: '9px',
        marginLeft: '-45px',
        textAlign: 'center',
        color: '#ffffff'
    },
    gameNotStartedText: {
        width: '524px',
        height: '39px',
        margin: '80px -119px 16px',
        fontFamily: 'Roboto',
        fontSize: '32px',
        fontWeight: 600,
        textAlign: 'center',
        color: 'rgb(0, 0, 0)'
    },
    scheduledText: {
        width: '524px;',
        height: '39px;',
        margin: '14px -130px 0',
        fontFamily: 'Roboto',
        fontSize: '21px;',
        fontWeight: 500,
        textAlign: 'center',
        color: '#686b70'
    },
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'middle'
    },
    name: {
        width: '350px',
        height: '45px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        border: 'solid 1px #558ea6'
    },
    button: {
        width: '175px',
        height: '45px',
        borderRadius: '5px',
        border: 'solid 2px #296daa',
        backgroundImage: 'linear-gradient(to bottom, #53a6d5, #296daa)',
        color: '#fff',
        fontSize: '19px',
        fontWeight: 500,
        textTransform: 'capitalize',
        marginLeft: '15px'
    }
}));

export default useStyles;