import { AnyAction, Store } from "redux";
import { GameActions, GameActionTypes } from "../game/store/GameActions";
import { SocketTypes } from "./models/SocketModel";
import { DialogActionTypes } from "../shared/components/dialogComponent/store/DialogActions";
import { opentokService } from "../video/services/opentok.service";
import { delay, first } from "rxjs/operators";
import { userService } from "../core/services/UserService";
import { AssignedUserBackend } from "./models/assigned-user-backend.model";
import { AssignUserModel } from "../shared/components/assingResources/user.model";
import { LoadingIndicatorService } from "../core/loadingIndicator/LoadingIndicatorService";
import { UserRoleModel } from "../core/models/UserModel";
import { GameStages, GameState } from "../game/store/GameReducer";
import { GameVisibilityService } from "../game/services/GameVisibilityService";
import { SocketActionTypes } from "./SocketActions";
import { ChatActionTypes } from "../shared/sidebar/components/chat/store/ChatActions";

import {
  casHelper,
  cStgCheck,
  dispatchAssignedMap,
  dispatchBulkCreate,
  dispatchChatRecd,
  dispatchCreated,
  dispatchCreateG,
  dispatchDataAndDetails,
  dispatchDebrief,
  dispatchDebriefUpd,
  dispatchDialogNS,
  dispatchDQChanged,
  dispatchEditGame,
  dispatchFacilUpd,
  dispatchFinalDisc,
  dispatchFinRes,
  dispatchFirstQtr,
  dispatchForceFld,
  dispatchFrceFUpd,
  dispatchGameEnded,
  dispatchGamePause,
  dispatchGameRsmd,
  dispatchGetGame,
  dispatchInSvc,
  dispatchInSvcOpt,
  dispatchMapAssign,
  dispatchMapMemoiz,
  dispatchMgrMeet,
  dispatchMgrSelect,
  dispatchPlrIntro,
  dispatchPrsntation,
  dispatchReadyToS,
  dispatchResults,
  dispatchRmvUsrFR,
  dispatchSaveRules,
  dispatchSecndQtr,
  dispatchSelField,
  dispatchSetOpenTok,
  dispatchSetRoomTeams,
  dispatchSetStage,
  dispatchSetTimer,
  dispatchStageTime,
  dispatchSTQtr,
  dispatchTeamNameId,
  dispatchTrainings,
  dispatchUserActions,
  dispatchUserCling,
  formInitialPayload,
  getFRDev,
  newStageMiddleware,
} from "./utils/socketInUtils";

const rowMap: any = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
};

let gameId: number | undefined = undefined;

const createGrid = (state: GameState) => {
  const gameNumbers = state.selectedFields;
  const generalMap: Array<any> = [];
  const cellNum = Object.keys(rowMap).length;
  const numbersArr: string[] = gameNumbers;
  for (let row = 0; row < cellNum; row++) {
    generalMap[row] = [];
    for (let col = 0; col < cellNum; col++) {
      let foundSomeKey = false;
      for (let numVal of numbersArr) {
        const number = Number(numVal.split(/(\d+)/)[1]) - 1;
        const foundKey = Number(getKeyByValue(rowMap));
        if (foundKey === row && number === col) {
          generalMap[row].push({ name: col, selected: false, value: true });
          foundSomeKey = true;
        }
      }
      if (!foundSomeKey) {
        generalMap[row].push({ name: col, selected: false, value: undefined });
      }
    }
  }
  return generalMap;
};

export const getPrevioslyOpenedFields = (
  fieldMap: any[],
  state: GameState,
  useGridCopy?: boolean
) => {
  const definedMap = fieldMap.map((item) => {
    const annotation = `${item.a1notation[0]}-${item.a1notation.substring(
      1,
      item.a1notation.length
    )}`;
    const suitable = item.suitable;
    return { a1notation: annotation, suitable };
  });
  let gridCopy: any[] = [];
  if (!useGridCopy) {
    gridCopy = [...state.grid];
  }
  if (!gridCopy.length) {
    gridCopy = createGrid(state);
  }
  definedMap.forEach((element: any) => {
    const hasMatch = element?.suitable;
    const field = element?.a1notation;
    const cell: string = field[0].toUpperCase();
    const cellId = Number(getKeyByValue(cell));
    const row: number = Number(field.substr(2, field.length - 1)) - 1;
    gridCopy[row][cellId].selected = true;
    if (hasMatch) {
      gridCopy[row][cellId].value = true;
    }
  });
  return gridCopy;
};

const getKeyByValue = (value: string) => {
  return Object.keys(rowMap).find((key) => rowMap[key] === value);
};

const getTypeOfResponse = (payload: any, store: Store): SocketTypes => {
  switch (payload?.action) {
    case "team-rules-changed":
      return SocketTypes.SAVE_RULES;
    case "opened-box":
      return SocketTypes.SELECT_FIELD;
    case "force-field-editor-updated":
      return SocketTypes.FFA_FACILITATOR_UPDATED;
    case "new-stage":
      return SocketTypes.NEW_STAGE;
    case "assigned-map":
      return SocketTypes.ASSIGNED_MAP;
    case "retracted-map":
      return SocketTypes.REMOVE_USER_FROM_RESOURCE;
    case "force-field-updated":
      return SocketTypes.FORCE_FIELD_UPDATED;
    case "inservice-used":
      return SocketTypes.IN_SERVICE_USED;
    case "get-stage-time":
      return SocketTypes.GET_STAGE_TIME;
    case "share-financial-results":
      return SocketTypes.FINANCIAL_RESULTS;
    case "debrief-updated":
      return SocketTypes.DEBRIEF_UPDATED;
    case "chat-received":
      return SocketTypes.CHAT_RECEIVED;
    case "viewed-debrief-question-changed":
      return SocketTypes.DEBRIEF_QUESTION_CHANGED;
    case "user-calling":
      return SocketTypes.USER_CALLING;
    case "select-rm":
      return SocketTypes.SELECT_RM;
    case "get-game":
      return SocketTypes.GET_GAME;
    case "create-game":
      return SocketTypes.CREATE_GAME;
    case "game-paused":
      return SocketTypes.GAME_PAUSED;
    case "game-resumed":
      return SocketTypes.GAME_RESUMED;
    case "join-trainer-to-team":
      return SocketTypes.JOIN_TRAINER_TO_TEAM;
    case "mute":
      return SocketTypes.MUTE;
  }

  if (payload?.action === "current-stage") {
    switch (payload.stage) {
      case SocketTypes.PLAYERS_INTRODUCTION:
        return SocketTypes.PLAYERS_INTRODUCTION;
      case SocketTypes.PRESENTATION:
        return SocketTypes.PRESENTATION;
      case SocketTypes.CREATED:
        return SocketTypes.CREATED;
      case SocketTypes.MAP_ASSIGNMENT:
        return SocketTypes.MAP_ASSIGNMENT;
      case SocketTypes.MAP_MEMORIZATION:
        return SocketTypes.MAP_MEMORIZATION;
      case SocketTypes.REGIONAL_MANAGER_SELECTION:
        return SocketTypes.REGIONAL_MANAGER_SELECTION;
      case SocketTypes.REGIONAL_MANAGERS_MEETING:
        return SocketTypes.REGIONAL_MANAGERS_MEETING;
      case SocketTypes.SECOND_QUARTER_RM_MEETING:
        return SocketTypes.SECOND_QUARTER_RM_MEETING;
      case SocketTypes.AFTER_FFA_QUARTER_RM_MEETING:
        return SocketTypes.AFTER_FFA_QUARTER_RM_MEETING;
      case SocketTypes.REGIONAL_MANAGER_SWITCH:
        return SocketTypes.REGIONAL_MANAGER_SWITCH;
      case SocketTypes.FIRST_QUARTER_DISCUSSION:
        return SocketTypes.FIRST_QUARTER_DISCUSSION;
      case SocketTypes.FIRST_QUARTER:
        return SocketTypes.FIRST_QUARTER;
      case SocketTypes.SECOND_QUARTER:
        return SocketTypes.SECOND_QUARTER;
      case SocketTypes.THIRD_FOURTH_QUARTER:
        return SocketTypes.THIRD_FOURTH_QUARTER;
      case SocketTypes.FORCE_FIELD_ANALYSIS:
        return SocketTypes.FORCE_FIELD_ANALYSIS;
      case SocketTypes.IN_SERVICE_OPTION:
        return SocketTypes.IN_SERVICE_OPTION;
      case SocketTypes.FINAL_DISCUSSION:
        return SocketTypes.FINAL_DISCUSSION;
      case SocketTypes.RESULTS:
        return SocketTypes.RESULTS;
      case SocketTypes.DEBRIEF:
        return SocketTypes.DEBRIEF;
      case SocketTypes.GAME_ENDED:
        return SocketTypes.GAME_ENDED;
    }
  }

  if (payload?.errorType === "not started") {
    return SocketTypes.DIALOG_NOT_STARTED;
  }

  return SocketTypes.UNDEFINED;
};

export const convertUsersFromBackend = (
  usersArr: any[],
  store: Store,
  allUsersArr: any[]
) => {
  usersArr.forEach((user: any) => {
    const convUser = convertAssignedUserResponse(
      user,
      store,
      "assign",
      true,
      allUsersArr
    );
    if (convUser) {
      const setUsersAction: GameActions = {
        type: GameActionTypes.ASSIGN_USER_TO_TEAM,
        payload: { user: convUser },
      };

      store.dispatch(setUsersAction);
    }
  });
};

export const setTeamResource = (assignedUser: any) => {
  let resourceName = "";
  const switchProperty = assignedUser.type.toString();
  switch (switchProperty) {
    case "0":
      resourceName = "Herbs";
      break;
    case "1":
      resourceName = "Humidity";
      break;
    case "2":
      resourceName = "Sun";
      break;
    case "3":
      resourceName = "Crops";
      break;
  }
  return resourceName;
};

export const convertAssignedUserResponse = (
  assignedUser: AssignedUserBackend,
  store: Store,
  action: string,
  assignFromBackend?: boolean,
  allUsersArr?: any[]
) => {
  const { teamName, team, availableTeam } = casHelper(
    assignedUser,
    assignFromBackend
  );

  let usersNewArr = [];
  if (!store.getState().gameState?.gameUsers?.length) {
    usersNewArr = [...Array(allUsersArr)];
  } else {
    usersNewArr = store.getState().gameState.gameUsers;
  }

  let user: AssignUserModel;
  if (!assignFromBackend) {
    user = usersNewArr.filter(
      (userNew: any) => userNew.id.toString() === assignedUser.user
    )[0];
  } else {
    user = usersNewArr.filter(
      (userNew: any) => userNew.id === assignedUser.userId
    )[0];
  }

  const newUsersArr = [];
  const userCopy = { ...user };
  if (!userCopy.teams) {
    userCopy.teams = [];
  }
  if (!userCopy.availableTeam && action === "assign") {
    userCopy.availableTeam = availableTeam;
  }
  if (
    !userCopy.teams?.includes(team) &&
    userCopy.availableTeam === availableTeam &&
    action === "assign"
  ) {
    userCopy.teams.push(team);
  } else if (action === "remove") {
    userCopy.teams = userCopy.teams.filter((teamId) => teamId !== team);
  }
  if (userCopy.teams.length === 2 && action === "assign") {
    userCopy.filtered = true;
  }

  userCopy.teamName = teamName;
  if (!userCopy.teams.length && action === "remove") {
    userCopy.availableTeam = undefined;
  }
  if (userCopy.teams.length === 1) {
    userCopy.filtered = false;
  }

  newUsersArr.push(userCopy);
  const setUsersAction: GameActions = {
    type: GameActionTypes.CHANGE_USERS,
    payload: { users: newUsersArr },
  };
  store.dispatch(setUsersAction);
  return userCopy;
};

const socketIn = (store: Store) => (event: MessageEvent) => {
  let payload: any;

  if (event.data.length) {
    payload = JSON.parse(event.data);
  }
  if (!payload) {
    return;
  }

  const socketType = getTypeOfResponse(payload, store);
  if (cStgCheck(payload)) {
    const newGrid = getPrevioslyOpenedFields(
      payload?.game?.map_fields,
      store.getState()?.gameState,
      true
    );

    const stage = payload.stage;
    const financeReportDev = getFRDev(stage, payload);
    const pl = formInitialPayload(stage, payload, newGrid, financeReportDev);

    store.dispatch({
      type: GameActionTypes.BATCH_UPDATE_STORE,
      payload: { ...pl },
    });

    store.dispatch({
      type: ChatActionTypes.RESET_MESSAGES,
      payload: true,
    });
  }

  opentokService.errorsByOpenTok$.next(undefined);
  const userRole = store.getState().userState.userData.role;

  dispatchDataAndDetails(payload, userService, store, gameId);
  dispatchSetRoomTeams(payload, store);
  dispatchEditGame(payload, store);
  dispatchGetGame(payload, store);
  dispatchSetStage(payload, store, gameId);

  if (payload?.err) {
    opentokService.errorsByOpenTok$.next(payload.err);
  }

  dispatchUserActions(payload, store);
  dispatchTrainings(payload, store);
  dispatchBulkCreate(payload, store);
  dispatchTeamNameId(payload, store);

  if (payload && payload.game && payload.game.teams) {
    localStorage.setItem("temp-teams-list", JSON.stringify(payload.game.teams));
  }

  dispatchSetTimer(payload, store);
  LoadingIndicatorService.stopLoading();
  dispatchSetOpenTok(payload, store);

  if (socketType === SocketTypes.JOIN_TRAINER_TO_TEAM && payload.avDetails) {
    switchTrainerToTeam(store, payload);
  }

  if (socketType === SocketTypes.MUTE) {
    if ([UserRoleModel.Trainer].indexOf(userRole) === -1) {
      opentokService.toggleAudio(true);
    }
  }

  switch (socketType) {
    case SocketTypes.GET_STAGE_TIME:
      dispatchStageTime(payload, store);
      break;
    case SocketTypes.GAME_PAUSED:
      dispatchGamePause(store);
      break;
    case SocketTypes.SAVE_RULES:
      dispatchSaveRules(payload, store);
      break;
    case SocketTypes.PLAYERS_INTRODUCTION:
      dispatchPlrIntro(store);
      break;
    case SocketTypes.PRESENTATION:
      dispatchPrsntation(payload, store);
      break;
    default:
      socketInSwitcherS1(socketType, payload, store); //triggers a cascade of switches (S1 and S2 for now)
  }
};

//this is used to split the massive swithch in socket - in
const socketInSwitcherS1 = (
  socketType: SocketTypes,
  payload: any,
  store: Store<AnyAction>
) => {
  switch (socketType) {
    case SocketTypes.NEW_STAGE:
      newStageMiddleware(payload, store);
      break;
    case SocketTypes.CREATED:
      dispatchCreated(payload, store);
      break;
    case SocketTypes.ASSIGNED_MAP:
      dispatchAssignedMap(payload, store);
      break;
    case SocketTypes.DEBRIEF_UPDATED:
      dispatchDebriefUpd(payload, store);
      break;
    case SocketTypes.DEBRIEF_QUESTION_CHANGED:
      dispatchDQChanged(payload, store);
      break;
    case SocketTypes.DEBRIEF:
      dispatchDebrief(payload, store);
      break;
    case SocketTypes.GAME_ENDED:
      dispatchGameEnded(store);
      break;
    case SocketTypes.FINANCIAL_RESULTS:
      dispatchFinRes(payload, store);
      break;
    case SocketTypes.IN_SERVICE_USED:
      dispatchInSvc(payload, store);
      break;
    case SocketTypes.FINAL_DISCUSSION:
      dispatchFinalDisc(payload, store);
      break;
    case SocketTypes.RESULTS:
      dispatchResults(payload, store);
      break;
    case SocketTypes.FFA_FACILITATOR_UPDATED:
      dispatchFacilUpd(payload, store);
      break;
    case SocketTypes.IN_SERVICE_OPTION:
      dispatchInSvcOpt(payload, store);
      break;
    case SocketTypes.READY_TO_START:
      dispatchReadyToS(payload, store);
      break;
    case SocketTypes.SELECT_FIELD: {
      dispatchSelField(payload, store);
      break;
    }
    case SocketTypes.CHAT_RECEIVED:
      dispatchChatRecd(payload, store);
      break;
    case SocketTypes.FORCE_FIELD_ANALYSIS:
      dispatchForceFld(payload, store);
      break;
    case SocketTypes.REMOVE_USER_FROM_RESOURCE:
      dispatchRmvUsrFR(payload, store);
      break;
    case SocketTypes.FORCE_FIELD_UPDATED:
      dispatchFrceFUpd(payload, store);
      break;
    case SocketTypes.GAME_RESUMED:
      dispatchGameRsmd(payload, store);
      break;
    case SocketTypes.CREATE_GAME:
      dispatchCreateG(payload, store);
      break;
    default:
      socketInSwitcherS2(socketType, payload, store);
  }
};

//has space for more cases before sonar lint warning
const socketInSwitcherS2 = (
  socketType: SocketTypes,
  payload: any,
  store: Store<AnyAction>
) => {
  switch (socketType) {
    case SocketTypes.REGIONAL_MANAGER_SELECTION:
      dispatchMgrSelect(payload, store);
      break;
    case SocketTypes.REGIONAL_MANAGERS_MEETING:
    case SocketTypes.REGIONAL_MANAGER_SWITCH:
    case SocketTypes.SECOND_QUARTER_RM_MEETING:
    case SocketTypes.AFTER_FFA_QUARTER_RM_MEETING:
      dispatchMgrMeet(payload, store);
      break;
    case SocketTypes.MAP_ASSIGNMENT:
      dispatchMapAssign(payload, store);
      break;
    case SocketTypes.MAP_MEMORIZATION:
      dispatchMapMemoiz(payload, store);
      break;
    case SocketTypes.FIRST_QUARTER:
      dispatchFirstQtr(payload, store);
      break;
    case SocketTypes.SECOND_QUARTER:
      dispatchSecndQtr(payload, store);
      break;
    case SocketTypes.THIRD_FOURTH_QUARTER:
      dispatchSTQtr(payload, store);
      break;
    case SocketTypes.FIRST_QUARTER_DISCUSSION:
      showFirstQuarterDiscussion(store, payload);
      break;
    case SocketTypes.USER_CALLING:
      dispatchUserCling(payload, store);
      break;
    case SocketTypes.DIALOG_NOT_STARTED:
      dispatchDialogNS(payload, store);
      break;
    default:
      break;
  }
};

export const showMapAssignmentDialog = (store: Store, payload: any) => {
  const userRole =
    payload.user && payload.user.role
      ? payload.user.role
      : userService.getUser().role;
  opentokService.streamDestroyed$.pipe(first(), delay(100)).subscribe(() => {
    store.dispatch({
      type: GameActionTypes.SAVE_OPENTOK_CONFIG,
      payload: {
        apiKey: payload.av.apiKey,
        sessionId: payload.av.session,
        token: payload.av.token,
      },
    });
    store.dispatch({
      type: GameActionTypes.SUBSTAGE_MAP_ASSIGNMENT,
    });
    if ([UserRoleModel.RM, UserRoleModel.Trainer].indexOf(userRole) > -1) {
      store.dispatch({
        type: GameActionTypes.MAP_ASSIGNMENT,
      });
    } else {
      store.dispatch({
        type: DialogActionTypes.TEXT_DIALOG,
        payload: {
          text: "Wait until you are assigned to a resource ...",
          open: true,
        },
      });
    }
  });
  commonEndStageActions(store);
};

const switchTrainerToTeam = (store: Store, payload: any) => {
  const userRole =
    payload.user && payload.user.role
      ? payload.user.role
      : userService.getUser().role;
  if ([UserRoleModel.Trainer].indexOf(userRole) > -1) {
    opentokService.streamDestroyed$.pipe(first(), delay(100)).subscribe(() => {
      store.dispatch({
        type: GameActionTypes.SAVE_OPENTOK_CONFIG,
        payload: {
          apiKey: payload.avDetails.apiKey,
          sessionId: payload.avDetails.session,
          token: payload.avDetails.token,
        },
      });

      store.dispatch({
        type: SocketActionTypes.CURRENT_STAGE,
      });
    });
    commonEndStageActions(store);
  }
};

export const showFirstQuarterDiscussion = (store: Store, payload: any) => {
  opentokService.streamDestroyed$.pipe(first(), delay(100)).subscribe(() => {
    store.dispatch({
      type: GameActionTypes.SUBSTAGE_FIRST_QUARTER_DISCUSSION,
    });
  });
  commonEndStageActions(store);
};

export const commonEndStageActions = (store: Store) => {
  GameVisibilityService.participantsListVisibility$.next(false);
  opentokService.endSession();
  if (store.getState().gameState.stage !== GameStages.MAP_ASSIGNMENT) {
    store.dispatch({
      type: DialogActionTypes.TEXT_DIALOG,
      payload: { open: false },
    });
  }
};

export default socketIn;
