import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketActions, SocketActionTypes } from '../../../socket/SocketActions';
import { AppState } from '../../../store/Store';
import useStyles from './styles/TeamRulesStyles';
import TeamRulesTextarea from './textarea/TeamRulesTextarea';
import './styles/teamRules.scss';
import { determinPosition } from '../../../core/services/ViewportService';
import { DETERMINE_POSITION } from '../../../core/models/PositionModel';

const TeamRules = (props?: any) => {
    const [position, setPosition] = useState({ isDown: DETERMINE_POSITION.BOTTOM, isOpen: false})
    const {isOpen,isDown} = position;
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectOpenFullRules = (state: AppState) => state.gameState.openedFullRules;
    const openFullRulesSel = useSelector(selectOpenFullRules);
    const openFullTeamRules = (el: any) => {
        const open = openFullRulesSel ? false : true;
        const openFullReportAction: SocketActions = {
            type: SocketActionTypes.OPEN_FULL_TEAM_RULES,
            payload: open
        };
        dispatch(openFullReportAction);
        setPosition({isDown: determinPosition(el.target), isOpen: !position.isOpen})
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            isOpen === true && setPosition((prevState: any) => {
                return {...prevState, isDown: determinPosition(document.querySelector('.team-rules'))}
            })
        });
        document.addEventListener('scroll', () => {
            isOpen === true && setPosition((prevState: any) => {
                return {...prevState, isDown: determinPosition(document.querySelector('.team-rules'))}
            })
        });
    }, [isOpen]);

    let rulesSyles = 'main-container-rules';

    if (props.finalDiscussionPage) {
        rulesSyles += ' team-rules-final-page';
    }

    if (openFullRulesSel) {
        rulesSyles += ' open';
    }

    return (<div className={`${rulesSyles} team-rules`}  onClick={openFullTeamRules}>
        <div className={`${classes.container} ${openFullRulesSel ? 'open' : ''}`}>
            <h5 className={classes.header}>Team Rules</h5>
        </div>
        <TeamRulesTextarea isDown={isDown} isOpen={isOpen}></TeamRulesTextarea>
    </div>);
}

export default React.memo(TeamRules);