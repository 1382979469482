import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "389px",
    left: "calc(50% - 301px) !important",
    top: "15vh !important",
    width: "650px",
    fontFamily: "Roboto",
    // backgroundImage: 'linear-gradient(to bottom, rgba(174, 174, 176, 0.3), rgba(174, 174, 176, 0) 41%)',
    "&#open_map_memorization_dialog": {
      width: "700px !important",
      left: "calc(50% - 350px) !important",
    },
    "&#open_map_assignment_dialog .MuiPaper-root.MuiDialog-paper": {
      height: "550px",
      width: "78%",
    },
    "&#open_ffa_dialog": {
      width: "750px",
    },
    "&#open_ffa_confirm_dialog": {
      width: "750px",
      height: "684px !important",
      "& .MuiDialog-container": {
        height: "238px",
        width: "541px",
      },
    },
    "&#open_debrief_confirm_dialog": {
      width: "750px",
      height: "auto !important",
      left: "calc(50% - 350px) !important",
      "& .MuiDialog-container": {
        width: "541px",
      },
    },
    "&#open_grid_map_dialog": {
      width: "750px",
      height: "684px !important",
      "& .MuiDialog-container": {
        height: "750px",
        width: "684px",
        marginTop: "-63px !important;",
        "& .game-num-left": {
          color: "black",
          transform: "translateY(4px)",
          textAlign: "right",
          paddingLeft: "0",
          paddingRight: "15px",
        },
      },
    },
    "&#share_screen": {
      top: "10vh !important",
      left: "150px !important",
      width: "calc(100% - 300px) !important",
      height: "calc(100% - 30vh) !important",
      "& .MuiDialog-container": {
        height: "100% !important",
      },
    },
    "&#open_create_player_dialog": {
      width: "750px !important;",
      height: "450px !important;",
    },
    "&#open_in_service_dialog": {
      width: "90%",
      left: "0 !important",
      right: "0 !important",
      margin: "0 auto",
      top: "50px !important",
      "& .map-memorization-container": {
        width: "initial",
        marginLeft: "50px",
      },
      "& .map-memorization-grid-container": {
        marginLeft: "initial",
        position: "initial",
      },
      '& .MuiPaper-root[role="dialog"]': {
        height: "750px",
      },
      "& .game-inside-container": {
        background: "none",
      },
      "& .game-num-left": {
        height: "46px",
        width: "initial",
        marginRight: "10px",
        textAlign: "right",
        transform: "translateY(4px)",
      },
      "& .game-num-top": {
        alignItems: "center",
        transform: "translateX(3px)",
      },
      "& .root-grid1": {
        marginLeft: "33px",
      },
      "& .MuiDialogContent-root": {
        "& .MuiContainer-root": {
          maxWidth: "initial !important",
          "& > .MuiGrid-root": {
            justifyContent: "center",
            flexDirection: "column",
            "& .MuiGrid-item": {
              "& > p": {
                textAlign: "center",
                position: "initial",
                marginLeft: "initial",
              },
            },
            "& > .MuiGrid-item:last-child": {
              marginTop: 0,
              left: "0",
              gridTemplateColumns: "1fr 1fr",
              "& > :first-child": {
                marginLeft: 63,
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("lg")]: {
                  paddingTop: "12px",
                  maxWidth: "610px",
                  margin: "auto",
                  transform: "translateX(-70px)",
                  position: "relative",
                  zIndex: "1",
                },
                [theme.breakpoints.down("md")]: {
                  paddingTop: "0",
                },
              },
            },
          },
        },
      },
    },
    "&#open_map_assignment_dialog": {
      left: "calc(50% - 525px) !important",
      top: "14vh !important",
      "& .MuiDialogContent-root": {
        display: "grid",
        gridTemplateColumns: "auto auto",
      },
      "& .main-cont": {
        marginTop: "-49px;",
        width: "130%;",
      },
    },
    "& .MuiDialog-container": {
      height: "initial",
    },
    "& .MuiDialog-root": {
      position: "absolute !important",
    },
    "& .MuiDialogContent-root.MuiDialogContent-dividers": {
      overflow: "scroll",
    },
    "& .MuiPaper-root.MuiDialog-paper": {
      borderRadius: "4px",
      boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.6)",
      backgroundColor: "rgb(250, 250, 250)",
      // border: 'solid 3px #ebc429',
      margin: "0 !important",
      width: "100%",
      minHeight: "100%",
      maxHeight: "750px",
      maxWidth: "unset",
    },
    "&#mngt_dialog_memo": {
      left: "calc(50% - 325px) !important",
    },
    "&#mngt_dialog_memo .MuiPaper-root.MuiDialog-paper": {
      backgroundImage: 'url("backgrounds/MemoPopTopRightBackground.png")',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right top",
    },
    "&#text_dialog": {
      left: "calc(50% - 270px) !important",
      height: "140px",
      width: "540px",
      "& .MuiDialogContent-root > div": {
        display: "flex",
        alignItems: "center",
        fontFamily: "Roboto",
        fontWeight: "500",
        textAlign: "center",
        fontSize: "21px",
        "& p": {
          width: "100%",
        },
      },
    },
  },
  assContainer: {
    width: "1000px !important",
  },
  biggerContainer: {
    maxWidth: "650px",
    backgroundImage:
      "linear-gradient(to bottom, rgba(174, 174, 176, 0.3), rgba(174, 174, 176, 0) 41%)",
  },
  title: {
    paddingLeft: "36%",
    "&#MNGT_DIALOG_MEMO-title": {
      paddingLeft: "0",
      position: "relative",
      margin: "auto",
    },
    "&.MuiDialogTitle-root": {
      padding: "16px",
    },
  },
  titleh2: {
    fontSize: "36px",
    fontWeight: "bolder",
    fontFamily: "Roboto",
  },
  contentContainer: {
    border: "0 !important",
  },
  contContainer: {
    paddingLeft: "30px",
  },
}));

export default useStyles;
