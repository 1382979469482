export const ROUTE_NAMES = Object.freeze({
    PAGES: {
        PLAYERS_INTRODUCTION: 'lobby',
        NOT_FOUND: '404',
        GAME: 'game',
        PRESENTATION: 'presentation',
        LOGIN: 'login',
        FFA: 'game-analysis',
        RESULT: 'results',
        DEBRIEF: 'debrief',
        NOT_STARTED: 'not-started',
        IN_SERVICE_OPTION: 'in-service',
        FINAL_DISCUSSION: 'final-discussion',
        ADMIN_PANEL: 'admin-panel'
    }
});