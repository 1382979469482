import { SocketActionTypes } from "./SocketActions";
import { GameActions, GameActionTypes } from "../game/store/GameActions";
import { Store } from "redux";
import { socketConfig } from "./socket.config";
import { AdminActionTypes } from "../admin/store/AdminActions";
import socketIn from "./socket-in";
import {
  DialogActions,
  DialogActionTypes,
} from "../shared/components/dialogComponent/store/DialogActions";
import { QuestionModel } from "../pages/finalTeamDiscussionPage/question.model";
import { GameMuteTypes } from "../game/enums/GameEnums";
import { Auth } from "aws-amplify";

const convertUserResourceForBackend = (
  usersArr: any[],
  resourceName: string,
  action: string,
  gameId: string,
  teamId: any
) => {
  const userId = usersArr.map((user) => user.id)[0].toString();
  let hint = "",
    leftHint;
  switch (resourceName) {
    case "herbs":
      hint = "0";
      leftHint = true;
      break;
    case "herbs2":
      hint = "0";
      leftHint = false;
      break;
    case "humidity":
      hint = "1";
      leftHint = true;
      break;
    case "humidity2":
      hint = "1";
      leftHint = false;
      break;
    case "sun":
      hint = "2";
      leftHint = true;
      break;
    case "sun2":
      hint = "2";
      leftHint = false;
      break;
    case "crops":
      hint = "3";
      leftHint = true;
      break;
    case "crops2":
      hint = "3";
      leftHint = false;
      break;
  }

  return {
    hint,
    leftHint,
    user: userId,
    action,
    gameId: gameId,
    teamId: teamId,
  };
};

export const SocketMiddleware = () => {
  let socket: WebSocket;

  const onOpen = (store: Store, isRefresh = false) => () => {
    if (!isRefresh) {
      const connectedAction: GameActions = {
        type: GameActionTypes.SOCKET_CONNECTED,
      };
      store.dispatch(connectedAction);
    }
  };

  const onClose = (store: Store) => () => {
    const closedAction: GameActions = {
      type: GameActionTypes.SOCKET_DISCONNECTED,
    };
    store.dispatch(closedAction);
  };

  const onMessage = socketIn;

  // the middleware part of this function
  return (store: any) => (next: any) => (action: any) => {
    if (
      [
        SocketActionTypes.TRY_CONNECT_SOCKET,
        SocketActionTypes.REFRESH_CONNECTION,
      ].indexOf(action.type) >= 0
    ) {
      if (socket) {
        console.log(`current socket`, socket);
      }
      socket = new WebSocket(
        `${socketConfig.endpoint}?token=${action.idToken}`
      );
      // websocket handlers
      socket.onmessage = onMessage(store);
      socket.onclose = onClose(store);
      socket.onopen = onOpen(
        store,
        action.type === SocketActionTypes.REFRESH_CONNECTION
      );
    }

    const gameId = store.getState().gameState.gameId;
    const teamId = store.getState().gameState.teamId;

    switch (action.type) {
      case SocketActionTypes.MUTE_ALL:
        socket.send(
          JSON.stringify({
            action: "mute-all",
            gameId,
            teamId,
            type: !teamId ? GameMuteTypes.GAME : GameMuteTypes.TEAM,
            muted: true,
          })
        );
        break;
      case SocketActionTypes.TRACK_USER_ATTENDANCE:
        socket.send(
          JSON.stringify({
            action: "track-game-attendance",
            ...action.payload,
          })
        );
        break;
      case SocketActionTypes.GET_GAME_ID:
        socket.send(JSON.stringify({ action: "upcoming-game-id" }));
        break;
      case SocketActionTypes.PAUSE_GAME_SOCKET:
        socket.send(JSON.stringify({ action: "pause", gameId }));
        break;
      case SocketActionTypes.RESUME_GAME_SOCKET:
        socket.send(JSON.stringify({ action: "resume", gameId }));
        break;
      case SocketActionTypes.CREATE_GAME_SOCKET:
        socket.send(
          JSON.stringify({ action: "create-game", ...action.payload })
        );
        break;
      case SocketActionTypes.START_GAME_SOCKET:
        console.log("ABOUT TO SEND START GAME WITH...", action.payload);
        socket.send(
          JSON.stringify({
            action: "end-stage",
            stage: action.stage,
            gameId: action.gameId,
          })
        );
        break;
      case SocketActionTypes.UPDATE_GAME_SOCKET:
        socket.send(JSON.stringify({ action: "edit-game", ...action.payload }));
        break;
      case SocketActionTypes.BULK_CREATE_USERS:
        socket.send(
          JSON.stringify({ action: "register-users", users: action.payload })
        );
        break;
      case SocketActionTypes.GET_UPCOMING_TRAININGS:
        socket.send(JSON.stringify({ action: "list-games", upcoming: true }));
        break;
      case SocketActionTypes.GET_CURRENT_TRAININGS:
        socket.send(
          JSON.stringify({ action: "list-last-games", upcoming: true })
        );
        break;
      case SocketActionTypes.GET_TRAINING_DETAILS:
        socket.send(
          JSON.stringify({ action: "get-game", gameId: action.payload })
        );
        break;
      case SocketActionTypes.GET_PREVIOUS_TRAININGS:
        socket.send(JSON.stringify({ action: "list-games", upcoming: false }));
        break;
      case SocketActionTypes.SEND_MESSAGE:
        socket.send(
          JSON.stringify({
            action: "send-chat",
            message: action.payload,
            gameId,
          })
        );
        break;
      case SocketActionTypes.GET_GAME_DETAILS:
        socket.send(
          JSON.stringify({ action: "get-game", gameId: action.payload })
        );
        break;
      case SocketActionTypes.ASSIGN_USER_RM:
        socket.send(
          JSON.stringify({
            action: "select-rm",
            teamId: action.teamId,
            rmUserId: action.userId,
          })
        );
        break;
      case SocketActionTypes.ASSIGN_PLAYER:
        socket.send(
          JSON.stringify({
            action: "join-user-to-team",
            teamId: action.teamId,
            userId: action.userId,
          })
        );
        break;
      case SocketActionTypes.SWITCH_TRAINER:
        socket.send(
          JSON.stringify({
            action: "join-trainer-to-team",
            teamId: action.teamId,
            userId: action.userId,
          })
        );
        break;
      case SocketActionTypes.SWITCH_TEAM:
        store.dispatch({
          type: GameActionTypes.SET_TEAM_ID,
          payload: action.payload,
        });
        socket.send(
          JSON.stringify({
            action: "join-trainer-to-team",
            teamId: action.payload,
          })
        );
        break;
      case SocketActionTypes.END_STAGE:
        store.dispatch({
          type: GameActionTypes.SET_TIMER,
          payload: { timeRemaining: 0 },
        });
        socket.send(JSON.stringify({ action: "end-stage", gameId }));
        break;
      case SocketActionTypes.CALL_TRAINER:
        socket.send(
          JSON.stringify({
            action: "call-trainers",
            gameId,
            active: action.payload,
          })
        );
        break;
      case SocketActionTypes.NEXT_STAGE:
        store.dispatch({
          type: GameActionTypes.SET_TIMER,
          payload: { timeRemaining: 0 },
        });
        socket.send(
          JSON.stringify({ action: "end-stage", stage: action.stage, gameId })
        );
        break;
      case SocketActionTypes.PING_ACTION:
        if (socket) {
          socket.send(JSON.stringify({ action: "echo", data: "" }));
        }
        break;
      case SocketActionTypes.CURRENT_STAGE:
        socket.send(JSON.stringify({ action: "current-stage", gameId }));
        break;
      case SocketActionTypes.RESET_STAGE:
        store.dispatch({
          type: GameActionTypes.SET_TIMER,
          payload: { timeRemaining: 0 },
        });
        socket.send(JSON.stringify({ action: "reset-stage", gameId }));
        break;
      case SocketActionTypes.INIT_VIDEO:
        // if (socket !== null) {
        //     setTimeout(() => {
        //         // socket.send(JSON.stringify({ action: BackendRequestActions.ReadyToStart, name: action.name }));
        //     }, 1000)
        // }
        break;
      case SocketActionTypes.READY_TO_START:
        socket.send(
          JSON.stringify({
            action: "ready-to-start",
            name: action.name,
            gameId,
          })
        );
        break;
      case SocketActionTypes.SET_DEBRIEF:
        const q: QuestionModel = action.payload;
        const gameIdNumber = Number(gameId);
        socket.send(
          JSON.stringify({
            action: "set-debrief",
            teamId: q.teamId,
            questionIndex: q.index,
            answer: q.answer,
            gameId: gameIdNumber,
          })
        );
        break;
      case SocketActionTypes.SET_DEBRIEF_QUESTION:
        const qIndex = action.payload;
        const gameIdNum = Number(gameId);
        socket.send(
          JSON.stringify({
            action: "change-viewed-debrief-question",
            questionIndex: qIndex,
            gameId: gameIdNum,
          })
        );
        break;
      case SocketActionTypes.SELECT_FIELD:
        const selectedField = action.payload;
        const originalField = action.originalField;
        const selectFieldAction: GameActions = {
          type: GameActionTypes.ADD_FIELD_TO_SELECTED,
          payload: originalField,
        };
        store.dispatch(selectFieldAction);
        socket.send(
          JSON.stringify({
            action: "open-box",
            a1notation: selectedField,
            gameId,
          })
        );

        break;
      case SocketActionTypes.REFRESH_FINANCIALS:
        socket.send(
          JSON.stringify({ action: "share-financial-results", gameId })
        );
        break;
      case SocketActionTypes.ASSIGN_USER_TO_RESOURCE:
        const payloadResources = action.payload;
        const usersArr = payloadResources.newUsers;
        const resourceName = payloadResources.resourceName;
        if (usersArr.length) {
          const backendObj = convertUserResourceForBackend(
            usersArr,
            resourceName,
            "assign-map",
            gameId,
            teamId
          );
          socket.send(JSON.stringify(backendObj));
        }
        break;
      case SocketActionTypes.REMOVE_USER_FROM_RESOURCE:
        const payloadUserRes = action.payload;
        const newUsersArr = payloadUserRes.newUsers;
        const resourceNames = payloadUserRes.resourceName;
        if (newUsersArr.length) {
          const backendObj = convertUserResourceForBackend(
            newUsersArr,
            resourceNames,
            "retract-map",
            gameId,
            teamId
          );
          socket.send(JSON.stringify(backendObj));
        }
        break;
      case SocketActionTypes.OPEN_FULL_REPORT:
        const openedFullReport = action.payload;
        const openFullReport: GameActions = {
          type: GameActionTypes.OPEN_FULL_REPORT,
          payload: { openedFullReport },
        };
        store.dispatch(openFullReport);
        break;
      case SocketActionTypes.SET_IN_SERVICE:
        socket.send(JSON.stringify({ action: "use-in-service", gameId }));
        break;
      case SocketActionTypes.OPEN_FULL_TEAM_RULES:
        const openedFullRules = action.payload;
        const openFullRulesAction: GameActions = {
          type: GameActionTypes.OPEN_FULL_TEAM_RULES,
          payload: { openedFullRules },
        };
        store.dispatch(openFullRulesAction);
        break;
      case SocketActionTypes.SOCKET_DISCONNECTED:
        const checkIfOnline = () => {
          if (navigator.onLine) {
            Auth.currentSession()
              .then(async (data) => {
                // Fake await because of some internal websocket closing issues
                await new Promise((resolve, reject) =>
                  setTimeout(() => resolve(""), 2000)
                );
                if (socket.readyState === 3) {
                  // Makes sure the connection is CLOSED
                  const newToken = (data.getIdToken() as any).jwtToken;
                  // Reinitializes the socket server
                  store.dispatch({
                    type: SocketActionTypes.REFRESH_CONNECTION,
                    idToken: newToken,
                  });
                  clearInterval(interval);
                } else {
                  console.log(`socket status:`, socket.readyState);
                }
              })
              .catch((err) => {
                console.log(`reconnected error`);
                // Something happened and the user has no longer an active session
                if (socket) {
                  socket.close();
                }
                window.location.replace("/");
              });
          }
        };
        var interval = setInterval(checkIfOnline, 5000);

        break;
      case SocketActionTypes.SAVE_RULES:
        socket.send(
          JSON.stringify({
            action: "set-team-rules",
            rules: action.rules,
            gameId,
          })
        );
        break;
      case SocketActionTypes.SET_FACILITATOR:
        const objToSend = {
          action: "set-force-field-editor",
          editor: action.payload,
          gameId,
        };
        socket.send(JSON.stringify(objToSend));
        break;
      case SocketActionTypes.RETURN_FROM_IN_SERVICE:
        socket.send(JSON.stringify({ action: "get-stage-time", gameId }));
        break;
      case SocketActionTypes.SET_FORCES:
        const driving = action?.payload?.driving;
        const restraining = action?.payload?.restraining;
        socket.send(
          JSON.stringify({
            action: "set-force-field",
            driving,
            restraining,
            gameId,
          })
        );
        break;
      case SocketActionTypes.NEW_MESSAGE:
        socket.send(
          JSON.stringify({ command: "NEW_MESSAGE", message: action.message })
        );
        break;
      case AdminActionTypes.TRY_GET_GAMES:
        socket.send(JSON.stringify({ action: "get-all-games" }));
        return next(action);

      case AdminActionTypes.LIST_USERS:
        socket && socket.send(JSON.stringify({ action: "list-users" }));
        return next(action);

      case DialogActionTypes.OPEN_MNGMT_DIALOG_MEMO:
        const payload = action.payload;
        const openDialogMemo: DialogActions = {
          type: DialogActionTypes.OPEN_MNGMT_DIALOG,
          payload: {
            openedManagementDialogMemo: payload,
            typeOfComponent: "MNGT_DIALOG_MEMO",
            title: "MEMO",
          },
        };
        store.dispatch(openDialogMemo);
        break;
      case DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG_SOCKET:
        const openedSmallMap = action.payload;
        const openSmallMapAction: DialogActions = {
          type: DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG,
          payload: openedSmallMap,
        };
        store.dispatch(openSmallMapAction);
        break;
      case AdminActionTypes.TRY_CREATE_GAME:
        const gameCreateData: any = {
          name: action.data.name,
          startDate: "2020-09-10",
        };

        const createGameMessage = {
          action: "create-game",
          data: gameCreateData,
        };

        socket.send(JSON.stringify(createGameMessage));
        return next(action);
      case AdminActionTypes.TRY_GET_ASSIGNED_USERS:
        const getUsersForGameRequest = {
          action: "get-users-for-game",
          data: { gameId: action.gameId },
        };
        socket.send(JSON.stringify(getUsersForGameRequest));
        break;

      default:
        return next(action);
    }
  };
};

export default SocketMiddleware();
