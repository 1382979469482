import { BehaviorSubject } from "rxjs";

const participantsListVisibility$ = new BehaviorSubject(false);
const chatVisibility$ = new BehaviorSubject(false);
const hasNewMessage$ = new BehaviorSubject(false);
const showScreenShare$ = new BehaviorSubject(false);

export const GameVisibilityService = {
    participantsListVisibility$,
    chatVisibility$,
    hasNewMessage$,
    showScreenShare$
};
