import React, { useEffect, useCallback } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import useStyles from "./styles/GameNotStartedStyles";
import moment from "moment-timezone";
import { AppState } from "../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import { AdminActionTypes } from "../../admin/store/AdminActions";
import { userService } from "../../core/services/UserService";
import { UserRoleModel } from "../../core/models/UserModel";
import { SocketActionTypes } from "../../socket/SocketActions";

const GameNotStartedPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const startDateSel = (state: AppState) => state.gameState.startGameDate;
  const startDate = useSelector(startDateSel);

  let parsedDateMoment, parsedDate: string;
  let daysHoursString = "";
  const location = window.location.href;

  if (startDate) {
    parsedDateMoment = moment(startDate, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    parsedDate = moment(startDate, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      .tz(timeZone)
      .format("Do MMMM, HH:mm");
    const daysHoursLeft = moment.duration(
      parsedDateMoment.diff(moment(new Date()))
    );
    const minutesLeft = daysHoursLeft.asMinutes();
    const totalDaysLeft = Math.floor(minutesLeft / 60 / 24);
    if (totalDaysLeft > 0) {
      daysHoursString = `${totalDaysLeft} days, `;
    }
    const hoursFromDay = Math.floor((minutesLeft / 60) % 24);
    let minutes: string | number = Math.floor(minutesLeft % 60);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (hoursFromDay > 0 || (hoursFromDay === 0 && minutes > 0)) {
      daysHoursString += `${hoursFromDay}:${minutes} hours`;
    }
  }
  if (!daysHoursString) {
    daysHoursString = "0:00 hours";
  }

  // TODO: if time < 1m display '0:17 minutes until the game' instead of 'hours'

  const returnDatePassed = () => {
    if (daysHoursString !== "0:00 hours") {
      return <span>Your game hasn’t started yet.</span>;
    }
    return <span>Your game has started.</span>;
  };

  const scheduledTime = () => {
    if (daysHoursString !== "0:00 hours") {
      return <span>It’s scheduled for {parsedDate}.</span>;
    }
    return <span>It was scheduled for {parsedDate}.</span>;
  };

  const goToAdminPanelHandler = useCallback(
    (reloadState?: boolean) => {
      dispatch({
        type: AdminActionTypes.LOGIN_TO_ADMIN,
        payload: true,
      });

      dispatch({
        type: SocketActionTypes.GET_UPCOMING_TRAININGS,
        payload: true,
      });
      if (reloadState) {
        dispatch({
          type: AdminActionTypes.SET_INITIAL_FLAG,
          payload: true,
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    //on landing check if the user is an admin and redirect him to the admin section
    const isTrainer = userService.getUser()?.role === UserRoleModel.Trainer;
    if (isTrainer) goToAdminPanelHandler();
  }, [goToAdminPanelHandler]);

  return (
    <div className={classes.root}>
      <Container maxWidth={"sm"}>
        <Grid container>
          <Grid item xs={12}>
            <img
              className={classes.logoImg}
              src="/icons/logo-big.svg"
              alt="InStorm"
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.countdownStyles}>
              <p className={classes.daysLeftStyles}>{daysHoursString}</p>
              <h1 className={classes.gameNotStartedText}>
                {returnDatePassed()}
              </h1>
              <h3 className={classes.scheduledText}>{scheduledTime()}</h3>
              {userService.getUser()?.role === UserRoleModel.Trainer &&
              location.includes("http://localhost:3000/") ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => goToAdminPanelHandler(true)}
                >
                  Go to admin panel
                </Button>
              ) : (
                ""
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default GameNotStartedPage;
