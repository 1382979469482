import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  withStyles,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoadingIndicatorService } from "../../core/loadingIndicator/LoadingIndicatorService";
import { UserRoleModel } from "../../core/models/UserModel";
import { scrollService } from "../../core/services/ScrollService";
import { VideoConfig } from "../../game/store/GameReducer";

import { SocketActionTypes } from "../../socket/SocketActions";
import { AppState } from "../../store/Store";
import { opentokService } from "../../video/services/opentok.service";
import { QuestionModel } from "../finalTeamDiscussionPage/question.model";
import useStyles from "./styles/DebriefPageStyles";
import "./styles/debriefStyles.scss";

const VIDEO_CONTAINER_ID = "video-container";
const unsubscribe$ = new Subject();
let subscr1: Subscription;

const DebriefPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showNavBtns, setShowNavBtns] = useState(false);
  const selectReadyToStart = (state: AppState) => state.gameState.videoConfig;
  const videoConfig = useSelector(selectReadyToStart) as VideoConfig;
  const questionsArrData = (state: AppState) => state.gameState.questionsArr;
  const questionsArr = useSelector(questionsArrData);
  const selectedQuestionIdData = (state: AppState) =>
    state.gameState.selectedQuestionIndex;
  const selectedQuestionId = useSelector(selectedQuestionIdData);
  const teamsData = (state: AppState) => state.gameState.teamNames;
  const teamNames = useSelector(teamsData);
  const financialsAllData = (state: AppState) => state.gameState.financialsAll;
  const financialsAll = useSelector(financialsAllData);
  const userData = (state: AppState) => state.userState.userData;
  const userSelector: any = useSelector(userData);
  const [isAdmin, setAdminState] = React.useState(false);
  const pixelsPerScroll = 672;
  let questionsSorted: any[] = [];

  const openTokSession = () => {
    LoadingIndicatorService.stopLoading();
    opentokService.connectedUsers$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((_) => {
        updateNavBtnsState();
      });
    if (videoConfig.sessionId && videoConfig.apiKey) {
      opentokService.initializeOpenTok(videoConfig, VIDEO_CONTAINER_ID);
    }

    const cleanup = () => {
      LoadingIndicatorService.startLoading();
      unsubscribe$.next();
      unsubscribe$.complete();
    };

    return cleanup;
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 15,
      borderBottom: "none !important",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "rgba(245, 224, 46, 0.2)",
      },
      "& td:nth-of-type(1), & td:nth-of-type(2)": {
        width: "45px",
        whiteSpace: "nowrap",
      },
      "& .MuiTableBody-root": {
        display: "block",
      },
    },
  }))(TableRow);

  useEffect(() => {
    if (selectedQuestionId) {
      showMainTitle();
    }
    var cleanup = openTokSession();
    if (userSelector) {
      setAdminState([UserRoleModel.Trainer].indexOf(userSelector.role) >= 0);
    }

    return cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    videoConfig,
    selectedQuestionId,
    userSelector,
    questionsSorted,
  ]);

  const updateNavBtnsState = () => {
    const winWidth = window.innerWidth;
    const elemWidth =
      document.querySelector(`#${VIDEO_CONTAINER_ID}`)?.scrollWidth || 0;
    setShowNavBtns(elemWidth + 95 > winWidth);
  };
  window.addEventListener("resize", updateNavBtnsState);
  window.addEventListener("load", updateNavBtnsState);

  const handlePrevClick = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
    scrollService.scrollLeft(elem, -pixelsPerScroll, 1000);
  };

  const handleNextClick = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
    scrollService.scrollLeft(elem, pixelsPerScroll, 1000);
  };

  const openQuestion = (questionId: number) => {
    dispatch({
      type: SocketActionTypes.SET_DEBRIEF_QUESTION,
      payload: questionId,
    });
  };

  const showMainTitle = () => {
    let foundIndexQuestion = -1;
    if (questionsArr.length) {
      foundIndexQuestion = questionsArr.findIndex(
        (q: any) => q.index === selectedQuestionId
      );
    }
    if (foundIndexQuestion > -1) {
      const numberQ = questionsArr[foundIndexQuestion].index;
      const questionTitle = questionsArr[foundIndexQuestion].question;
      return (
        <p className="main-question-styles">
          {numberQ}.{questionTitle}
        </p>
      );
    }
    return <p></p>;
  };

  const returnBadges = (teamRank: number) => {
    if (teamRank === 1) {
      return (
        <img
          src="/icons/icons-badge-1.svg"
          className={classes.team1Badge}
          alt="Winner"
        />
      );
    } else if (teamRank === 2) {
      return (
        <img
          src="/icons/icons-badge-2.svg"
          className={classes.team2Badge}
          alt="Second Place"
        />
      );
    } else if (teamRank === 3) {
      return (
        <img
          src="/icons/icons-badge-3.svg"
          className={classes.team3Badge}
          alt="Third Place"
        />
      );
    }
    return <span></span>;
  };

  const checkIfFirstArrowIsShown = () => {
    if (isAdmin) {
      return (
        <div className="question-prev" hidden={selectedQuestionId === 1}>
          <button
            disabled={selectedQuestionId === 0}
            onClick={() => openQuestion(selectedQuestionId - 1)}
          ></button>
        </div>
      );
    }
    return <></>;
  };

  const checkIfNextBtnIsShown = () => {
    if (isAdmin) {
      const uniqueValuesArr = _.uniq(_.map(questionsArr, "index"));
      return (
        <div
          className="question-next"
          hidden={selectedQuestionId === uniqueValuesArr?.length}
        >
          <button
            disabled={selectedQuestionId === questionsArr?.length}
            onClick={() => openQuestion(selectedQuestionId + 1)}
          ></button>
        </div>
      );
    }
    return <></>;
  };

  const { addToast } = useToasts();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (subscr1) {
        subscr1.unsubscribe();
      }
      subscr1 = opentokService.errorsByOpenTok$.subscribe(
        (error: string | number) => {
          if (error === 1500) {
            addToast(
              `Error: Please allow access to your camera and microphone in order to be able to talk.`,
              { appearance: "error", autoDismiss: true }
            );
          } else if (error === 1004) {
            addToast(
              `Authentication Error: The token does not match the session ID.`,
              { appearance: "error", autoDismiss: true }
            );
          } else {
            if (error) {
              addToast(`Error: ${error}`, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }
        }
      );
    }
    return () => {
      if (mounted && subscr1) {
        subscr1.unsubscribe();
      }
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTable = () => {
    questionsSorted = [];
    const sortedTeamsByFinancials: any[] = _.orderBy(
      financialsAll,
      ["resFinal", "teamName"],
      ["desc", "asc"]
    );
    const filteredQuestions: any[] = questionsArr.filter(
      (item) => item.index === selectedQuestionId
    );
    filteredQuestions.forEach((teamQuestion: QuestionModel) => {
      const teamName = teamNames.filter(
        (team) => team.id === teamQuestion.teamId
      )[0]?.name;
      const foundTeamRank =
        sortedTeamsByFinancials.findIndex(
          (team: any) => team.teamId === teamQuestion.teamId
        ) + 1;
      questionsSorted.push({
        ...teamQuestion,
        teamId: teamQuestion.teamId,
        name: teamName,
        teamRank: foundTeamRank,
      });
    });

    return (
      <div className="table-container table-cont-debrief">
        <TableContainer component={Paper}>
          <Table className="table" aria-label="customized table">
            <TableBody>
              {questionsSorted.map((question: any) => {
                return (
                  <StyledTableRow
                    key={`debrief-${question.teamId}-${question.index}`}
                  >
                    <StyledTableCell scope="row">
                      <span className={classes.currentTeam}>
                        {question.teamName}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {returnBadges(question.teamRank)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {question.answer?.length
                        ? question.answer
                        : "No answer was provided!"}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <div className="App">
      <div className={`${classes.root} root-debrief`}>
        <div className="top-name">Debrief</div>
        <div className="background-share">
          {checkIfFirstArrowIsShown()}
          {showMainTitle()}
          {checkIfNextBtnIsShown()}
          {renderTable()}
        </div>
        <div className="video-container">
          <div
            className={`${classes.carouselPrev} ${showNavBtns ? "" : "hidden"}`}
          >
            <button onClick={handlePrevClick}></button>
          </div>
          <div id={`${VIDEO_CONTAINER_ID}`}></div>
          <div
            className={`${classes.carouselNext} ${showNavBtns ? "" : "hidden"}`}
          >
            <button onClick={handleNextClick}></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebriefPage;
