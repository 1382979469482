import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    register: {
        width: '100%',
        background: 'linear-gradient(to bottom, #f7e453, #ebc429)',
        border: 'solid 2px #a69400',
        borderRadius: '5px',
        '&:hover': {
            background: 'linear-gradient(to bottom, #ebc429, #f7e453)'
        },
        '& span': {
            fontSize: '19px',
            fontWeight: 'bold',
            textTransform: 'capitalize'
        }
    },
    login: {
        width: '100%',
        color: '#fff',
        marginTop: '15px',
        background: 'linear-gradient(to bottom, #53a6d5, #296daa)',
        border: 'solid 2px #296daa',
        borderRadius: '5px',
        '&:hover': {
            background: 'linear-gradient(to bottom, #296daa, #53a6d5)'
        },
        '& span': {
            fontSize: '19px',
            fontWeight: 'bold',
            textTransform: 'capitalize'
        }
    },
    link: {
        cursor: 'pointer',
        marginTop: '15px',
        textDecoration: 'underline',
        color: '#0d4171',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    mainContainer: {
        minHeight: '100%',
        maxWidth: '100% !important',
        // backgroundImage: 'linear-gradient(to bottom, rgb(0, 68, 130), rgb(25, 113, 162) 100%)'
        background: 'url(/icons/login-background.svg) no-repeat 3% bottom',
    },
    paper: {
        paddingTop: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            paddingTop: 0
          },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    textarea: {
        '& input': {
            color: '#bfbfc1',
            fontSize: '21px',
            backgroundColor: '#fff !important',
            borderRadius: '5px',
        },
        '& fieldset': {
            borderRadius: '5px',
            border: '2px solid #aeaeb0 !important'
        }
    },
    hr: {
        width: '150%',
        border: '1px solid #fff',
        backgroundColor: '#fff',
        height: '2px',
        margin: '30px 0 25px -25%',
        opacity: '0.5'
    },
    error: {
        textAlign: 'center',
        color: 'red',
        fontSize: '18px',
        fontWeight: 'bold'
    },
    success: {
        textAlign: 'center',
        color: 'green',
        fontSize: '18px',
    }
}));

export default useStyles;