import React, { useEffect } from 'react';
import { AppState } from '../../store/Store';
import { useSelector } from 'react-redux';
import Login from './Login';
import Signup from './Signup';
import Confirm from './Confirm';
import { LoadingIndicatorService } from '../../core/loadingIndicator/LoadingIndicatorService';

const LoginSignup = () => {
    const selectAuthIntent = (state: AppState) => state.userState.authIntent;
 
    const authIntent = useSelector(selectAuthIntent);

    useEffect(() => {
        LoadingIndicatorService.stopLoading();
      }, [])

    return (<>
        {authIntent === 'login' ? <Login /> : null}
        {authIntent === 'signup' ? <Signup /> : null}
        {authIntent === 'confirm' ? <Confirm /> : null}
    </>)

}

export default LoginSignup