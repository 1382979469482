import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../store/Store";
import FinancialReport from "../../../financialReport/FinancialReport";
import TeamRules from "../../../teamRules/TeamRules";
import useStyles from "./styles/forceFieldstyles";
import clsx from "clsx";
import { UserRoleModel } from "../../../../../core/models/UserModel";
import VideoComponent from "../../../../../video/components/VideoComponent";
import CountDown from "../../../countDown/CountDown";
import { RouteComponentProps, withRouter } from "react-router";
import {
  SocketActions,
  SocketActionTypes,
} from "../../../../../socket/SocketActions";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { LoadingIndicatorService } from "../../../../../core/loadingIndicator/LoadingIndicatorService";
import "./styles/ffaStyles.scss";

const changedTimes = [0, 0];
const ForceFieldDialog = (props: RouteComponentProps) => {
  const classes = useStyles();
  const [facilitatorId, setfacilitatorId] = React.useState("-1");
  const [drivingForces, setDrivingForces] = React.useState("");
  const [restrainingForces, setRestrainingForces] = React.useState("");
  const dispatch = useDispatch();
  const modelChanged1: Subject<string> = new Subject<string>();
  const modelChanged2: Subject<string> = new Subject<string>();
  const firstButton = `${classes.mainButtons} ${classes.drivingForcesButton}`;
  const secButton = `${classes.mainButtons} ${classes.restrainingForcesButton}`;
  const usersData = (state: AppState) => state.gameState.users;
  const usersDataSelector = useSelector(usersData);
  const userData = (state: AppState) => state.userState.userData;
  const userSelector: any = useSelector(userData);
  const [isAdmin, setAdminState] = React.useState(false);
  const facilitatorData = (state: AppState) => state.gameState.facilitatorId;
  const facilitatorSelector = useSelector(facilitatorData);
  const drivingForcesData = (state: AppState) => state.gameState.drivingForces;
  const drivingForcesSelector = useSelector(drivingForcesData);
  const restrainingForcesData = (state: AppState) =>
    state.gameState.restrainingForces;
  const restrainingForcesSelector = useSelector(restrainingForcesData);

  const placeholder1 = "Enter driving forces for the team";
  const placeholder2 = "Enter restraining forces for the team";
  const selectTeamName = (state: AppState) => state.gameState.teamName;
  const teamNameSel = useSelector(selectTeamName);
  const fixedContentHeight = clsx(classes.paper, "fixed-main-height-ffa");

  const sendForces = (forcesArg: number, changeValue: string) => {
    const forces1 = forcesArg === 1 ? changeValue : drivingForces;
    const forces2 = forcesArg === 1 ? restrainingForces : changeValue;
    const setForcesAction: SocketActions = {
      type: SocketActionTypes.SET_FORCES,
      payload: { driving: forces1, restraining: forces2 },
    };
    dispatch(setForcesAction);
  };

  modelChanged1
    .pipe(filter(Boolean), debounceTime(1000), distinctUntilChanged())
    .subscribe((modelUpdated1: any) => {
      changedTimes[0]++;
      if (changedTimes[0] === 5) {
        sendForces(1, modelUpdated1);
        changedTimes[0] = 0;
      }
    });

  modelChanged2
    .pipe(filter(Boolean), debounceTime(1000), distinctUntilChanged())
    .subscribe((modelUpdated2: any) => {
      changedTimes[1]++;
      if (changedTimes[1] === 5) {
        sendForces(2, modelUpdated2);
        changedTimes[1] = 0;
      }
    });

  useEffect(() => {
    LoadingIndicatorService.stopLoading();
    if (userSelector) {
      const adminsArr = [UserRoleModel.Trainer, UserRoleModel.RM];
      const userRole = userSelector.role;
      const isAdminTrue = adminsArr.indexOf(userRole) >= 0;
      setAdminState(isAdminTrue);
    }
    if (facilitatorSelector !== undefined) {
      setfacilitatorId(facilitatorSelector);
    }
    if (drivingForcesSelector !== undefined) {
      if (drivingForcesSelector !== drivingForces) {
        setDrivingForces(drivingForcesSelector);
      }
    }
    if (restrainingForcesSelector !== undefined) {
      if (restrainingForcesSelector !== restrainingForces) {
        setRestrainingForces(restrainingForcesSelector);
      }
    }
  }, [
    //drivingForces,
    //restrainingForces, <<<---- Exhaustive deps violated! TODO: Make a task to eliminate all warnings
    userSelector,
    facilitatorSelector,
    drivingForcesSelector,
    restrainingForcesSelector,
  ]);

  const handleChangeFacilitator = (event: any) => {
    const userId = event.target.value;
    if (userId !== "-1") {
      setfacilitatorId(
        usersDataSelector.filter((user) => user.id === userId)[0]?.id
      );
      const setFacilitatorAction: SocketActions = {
        type: SocketActionTypes.SET_FACILITATOR,
        payload: userId,
      };

      dispatch(setFacilitatorAction);
    } else {
      setfacilitatorId("-1");
    }
  };

  const formControlLabel = `${classes.formControl} ${classes.formControlLabel}`;

  const facilitatorDropdown = () => {
    if (isAdmin) {
      return (
        <div className={`${classes.facilitatorDiv} facilitator-div`}>
          <span className={`facilitator-span`}>Choose Facilitator: </span>
          <FormControl className={classes.formControl}>
            <Select
              labelId="facilitator-id"
              id="facilitator-select"
              value={facilitatorId}
              onChange={handleChangeFacilitator}
            >
              {returnUsers()}
            </Select>
          </FormControl>
        </div>
      );
    } else {
      let facilitatorName;
      if (facilitatorId !== "-1") {
        facilitatorName = usersDataSelector.filter(
          (user) => user.id === facilitatorId
        )[0].name;
      }
      return (
        <div className={`${classes.facilitatorDiv} facilitator-div user`}>
          Facilitator:
          <FormControl className={`${formControlLabel} label`}>
            <InputLabel id="facilator-dropdown">{facilitatorName}</InputLabel>
          </FormControl>
        </div>
      );
    }
  };

  const showMainTitle = () => {
    const location: any = props.location;
    let pageTitle = "";
    if (!location.state?.mapDisabled) {
      pageTitle = "Force Field Analisys";
    }
    return <h1 className={`page-title`}>{pageTitle}</h1>;
  };

  const returnUsers = () => {
    let menuItemsArr = [
      <MenuItem key={-1} value={"-1"}>
        Select who edits the fields
      </MenuItem>,
    ];
    for (let user of usersDataSelector) {
      if (user.id === userSelector.id && !user?.name?.includes("(Me)")) {
        user.name = user.name + " (Me)";
      }
      menuItemsArr.push(
        <MenuItem key={user?.id} value={user?.id}>
          {user?.name}
        </MenuItem>
      );
    }
    return menuItemsArr;
  };

  const showTeamInfo = () => {
    let teamName: string | undefined;
    return (
      teamName ?? (
        <div className={"bottom-div1"}>
          <p className={`team-name`}>You are in team {teamNameSel}</p>
        </div>
      )
    );
  };

  const setForces = (event: any, forces: number) => {
    const textTyped = event.target.value;
    if (forces === 1) {
      // setDrivingForces(textTyped);
      sendForces(1, textTyped);
    } else {
      // setRestrainingForces(textTyped);
      sendForces(2, textTyped);
    }
  };

  const returnTextarea = (forces: number) => {
    const userIsFacilitator = facilitatorId
      ? userSelector.id === Number(facilitatorId)
      : undefined;
    const userCanWrite = isAdmin || userIsFacilitator;
    return (
      <div className={classes.mainDiv}>
        <TextareaAutosize
          className={classes.textarea}
          placeholder={forces === 1 ? placeholder1 : placeholder2}
          value={forces === 1 ? drivingForces : restrainingForces}
          disabled={!userCanWrite}
          onChange={(event) => {
            const textTyped = event.target.value;
            if (forces === 1) {
              setDrivingForces(textTyped);
            } else {
              setRestrainingForces(textTyped);
            }
          }}
          onBlur={(event) => {
            setForces(event, forces);
          }}
        ></TextareaAutosize>
      </div>
    );
  };

  const examples2 = `${classes.exampleClasses} ${classes.examples2}`;

  return (
    <div className="App">
      <VideoComponent></VideoComponent>
      <CountDown></CountDown>
      <div className="force-field-analysis-row">
        <div className="left">
          {showMainTitle()}
          {facilitatorDropdown()}
          {showTeamInfo()}
          <FinancialReport></FinancialReport>
          <TeamRules></TeamRules>
        </div>
        <div className="right">
          <div className={classes.root}>
            <main className={classes.content}>
              <Container className={classes.container} disableGutters={true}>
                <Grid container>
                  <Paper className={`${fixedContentHeight}`} elevation={0}>
                    <div className={classes.gameTitle}></div>
                    <div className="main-dialog-ffa">
                      <div className={classes.mainGrid}>
                        <p className={firstButton}>Driving Forces</p>
                        <p className={secButton}>Restraining Forces</p>
                        {returnTextarea(1)}
                        {returnTextarea(2)}
                        <p className={classes.exampleClasses}>
                          <span className={classes.examples}>Examples:</span>
                          Motivation to complete task; Excellent People
                          resources; Appropriate work process; High energy
                          levels.
                        </p>
                        <p className={examples2}>
                          <span className={classes.examples}>Examples:</span>
                          Decision Making system breaking down; Plans too
                          complicated; People not listening to each other;
                          leadership too loose.
                        </p>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Container>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForceFieldDialog);
