import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { AppState } from '../../../../store/Store';
import { useDispatch, useSelector } from 'react-redux';
import './styles/Chat.scss';
import { GameVisibilityService } from '../../../../game/services/GameVisibilityService';
import { takeUntil } from 'rxjs/operators';
import { Button, TextareaAutosize } from '@material-ui/core';
import { SocketActions, SocketActionTypes } from '../../../../socket/SocketActions';
import moment from 'moment';

const unsubscribe$ = new Subject();

const Chat = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    let messagesEnd: any;

    const messagesData = (state: AppState) => state.chatState.messages;
    const messagesSelector = useSelector(messagesData);
    const dispatch = useDispatch();

    useEffect(() => {
        GameVisibilityService.chatVisibility$
            .pipe(takeUntil(unsubscribe$))
            .subscribe((isVisible: boolean) => {
                setIsVisible(isVisible);

                if (isVisible && messagesEnd) {
                    messagesEnd.scrollIntoView({ behavior: "smooth" });
                }
            });

        return () => {
            unsubscribe$.next();
            unsubscribe$.complete();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesSelector])

    const handleNewMessage = (event: any) => {
        setMessage(event.target.value);
    }

    const sendMessage = () => {
        setMessage('');
        const addChatMessage: SocketActions = {
            type: SocketActionTypes.SEND_MESSAGE,
            payload: message
        };
        dispatch(addChatMessage);
    }

    return (<div className={`chat-container${isVisible ? ' chat-visible' : ''}`}>
            <div>Chat</div>
            <ul>
                {!messagesSelector.length
                ? <li>No messages</li>
                : messagesSelector.sort((a: any, b: any) => (moment(a.timestamp) as any) - (moment(b.timestamp) as any) ).map((message, index) => {
                    return <li key={`message-${index}`}>
                        <div className="message-container">
                            <p className="username">{message.username}:</p> <p className="text-msg">{message.message}</p>
                        </div>
                        <div className="message-timestamp">
                            <span>{moment(message.timestamp).format('hh:mm')}</span>
                        </div>
                    </li>;
                })}
                <li ref={(el) => { messagesEnd = el; }}></li>
            </ul>
            <div className="chat-footer">
                <TextareaAutosize value={message} onChange={handleNewMessage} />
                <Button className={`sent-message-btn btn-secondary`} onClick={sendMessage}>Send Message</Button>
            </div>
        </div>);
}

export default Chat;