import { Reducer } from "redux";
import { AdminActions, AdminActionTypes } from "./AdminActions";
import { TrainingModel } from "../models/AdminTrainingsModel";
import { AvailableUsers } from "../models/AvailableUsersModel";
import { CreateUsersResponse } from "../models/AdminCreateUsersModel";

export interface AdminState {
  adminPanelActive: boolean;
  isAfterLogin: boolean;
  selectedTrainingId: number | undefined;
  trainings: TrainingModel[];
  pastTrainings: TrainingModel[];
  currentTrainings: TrainingModel[];
  availableUsers: AvailableUsers[];
  addUsersResponse: CreateUsersResponse;
}

const initialAdminState: AdminState = {
  adminPanelActive: false,
  isAfterLogin: true,
  selectedTrainingId: undefined,
  trainings: [],
  pastTrainings: [],
  currentTrainings: [],
  availableUsers: [],
  addUsersResponse: { errors: null, createdUsers: null },
};

export const adminReducer: Reducer<AdminState, AdminActions> = (
  state = initialAdminState,
  action
) => {
  switch (action.type) {
    case AdminActionTypes.LOGIN_TO_ADMIN: {
      return {
        ...state,
        adminPanelActive: action.payload,
      };
    }
    case AdminActionTypes.SET_INITIAL_FLAG: {
      return {
        ...state,
        isAfterLogin: action.payload,
      };
    }
    case AdminActionTypes.GET_UPCOMING_TRAININGS: {
      console.log("in the reducer for GET_UPCOMING_TRAININGS", action.payload);

      return {
        ...state,
        trainings: [...action.payload],
      };
    }
    case AdminActionTypes.GET_PREVIOUS_TRAININGS: {
      return {
        ...state,
        pastTrainings: [...action.payload],
      };
    }

    case AdminActionTypes.GET_CURRENT_TRAININGS: {
      return {
        ...state,
        currentTrainings: [...action.payload],
      };
    }
    case AdminActionTypes.SELECT_TRAINING: {
      return {
        ...state,
        selectedTrainingId: action.payload,
      };
    }
    case AdminActionTypes.SET_AVAILABLE_USERS: {
      return {
        ...state,
        availableUsers: [...action.payload],
      };
    }
    case AdminActionTypes.SET_BULK_CREATE_RESPONSE: {
      return {
        ...state,
        addUsersResponse: { ...action.payload },
      };
    }
    default: {
      return state;
    }
  }
};
