import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingIndicatorService } from '../../core/loadingIndicator/LoadingIndicatorService';
import { VideoConfig } from '../../game/store/GameReducer';
import { AppState } from '../../store/Store';
import { opentokService } from '../../video/services/opentok.service';
import useStyles from './styles/LobbyPageStyles';
import { Subject } from 'rxjs';

const unsubscribe$ = new Subject();

const LobbyPage = () => {
    const classes = useStyles();
    const selectReadyToStart = (state: AppState) => state.gameState.videoConfig;
    const videoConfig = useSelector(selectReadyToStart) as VideoConfig;

    if (videoConfig.apiKey && videoConfig.sessionId) {
        opentokService.initializeOpenTok(videoConfig, 'video-container');
    }

    useEffect(() => {
        LoadingIndicatorService.stopLoading();

        // returned function will be called on component unmount/destroy
        return () => {
            unsubscribe$.next();
            unsubscribe$.complete();
            LoadingIndicatorService.startLoading();
        }
    }, [])

    return (<div className={classes.root} id="publisher">
        <div className={classes.container} id="video-container"></div>
    </div>);
}

export default LobbyPage;