import { makeStyles } from "@material-ui/core";

const 
useStyles = makeStyles((theme) => ({
    container: {
        padding: '10px 26px',
        width: '278px',
        lineHeight: '15px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        boxSizing: 'border-box',
        color: '#dee1e3',
        minHeight: '46px',
        background: '#000',
        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 40%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 9999,
    },
    containerBigger: {
        width: '280px',
    },
    text: {
        fontSize: '13px',
        marginRight: '25px'
    },
    counter: {
        fontSize: '23px',
        fontWeight: 'bold',
        color: '#ebc429'
    },
    counterRed: {
        fontFamily:' Roboto',
        fontSize: '21px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        textAlign: 'right',
        color: 'rgb(255, 120, 136)',
    }
}));

export default useStyles;