import React, { useEffect, useState } from 'react';
import { opentokService } from '../services/opentok.service';
import useStyles from '../styles/VideoStyles';
import { useSelector } from "react-redux";
import { AppState } from '../../store/Store';
import { VideoConfig } from '../../game/store/GameReducer';
import { withRouter, RouteComponentProps } from 'react-router';
import { scrollService } from '../../core/services/ScrollService';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const VideoComponent = (props: RouteComponentProps) => {
    const unsubscribe$ = new Subject();
    const classes = useStyles();
    const VIDEO_CONTAINER_ID = 'video-container';
    const pixelsPerScroll = 672;
    const [showNavBtns, setShowNavBtns] = useState(false);
    const selectReadyToStart = (state: AppState) => state.gameState.videoConfig;
    const videoConfig = useSelector(selectReadyToStart) as VideoConfig;

    useEffect(() => {
        if (videoConfig.apiKey && videoConfig.sessionId) {
            opentokService.initializeOpenTok(videoConfig, 'video-container');
        }
        opentokService.connectedUsers$
            .pipe(takeUntil(unsubscribe$))
            .subscribe(_ => {
                updateNavBtnsState();
            });
            
        return () => {
            unsubscribe$.next();
            unsubscribe$.complete();
        }
    }, [unsubscribe$, videoConfig]);

    const updateNavBtnsState = () => {
        const winWidth = window.innerWidth;
        const elemWidth = document.querySelector(`#${VIDEO_CONTAINER_ID}`)?.scrollWidth || 0;
        setShowNavBtns(elemWidth + 95> winWidth);
    }
    window.addEventListener('resize', updateNavBtnsState);
    window.addEventListener('load', updateNavBtnsState);

    const handlePrevClick = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
        scrollService.scrollLeft(elem, -pixelsPerScroll, 1000 );
    };
    
    const handleNextClick = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
        scrollService.scrollLeft(elem, pixelsPerScroll, 1000 );
    };

    return (<div className={`${classes.videoContainer}`}>
        <div className={`${classes.carouselPrev} ${showNavBtns ? '' : 'hidden'}`}>
            <button onClick={handlePrevClick}></button>
        </div>
        <div id={`${VIDEO_CONTAINER_ID}`}></div>
        <div className={`${classes.carouselNext} ${showNavBtns ? '' : 'hidden'}`}>
            <button onClick={handleNextClick}></button>
        </div>
    </div>);
}

export default withRouter(VideoComponent);