import React from 'react';
import { useSelector } from "react-redux";
import { AppState } from '../../../store/Store';
import './styles/TeamNameStyles.scss';

const TeamName = (props?: any) => {
    const selectTeamName = (state: AppState) => state.gameState.teamName;
    const teamNameSel = useSelector(selectTeamName);
    let container = 'team-name-container';
    if (props.inServicePage) {
        container += ' in-service-cont1';
    }
    const teamElem = !teamNameSel ? <></> : <div className={container}>
        <p>You are in team {teamNameSel}</p>
    </div>;

    return (teamElem);
}

export default TeamName;
