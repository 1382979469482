import { Button, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DialogActionTypes } from '../../store/DialogActions';
import useStyles from './styles/LeaveMeetingDialogStyles';

const LeaveMeetingDialog = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        dispatch({
            type: DialogActionTypes.LEAVE_MEETING,
            payload: {
                open: false
            }
        });
    }

    const handleLeave = () => {
        window.location.reload();
    }

    return (<Container>
            <Grid container className={classes.root}>
                <Grid item className={classes.header}>
                    <p>Are you sure you want to leave the training ?</p>
                </Grid>
                <Grid item className={classes.buttons}>
                    <Button className={`cancel`} onClick={handleClose}>Nope, I'll stay</Button>
                    <Button className={`confirm`} onClick={handleLeave}>Leave meeting</Button>
                </Grid>
            </Grid>
    </Container>);
}

export default LeaveMeetingDialog;
