import { GameStages } from "../../game/store/GameReducer";
import { Store } from "redux";
import { opentokService } from "../../video/services/opentok.service";
import { GameActionTypes } from "../../game/store/GameActions";
import { DialogActionTypes } from "../../shared/components/dialogComponent/store/DialogActions";
import { delay, first } from "rxjs/operators";

const goToStage = (
  store: Store,
  payload: any,
  stageType: GameStages,
  destroyConfigFromStore = true
) => {
  switch (stageType) {
    case GameStages.PRESENTATION:
      sendOpenTokConfig(store, payload);

      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          dispatchNextStage(store, GameActionTypes.PRESENTATION);
        });

      commonEndStageActions(store);
      break;

    default:
      break;
  }
};

const dispatchNextStage = (store: Store, actionType: GameActionTypes) => {
  store.dispatch({
    type: actionType,
  });
};

const sendOpenTokConfig = (store: Store, payload: any) => {
  store.dispatch({
    type: GameActionTypes.SAVE_OPENTOK_CONFIG,
    payload: {
      apiKey: payload.av.apiKey,
      sessionId: payload.av.session,
      token: payload.av.token,
    },
  });
};

const commonEndStageActions = (store: Store) => {
  opentokService.endSession();
  store.dispatch({
    type: DialogActionTypes.TEXT_DIALOG,
    payload: { open: false },
  });
};

export const stageManagement = { goToStage };
