import React from 'react';
import useStyles from './styles/GridMapStyles';
import { Button, Grid } from '@material-ui/core';
import GeneralMap from '../../../gameMap/GeneralMap';
import { AppState } from '../../../../../store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActionTypes } from '../../store/DialogActions';
import '../../../../../game/components/styles/gameContainerStyles.scss';
import './styles/gridMapStyles.scss';

const GridMapDialog = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectGameNumbers = (state: AppState) => state.gameState.selectedFields;
    const gameNumbers = useSelector(selectGameNumbers);

    const closeGrid = () => {
        dispatch({
            type: DialogActionTypes.HIDE_ALL
        });
    }

    return (<div>
        <Grid container spacing={3} className={classes.contContainer}>
            <Grid item xs={12} className={classes.grid}>
            </Grid>
            <Grid item xs={12} className={classes.teamGrid}>
                <div className={classes.gameDiv}>
                    <GeneralMap numbers={gameNumbers}></GeneralMap>
                </div>
                <Button className={classes.buttonClose} onClick={closeGrid}>Close</Button>
            </Grid>
        </Grid>
    </div>)
}

export default GridMapDialog;