import { Box, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";
import { opentokService } from "../../../video/services/opentok.service";
import { first } from "rxjs/operators";
import { DialogActionTypes } from "../dialogComponent/store/DialogActions";
import { useDispatch, useSelector, useStore } from "react-redux";
import { GameVisibilityService } from "../../../game/services/GameVisibilityService";
import { AppState } from "../../../store/Store";
import { GameActionTypes } from "../../../game/store/GameActions";
import "./styles/VideoControls.scss";
import SVG from "react-inlinesvg";
import { UserModel, UserRoleModel } from "../../../core/models/UserModel";
import {
  SocketActions,
  SocketActionTypes,
} from "../../../socket/SocketActions";
import AdminRooms from "../adminRooms/AdminRooms";
import { useToasts } from "react-toast-notifications";
import { isSidebarVisible } from "../../../core/services/SidebarService";
import { GameStages } from "../../../game/store/GameReducer";
import { AdminActionTypes } from "../../../admin/store/AdminActions";

type Controls = {
  showCallTrainer?: boolean;
  showLeave?: boolean;
  showShareBtn?: boolean;
};

let isTrainer: boolean;
let audioDevices: any = [];

const VideoControls = (props: Controls) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    audioOptionsOpen: false,
    sharing: false,
    participantsVisibility: false,
    chatVisibility: false,
    callTrainer: true,
    audioDevicesOpen: false,
    audioDevices: [],
    currentActiveDevice: undefined,
  });

  const { addToast } = useToasts();
  const store = useStore();
  const [micMutedState, setMicMutedState] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [connectedUsersState, setConnectedUsersState] = useState(0);
  const [micMuteAll, setMicMuteAll] = useState(false);
  const [videoState, setVideoState] = useState(false);

  const selectOpenFullControls = (s: AppState) =>
    s.gameState.openedFullControls;

  const openFullControlsSelector = useSelector(selectOpenFullControls);

  const userData = (s: AppState) => s.userState.userData;

  const userDataSelector: UserModel | any = useSelector(userData);

  const confirmButtonSelected = (s: AppState) => s.dialogState.confirmedText;
  const confirmButton = useSelector(confirmButtonSelected);
  const currentStageData = (s: AppState) => s.gameState.stage;
  const currentStageSelector = useSelector(currentStageData);
  const pausedGameData = (s: AppState) => s.dialogState.openedGamePausedDialog;
  const pausedGameSelector = useSelector(pausedGameData);

  switch (confirmButton) {
    case "Reset_Stage_Dialog":
      store.dispatch({
        type: SocketActionTypes.RESET_STAGE,
        payload: true,
      });
      store.dispatch({
        type: DialogActionTypes.SET_CONFIRM_BUTTON,
        payload: "",
      });
      addToast(`Successfully reset the game!`, {
        appearance: "success",
        autoDismiss: true,
      });
      break;
    case "End_game":
      dispatch({
        type: SocketActionTypes.NEXT_STAGE,
        stage: 19,
      });
      store.dispatch({
        type: DialogActionTypes.SET_CONFIRM_BUTTON,
        payload: "",
      });
      addToast(`Successfully ended the game!`, {
        appearance: "success",
        autoDismiss: true,
      });
      break;
    default:
      break;
  }

  const handleShowParticipants = () => {
    // Need to close the chat sidebar and open the participants
    setState({
      ...state,
      chatVisibility: false,
      participantsVisibility: !state.participantsVisibility,
    });
    GameVisibilityService.chatVisibility$.next(false);
    GameVisibilityService.participantsListVisibility$.next(
      !state.participantsVisibility
    );
  };

  const handleAudioDevicesVisibility = () => {
    // Audio devices are shown here
    opentokService.getAudioDevices();
    opentokService.audioDevices$.pipe(first()).subscribe((data: any) => {
      if (data) {
        audioDevices = data;
        setState({
          ...state,
          audioDevicesOpen: !state.audioDevicesOpen,
          audioDevices: data,
        });
      }
    });
  };

  const switchAudioDevice = (device?: any) => {
    opentokService.setAudioSource(device.deviceId);
    setState({ ...state, currentActiveDevice: device.deviceId });
  };

  const getAudioClass = (deviceId: string) => {
    if (deviceId === state.currentActiveDevice) {
      return "audio-dev active-device";
    }
    return "audio-dev";
  };

  const devicesMap = () => {
    const arrMap: any = [];
    if (audioDevices.length) {
      audioDevices.forEach((device: any) => {
        arrMap.push(
          <p
            key={device.deviceId}
            className={getAudioClass(device.deviceId)}
            onClick={() => {
              switchAudioDevice(device);
            }}
          >
            {device.label}
          </p>
        );
      });
      if (!state.currentActiveDevice) {
        setState({ ...state, currentActiveDevice: audioDevices[0].deviceId });
      }
    }
    return arrMap;
  };

  const handleChatVisibility = () => {
    // Need to close the participants sidebar and open the chat
    setState({
      ...state,
      participantsVisibility: false,
      chatVisibility: !state.chatVisibility,
    });
    GameVisibilityService.participantsListVisibility$.next(false);

    const isChatVisible = !state.chatVisibility;
    GameVisibilityService.chatVisibility$.next(isChatVisible);

    if (isChatVisible) {
      GameVisibilityService.hasNewMessage$.next(false);
    }
  };

  const handleToggleMic = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    opentokService.toggleAudio(!micMutedState);
  };

  const handleToggleMicAll = () => {
    dispatch({
      type: SocketActionTypes.MUTE_ALL,
    });
    setMicMuteAll(!micMuteAll);
  };

  const handleToggleVideo = () => {
    opentokService.toggleVideo(!videoState);
  };

  const handleShareScreen = () => {
    if (currentStageSelector === GameStages.PRESENTATION) {
      if (state.sharing) {
        setState({
          ...state,
          sharing: false,
        });
        opentokService.stopShareScreen();
      } else {
        setState({
          ...state,
          sharing: true,
        });
        opentokService.shareScreen();
      }
    } else {
      // Need to open a dialog where the sharing will happen
      if (state.sharing) {
        setState({
          ...state,
          sharing: false,
        });
        dispatch({
          type: DialogActionTypes.SHARE_SCREEN,
          payload: {
            open: false,
          },
        });
      } else {
        setState({
          ...state,
          sharing: true,
        });
        dispatch({
          type: DialogActionTypes.SHARE_SCREEN,
          payload: {
            open: true,
          },
        });
      }
    }
  };

  const handleOnLeave = () => {
    dispatch({
      type: DialogActionTypes.LEAVE_MEETING,
      payload: {
        open: true,
      },
    });
  };

  const handleOnOpenChevron = () => {
    dispatch({
      type: GameActionTypes.OPEN_RIGHT_CHEVRON,
      payload: !openFullControlsSelector,
    });
  };

  const handleOnStageSelect = () => {
    store.dispatch({
      type: DialogActionTypes.SELECT_GAME_STAGE_DIALOG,
      payload: true,
    });
  };

  const handleOnAssignRM = () => {
    store.dispatch({
      type: DialogActionTypes.OPEN_ASSIGN_RMS_DIALOG,
      payload: true,
    });
  };

  const handleOnAssignPlayer = () => {
    store.dispatch({
      type: DialogActionTypes.OPEN_ASSIGN_PLAYERS_DIALOG,
      payload: true,
    });
  };

  const renderIcon = (src: string, w?: number, h?: number) => {
    const width = w ? w : 20;
    const height = h ? h : 20;
    return (
      <span className="icon">
        <SVG width={`${width}`} height={`${height}`} src={src}></SVG>
      </span>
    );
  };

  const showLeaveGrid = () => {
    return props.showLeave === false || isTrainer ? null : (
      <Grid item className={`additional-text leave-container`}>
        <button onClick={handleOnLeave} className={`button-clear btn-primary`}>
          {renderIcon("/icons/shut-down.svg")}
          <span className={`long-text`}>Leave Meeting</span>
        </button>
      </Grid>
    );
  };

  const handleOnShareResults = () => {
    const refreshFinancials: SocketActions = {
      type: SocketActionTypes.REFRESH_FINANCIALS,
    };
    dispatch(refreshFinancials);
    addToast(`Successfully refreshed the financials for all teams!`, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handleAddPlayer = () => {
    store.dispatch({
      type: DialogActionTypes.OPEN_CREATE_PLAYER_DIALOG,
      payload: true,
    });
  };

  const handleOnEnd = () => {
    store.dispatch({
      type: DialogActionTypes.CONFIRM_GENERIC_DIALOG,
      payload: {
        open: true,
        questionText: "Are you sure you want to end the game?",
        confirmButtonSelectedFor: "End_game",
      },
    });
  };

  const checkIfPauseOrResumeIsShown = () => {
    if (!pausedGameSelector) {
      return (
        <button
          onClick={handleOnPause}
          className={`button-clear btn-primary pause-button`}
        >
          {renderIcon("/icons/admin/pause.svg")}
          <span className={`long-text`}>Pause</span>
        </button>
      );
    } else {
      return (
        <button
          onClick={handleOnPause}
          className={`button-clear btn-primary pause-button resume-button`}
        >
          {renderIcon("/icons/admin/play.svg")}
          <span className={`long-text lng-text1`}>Resume</span>
        </button>
      );
    }
  };

  const handleOnPause = () => {
    if (!pausedGameSelector) {
      dispatch({
        type: SocketActionTypes.PAUSE_GAME_SOCKET,
        payload: true,
      });
    } else {
      dispatch({
        type: SocketActionTypes.RESUME_GAME_SOCKET,
        payload: true,
      });
    }
  };

  const handleOnSettings = () => {
    dispatch({
      type: DialogActionTypes.SETTINGS_DIALOG,
      payload: {
        open: true,
      },
    });
  };

  const handleOnAdminClick = useCallback(() => {
    dispatch({
      type: AdminActionTypes.LOGIN_TO_ADMIN,
      payload: true,
    });
    dispatch({
      type: AdminActionTypes.SET_INITIAL_FLAG,
      payload: true,
    });
    dispatch({
      type: SocketActionTypes.GET_UPCOMING_TRAININGS,
      payload: true,
    });
  }, []);

  const handleOnNextStage = () => {
    dispatch({
      type: SocketActionTypes.END_STAGE,
    });
  };

  const handleCallTrainer = () => {
    dispatch({
      type: SocketActionTypes.CALL_TRAINER,
      payload: state.callTrainer,
    });
    setState({ ...state, callTrainer: !state.callTrainer });
  };

  useEffect(() => {
    let isMicMutedSub = opentokService.isMicMuted$.subscribe((isMutted) => {
      const changedMuteState = isMutted;
      setMicMutedState(changedMuteState);
    });

    let isVideoDisabledSub = opentokService.isVideoDisabled$.subscribe(
      (isDisabled) => {
        const changedState = isDisabled;
        setVideoState(changedState);
      }
    );

    let connectedUsersSub = opentokService.connectedUsers$.subscribe(
      (data: number) => {
        setConnectedUsersState(+data);
      }
    );

    let newMessage = GameVisibilityService.hasNewMessage$.subscribe(
      (hasNewMsg: boolean) => {
        setHasNewMessage(!state.chatVisibility && hasNewMsg);
      }
    );

    return () => {
      isMicMutedSub.unsubscribe();
      isVideoDisabledSub.unsubscribe();
      connectedUsersSub.unsubscribe();
      newMessage.unsubscribe();
    };
  }, [state]);

  useEffect(() => {
    if (userDataSelector) {
      isTrainer = userDataSelector.role === UserRoleModel.Trainer;
    }
  }, [userDataSelector]);

  const getVClassName = () => {
    return `video-controls-container ${
      isTrainer ? "video-controls-container-admin" : ""
    } ${openFullControlsSelector ? "opened" : ""}`;
  };

  const renderAdminRooms = () => {
    return isTrainer ? <AdminRooms /> : null;
  };

  const showTrainerGrid = () => {
    const muteall = micMuteAll ? "muted" : "";
    const svgSrc = micMuteAll
      ? "/icons/mic-disabled-all.svg"
      : "/icons/mic-enabled-all.svg";

    return !isTrainer ? null : (
      <Grid item className={`btns-group`}>
        <Grid item className={`additional-text`} onClick={handleToggleMicAll}>
          <button className={`mic-button-all button-clear`}>
            <span className={muteall}>{renderIcon(svgSrc, 25, 25)}</span>
          </button>
          <span className={`short-text`}>All</span>
          <span className={`long-text`}>Mute All Participants</span>
        </Grid>
      </Grid>
    );
  };

  const displayAudioDevicesOpen = () => {
    return state.audioDevicesOpen ? (
      <Grid item className="grid-audio-devices">
        {devicesMap()}
      </Grid>
    ) : null;
  };

  const displayMuteGrid = () => {
    const svgSrc = micMutedState
      ? "/icons/mic-disabled.svg"
      : "/icons/mic-enabled.svg";

    return (
      <Grid item className={`additional-text`} onClick={handleToggleMic}>
        <button className={`mic-button button-clear`}>
          <span className={`${micMutedState ? "muted" : ""}`}>
            {renderIcon(svgSrc, 25, 25)}
          </span>
        </button>
        <span className={`long-text`}>
          {!micMutedState ? "Mute Yourself" : "Unmute Yourself"}
        </span>
      </Grid>
    );
  };

  const displayVideoGrid = () => {
    const svgSrc = videoState
      ? "/icons/video-disabled.svg"
      : "/icons/video-enabled.svg";

    return (
      <Grid item className={`additional-text`} onClick={handleToggleVideo}>
        <button className={`video-button button-clear`}>
          <span className={`${videoState ? "no-video" : ""}`}>
            {renderIcon(svgSrc, 25, 25)}
          </span>
        </button>
        <span className={`long-text`}>
          {!videoState ? "Stop Video" : "Start Video"}
        </span>
      </Grid>
    );
  };

  const displayChatGrid = () => {
    return (
      <Grid
        item
        className={`chat additional-text ${
          state.chatVisibility ? "chat-visible" : ""
        }`}
        onClick={handleChatVisibility}
      >
        <button className={`chat-button button-clear`}>
          <span
            className={`${
              hasNewMessage && !state.chatVisibility
                ? "blinking-yellow-fill"
                : ""
            }`}
          >
            {renderIcon("/icons/chat.svg", 25, 25)}
          </span>
        </button>
        <span className={`app-chat-label long-text`}>Send Message</span>
      </Grid>
    );
  };

  const displayPPLGrid = () => {
    return (
      <Grid
        item
        className={`ppl additional-text ${
          state.participantsVisibility ? "participants-visible" : ""
        }`}
        onClick={handleShowParticipants}
      >
        <button className={`ppl-button button-clear`}>
          {renderIcon("/icons/ppl.svg", 25, 25)}
        </button>
        <span className={`connected-users-label short-text`}>
          {connectedUsersState}
        </span>
        <span className={`app-participants-label long-text`}>
          Participants ({connectedUsersState})
        </span>
      </Grid>
    );
  };

  const displayShowCallTrainer = () => {
    return props.showCallTrainer === false || isTrainer ? null : (
      <Grid item className={`additional-text call-trainer-container`}>
        <button onClick={handleCallTrainer} className={`button-clear`}>
          {renderIcon("/icons/white_hand.svg")}
          <span className={`long-text`}>Call Trainer</span>
        </button>
      </Grid>
    );
  };

  const displayShareBtnGrid = () => {
    const sharing = state.sharing ? " sharing-active" : "";
    const sharingTxt = state.sharing ? "Stop Sharing" : "Share Screen";

    return props.showShareBtn === false || !isTrainer ? null : (
      <Grid item className={`additional-text share-container${sharing}`}>
        <button onClick={handleShareScreen} className={`button-clear`}>
          {renderIcon("/icons/share-screen-normal.svg")}
          <span className={`short-text`}>Screen</span>
          <span className={`long-text`}>{sharingTxt}</span>
        </button>
      </Grid>
    );
  };

  return !userDataSelector.role ||
    !isSidebarVisible(userDataSelector.role, currentStageSelector) ? null : (
    <div className={getVClassName()}>
      {renderAdminRooms()}
      <Container
        maxWidth={false}
        className={`btns-container`}
        disableGutters={true}
      >
        <Grid
          container
          direction="row"
          spacing={0}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item className={`open-close-btn`}>
            <button className={`button-clear`} onClick={handleOnOpenChevron}>
              {renderIcon("/icons/white-arrow-up.svg", 12, 12)}
            </button>
          </Grid>

          {showTrainerGrid()}
          <Grid item className={`btns-group`}>
            {/* Devices START */}
            <span
              className="devices-btn"
              onClick={handleAudioDevicesVisibility}
            >
              <SVG
                width="15"
                height="15"
                className="svg-devices"
                src={"/icons/arrow-right.svg"}
              />
            </span>
            {/* Devices END */}
            {displayAudioDevicesOpen()}
            {displayMuteGrid()}
            {displayVideoGrid()}
            {displayChatGrid()}
            {displayPPLGrid()}
          </Grid>

          <Grid
            container
            direction="row"
            spacing={0}
            alignItems="flex-start"
            justifyContent="flex-start"
            className={`btns-group-inline share-leave-btn-container`}
          >
            {displayShowCallTrainer()}
            {displayShareBtnGrid()}
            {showLeaveGrid()}
          </Grid>

          {/* ---------- Main admin specific buttons */}
          <Grid
            container
            direction="row"
            spacing={0}
            alignItems="flex-start"
            justifyContent="flex-start"
            className={`btns-group-inline trainer-section`}
          >
            <Grid item className={`additional-text leave-container`}>
              <button
                onClick={handleOnNextStage}
                className={`button-clear btn-primary`}
              >
                {renderIcon("/icons/admin/play.svg")}
                <span className={`short-text`}>Next</span>
                <span className={`long-text`}>Next Stage</span>
              </button>
            </Grid>
            <Grid item className={`additional-text open-select-stage`}>
              <button
                onClick={handleOnStageSelect}
                className={`button-clear text-button btn-secondary`}
              >
                <span className={`short-text`}>Stage</span>
                <span className={`long-text`}>Go to different stage</span>
              </button>
            </Grid>
            <Grid item className={`additional-text open-assign-rm`}>
              <button
                onClick={handleOnAssignRM}
                className={`button-clear text-button btn-secondary`}
              >
                <span className={`short-text`}>RM</span>
                <span className={`long-text`}>Assign RM</span>
              </button>
            </Grid>
            <Grid item className={`additional-text open-assign-rm`}>
              <button
                onClick={handleOnAssignPlayer}
                className={`button-clear text-button btn-secondary`}
              >
                <span className={`short-text`}>Player</span>
                <span className={`long-text`}>Assign Player</span>
              </button>
            </Grid>
            {/* <Grid item className={`additional-text open-assign-trainer`}>
                        <button onClick={handleOnAssignTrainer} className={`button-clear text-button btn-secondary`}>
                            <span className={`short-text`}>Trainer</span>
                            <span className={`long-text`}>Add Trainer</span>
                        </button>
                    </Grid>
                    <Grid item className={`additional-text open-assign-trainer`}>
                        <button onClick={handleOnResetGame} className={`button-clear text-button btn-contrast`}>
                            <span className={`short-text`}>Reset</span>
                            <span className={`long-text`}>Reset Game</span>
                        </button>
                    </Grid> */}
          </Grid>

          <Grid
            container
            direction="row"
            spacing={0}
            alignItems="flex-start"
            justifyContent="flex-start"
            className={`btns-group-inline trainer-section share-leave-btn-container`}
          >
            <Grid item className={`additional-text share-results-container`}>
              <button
                onClick={handleAddPlayer}
                className={`button-clear btn-secondary`}
              >
                <span className="icon icon-add">
                  {renderIcon("/icons/admin/add.svg")}
                </span>
                <span className={`short-text`}>Players</span>
                <span className={`long-text`}>Add Players</span>
              </button>
            </Grid>
            <Grid item className={`additional-text share-results-container`}>
              <button
                onClick={handleOnShareResults}
                className={`button-clear btn-secondary`}
              >
                {renderIcon("/icons/admin/eye.svg")}
                <span className={`short-text`}>Share</span>
                <span className={`long-text`}>Share Results</span>
              </button>
            </Grid>
            <Grid item className={`additional-text settings-container`}>
              <button
                onClick={handleOnSettings}
                className={`button-clear btn-secondary`}
              >
                {renderIcon("/icons/admin/settings.svg")}
                <span className={`short-text`}>Settings</span>
                <span className={`long-text`}>Settings</span>
              </button>
            </Grid>
            <Grid item className={`additional-text settings-container`}>
              <button
                onClick={handleOnAdminClick}
                className={`button-clear btn-secondary`}
              >
                {renderIcon("/icons/admin/settings.svg")}
                <span className={`short-text`}>Admin</span>
                <span className={`long-text`}>Admin Menu</span>
              </button>
            </Grid>
            <Grid item className={`additional-text pause-end-container`}>
              <Box flexDirection="row">
                {checkIfPauseOrResumeIsShown()}

                <button
                  onClick={handleOnEnd}
                  className={`button-clear btn-primary end-game-button`}
                >
                  {renderIcon("/icons/shut-down.svg")}
                  <span className={`long-text`}>End</span>
                </button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default VideoControls;
