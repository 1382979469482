import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        justifyContent: 'center'
    },
    btnContainer: {
        marginTop: '30px',
    },
    button1: {
        minWidth: '176px',
        marginRight: '30px',
        height: '44px',
        padding: '6px 12px 6px 13px',
        borderRadius: '5px',
        border: 'solid 2px #296daa',
        backgroundImage: 'linear-gradient(to bottom, rgb(83, 166, 213), #296daa)',
        fontFamily: 'Roboto',
        fontSize: '16px',
        lineHeight: '1.68',
        textAlign: 'center',
        color: '#fff',
        textTransform: 'inherit'
    },
    button2: {
        minWidth: '128px',
        height: '32px',
        padding: '6px 6px 5px 8px',
        borderRadius: '5px',
        border: 'solid 2px #aeaeb0',
        backgroundImage: 'linear-gradient(to bottom, rgb(237, 240, 242), #aeaeb0)',
        textTransform: 'inherit',
        color: '#1b1c20',
        fontWeight: 'bold'
    },
    question1: {
        height: '40px',
        fontFamily: 'Roboto',
        fontSize: '17px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'rgb(0, 0, 0)'
    }
}));

export default useStyles;