export interface UserModel {
    id?: number,
    name?: string,
    role?: UserRoleModel,
    email?: string,
    trainer?: UserRoleModel
}

export enum UserRoleModel {
    Trainer = 'Trainer',
    Player = 'Player',
    RM = 'RM'
}