import { Paper } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingIndicatorService } from '../../core/loadingIndicator/LoadingIndicatorService';
import { scrollService } from '../../core/services/ScrollService';
import { VideoConfig } from '../../game/store/GameReducer';
import FinancialReport from '../../shared/components/financialReport/FinancialReport';
import { AppState } from '../../store/Store';
import { opentokService } from '../../video/services/opentok.service';
import useStyles from './styles/ResultPageStyles';
import './styles/resultsStyles.scss';

const VIDEO_CONTAINER_ID = 'video-container';
const unsubscribe$ = new Subject();

const ResultPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectReadyToStart = (state: AppState) => state.gameState.videoConfig;
    const videoConfig = useSelector(selectReadyToStart) as VideoConfig;
    const teamsResultsData = (state: AppState) => state.gameState.financialsAll;
    const allTeamsData = useSelector(teamsResultsData);
    const [showNavBtns, setShowNavBtns] = useState(false);

    const rankTeams = () => {
        let teamRank = 1;
        const sortedArr = _.orderBy(allTeamsData, ['resFinal', 'teamName'], ['desc', 'asc']);
        let elementsArr: any[] = [];
        sortedArr.forEach((team, index) => {
            if (teamRank === 1) {
                elementsArr.push(
                <div key={`team1cont-${index}`} className='team1Cont'>
                    <div className={classes.team1Badge}></div>
                    {team.teamName}
                </div>)
            } else if (teamRank === 2) {
                elementsArr.push(
                <div key={`team2cont-${index}`} className='team2Cont'>
                    <div className={`${classes.team2Badge} team2Badge`}></div>
                    {team.teamName}
                </div>)
            } else if (teamRank === 3) {
                elementsArr.push(
                    <div key={`team3cont-${index}`} className='team3Cont'>
                        <div className={`${classes.team3Badge} team3Badge`}></div>
                        {team.teamName}
                    </div>)
            } else {
                elementsArr.push(
                    <div key={`team4cont-${index}`} className='team4Cont'>
                        {team.teamName}
                    </div>)
            }
            teamRank++;
        });
        return elementsArr;
    }

    const pixelsPerScroll = 672; // jumps over 4 video boxes

    useEffect(() => {
        LoadingIndicatorService.stopLoading();
        if (videoConfig.sessionId && videoConfig.apiKey) {
            opentokService.initializeOpenTok(videoConfig, VIDEO_CONTAINER_ID);
        }

        opentokService.connectedUsers$
            .pipe(takeUntil(unsubscribe$))
            .subscribe(_ => {
                updateNavBtnsState();
            });
        return () => {
            LoadingIndicatorService.startLoading();
            unsubscribe$.next();
            unsubscribe$.complete();
        }
    }, [dispatch, videoConfig])

    const updateNavBtnsState = () => {
        const winWidth = window.innerWidth;
        const elemWidth = document.querySelector(`#${VIDEO_CONTAINER_ID}`)?.scrollWidth || 0;
        setShowNavBtns((elemWidth + 200) > winWidth);
    }

    window.addEventListener('resize', updateNavBtnsState);
    window.addEventListener('load', updateNavBtnsState);

    const handlePrevClick = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
        scrollService.scrollLeft(elem, -pixelsPerScroll, 1000 );
    };
    
    const handleNextClick = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
        scrollService.scrollLeft(elem, pixelsPerScroll, 1000 );
    };

    return (<div className={`${classes.root} result-styles`}>
        <div className={`${classes.topName} top-name`}>Results</div>
        <div className={`${classes.videoContainer}`}>
            <div className={`${classes.carouselPrev} ${showNavBtns ? '' : 'hidden'}`}>
                <button onClick={handlePrevClick}></button>
            </div>
            <div id={`${VIDEO_CONTAINER_ID}`}></div>
            <div className={`${classes.carouselNext} ${showNavBtns ? '' : 'hidden'}`}>
                <button onClick={handleNextClick}></button>
            </div>
        </div>
        <div className='first-backgr'></div>
        {rankTeams()}
        <Paper className='fin-report' elevation={0}>
            <FinancialReport finalReport={true}></FinancialReport>
        </Paper>
    </div>);
}

export default ResultPage;