import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from "react-redux";
import { UserActions, UserActionTypes } from '../store/UserActions';
import { AppState } from '../../store/Store';
import { authService } from '../services/auth.service';
import { Grid } from '@material-ui/core';
import useStyles from '../styles/styleClasses';
import { UserSignupData } from '../store/UserReducer';

const initialSignupValues: UserSignupData = {
  name: '',
  password: '',
  email: ''
}

export default function Signup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectIsLoading = (state: AppState) => state.userState.isLoading;
  const selectIsError = (state: AppState) => state.userState.isError;
  const selectError = (state: AppState) => state.userState.error;
  const selectSuccess = (state: AppState) => state.userState.isSuccess;

  const isLoading = useSelector(selectIsLoading);
  const isError = useSelector(selectIsError);
  const error = useSelector(selectError);
  const isSuccess = useSelector(selectSuccess);

  const [values, setValues] = useState(initialSignupValues);

  const changeValue = (e: any) => {
    const { name, value } = e.target;
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const signup = async (event: React.FormEvent) => {
    event.preventDefault();
    const trySignupAction: UserActions = {
      type: UserActionTypes.TRY_SIGNUP
    }

    dispatch(trySignupAction);

    const signupResult = await authService.signup(values);

    if (signupResult.success) {
      const signupSuccessAction: UserActions = {
        type: UserActionTypes.SIGNUP_SUCCESS,
        username: values.email
      };

      dispatch(signupSuccessAction);
    } else if (signupResult.error) {
      const signupFailAction: UserActions = {
        type: UserActionTypes.SIGNUP_FAIL,
        error: signupResult.error
      };
      dispatch(signupFailAction);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      signup(event);
    }
  }

  const changeIntent = (intent: 'login' | 'confirm') => {
    const changeIntentAction: UserActions = {
      type: UserActionTypes.CHANGE_AUTH_INTENT,
      intent
    };

    dispatch(changeIntentAction);
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={`logo`}>
          <img src="/icons/logo-big.svg" alt="logo" />
        </div>

        <form className={classes.form} onSubmit={signup}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            className={classes.textarea}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={changeValue}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            className={classes.textarea}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={changeValue}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            className={classes.textarea}
            id="name"
            label="Name"
            name="name"
            onChange={changeValue}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.login}
            onClick={signup}
            disabled={isLoading}
          >
            Sign Up
          </Button>
          {isError ? <p className={classes.error}>{error}</p> : null}
          {isSuccess ? <p className={classes.success}>Please follow the link sent to your email to confirm your registration</p> : null}
          <Grid container>
            <span onClick={() => { changeIntent('login') }} className={classes.link}>Already have an account? Sign In</span>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
