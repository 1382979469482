import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    bottomLabel: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        margin: '40px 0'
    },
    contentContainer: {
        border: '0 !important',
        overflowY: 'hidden'
    },
    gameDiv: {
        marginLeft: '-272px'
    },
    buttonText: {
        textDecoration: 'underline',
        fontWeight: 'bold',
        color: '#0d4171',
        cursor: 'pointer',
    },
    tableRectangle: {
        width: '311px',
        height: '311px',
        padding: '0 1px 1px 0',
        marginLeft: '-291px',
        borderRadius: '4px',
        border: 'solid 3px #1b1c20',
        backgroundImage: 'linear-gradient(172deg, rgb(194, 194, 194) 6%, rgb(143, 143, 143) 96%);'
    },
    showHideIcon: {
        position: 'absolute',
        marginTop: '1px;',
        marginLeft: '-25px'
    },
    mapsContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '> div': {
            marginRight: '25px'
        },
        '& table': {
            border: '3px solid #1b1c20',
            borderRadius: '10px',
            borderCollapse: 'collapse',
            '& td': {
                border: '2px solid #1b1c20',
                width: '26px',
                height: '26px',
                boxSizing: 'border-box'
            }
        },
        '& .cell-type-0': {
            backgroundColor: '#aeaeb0'
        },
        '& .cell-type-1': {
            backgroundColor: '#fff'
        },
        '& .cell-type-2': {
            backgroundColor: '#fff'
        }
    },
    matrixContainer: {
        marginLeft: '-22.5px'
    },
    bold: {
        fontWeight: 'bold'
    },
    paper: {
        height: '48px',
        width: '48px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: 'transparent'
    },
    paperNumTop: {
        height: '30px',
        width: '54px',
        fontWeight: 'bold',
        display: "flex",
        color: '#fff',
        flexDirection: "column",
        justifyContent: "center",
        background: 'transparent'
    },
    paperNumLeft: {
        height: '54px',
        width: '30px',
        color: '#fff',
        paddingLeft: '18px',
        fontWeight: 'bold',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: 'transparent'
    },
    paperInsideContainer: {
        borderRadius: '3px',
        margin: '3px',
        backgroundImage: 'linear-gradient(to bottom, #ffffff, rgb(213, 213, 213))'
    },
    paperMatch: {
        boxSizing: 'border-box',
        height: '40px',
        width: '40px',
        margin: '6px',
        transition: 'all .2s',
        border: 'solid 1px rgb(56, 126, 152)',
        backgroundColor: '#fff',
        background: 'no-repeat center center url("icons/flower.svg");'
    },
    paperNoMatch: {
        boxSizing: 'border-box',
        height: '40px',
        margin: '6px',
        width: '40px',
        border: 'solid 1px rgb(56, 126, 152)',
        transition: 'all .2s',
        background: '#fff'
    },
    paperInside: {
        boxSizing: 'border-box',
        height: '40px',
        width: '40px',
        borderRadius: '2px',
        border: 'solid 1px rgb(56, 126, 152)',
        backgroundColor: '#62b2cf',
        background: '#62b2cf',
        transition: 'all .2s',
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(135deg, #62b2cf 7%, #87c9e5);',
            zIndex: '99',
            position: 'relative'
        }
    },
    buttonClose: {
        width: '128px',
        height: '32px',
        padding: '6px 6px 5px 8px',
        borderRadius: '5px',
        border: 'solid 2px #aeaeb0',
        backgroundImage: 'linear-gradient(to bottom, rgb(237, 240, 242), #aeaeb0)',
        textTransform: 'capitalize',
        marginLeft: '-300px',
        marginTop: '34px',
        color: '#1b1c20',
        fontWeight: 'bold'
    },
    contContainer: {
        textAlign: 'center',
        marginLeft: '140px',
        marginTop: '-79px'
    },
    content: {
        marginTop: '44px',
        fontSize: '19px',
        fontFamily: 'Roboto',
        fontWeight: 'bolder'
    },
    grid: {
        padding: '0 !important'
    },
    teamGrid: {
        marginTop: '20px',
        fontWeight: 'bold'
    },
    bottomText: {
        marginLeft: '33%'
    },
    gridDialog: {
        marginLeft: '22%',
    },
    gridDialogBottom: {
        marginBottom: '60px',
        padding: '0 !important',
    },
    otherText: {
        position: 'absolute',
        bottom: '75px',
        marginLeft: '8px'
    },
    header: {
        fontFamily: 'Roboto',
        fontSize: '21px',
        fontWeight: 'normal'
    },
    teamName: {
        fontFamily: 'Roboto',
        fontSize: '19px'
    },
    notAdded: {
        fontSize: '17px',
        fontWeight: 'bold',
        color: 'red',
        alignItems: 'center'
    },
    notAddedInService: {
         marginLeft: '196px',
         fontSize: '17px',
         fontWeight: 'bold',
         color: 'red',
    },
    button: {
        width: '176px',
        height: '44px',
        borderRadius: '5px',
        border: 'solid 2px #296daa',
        backgroundImage: 'linear-gradient(to bottom, #53a6d5, #296daa)',
        color: '#fff',
        marginLeft: '32%',
        position: 'absolute',
        bottom: '20px'
    }
}));

export default useStyles;