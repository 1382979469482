import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { Chip, TextField } from "@material-ui/core";
import { AvailableUsers } from "../../models/AvailableUsersModel";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 240,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

interface IPSProps {
  teamId: number;
  selectedUsers: string[];
  availableUsers: AvailableUsers[];
  onUsersSelected: (emails: string[]) => void;
}

export default function PlayersSelector(props: IPSProps) {
  const classes = useStyles();

  const handleChange = (value: string[]) => {
    props.onUsersSelected(value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Autocomplete
          style={{ zIndex: 100 }}
          multiple
          disablePortal
          id={`tags-filled-${props.teamId}`}
          options={props.availableUsers.map((au) => au.email)}
          onChange={(_, value) => handleChange(value)}
          value={props.selectedUsers}
          freeSolo
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                style={{ marginTop: 10 }}
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              placeholder="Type to search"
              {...params}
              variant="outlined"
              label={`Pick players in team ${props.teamId}`}
            />
          )}
        />
      </FormControl>
    </div>
  );
}
