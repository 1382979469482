import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/Store";
import { HintFieldModel, HintFieldType, HintModel } from "./model/MapMemorizationDialogModel";
import useStyles from './styles/MapMemorizationDialogStyles';
import parse from 'html-react-parser';

let keyId = 1, rowKey = 1;
const colToNameMap: any = {
    "a": 0,
    "b": 1,
    "c": 2,
    "d": 3,
    "e": 4,
    "f": 5,
    "g": 6,
    "h": 7,
    "i": 8,
    "j": 9,
    "k": 10,
    "l": 11
};

const MapMemorizationGrid = (props: any) => {
    const classes = useStyles();
    const userHintsData = (state: AppState) => state.gameState.userHints;
    const userHintsDataSelector = useSelector(userHintsData);
    const [hiddenMapIndex, setHiddenMapIndex] = React.useState(-1);

    const buildMatrix = (hintFields: HintFieldModel[]): [][] => {
        let result: any[] = [];

        hintFields.forEach(hint => {
            const row = (hint.a1notation.match(/\d+/g) as any).join([]);
            const col = hint.a1notation.split('' + row)[0];
            result[row]
                ? result[row][colToNameMap[col]] = hint.suitable
                : result[row] = [hint.suitable]
        })

        return fillEmptyRowCellFallback(result);
    }

    const fillEmptyRowCellFallback = (matrix: any): [][] => {
        for (let row = 1; row <= 12; row++) {
            if (!matrix[row]) {
                const tempStr = (HintFieldType.EMPTY.toString() + '--').repeat(12);
                matrix[row] = tempStr.split('--').map(e => +e);
                matrix[row].pop();
            } else {
                for (let col = 0; col < 12; col++) {
                    let colElemns = matrix[row][col];
                    if (colElemns === undefined) {
                        matrix[row][col] = HintFieldType.FILLED;
                    }
                }
            }
        }

        return matrix;
    }

    const returnXOrBlank = (cellNum: number) => {
        if (cellNum === 2) {
            return (`<span class="full-cell">X</span>`);
        }
        return (`<span>&nbsp;</span>`);
    }

    const buildTableFromMatrix = (matrix: [][]): string => {
        let result = '<tbody>';
        for (let row = 1; row < matrix.length; row++) {
            const rowElem = matrix[row];
            result += `<tr key=${rowKey}>`;
            for (let col = 0; col < rowElem.length; col++) {
                const cellValue = returnXOrBlank(rowElem[col]);
                result += `<td key='${keyId}' class="cell-type-${rowElem[col]}">${cellValue}</td>`;
                keyId++;
            }
            rowKey++;
            result += '</tr>';
        }
        result += '</tbody>';
        return result;
    }

    const toggleMap = (mapIndex: number) => {
        let newState = mapIndex;
        if (newState === hiddenMapIndex) {
            newState = -1;
        }
        setHiddenMapIndex(newState);
    }

    const displayShowHideButtons = (index: number) => {
        if (userHintsDataSelector.length > 1) {
            return (<button className={`ma-toggle-button ${index === hiddenMapIndex 
                ? 'hidde-map-icon' : 'show-map-icon'}`} 
                onClick={() => { toggleMap(index) }}>
                { index === hiddenMapIndex ? 'Show Map' : 'Hide Map' }
            </button>)
        }
        return (<></>);
    }

    const getResultMap = (hint: HintModel, index: number): any => {
        const matrixTable = buildTableFromMatrix(buildMatrix(hint.hint_fields));
        return (<>
            <div>
                {displayShowHideButtons(index)}
            </div>
            <div className={`${index === hiddenMapIndex ? 'hidden-map' : ''}`}>
                <table>{parse(matrixTable)}</table>
            </div>
        </>);
    }

    const classesNotAdded = !props?.inServiceOption ? `${classes.notAdded}` : `${classes.notAddedInService}`;

    const checkIfSelector = () => {
        if (userHintsDataSelector.length) {
            return (<span>X - has the needed conditions</span>);
        }
        return (<span className={classesNotAdded}>Map is NOT Added for this User. Please contact an Administrator</span>)
    }

    const checkIfLengthForMap = () => {
        if (userHintsDataSelector.length) {
            return (userHintsDataSelector.map((hint, index) => {
                return (<div key={index} className={`ma-table-container-${index}`}>
                    { getResultMap(hint, index) }
                </div>);
            }));
        }
        return (<span></span>)
    }

    return (<Grid container spacing={3}>
        <div className={`${classes.mapsContainer} map-memorization-container`}>
            {checkIfLengthForMap()}
        </div>
        <div className={classes.bottomLabel}>{checkIfSelector()}</div>
    </Grid>)
}

export default MapMemorizationGrid;