import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    topName: {
        bottom: '643px',
        color: 'white',
        left: 'calc(50% - -23px)',
        position: 'absolute',
        fontFamily: 'Roboto',
        fontSize: '36px',
        fontWeight: 'bold'
    },
    videoContainer: {
        height: '110px',
        position: 'fixed',
        top: '44px',
        left: 0,
        padding: '5px 10px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        '& video': {
            height: '96px',
            width: '159px',
            objectFit: 'cover'
        },
        '& .videos-wrapper': {
            width: '100%',
        },
        '& #video-container': {
            height: '110px',
            margin: ' 0 10px',
            padding: '5px 0',
            overflowX: 'hidden',
            overflowY: 'hidden',
            boxSizing: 'border-box',
            width: 'calc(100% - 180px)',
            display: 'flex',
            '& > div': {
                borderRadius: '3px',
                border: '2px solid#558EA6 !important',
                height: '100px !important',
                minWidth: '163px !important',
                boxSizing: 'border-box'
            },
            '& > div:not(:last-child)': {
                margin: '0 2.5px'
            }
        }
    },
    carouselPrev: {
        display: 'flex',
        top: '7px',
        position: 'relative',
        height: '100px',
        zIndex: 999,
        left: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '16px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            transform: 'rotate(180deg)',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    carouselNext: {
        display: 'flex',
        position: 'relative',
        height: '100px',
        top: '7px',
        zIndex: 999,
        right: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '16px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    team1Badge: {
        marginLeft: '20px',
        background: 'url("/icons/icons-badge-1.svg") no-repeat center 6px',
        width: '53px',
        height: '60px',
    },
    team2Badge: {
        marginLeft: '4px',
        background: 'url("/icons/icons-badge-2.svg") no-repeat center 6px',
        width: '53px',
        height: '60px',
    },
    team3Badge: {
        background: 'url("/icons/icons-badge-3.svg") no-repeat center 6px',
        width: '53px',
        height: '60px',
    },
}));

export default useStyles;