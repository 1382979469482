import { Reducer } from "redux";
import { GameActions, GameActionTypes } from "./GameActions";
import _ from "lodash";
import { AssignUserModel } from "../../shared/components/assingResources/user.model";
import { HintModel } from "../../shared/components/dialogComponent/dialogs/mapMemorizationDialog/model/MapMemorizationDialogModel";
import { QuestionModel } from "../../pages/finalTeamDiscussionPage/question.model";
import { FinancialResult } from "../../socket/models/financials.model";
import { TrainingModel } from "../../admin/models/AdminTrainingsModel";
export enum GameStages {
  NONE = "NONE",
  AUTHENTICATING = "AUTHENTICATING",
  CONNECTED = "CONNECTED",
  GAME_NOT_STARTED = "GAME_NOT_STARTED",
  PLAYERS_INTRODUCTION = "PLAYERS_INTRODUCTION",
  PRESENTATION = "PRESENTATION",
  REGIONAL_MANAGER_SELECTION = "REGIONAL_MANAGER_SELECTION",
  REGIONAL_MANAGERS_MEETING = "REGIONAL_MANAGERS_MEETING",
  REGIONAL_MANAGER_SWITCH = "REGIONAL_MANAGER_SWITCH",
  MAP_ASSIGNMENT = "MAP_ASSIGNMENT",
  SUBSTAGE_ROOM_START = "SUBSTAGE_ROOM_START",
  SUBSTAGE_MAP_DISCUSS_STRATEGY = "SUBSTAGE_MAP_DISCUSS_STRATEGY",
  SUBSTAGE_MAP_ASSIGNMENT = "SUBSTAGE_MAP_ASSIGNMENT",
  SUBSTAGE_MAP_MEMORIZATION = "SUBSTAGE_MAP_MEMORIZATION",
  SUBSTAGE_FIRST_QUARTER = "SUBSTAGE_FIRST_QUARTER",
  SUBSTAGE_FIRST_QUARTER_DISCUSSION = "SUBSTAGE_FIRST_QUARTER_DISCUSSION",
  MAP_MEMORIZATION = "MAP_MEMORIZATION",
  FIRST_QUARTER = "FIRST_QUARTER",
  FIRTST_QUARTER_DISCUSSION = "FIRTST_QUARTER_DISCUSSION",
  STAGE_FORCE_FIELD_ANALYSIS = "FORCE_FIELD_ANALYSIS",
  SECOND_QUARTER = "SECOND_QUARTER",
  SECOND_QUARTER_RM_MEETING = "SECOND_QUARTER_RM_MEETING",
  IN_SERVICE_OPTION = "IN_SERVICE_OPTION",
  THIRD_FOURTH_QUARTER = "THIRD_FOURTH_QUARTER",
  TRAINING = "TRAINING",
  RESULTS = "RESULTS",
  DEBRIEF = "DEBRIEF",
  CREATED = "CREATED",
  QUESTIONS_DEBRIEF = "QUESTIONS_DEBRIEF",
  FINAL_DISCUSSION = "FINAL_DISCUSSION",
  GAME_END = "GAME_END",
  CHANGE_USERS = "CHANGE_USERS",
}

export interface VideoConfig {
  apiKey?: string;
  sessionId?: string;
  token?: string;
}

const rowMap: any = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
};

const getKeyByValue = (value: string) => {
  return Object.keys(rowMap).find((key) => rowMap[key] === value);
};

let prevSelectedFields: string[] = [];

const setTeam = (user: AssignUserModel, teams: any) => {
  let newTeams = { ...teams };
  let uniqueBy: any;
  switch (user.teamName) {
    case "herbs":
      uniqueBy = newTeams.herbs.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, herbs: [...newTeams.herbs, user] };
      }
      break;
    case "herbs2":
      uniqueBy = newTeams.herbs2.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, herbs2: [...newTeams.herbs2, user] };
      }
      break;
    case "sun":
      uniqueBy = newTeams.sun.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, sun: [...newTeams.sun, user] };
      }
      break;
    case "sun2":
      uniqueBy = newTeams.sun2.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, sun2: [...newTeams.sun2, user] };
      }
      break;
    case "humidity":
      uniqueBy = newTeams.humidity.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, humidity: [...newTeams.humidity, user] };
      }
      break;
    case "humidity2":
      uniqueBy = newTeams.humidity2.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, humidity2: [...newTeams.humidity2, user] };
      }
      break;
    case "crops":
      uniqueBy = newTeams.crops.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, crops: [...newTeams.crops, user] };
      }
      break;
    case "crops2":
      uniqueBy = newTeams.crops2.filter(
        (item: AssignUserModel) => item.id === user.id
      );
      if (!uniqueBy.length) {
        newTeams = { ...newTeams, crops2: [...newTeams.crops2, user] };
      }
      break;
  }

  return newTeams;
};

const removeTeam = (user: AssignUserModel, teams: any) => {
  let newTeams = { ...teams };
  let teamNew;
  switch (user.teamName) {
    case "herbs":
      teamNew = newTeams.herbs.filter(
        (userTeam: any) => userTeam.id !== user.id
      );
      newTeams = { ...newTeams, herbs: [...teamNew] };
      break;
    case "herbs2":
      teamNew = newTeams.herbs2.filter(
        (userTeam: any) => userTeam.id !== user.id
      );
      newTeams = { ...newTeams, herbs2: [...teamNew] };
      break;
    case "sun":
      teamNew = newTeams.sun.filter((userTeam: any) => userTeam.id !== user.id);
      newTeams = { ...newTeams, sun: [...teamNew] };
      break;
    case "sun2":
      teamNew = newTeams.sun2.filter(
        (userTeam: any) => userTeam.id !== user.id
      );
      newTeams = { ...newTeams, sun2: [...teamNew] };
      break;
    case "humidity":
      teamNew = newTeams.humidity.filter(
        (userTeam: any) => userTeam.id !== user.id
      );
      newTeams = { ...newTeams, humidity: [...teamNew] };
      break;
    case "humidity2":
      teamNew = newTeams.humidity2.filter(
        (userTeam: any) => userTeam.id !== user.id
      );
      newTeams = { ...newTeams, humidity2: [...teamNew] };
      break;
    case "crops":
      teamNew = newTeams.crops.filter(
        (userTeam: any) => userTeam.id !== user.id
      );
      newTeams = { ...newTeams, crops: [...teamNew] };
      break;
    case "crops2":
      teamNew = newTeams.crops.filter(
        (userTeam: any) => userTeam.id !== user.id
      );
      newTeams = { ...newTeams, crops2: [...teamNew] };
      break;
  }

  return newTeams;
};

const convertNullToString = (value: any) => {
  if (value === null) {
    return "";
  } else {
    return value;
  }
};

export interface GameState {
  stage: GameStages;
  subStage: GameStages; // Use this to show dialogs or else inside the gameContainer
  teamName: string;
  teamId: number | undefined;
  currentStageTimerEnd: number | null; //in miliseconds since epoch
  result: number;
  selectedFields: string[];
  openedFields: string[];
  grid: any[][];
  financialReport: number | undefined;
  financialsObj: FinancialResult | undefined;
  openedFullReport: boolean;
  openedFullRules: boolean;
  videoConfig?: VideoConfig;
  teamRules: string;
  inServiceUsed: undefined | boolean;
  users: any[];
  gameUsers: any[];
  facilitatorId: string;
  userHints: HintModel[];
  resourceName: string;
  drivingForces: string;
  restrainingForces: string;
  textUpdated: undefined | boolean;
  teams: {
    herbs: [];
    herbs2: [];
    humidity: [];
    humidity2: [];
    sun: [];
    sun2: [];
    crops: [];
    crops2: [];
  };
  teamNames: any[];
  teamsList: any[];
  gameId: any;
  questionsArr: QuestionModel[];
  selectedQuestionIndex: number;
  financialsAll: any[];
  startGameDate: string;
  openedFullControls: boolean;
  roomTeams: any[];
  userCallingFromTeam: string[];
  gameDetails: TrainingModel;
  disabledNextStageBtn: undefined | boolean;
  disableRoomsBtns: undefined | boolean;
  gameEdited: boolean | null;
}

const initialGameState: GameState = {
  stage: GameStages.AUTHENTICATING,
  subStage: GameStages.NONE,
  teamName: "",
  teamId: undefined,
  currentStageTimerEnd: 0,
  result: 0,
  selectedFields: [],
  openedFields: [],
  grid: [],
  financialReport: undefined,
  financialsObj: undefined,
  openedFullReport: false,
  openedFullRules: false,
  videoConfig: {
    apiKey: undefined,
    sessionId: undefined,
    token: undefined,
  },
  teamRules: "",
  inServiceUsed: false,
  users: [],
  gameUsers: [],
  facilitatorId: "",
  textUpdated: undefined,
  resourceName: "",
  userHints: [],
  drivingForces: "",
  restrainingForces: "",
  teams: {
    herbs: [],
    herbs2: [],
    humidity: [],
    humidity2: [],
    sun: [],
    sun2: [],
    crops: [],
    crops2: [],
  },
  teamNames: [],
  teamsList: [],
  gameId: "",
  questionsArr: [],
  selectedQuestionIndex: 1,
  financialsAll: [],
  startGameDate: "",
  openedFullControls: false,
  roomTeams: [],
  userCallingFromTeam: [],
  gameDetails: { name: "" },
  disableRoomsBtns: undefined,
  disabledNextStageBtn: undefined,
  gameEdited: null,
};

export const gameReducer: Reducer<GameState, GameActions> = (
  state = initialGameState,
  action
) => {
  const newStateStage: GameState = _.cloneDeep(state);
  switch (action.type) {
    case GameActionTypes.SOCKET_CONNECTED: {
      return {
        ...newStateStage,
        stage: GameStages.CONNECTED,
      };
    }
    case GameActionTypes.SUBSTAGE_MAP_ASSIGNMENT: {
      return {
        ...newStateStage,
        subStage: GameStages.SUBSTAGE_MAP_ASSIGNMENT,
        stage: GameStages.MAP_ASSIGNMENT,
      };
    }
    case GameActionTypes.RESET_TEAMS: {
      return {
        ...newStateStage,
        teams: {
          herbs: [],
          herbs2: [],
          humidity: [],
          humidity2: [],
          sun: [],
          sun2: [],
          crops: [],
          crops2: [],
        },
      };
    }
    case GameActionTypes.SUBSTAGE_FIRST_QUARTER_DISCUSSION: {
      return {
        ...newStateStage,
        stage: GameStages.FIRST_QUARTER,
        subStage: GameStages.SUBSTAGE_FIRST_QUARTER_DISCUSSION,
      };
    }
    case GameActionTypes.DISABLE_NEXT_STAGE_BTN: {
      return {
        ...newStateStage,
        disabledNextStageBtn: action.payload,
      };
    }
    case GameActionTypes.DISABLE_ROOMS_BTNS: {
      return {
        ...newStateStage,
        disableRoomsBtns: action.payload,
      };
    }
    case GameActionTypes.OPEN_RIGHT_CHEVRON: {
      return {
        ...newStateStage,
        openedFullControls: action.payload,
      };
    }
    case GameActionTypes.SELECT_QUESTION_ID: {
      return {
        ...newStateStage,
        selectedQuestionIndex: action.payload,
      };
    }
    case GameActionTypes.SET_TEAMS: {
      return {
        ...newStateStage,
        teamsList: action.payload,
      };
    }
    case GameActionTypes.USER_CALLING: {
      const userCallingFromTeamResult = [...newStateStage.userCallingFromTeam];
      if (userCallingFromTeamResult.indexOf(action.payload) === -1) {
        userCallingFromTeamResult.push(action.payload);
      }

      return {
        ...newStateStage,
        userCallingFromTeam: userCallingFromTeamResult,
      };
    }
    case GameActionTypes.REMOVE_USER_CALLING: {
      const userCallingFromTeamResult = [...newStateStage.userCallingFromTeam];
      const userToRemoveIndex = userCallingFromTeamResult.indexOf(
        action.payload
      );
      if (userToRemoveIndex > -1) {
        userCallingFromTeamResult.splice(userToRemoveIndex, 1);
      }

      return {
        ...newStateStage,
        userCallingFromTeam: userCallingFromTeamResult,
      };
    }
    case GameActionTypes.STAGE_FORCE_FIELD_ANALYSIS: {
      const financeReport = action.payload?.finResult?.resQ1;
      const finResult = action.payload?.finResult;
      const financialsAll = action.payload?.allFinancials;
      return {
        ...newStateStage,
        stage: GameStages.STAGE_FORCE_FIELD_ANALYSIS,
        financialReport: financeReport,
        financialsAll: financialsAll,
        financialsObj: finResult,
      };
    }
    case GameActionTypes.SUBSTAGE_MAP_MEMORIZATION: {
      const hints = action.payload?.hints;
      const resourceName = action.payload?.resourceName;
      return {
        ...newStateStage,
        subStage: GameStages.SUBSTAGE_MAP_MEMORIZATION,
        stage: GameStages.MAP_MEMORIZATION,
        userHints: hints,
        resourceName,
      };
    }
    case GameActionTypes.USE_INSERVICE: {
      const useInService = action.payload;
      return {
        ...newStateStage,
        inServiceUsed: useInService,
      };
    }
    case GameActionTypes.SET_CREATED_STAGE: {
      const startDate = action.payload?.startDate;
      return {
        ...newStateStage,
        stage: GameStages.CREATED,
        startGameDate: startDate,
      };
    }
    case GameActionTypes.FFA_TEXT_UPDATED: {
      const updated = action.payload;
      return {
        ...newStateStage,
        textUpdated: updated,
      };
    }
    case GameActionTypes.FORCE_FIELD_UPDATED: {
      const drivingForces = action.payload?.driving;
      const restrainingForces = action.payload?.restraining;
      return {
        ...newStateStage,
        drivingForces,
        restrainingForces,
      };
    }
    case GameActionTypes.SET_ROOM_TEAMS: {
      const teamRooms = action.payload;
      return {
        ...newStateStage,
        roomTeams: teamRooms,
      };
    }
    case GameActionTypes.SUBSTAGE_FIRST_QUARTER: {
      let financeReport;
      const newGrid = action.payload?.grid;
      const finResult = action.payload?.finResult;
      const teams = action.payload?.roomTeams;
      if (action.payload?.q1Res !== 0) {
        financeReport = action.payload?.q1Res;
      }
      const financialsAll = action.payload?.financialsAll;
      return {
        ...newStateStage,
        stage: GameStages.FIRST_QUARTER,
        subStage: GameStages.SUBSTAGE_FIRST_QUARTER,
        grid: newGrid,
        financialReport: financeReport,
        financialsObj: finResult,
        financialsAll,
        openedFullRules: true,
        openedFullReport: false,
        roomTeams: teams,
      };
    }
    case GameActionTypes.BATCH_UPDATE_STORE: {
      let financeReport;
      const newGrid = action.payload?.grid;
      const finResult = action.payload?.finResult;
      const teams = action.payload?.roomTeams;
      let rules = action.payload?.rules;
      let drivingForces,
        restrainingForces,
        facilitatorId = undefined;
      if (rules === null) {
        rules = "";
      }
      if (action.payload.financialRes !== undefined) {
        financeReport = action.payload?.financialRes;
      }
      const financialsAll = action.payload?.financialsAll;

      if (action.payload?.clearPreviouslySelectedGridFlds) {
        prevSelectedFields = [];
      }

      if (action.payload?.drivingForces !== undefined) {
        drivingForces = convertNullToString(action.payload.drivingForces);
      }
      if (action.payload?.restrainingForces !== undefined) {
        restrainingForces = convertNullToString(
          action.payload.restrainingForces
        );
      }
      if (action.payload?.facilitatorId !== undefined) {
        facilitatorId = action.payload.facilitatorId;
      }

      return {
        ...newStateStage,
        grid: newGrid,
        subStage: action.payload.subStage,
        teamRules: rules,
        financialReport: financeReport,
        financialsObj: finResult,
        financialsAll,
        openedFullRules: false,
        roomTeams: teams,
        drivingForces: drivingForces ?? drivingForces,
        restrainingForces: restrainingForces ?? restrainingForces,
        facilitatorId: facilitatorId ? facilitatorId : undefined,
        textUpdated: false,
      };
    }
    case GameActionTypes.SECOND_QUARTER: {
      const newGrid = action.payload?.grid;
      const financeReport = action.payload?.q2Res;
      const finResult2 = action.payload?.finResult;
      const financialsAll2 = action.payload?.financialsAll;
      return {
        ...newStateStage,
        stage: GameStages.SECOND_QUARTER,
        subStage: GameStages.SECOND_QUARTER,
        grid: newGrid,
        financialReport: financeReport,
        financialsObj: finResult2,
        financialsAll: financialsAll2,
      };
    }
    case GameActionTypes.SUBSTAGE_THIRD_FOURTH_QUARTER: {
      const newGrid = action.payload?.grid;
      const financeReport = action.payload?.q3Res;
      const finResult3 = action.payload?.finResult;
      const financialsAll3 = action.payload?.financialsAll;

      return {
        ...newStateStage,
        stage: GameStages.THIRD_FOURTH_QUARTER,
        subStage: GameStages.THIRD_FOURTH_QUARTER,
        grid: newGrid,
        financialReport: financeReport,
        financialsObj: finResult3,
        financialsAll: financialsAll3,
      };
    }
    case GameActionTypes.FINAL_DISCUSSION: {
      const questionsArr = action.payload?.questions;
      return {
        ...newStateStage,
        stage: GameStages.FINAL_DISCUSSION,
        subStage: GameStages.NONE,
        openedFullReport: false,
        questionsArr,
      };
    }
    case GameActionTypes.SET_SHARED_FINANCIALS: {
      const finAll = action.payload?.financials;
      return {
        ...newStateStage,
        financialsAll: finAll,
      };
    }
    case GameActionTypes.SET_FINANCIAL_RESULT: {
      const finAll = action.payload?.finAllResult;
      const finCurrent = action.payload?.finResult;

      return {
        ...newStateStage,
        financialsAll: finAll,
        financialReport: finCurrent,
      };
    }
    case GameActionTypes.DEBRIEF_UPDATED: {
      const payloadQuestion: any = action.payload;
      const currentQuestions: QuestionModel[] = newStateStage.questionsArr;
      const foundIndexQuestion = currentQuestions.findIndex(
        (q) => q.index === payloadQuestion.questionIndex
      );
      if (foundIndexQuestion > -1) {
        currentQuestions[foundIndexQuestion].answer = payloadQuestion.answer;
      }
      return {
        ...newStateStage,
      };
    }
    case GameActionTypes.RESULTS: {
      const financials = action.payload?.finResult;
      const finalRes = action.payload?.finalRes;
      const allFinancials = action.payload?.allFinancials;
      return {
        ...newStateStage,
        stage: GameStages.RESULTS,
        subStage: GameStages.NONE,
        openedFullReport: true,
        financialsObj: financials,
        financialReport: finalRes,
        financialsAll: allFinancials,
        roomTeams: [],
      };
    }
    case GameActionTypes.SET_GAME: {
      const newGrid = action.payload;
      return {
        ...newStateStage,
        grid: newGrid,
      };
    }
    case GameActionTypes.SET_RULES: {
      let rules = action.payload;
      if (rules === null) {
        rules = "";
      }
      return {
        ...newStateStage,
        teamRules: rules,
      };
    }
    case GameActionTypes.DEBRIEF: {
      const questionsArrDebrief = action.payload?.questions;
      const teams = action.payload?.teams;
      const financialsAll = action.payload?.financialsAll;

      return {
        ...newStateStage,
        stage: GameStages.DEBRIEF,
        subStage: GameStages.NONE,
        questionsArr: questionsArrDebrief,
        teamNames: teams,
        financialsAll,
      };
    }
    case GameActionTypes.MAP_ASSIGNMENT: {
      return {
        ...newStateStage,
        stage: GameStages.MAP_ASSIGNMENT,
      };
    }
    case GameActionTypes.PLAYERS_INTRODUCTION: {
      return {
        ...newStateStage,
        stage: GameStages.PLAYERS_INTRODUCTION,
        subStage: GameStages.NONE,
      };
    }
    case GameActionTypes.PRESENTATION: {
      return {
        ...newStateStage,
        stage: GameStages.PRESENTATION,
        subStage: GameStages.NONE,
      };
    }
    case GameActionTypes.SAVE_RULES: {
      return {
        ...newStateStage,
        teamRules: action.rules,
      };
    }
    case GameActionTypes.SET_FACILITATOR: {
      return {
        ...newStateStage,
        facilitatorId: action.payload,
      };
    }
    case GameActionTypes.SET_IN_SERVICE_OPTION: {
      const financeReport = action.payload?.financialResult;
      return {
        ...newStateStage,
        financialReport: financeReport,
        currentStageTimerEnd: 60,
      };
    }
    case GameActionTypes.SET_IN_SERVICE: {
      const timeEnd = action.payload?.timer;
      const newGrid = action.payload?.grid;
      const financeReport = action.payload?.q2Res;
      const allFinancials = action.payload?.financialsAll;

      const hints = action.payload?.hints;
      const resourceName = action.payload?.resourceName;
      return {
        ...newStateStage,
        stage: GameStages.IN_SERVICE_OPTION,
        currentStageTimerEnd: timeEnd,
        subStage: GameStages.NONE,
        financialReport: financeReport,
        grid: newGrid,
        financialsAll: allFinancials,
        userHints: hints,
        resourceName,
      };
    }
    case GameActionTypes.SET_RESULTS: {
      return {
        ...newStateStage,
        stage: GameStages.RESULTS,
        subStage: GameStages.NONE,
      };
    }
    case GameActionTypes.GRID_CREATED: {
      return {
        ...newStateStage,
        grid: action.payload,
      };
    }
    case GameActionTypes.READY_TO_START: {
      return {
        ...newStateStage,
        videoConfig: { ...action.payload },
      };
    }
    case GameActionTypes.FIRST_QUARTER: {
      return {
        ...newStateStage,
        stage: GameStages.FIRST_QUARTER,
        videoConfig: { ...action.payload },
      };
    }
    case GameActionTypes.SAVE_OPENTOK_CONFIG: {
      return {
        ...newStateStage,
        videoConfig: { ...action.payload },
      };
    }
    case GameActionTypes.REGIONAL_MANAGER_SELECTION: {
      return {
        ...newStateStage,
        stage: GameStages.REGIONAL_MANAGER_SELECTION,
        subStage: GameStages.REGIONAL_MANAGER_SELECTION,
      };
    }

    case GameActionTypes.SET_GAME_ID: {
      return {
        ...newStateStage,
        gameId: action.payload,
      };
    }

    case GameActionTypes.SET_GAME_DETAILS: {
      return {
        ...newStateStage,
        gameDetails: action.payload,
      };
    }

    case GameActionTypes.SET_GAME_EDITED_RESPONSE: {
      return {
        ...newStateStage,
        gameEdited: action.payload,
      };
    }

    case GameActionTypes.SET_TIMER: {
      const newObj: any = {};
      if (action.payload.timeRemaining) {
        newObj.currentStageTimerEnd = action.payload.timeRemaining;
      }
      if (action.payload.stage) {
        newObj.users = action.payload?.users;
      }
      return {
        ...newStateStage,
        ...newObj,
      };
    }
    case GameActionTypes.SET_USERS: {
      return {
        ...newStateStage,
        users: action.payload,
      };
    }
    case GameActionTypes.SET_GAME_USERS: {
      const gameUsers = action.payload;
      return {
        ...newStateStage,
        gameUsers,
      };
    }
    case GameActionTypes.SET_TEAM_NAME: {
      return {
        ...newStateStage,
        teamName: action.payload,
      };
    }
    case GameActionTypes.SET_TEAM_ID: {
      return {
        ...newStateStage,
        teamId: action.payload,
      };
    }
    case GameActionTypes.DESTROY_OT_SESSION: {
      return {
        ...newStateStage,
        videoConfig: { ...initialGameState.videoConfig },
      };
    }
    case GameActionTypes.OPEN_FULL_REPORT: {
      const payload = action.payload.openedFullReport;
      return {
        ...newStateStage,
        openedFullReport: payload,
      };
    }
    case GameActionTypes.OPEN_FULL_TEAM_RULES: {
      const payload = action.payload.openedFullRules;
      return {
        ...newStateStage,
        openedFullRules: payload,
      };
    }
    case GameActionTypes.ASSIGN_USER_TO_TEAM: {
      const payloadUser: AssignUserModel = action.payload?.user;
      const teamsState = { ...state.teams };
      const newTeams = setTeam(payloadUser, teamsState);
      return {
        ...newStateStage,
        teams: newTeams,
      };
    }
    case GameActionTypes.REMOVE_USER_FROM_TEAM: {
      const deleteUser: AssignUserModel = action.payload?.user;
      const teamsOldState = { ...state.teams };
      const newTeams = removeTeam(deleteUser, teamsOldState);
      return {
        ...newStateStage,
        teams: newTeams,
      };
    }
    case GameActionTypes.CHANGE_STAGE: {
      const stage = action.payload.stage;
      return {
        ...newStateStage,
        stage,
      };
    }
    case GameActionTypes.CHANGE_USERS: {
      const usersComingArr = action.payload.users;
      const newStateArr = [...state.gameUsers];
      if (usersComingArr.length) {
        usersComingArr.forEach((user: any) => {
          const indexUser = newStateArr.findIndex(
            (item) => item.id === user.id
          );
          newStateArr[indexUser] = user;
        });
      }
      const totalArray = [...newStateArr];
      return {
        ...newStateStage,
        gameUsers: totalArray,
      };
    }
    case GameActionTypes.ADD_FIELD_TO_SELECTED: {
      const payload = action.payload;
      const selectedFieldArr = [...state.selectedFields, payload];
      return {
        ...newStateStage,
        selectedFields: selectedFieldArr,
      };
    }
    case GameActionTypes.RESET_GAME_MATRIX: {
      const resetedGrid = _.cloneDeep(state.grid).map((row) => {
        return row.map((cell) => {
          return { name: cell.name, selected: false };
        });
      });
      return {
        ...newStateStage,
        grid: resetedGrid,
        selectedFields: [],
      };
    }
    case GameActionTypes.FIELD_SELECTED: {
      let fieldsArr = [...state.selectedFields];
      const hasMatch = action.payload?.value;
      const field = action.payload?.a1notation;
      const newGrid: any = [...state.grid];
      const cell: string = field[0].toUpperCase();
      const cellId = Number(getKeyByValue(cell));
      const row: number = Number(field.substr(1, field.length - 1)) - 1;
      newGrid[row][cellId].selected = true;
      let financeReport = 0;

      if (newStateStage.stage === GameStages.FIRST_QUARTER) {
        if (
          action.payload.financialResult &&
          action.payload.financialResult.resQ1 !== undefined
        ) {
          financeReport = action.payload?.financialResult.resQ1;
        }
      } else if (newStateStage.stage === GameStages.SECOND_QUARTER) {
        if (
          action.payload.financialResult &&
          action.payload.financialResult.resQ2 !== undefined
        ) {
          financeReport = action.payload?.financialResult.resQ2;
        }
      } else if (newStateStage.stage === GameStages.THIRD_FOURTH_QUARTER) {
        if (
          action.payload.financialResult &&
          action.payload.financialResult.resQ3 !== undefined
        ) {
          financeReport = action.payload?.financialResult.resQ3;
        }
      } else {
        if (
          action.payload.financialResult &&
          action.payload.financialResult.resFinal !== undefined
        ) {
          financeReport = action.payload.financialResult.resFinal;
        }
      }
      const financialsForTeam = action.payload?.financialResult;
      const foundTeamId = newStateStage.financialsAll.findIndex(
        (team) => team.teamName === financialsForTeam.teamName
      );
      const newTeamsFinancials = [...newStateStage.financialsAll];
      if (foundTeamId > -1) {
        newTeamsFinancials[foundTeamId] = financialsForTeam;
      }
      if (!prevSelectedFields.includes(field)) {
        if (hasMatch) {
          newGrid[row][cellId].value = true;
        }
      }

      prevSelectedFields.push(field);

      return {
        ...newStateStage,
        selectedFields: fieldsArr,
        grid: newGrid,
        financialReport: financeReport,
        financialsAll: newTeamsFinancials,
      };
    }
    default: {
      return state;
    }
  }
};
