import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UserRoleModel } from '../../../../../core/models/UserModel';
import { userService } from '../../../../../core/services/UserService';
import { AppState } from '../../../../../store/Store';
import { opentokService } from '../../../../../video/services/opentok.service';
import { DialogActionTypes } from '../../store/DialogActions';
import './styles/SharedScreenDialogStyles.scss';

const ShareScreenDialog = (props: { text?: string }) => {
    const selectOpenDialog = (state: AppState) => state.dialogState;
    const openDialogSelector = useSelector(selectOpenDialog);

    useEffect(() => {
        const userRole = userService.getUser().role;
        if (UserRoleModel.Trainer === userRole && openDialogSelector.open && openDialogSelector.typeOfDialog === DialogActionTypes.SHARE_SCREEN) {
            opentokService.shareScreen();
        }

        return () => {
            opentokService.stopShareScreen();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<div id="share-screen-dialog-container">
        <div id="screen-preview"></div>
    </div>);
}

export default ShareScreenDialog;
