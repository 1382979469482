import { Card, CardContent } from "@material-ui/core";
import _ from "lodash";
import useStyles from "../AdminPanelStyles";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store/Store";
import { TrainingModel } from "../../models/AdminTrainingsModel";
import { AdminActionTypes } from "../../store/AdminActions";
import AdminTrainingList from "../trainingList/AdminTrainingList";
import AdminTrainingHistoryDetails from "../trainingHistoryDetails/AdminTrainingsHistoryDetails";
import {
  SocketActions,
  SocketActionTypes,
} from "../../../socket/SocketActions";

const AdminTrainingHistory = () => {
  const [items, setItems] = useState<TrainingModel[]>();
  const [selectedTrainingId, setSelectedTrainingId] = useState<
    number | undefined
  >(undefined);
  const [selectedTraining, setSelectedTraining] = useState<
    TrainingModel | undefined
  >(undefined);
  const trainingData = (state: AppState) => state.adminState.pastTrainings;
  const trainings = useSelector(trainingData);
  const dispatch = useDispatch();

  const onItemSelected = (id: number) => {
    setSelectedTrainingId(id);
    dispatch({
      type: SocketActionTypes.GET_TRAINING_DETAILS,
      payload: id,
    });

    const openFullReportAction: SocketActions = {
      type: SocketActionTypes.OPEN_FULL_REPORT,
      payload: true,
    };
    dispatch(openFullReportAction);
  };

  useEffect(() => {
    if (trainings) {
      setItems(trainings);
    }

    if (selectedTrainingId) {
      const selTraining = _.find(trainings, {
        id: selectedTrainingId,
      });
      setSelectedTraining(selTraining);
      dispatch({
        type: AdminActionTypes.SELECT_TRAINING,
        payload: selTraining?.id,
      });
    }
  }, [trainings, selectedTrainingId, dispatch]);

  const classes = useStyles();

  return (
    <>
      <div id="admin-dashboard-container">
        <Card className="trainings-scroll">
          <CardContent>
            <span className={`trainings`}>Past Trainings</span>
            <AdminTrainingList items={items} itemSelected={onItemSelected} />
          </CardContent>
        </Card>
        <span className={classes.arrow} />
        <Card>
          <CardContent>
            <AdminTrainingHistoryDetails training={selectedTraining} />
          </CardContent>
        </Card>
      </div>
    </>
  );
};
export default AdminTrainingHistory;
