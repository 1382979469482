import { combineReducers, createStore, Store, applyMiddleware } from "redux";
import { GameState, gameReducer } from "../game/store/GameReducer";
import {
  ChatState,
  chatReducer,
} from "./../shared/sidebar/components/chat/store/ChatReducer";
import { SocketMiddleware } from "../socket/socket.middleware";
import { AdminState, adminReducer } from "../admin/store/AdminReducer";
import { userReducer, UserState } from "../authentication/store/UserReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  DialogReducer,
  DialogState,
} from "../shared/components/dialogComponent/store/DialogReducer";

export interface AppState {
  userState: UserState;
  gameState: GameState;
  adminState: AdminState;
  dialogState: DialogState;
  chatState: ChatState;
}

const rootReducer = combineReducers<AppState>({
  userState: userReducer,
  gameState: gameReducer,
  adminState: adminReducer,
  dialogState: DialogReducer,
  chatState: chatReducer,
});

const configureStore = (): Store<AppState> => {
  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(SocketMiddleware()))
  );
};

export default configureStore;
