import { Dialog, DialogActions, DialogTitle, Theme, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/Store';
import useStyles from './styles/dialogStyles';
import './styles/dialogStyles.scss';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ManagementDialogComponent from './dialogs/managementDialog/managementDialogMemo';
import MapMemorizationDialog from './dialogs/mapMemorizationDialog/MapMemorizationDialog';
import TextDialog from './dialogs/textDialog/TextDialog';
import LeaveMeetingDialog from './dialogs/leaveMeetingDialog/LeaveMeetingDialog';
import AssignResources from '../assingResources/AssignResources';
import { LoadingIndicatorService } from '../../../core/loadingIndicator/LoadingIndicatorService';
import ForceFieldDialog from './dialogs/forcefieldAnalysisDialog/ForceFieldAnalysisDialog';
import ConfirmFFADialog from './dialogs/confrimFFADialog/ConfirmFFADialog';
import { DialogActionTypes } from './store/DialogActions';
import InServiceDialog from './dialogs/inServiceDialog/InServiceDialog';
import ConfirmDebriefDialog from './dialogs/confrimDebriefDialog/ConfirmDebriefDialog';
import GridMapDialog from './dialogs/gridMapDialog/GridMapDialog';
import SelectGameStageDialog from './dialogs/selectGameStageDialog/selectGameStageDialog';
import ConfirmGenericDialog from './dialogs/confirmGenericDialog/ConfirmGenericDialog';
import AdminTrainingViewDialog from './dialogs/adminTrainingViewDialog/AdminTrainingViewDialog';
import AssignRmsDialog from './dialogs/assignRMsDialog/assignRMsDialog';
import AssignTrainerDialog from './dialogs/assignTrainerDialog/assignTrainerDialog';
import SharedScreenDialog from './dialogs/sharedScreenDialog/SharedScreenDialog';
import AssignPlayersDialog from './dialogs/assignPlayersDialog/assignPlayersDialog';
import CreatePlayerDialog from './dialogs/createPlayerDialog/createPlayerDialog';
import GamePausedDialog from './dialogs/gamePausedDialog/gamePausedDialog';

const DialogComponent = () => {
    const classes = useStyles();
    const selectOpenDialog = (state: AppState) => state.dialogState;
    const openDialogSelector = useSelector(selectOpenDialog);

    const selectDialogState = () => {
        let state: boolean | undefined;
        switch (openDialogSelector?.typeOfDialog) {
            case DialogActionTypes.MNGT_DIALOG_MEMO:
                state = openDialogSelector.openedManagementDialogMemo;
                break;
            case DialogActionTypes.CONFIRM_GENERIC_DIALOG:
                state = openDialogSelector.openedManagementDialogMemo;
                break;
            case DialogActionTypes.SELECT_GAME_STAGE_DIALOG:
                state = openDialogSelector.openedSelectGameStageDialog;
                break;
            case DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG:
                state = openDialogSelector.openSmallMap;
                break;
            case DialogActionTypes.TEXT_DIALOG:
                state = openDialogSelector.textDialog;
                break;
            case DialogActionTypes.SHARE_SCREEN:
                state = openDialogSelector.shareScreenDialog;
                dialogTitle = 'Presentation';
                break;
            case DialogActionTypes.LEAVE_MEETING:
                state = openDialogSelector.textDialog;
                break;
            case DialogActionTypes.OPEN_MAP_ASSIGNMENT_DIALOG:
                state = openDialogSelector.openedMapAssignmentDialog;
                break;
            case DialogActionTypes.OPEN_GAME_PAUSED_DIALOG:
                state = openDialogSelector.openedGamePausedDialog;
                break;
            case DialogActionTypes.OPEN_ASSIGN_RMS_DIALOG:
                state = openDialogSelector.openedAssignRMsDialog;
                break;
            case DialogActionTypes.OPEN_ASSIGN_PLAYERS_DIALOG:
                state = openDialogSelector.openedAssignPlayersDialog;
                break;
            case DialogActionTypes.OPEN_CREATE_PLAYER_DIALOG:
                state = openDialogSelector.openedCreatePlayerDialog;
                break;
            case DialogActionTypes.OPEN_ASSIGN_TRAINER_DIALOG:
                state = openDialogSelector.openedAssignTrainerDialog;
                break;
            case DialogActionTypes.OPEN_FFA_DIALOG:
                state = openDialogSelector.openFFADialog;
                break;
            case DialogActionTypes.OPEN_FFA_CONFIRM_DIALOG:
                state = openDialogSelector.openConfirmFFADone;
                break;
            case DialogActionTypes.OPEN_IN_SERVICE_DIALOG:
                state = openDialogSelector.openInServiceDialog;
                break;
            case DialogActionTypes.OPEN_DEBRIEF_CONFIRM_DIALOG:
                state = openDialogSelector.openDebriefConfirmDialog;
                break;
            case DialogActionTypes.OPEN_GRID_MAP_DIALOG:
                state = openDialogSelector.openedGridMapDialog;
                break;
            default:
                dialogTitle = '';
                break;
        }
        return state;
    }

    const checkIfDialogOpen = () => {
        const checkDialogType = selectDialogState();
        if (checkDialogType || openDialogSelector.open) {
            return true;
        }
        return false;
    }

    const DialogContent = withStyles((theme: Theme) => ({
        root: {
          padding: theme.spacing(2),
        },
    }))(MuiDialogContent);


    let dialogTitle: string;

    switch (openDialogSelector?.typeOfDialog) {
        case DialogActionTypes.MNGT_DIALOG_MEMO:
            dialogTitle = 'MEMO';
            break;
        default:
            dialogTitle = '';
            break;
    }

    const returnDialogContent = () => {
        switch (openDialogSelector?.typeOfDialog) {
            case DialogActionTypes.MNGT_DIALOG_MEMO:
                return (<ManagementDialogComponent openTokConfig={openDialogSelector?.data}></ManagementDialogComponent>);
            case DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG:
                return (<MapMemorizationDialog></MapMemorizationDialog>);
            case DialogActionTypes.SELECT_GAME_STAGE_DIALOG:
                return (<SelectGameStageDialog></SelectGameStageDialog>);
            case DialogActionTypes.TEXT_DIALOG:
                return (<TextDialog text={openDialogSelector?.data}></TextDialog>);
            case DialogActionTypes.SHARE_SCREEN:
                return (<SharedScreenDialog></SharedScreenDialog>);
            case DialogActionTypes.OPEN_MAP_ASSIGNMENT_DIALOG:
                return (<AssignResources></AssignResources>);
            case DialogActionTypes.OPEN_ASSIGN_RMS_DIALOG:
                return (<AssignRmsDialog></AssignRmsDialog>);
            case DialogActionTypes.OPEN_CREATE_PLAYER_DIALOG:
                return (<CreatePlayerDialog></CreatePlayerDialog>);
            case DialogActionTypes.OPEN_ASSIGN_PLAYERS_DIALOG:
                return (<AssignPlayersDialog></AssignPlayersDialog>);
            case DialogActionTypes.OPEN_GAME_PAUSED_DIALOG:
                return (<GamePausedDialog text={'Game is Paused!'}></GamePausedDialog>);
            case DialogActionTypes.OPEN_ASSIGN_TRAINER_DIALOG:
                return (<AssignTrainerDialog></AssignTrainerDialog>);
            case DialogActionTypes.LEAVE_MEETING:
                return (<LeaveMeetingDialog></LeaveMeetingDialog>);
            case DialogActionTypes.SETTINGS_DIALOG:
                return (<AdminTrainingViewDialog></AdminTrainingViewDialog>);
            case DialogActionTypes.OPEN_FFA_DIALOG:
                return (<ForceFieldDialog></ForceFieldDialog>);
            case DialogActionTypes.OPEN_IN_SERVICE_DIALOG:
                return (<InServiceDialog></InServiceDialog>);
            case DialogActionTypes.OPEN_FFA_CONFIRM_DIALOG:
                return (<ConfirmFFADialog></ConfirmFFADialog>);
            case DialogActionTypes.OPEN_DEBRIEF_CONFIRM_DIALOG:
                return (<ConfirmDebriefDialog></ConfirmDebriefDialog>);
            case DialogActionTypes.OPEN_GRID_MAP_DIALOG:
                return (<GridMapDialog></GridMapDialog>);
            case DialogActionTypes.CONFIRM_GENERIC_DIALOG:
                return (<ConfirmGenericDialog textQuestion={openDialogSelector.confirmQuestionText} 
                        actionAfterDone={openDialogSelector.confirmButtonSelectedFor}></ConfirmGenericDialog>);
            default:
                return <div></div>;
        }
    }

    useEffect(() => {
        LoadingIndicatorService.stopLoading();
        return () => {
            LoadingIndicatorService.startLoading();
        }
    }, []);
    
    const containerClasses = openDialogSelector?.typeOfDialog === 'OPEN_MAP_ASSIGNMENT_DIALOG' ?
         `${classes.container} ${classes.assContainer} dialog-classes` : `${classes.container} dialog-classes`;

    return (<div className={classes.biggerContainer}>
            <Dialog classes={{ root: containerClasses }} key={`${openDialogSelector?.typeOfDialog.toLowerCase()}`} 
                id={openDialogSelector?.typeOfDialog.toLowerCase()} 
                aria-labelledby={openDialogSelector?.typeOfDialog + '-title'} open={checkIfDialogOpen()}>
                <DialogTitle id={openDialogSelector?.typeOfDialog + '-title'} className={classes.title}>
                   <span className={classes.titleh2}>
                       { dialogTitle }
                    </span>
                </DialogTitle>
                <DialogContent key={`${openDialogSelector?.typeOfDialog.toLowerCase()}-dialog`}  className={classes.contentContainer} dividers>
                    { returnDialogContent() }
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>)
}

export default DialogComponent