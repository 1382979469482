import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mainDiv: {
        marginLeft: '-20px',
        height: '395px',
        width: '98%'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1
    },
    container: {
        paddingTop: '5px',
        paddingBottom: theme.spacing(4),
        background: 'transparent',
        maxWidth: '100%;'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    fixedHeight: {
        minHeight: 240,
    },
    gameTitle: {
        '& h1': {
            margin: 0,
            fontSize: '21px'
        },
        '& h5': {
            color: '#6d6d70',
            margin: 0,
        }
    },
    mainGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        paddingTop: '27px',
        paddingLeft: '27px',

    },
    mainButtons: {
        width: '97%',
        height: '38px',
        margin: '0 21px 8px 0',
        padding: '10px 28px 10px 18px',
        borderRadius: '5px',
        boxSizing: 'border-box',
        color: 'white'
    },
    drivingForcesButton: {
        backgroundColor: '#296daa'
    },
    restrainingForcesButton: {
        backgroundColor: '#0d4171'
    },
    textarea: {
        width: '98%',
        height: '377px !important',
        margin: '8px 21px 13px 1px',
        marginLeft: '20px',
        padding: '17px 16px 5px 14px',
        borderRadius: '3px',
        border: 'solid 2px rgb(195, 197, 199)',
        backgroundColor: '#ffffff',
        fontFamily: 'Roboto',
        fontSize: '15px',
        boxSizing: 'border-box',
        resize: 'none',
        '&::placeholder' : {
            color: '#aeaeb0',
            fontFamily: 'Roboto',
            fontSize: '13px;'
        },
        '&:focus': {
            border: 'solid 2px #87c9e5',
            outline: 'none !important'
        }
    },
    facilitatorDiv: {
        position: 'absolute',
        marginTop: '20px',
        color: 'white',
        lineHeight: '43px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& #demo-simple-select-label.MuiFormLabel-filled': {
            display: 'none'
        },
        '& .MuiInputLabel-formControl': {
            zIndex: '9999'
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'unset !important',
            transition: 'unset !important'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'unset !important',
            transition: 'unset !important'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'unset !important'
        },
        '& .MuiInputBase-input': {
            animationName: 'unset !important',
            animationDuration: 'unset !important'
        },
        '& .Mui-selected': {
            backgroundColor: '#f5e02e'
        }
    },
    formControl: {
        width: '222px',
        marginTop: '-6px',
        marginLeft: '6px',
        fontSize:' 13px',
        marginBottom: '15px',
        fontFamily: 'Roboto',
        color: 'rgb(191, 191, 193)',
        '& .MuiInput-underline .MuiSelect-select.MuiSelect-selectMenu': {
            margin: '0px 0 2px -6px',
            padding:'4px 18px 3px 0px',
            borderRadius: '3px',
            border: 'solid 2px #aeaeb0',
            backgroundColor: '#fff;'
        }
    },
    formControlLabel: {
        marginTop: '-22px',
        marginLeft: '8px',
        fontSize: '15px',
        '& label': {
            color: 'rgb(29, 30, 36)',
        }
    },
    exampleClasses: {
        width: '100%',
        height: '45px',
        margin: '2px 0 7px 6px',
        marginLeft: '-11px',
        fontFamily: 'Roboto',
        fontSize: '13px',
        color: '#57575a'
    },
    examples2: {
        marginLeft: '-4px',
    },
    examples: {
        fontWeight: 'bold'
    },
    doneButton: {
        width: '176px',
        height: '44px',
        margin: '0px 269px 2px',
        fontSize: '17px',
        padding: '6px 12px 6px 13px',
        borderRadius:' 5px',
        border: 'solid 2px #296daa',
        backgroundImage: 'linear-gradient(to bottom, rgb(83, 166, 213), #296daa);',
        color: 'white',
        '&:disabled': {
            opacity: '0.5',
            cursor: 'not-allowed'
        }
    }
}));

export default useStyles;