import { Reducer } from 'redux';
import { ChatActions, ChatActionTypes } from './ChatActions';
import { MessageModel } from '../models/ChatModel';
import _ from 'lodash';
import moment from 'moment';

export interface ChatState {
    messages: MessageModel[]
}

const initialAdminState: ChatState = {
    messages: []
}

export const chatReducer: Reducer<ChatState, ChatActions> = (
    state = initialAdminState,
    action
) => {
    const newStateStage: ChatState = _.cloneDeep(state);
    switch (action.type) {
        case ChatActionTypes.ADD_MESSAGE: {
            const chatMessage = {message: action.payload?.message, username: action.payload?.from?.name, timestamp: moment.now() };
            return {
                ...newStateStage,
                messages: [...newStateStage.messages, chatMessage ]
            }
        }
        case ChatActionTypes.RESET_MESSAGES: {
            return {
                ...newStateStage,
                messages: []
            }
        }
        default: {
            return state;
        }
    }
}