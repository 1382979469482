import { DETERMINE_POSITION } from "../models/PositionModel";

export const determinPosition = (el: HTMLElement | null, defaultPositon = DETERMINE_POSITION.BOTTOM) => {
    if (el != null) {
        let buttonHeight = el.getBoundingClientRect().bottom;
        let position = el.getBoundingClientRect().top;
        let menuHeight =  el.getBoundingClientRect().height;
        let wins = window.outerHeight;
        
        if(position >= menuHeight && wins - position <= buttonHeight + menuHeight - 150) {
            return DETERMINE_POSITION.TOP;
        } else {
            return DETERMINE_POSITION.BOTTOM;
        }
    }
    return defaultPositon;
}