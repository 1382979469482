import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GameVisibilityService } from '../../../../game/services/GameVisibilityService';
import { AppState } from '../../../../store/Store';
import { OpentokDataModel } from '../../../../video/model/VideoModel';
import { opentokService } from '../../../../video/services/opentok.service';
import useStyles from './styles/ParticipantsListStyles';
import { useSelector } from 'react-redux';

const unsubscribe$ = new Subject();

const ParticipantsList = () => {
    const classes = useStyles();
    const [users, setUsers] = useState<OpentokDataModel[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [micMutted, setMicMutted] = useState(false);

    const usersData = (state: AppState) => state.userState.userData;
    const usersSelector = useSelector(usersData);
 
    useEffect(() => {
        GameVisibilityService.participantsListVisibility$
            .pipe(takeUntil(unsubscribe$))
            .subscribe((isVisible: boolean) => {
                setIsVisible(isVisible);
            });

        opentokService.listOfUsers$
            .pipe(takeUntil(unsubscribe$))
            .subscribe((participants: OpentokDataModel[]) => {
                setUsers(participants);
            });

            opentokService.isMicMuted$
                .pipe(takeUntil(unsubscribe$))
                .subscribe((isMutted: boolean) => {
                    setMicMutted(isMutted);
                });

        return () => {
            unsubscribe$.next();
            unsubscribe$.complete();
        }
    }, [])

    const handleToggleMic = () => {
        opentokService.isMicMuted$.next(!micMutted);
    }

    return (<div className={`${classes.container}${isVisible ? ' participants-visible' : ''}`}>
        <div>Participants <span>({users.length})</span></div>
        <ul>
            {users.map((user, index) => {
                let button = usersSelector.id === user.id
                    ? <button className={`${micMutted ? 'mutted' : ''}`} onClick={handleToggleMic}></button>
                    : '';
                return <li key={`parts-${index}`}>{user.name} <span>({usersSelector.id === user.id ? 'Me' : user.role})</span> {button} </li>;
            })}
        </ul>
    </div>);
}

export default ParticipantsList