import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/Store";
import { TrainingModel } from "../../models/AdminTrainingsModel";

type PropsDef = {
  items?: TrainingModel[];
  itemSelected: (id: any) => void;
};

const AdminTrainingList = (props: PropsDef) => {
  const stId = (state: AppState) => state.adminState.selectedTrainingId;
  const selectedTrainingId = useSelector(stId);

  const handleListItemClick = (id: any) => props.itemSelected(id);

  const parsedTrainings =
    props.items &&
    props.items.map((training: TrainingModel) => {
      training.date = moment(training.start_date).format("DD MMM YY, HH:mm");
      return training;
    });

  const getTrainingTitle = (training: TrainingModel) => {
    return training.id
      ? `[${training.id}] ${training.name}`
      : `${training.name}`;
  };

  return (
    <List className={"admin-list"}>
      {props && parsedTrainings
        ? parsedTrainings.map((training) => {
            return (
              <ListItem
                selected={selectedTrainingId === training.id}
                key={training.id}
                dense
                onClick={() => handleListItemClick(training.id)}
              >
                <ListItemText>{getTrainingTitle(training)}</ListItemText>
                <ListItemSecondaryAction>
                  {training.teams} groups
                  <span className="admin-date"> {training.date} </span>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        : "No Records"}
    </List>
  );
};
export default AdminTrainingList;
