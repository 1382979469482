/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { UserActions, UserActionTypes } from "../store/UserActions";
import { authService } from "../services/auth.service";
import useStyles from "../styles/styleClasses";
import { AppState } from "../../store/Store";
import { GameActions, GameActionTypes } from "../../game/store/GameActions";
import { SocketActions, SocketActionTypes } from "../../socket/SocketActions";
import { UserLoginData } from "../store/UserReducer";
import { GameStages } from "../../game/store/GameReducer";
import { LoadingIndicatorService } from "../../core/loadingIndicator/LoadingIndicatorService";
import { useLocation } from "react-router-dom";

const initialLoginValues: UserLoginData = {
  email: "",
  password: "",
};

let user: any;

const Login = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectIsError = (state: AppState) => state.userState.isError;
  const selectError = (state: AppState) => state.userState.error;
  const socketConnectionState = (state: AppState) => state.gameState.stage;
  const isError = useSelector(selectIsError);
  const error = useSelector(selectError);
  const socketConnectionStateSelector = useSelector(socketConnectionState);

  useEffect(() => {
    const gameid = new URLSearchParams(location.search).get("gameid");
    if (gameid) {
      dispatch({
        type: GameActionTypes.SET_GAME_ID,
        payload: gameid,
      });
    }
    //prepare all 4 actions to go through
    if (socketConnectionStateSelector === GameStages.CONNECTED) {
      const getGameId: SocketActions = {
        type: SocketActionTypes.GET_GAME_ID,
      };
      const getTrainings: SocketActions = {
        type: SocketActionTypes.GET_UPCOMING_TRAININGS,
        payload: true,
      };

      const loginSuccessAction: UserActions = {
        type: UserActionTypes.LOGIN_SUCCESS,
        token: user.signInUserSession.accessToken.jwtToken,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        username: user.attributes.email,
      };
      const gameLoginSuccessAction: GameActions = {
        type: GameActionTypes.USER_AUTHENTICATED_SUCCESSFULLY,
      };
      dispatch(getGameId);
      dispatch(getTrainings);
      setTimeout(() => {
        dispatch(loginSuccessAction);
        dispatch(gameLoginSuccessAction);
      }, 200);
    }
  }, [socketConnectionStateSelector, isError, error]);

  const [values, setValues] = useState(initialLoginValues);

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    LoadingIndicatorService.startLoading();

    const tryLoginAction: UserActions = {
      type: UserActionTypes.TRY_LOGIN,
    };

    dispatch(tryLoginAction);

    const loginResult = await authService.login(values);

    if (loginResult.success && loginResult.userData) {
      user = loginResult.userData;
      const token = loginResult.userData.signInUserSession.idToken.jwtToken;
      const socketConnectAction: SocketActions = {
        type: SocketActionTypes.TRY_CONNECT_SOCKET,
        idToken: token,
      };

      dispatch(socketConnectAction);
    } else if (loginResult.error) {
      LoadingIndicatorService.stopLoading();
      if (loginResult.error === "User is not confirmed.") {
        changeIntent("confirm");
      } else {
        const loginFailAction: UserActions = {
          type: UserActionTypes.LOGIN_FAIL,
          error: loginResult.error,
        };

        dispatch(loginFailAction);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      login(event);
    }
  };

  const changeIntent = (intent: "signup" | "confirm") => {
    const changeIntentAction: UserActions = {
      type: UserActionTypes.CHANGE_AUTH_INTENT,
      intent,
    };

    dispatch(changeIntentAction);
  };

  const changeValue = (e: any) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container component="main" className={classes.mainContainer}>
      <Container maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={`logo`}>
            <img src="/icons/logo-big.svg" alt="logo" />
          </div>

          <Grid container>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  changeIntent("signup");
                }}
                className={classes.register}
              >
                Register
              </Button>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
          </Grid>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              className={classes.textarea}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={changeValue}
              onKeyDown={(event) => handleKeyDown(event)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              className={classes.textarea}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={changeValue}
              onKeyDown={(event) => handleKeyDown(event)}
            />
            {isError ? <p className={classes.error}>{error}</p> : null}
            <Button fullWidth className={classes.login} onClick={login}>
              {" "}
              Sign In{" "}
            </Button>
            <Grid container>
              <a href="/forgot-pass" className={classes.link}>
                Forgot password?
              </a>
            </Grid>
          </form>
        </div>
      </Container>
    </Container>
  );
};

export default Login;
