import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    carouselPrev: {
        display: 'flex',
        position: 'absolute',
        height: '100px',
        zIndex: 999,
        left: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '41px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            transform: 'rotate(180deg)',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    carouselNext: {
        display: 'flex',
        position: 'absolute',
        height: '100px',
        zIndex: 999,
        right: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '41px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    currentTeam: {
        fontSize: '18px',
        color: '#1b1c20',
        fontWeight: 'bold'
    },
    team1Badge: {
        width: '29px',
        height: '29px',
        display: 'block'
    },
    team2Badge: {
        width: '29px',
        height: '29px',
        display: 'block'
    },
    team3Badge: {
        width: '29px',
        height: '29px',
        display: 'block'
    },
    bottomText: {
        fontSize: '12px',
        right: '-104px',
        fontWeight: 500,
        color: '#57575a',
    },
    last: {
        padding: '0 !important',
        backgroundColor: '#fff !important'
    },
    tableCell: {
        lineHeight: '0px'
    },
}));

export default useStyles;