import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GroupOptions, ItemInterface, ReactSortable } from "react-sortablejs";
import { AppState } from "../../../store/Store";
import useStyles from './styles/assignResources';
import { AssignUserModel } from "./user.model";
import _ from 'lodash';
import { GameActions, GameActionTypes } from "../../../game/store/GameActions";
import { AssignUserToResource, RemoveUserFromResource, SocketActionTypes } from "../../../socket/SocketActions";
// import { useToasts } from "react-toast-notifications";

const groupOptions: GroupOptions = { name: 'group1', put: true, pull: 'clone' };
const groupOptions2: GroupOptions = { name: 'group2', put: true };
const groupOptions3: GroupOptions = { name: 'group3', put: true };
const groupOptions4: GroupOptions = { name: 'group4', put: true };
const groupOptions5: GroupOptions = { name: 'group5', put: true };
const groupOptions6: GroupOptions = { name: 'group6', put: true };
const groupOptions7: GroupOptions = { name: 'group7', put: true };
const groupOptions8: GroupOptions = { name: 'group8', put: true };

const AssignResources = (_props: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // const { addToast } = useToasts();

    const [error, setError] = useState<boolean>(false);

    const assignedUsersSelector = (state: AppState) => state.gameState.gameUsers;
    const assignedUsers: ItemInterface[] = useSelector(assignedUsersSelector);

    const teamsSelector = (state: AppState) => state.gameState.teams;
    const teams: any = useSelector(teamsSelector);

    const updateState = (e: any) => {}

    const checkIfErrorIsShown = () => {
        if (error) {
            return (<div className={classes.error}>
                <span className={classes.errorImage}></span> &nbsp; 
                You can't assign one member to two different resources!
            </div>) 
        }
        return (<span></span>)
    }

    const setUpdatedUsers = (updatedUsers: any[]) => {
        const setUsersAction: GameActions = {
            type: GameActionTypes.CHANGE_USERS,
            payload: {users: updatedUsers}
        };
        dispatch(setUsersAction);
    }

    const dispatchAssignedUser = (resourceName: string, updatedUsers: any[], xorUsers: any[]) => {
        setUpdatedUsers(updatedUsers);
        const assignUserToResouceAction: AssignUserToResource = {
            type: SocketActionTypes.ASSIGN_USER_TO_RESOURCE,
            payload: {newUsers: xorUsers, resourceName}
        }
        dispatch(assignUserToResouceAction);
    }

    const setErrorUsers = (resourceName: string, xorUsers: any[]) => {
        const userName = xorUsers[0].name;
        return {error: true, resourceName, userName }
    }

    const setUsers = (users: ItemInterface[], teamName: string, teamId: number) => {
        if (users?.length) {
            const newUsersArr: ItemInterface[] = [];
            const updatedUsers: any[] = [];
            users.forEach(user => {
                const stateArr = assignedUsers.filter((userNew : any) => userNew.id === user.id);
                stateArr.forEach((userFromState: ItemInterface) => {
                    let userError = false;
                    const userCopy = {...userFromState };
                    if (!userCopy.teams) {
                        userCopy.teams = [];
                    }
                    if (!userCopy.availableTeam) {
                        userCopy.availableTeam = teamName;
                    }
                    if (!userCopy.teams.includes(teamId) && userCopy.availableTeam === teamName) {
                        userCopy.teams.push(teamId);
                    } else {
                        userError = true;
                    }
                    if (userCopy.teams.length === 2) {
                        userCopy.filtered = true;
                    }
                    if (userCopy.availableTeam === teamName) {
                        newUsersArr.push(userFromState);
                        setError(false);
                    }
                    if (!userError) {
                        updatedUsers.push(userCopy);
                    }
                });
            });
            if (!newUsersArr.length) {
                setError(true);
            }
            let errorFound: any;
            // let userName, resourceName = '';
            switch (teamId) {
                case 1:
                    const uniqueUsers = _.uniqBy(newUsersArr, 'id');
                    const xorUsers = _.xorBy([...teams.herbs], [...uniqueUsers], 'id');
                    if (teams.herbs && teams.herbs.length === 0 && xorUsers.length) {
                        dispatchAssignedUser('herbs', updatedUsers, xorUsers);
                    } else if (xorUsers.length) {
                        errorFound = setErrorUsers('Herbs', xorUsers);
                    }
                    break;
                case 2:
                    const xorUsers2 = _.xorBy([...teams.herbs2], [...newUsersArr], 'id');
                    if (teams.herbs2 && teams.herbs2.length === 0 && xorUsers2.length) {
                        dispatchAssignedUser('herbs2', updatedUsers, xorUsers2);
                    } else if (xorUsers2.length) {
                        errorFound = setErrorUsers('Herbs', xorUsers2);
                    }
                    break;
                case 3:
                    const xorUsers3 = _.xorBy([...teams.humidity], [...newUsersArr], 'id');
                    if (teams.humidity && teams.humidity.length === 0 && xorUsers3.length) {
                        dispatchAssignedUser('humidity', updatedUsers, xorUsers3);
                    } else if (xorUsers3.length) {
                        errorFound = setErrorUsers('Humidity', xorUsers3);
                    }
                    break;
                case 4:
                    const xorUsers4 = _.xorBy([...teams.humidity2], [...newUsersArr], 'id');
                    if (teams.humidity2 && teams.humidity2.length === 0 && xorUsers4.length) {
                        dispatchAssignedUser('humidity2', updatedUsers, xorUsers4);
                    } else if (xorUsers4.length) {
                        errorFound = setErrorUsers('Humidity', xorUsers4);
                    }
                    break;
                case 5:
                    const xorUsers5 = _.xorBy([...teams.sun], [...newUsersArr], 'id');
                    if (teams.sun && teams.sun.length === 0 && xorUsers5.length) {
                        dispatchAssignedUser('sun', updatedUsers, xorUsers5);
                    } else if (xorUsers5.length) {
                        errorFound = setErrorUsers('Sun', xorUsers5);
                    }
                    break;
                case 6:
                    const xorUsers6 = _.xorBy([...teams.sun2], [...newUsersArr], 'id');
                    if (teams.sun2 && teams.sun2.length === 0 && xorUsers6.length) {
                        dispatchAssignedUser('sun2', updatedUsers, xorUsers6);
                    } else if (xorUsers6.length) {
                        errorFound = setErrorUsers('Sun', xorUsers6);
                    }
                    break;
                case 7:
                    const xorUsers7 = _.xorBy([...teams.crops], [...newUsersArr], 'id');
                    if (teams.crops && teams.crops.length === 0 && xorUsers7.length) {
                        dispatchAssignedUser('crops', updatedUsers, xorUsers7);
                    } else if (xorUsers7.length) {
                        errorFound = setErrorUsers('Crops', xorUsers7);
                    }
                    break;
                case 8:
                    const xorUsers8 = _.xorBy([...teams.crops2], [...newUsersArr], 'id');
                    if (teams.crops2 && teams.crops2.length === 0 && xorUsers8.length) {
                        dispatchAssignedUser('crops2', updatedUsers, xorUsers8);
                    } else if (xorUsers8.length) {
                        errorFound = setErrorUsers('Crops', xorUsers8);
                    }
                    break;
            }

            if (errorFound) {
                // userName = errorFound?.userName;
                // resourceName = errorFound?.resourceName;
                // addToast(`Error: You cannot add ${userName} to ${resourceName}, because another user is assigned!`, 
                //     {appearance: 'error', autoDismiss: true});
            }
        }
    };

    const containerClasses1 = `${classes.containerElements} ${classes.herbsContainers}`;
    const containerClasses2 = `${classes.containerElements} ${classes.herbsContainers} ${classes.herbsContainers2}`;
    const containerClasses3 = `${classes.containerElements} ${classes.humidityContainers}`;
    const containerClasses4 = `${classes.containerElements} ${classes.humidityContainers} ${classes.humidityContainers2}`;
    const containerClasses5 = `${classes.containerElements} ${classes.sunContainers}`;
    const containerClasses6 = `${classes.containerElements} ${classes.sunContainers} ${classes.sunContainers2}`;
    const containerClasses7 = `${classes.containerElements} ${classes.sunContainers} ${classes.cropsContainers}`;
    const containerClasses8 = `${classes.containerElements} ${classes.sunContainers} ${classes.cropsContainers} ${classes.sunContainers2}`;
    const humidityTick = `${classes.tick}`;
    const humidityLabels = `${classes.elementLabel} ${classes.humidityLabel}`;
    const cropsLabels = `${classes.elementLabel} ${classes.cropsLabel}`;


    const getClasses = (user: AssignUserModel): string => {
        let keyClasses = classes.divItem;
        if (user.filtered) {
            keyClasses += ` ${classes.disabledItem}`;
        }
        if (user.teams?.length === 2) {
            keyClasses += ` ${classes.disableSelect}`;
        }
        return keyClasses;
    }

    const dragContainer2 = `${classes.containerToDrag} ${classes.containerToDrag2}`;
    const divItemCrops = `${classes.divItem}`;

    const checkStyles = (teamUsers: any[], initialClasses: string) => {
        if (!teamUsers.length) {
            return `${initialClasses}`;
        }
        return `${initialClasses} ${classes.containerAssignedUsers}`;
    }

    const checkElementLabel = (teamUsers: any[], initialClasses: string) => {
        if (!teamUsers.length) {
            return `${initialClasses}`;
        }
        return `${initialClasses} ${classes.elementLabelAssigned}`;
    }

    const checkUserTeam = (user: AssignUserModel) => {
        if (user?.teams?.length) {
            if (user.teams.length === 1) {
                return (<span className={classes.assignedTick}>
                        <img src="icons/shape.svg" alt="tick" />
                    </span>);
            } else if (user.teams.length === 2) {
                return (<span>
                        <span className={classes.assignedTick}>
                            <img src="icons/shape.svg" alt="tick" />
                        </span>
                        <span className={classes.assignedTick2}>
                            <img src="icons/shape.svg" alt="tick" />
                        </span>
                    </span>);
            }
        }
        return (<></>);
    }

    const returnSortableEl = () => {
        if (assignedUsers?.length) {
            return (<ReactSortable
                className={classes.namesContainer}
                group={groupOptions}
                animation={200}
                list={assignedUsers}
                filter={".sortable-filter"}
                setList={(e) => updateState(e)} >
                {assignedUsers?.map((user: AssignUserModel) => (
                    <div className={getClasses(user)} key={user.id}>
                        <span className={classes.item}>{user.name}</span>
                        {checkUserTeam(user)}
                    </div>
                ))}
                </ReactSortable>)
        } 
    }

    const deleteUsers = (user: any, teamName: string) => {
        const userArr = [user];
        const removeUserFromResource: RemoveUserFromResource = {
            type: SocketActionTypes.REMOVE_USER_FROM_RESOURCE,
            payload: {newUsers: userArr, resourceName: teamName}
        }
        dispatch(removeUserFromResource);
    }

    return (
        <div className="main-cont">
            <div className={classes.mainDiv}>
                <div className={classes.mainLabel}>Drag & Drop from team members column to each card
                    <span className={classes.dragIcon}>
                      <img src="icons/icons-transfer-arrow-right.png" alt="Drag text" />
                    </span>
                </div>

                {/* setState */}
                { returnSortableEl() }

                <div className={classes.elementsContainer}>
                  {/* Herbs Start */}
                    <div className={checkStyles(teams.herbs, containerClasses1)}>
                        <div className={checkElementLabel(teams.herbs, classes.elementLabel)}>
                            Herbs
                            <span className={classes.tick}>
                                <img src="icons/icons-checked-grey.svg" alt="tick" />
                            </span>
                            <ReactSortable
                                className={classes.containerToDrag}
                                group={groupOptions}
                                animation={200}
                                list={teams.herbs}
                                setList={(users) => { setUsers(users, 'herbs', 1) }} >
                                {teams.herbs?.map((user: ItemInterface) => (
                                    <div className={classes.divItem} key={user.id}>
                                        <span className={classes.item}>{user.name}
                                            <span className={classes.spanTick}>
                                                <img src="icons/icons-remove-green.svg" alt="tick" 
                                                    className={classes.xTick} 
                                                    onClick={() => deleteUsers(user, 'herbs')} />
                                            </span>
                                        </span>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>

                    <div className={checkStyles(teams.herbs2, containerClasses2)}>
                        <div className={checkElementLabel(teams.herbs2, classes.elementLabel)}>
                            Herbs
                            <span className={classes.tick}>
                            <img src="icons/icons-checked-grey.svg" alt="tick" />
                            </span>
                            <ReactSortable
                                className={classes.containerToDrag}
                                group={groupOptions2}
                                animation={200}
                                list={teams.herbs2}
                                setList={(users) => { setUsers(users, 'herbs', 2) }}>
                                {teams.herbs2?.map((user: ItemInterface) => (
                                    <div className={classes.divItem} key={user.id}>
                                        <span className={classes.item}>{user.name}
                                            <span className={classes.spanTick}>
                                                <img src="icons/icons-remove-green.svg" 
                                                    alt="tick" 
                                                    className={classes.xTick}
                                                    onClick={() => deleteUsers(user, 'herbs2')} />
                                            </span>
                                        </span>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>
                    {/* Herbs End */}

                    {/* Humidity Start */}

                    <div className={checkStyles(teams.humidity, containerClasses3)}>
                        <div className={checkElementLabel(teams.humidity, humidityLabels)}>
                            Humidity
                            <span className={humidityTick}>
                            <img src="icons/icons-checked-grey.svg" alt="tick" />
                            </span>
                            <ReactSortable
                                className={dragContainer2}
                                group={groupOptions3}
                                animation={200}
                                list={teams.humidity}
                                setList={(users) => { setUsers(users, 'humidity', 3) }}>
                                {teams.humidity?.map((user: ItemInterface) => (
                                    <div className={classes.divItem} key={user.id}>
                                        <span className={classes.item}>{user.name}
                                            <span className={classes.spanTick}>
                                            <img src="icons/icons-remove-green.svg" 
                                                    alt="tick" 
                                                    className={classes.xTick}
                                                    onClick={() => deleteUsers(user, 'humidity')} />
                                            </span>
                                        </span>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>

                    <div className={checkStyles(teams.humidity2, containerClasses4)}>
                        <div className={checkElementLabel(teams.humidity2, humidityLabels)}>
                            Humidity
                            <span className={humidityTick}>
                            <img src="icons/icons-checked-grey.svg" alt="tick" />
                            </span>
                            <ReactSortable
                                className={dragContainer2}
                                group={groupOptions4}
                                animation={200}
                                list={teams.humidity2}
                                setList={(users) => { setUsers(users, 'humidity', 4) }}>
                                {teams.humidity2?.map((user: ItemInterface) => (
                                    <div className={classes.divItem} key={user.id}>
                                        <span className={classes.item}>{user.name}
                                            <span className={classes.spanTick}>
                                            <img src="icons/icons-remove-green.svg" 
                                                    alt="tick" 
                                                    className={classes.xTick}
                                                    onClick={() => deleteUsers(user, 'humidity2')} />
                                            </span>
                                        </span>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>
                    {/* Humidity End */}

                    {/* Sun Start */}
                    <div className={checkStyles(teams.sun, containerClasses5)}>
                        <div className={checkElementLabel(teams.sun, classes.elementLabel)}>
                            Sun
                            <span className={classes.tick}>
                            <img src="icons/icons-checked-grey.svg" alt="tick" />
                            </span>
                            <ReactSortable
                                className={classes.containerToDrag}
                                group={groupOptions5}
                                animation={200}
                                list={teams.sun}
                                setList={(users) => { setUsers(users, 'sun', 5) }} >
                                {teams.sun?.map((user: ItemInterface) => (
                                    <div className={classes.divItem} key={user.id}>
                                        <span className={classes.item}>{user.name}
                                            <span className={classes.spanTick}>
                                            <img src="icons/icons-remove-green.svg" 
                                                    alt="tick" 
                                                    className={classes.xTick}
                                                    onClick={() => deleteUsers(user, 'sun')} />
                                            </span>
                                        </span>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    </div>

                    <div className={checkStyles(teams.sun2, containerClasses6)}>
                        <div className={checkElementLabel(teams.sun2, classes.elementLabel)}>
                          Sun
                          <span className={classes.tick}>
                          <img src="icons/icons-checked-grey.svg" alt="tick" />
                          </span>
                          <ReactSortable
                              className={classes.containerToDrag}
                              group={groupOptions6}
                              animation={200}
                              list={teams.sun2}
                              setList={(users) => { setUsers(users, 'sun', 6) }} >
                              {teams.sun2?.map((user: ItemInterface) => (
                                  <div className={classes.divItem} key={user.id}>
                                      <span className={classes.item}>{user.name}
                                        <span className={classes.spanTick}>
                                            <img src="icons/icons-remove-green.svg" 
                                                alt="tick" 
                                                className={classes.xTick}
                                                onClick={() => deleteUsers(user, 'sun2')} />
                                        </span>
                                      </span>
                                  </div>
                              ))}
                          </ReactSortable>
                        </div>
                    </div>
                    {/* Sun End */}

                    {/* Crops Start */}
                    <div className={checkStyles(teams.crops, containerClasses7)}>
                        <div className={checkElementLabel(teams.crops, cropsLabels)}>
                          5km from crops
                          <span className={classes.tick}>
                          <img src="icons/icons-checked-grey.svg" alt="tick" />
                          </span>
                          <ReactSortable
                              className={classes.containerToDrag}
                              group={groupOptions7}
                              animation={200}
                              list={teams.crops}
                              setList={(users) => { setUsers(users, 'crops', 7) }} >
                              {teams.crops?.map((user: ItemInterface) => (
                                  <div className={divItemCrops} key={user.id}>
                                      <span className={classes.item}>{user.name}
                                        <span className={classes.spanTick}>
                                            <img src="icons/icons-remove-green.svg" 
                                                alt="tick" 
                                                className={classes.xTick}
                                                onClick={() => deleteUsers(user, 'crops')} />
                                        </span>
                                      </span>
                                  </div>
                              ))}
                          </ReactSortable>
                        </div>
                    </div>

                    <div className={checkStyles(teams.crops2, containerClasses8)}>
                        <div className={checkElementLabel(teams.crops2, cropsLabels)}>
                          5km from crops
                          <span className={classes.tick}>
                          <img src="icons/icons-checked-grey.svg" alt="tick" />
                          </span>
                          <ReactSortable
                              className={classes.containerToDrag}
                              group={groupOptions8}
                              animation={200}
                              list={teams.crops2}
                              setList={(users) => { setUsers(users, 'crops', 8) }} >
                              {teams.crops2?.map((user: ItemInterface) => (
                                  <div className={divItemCrops} key={user.id}>
                                      <span className={classes.item}>{user.name}
                                        <span className={classes.spanTick}>
                                            <img src="icons/icons-remove-green.svg" 
                                                alt="tick" 
                                                className={classes.xTick}
                                                onClick={() => deleteUsers(user, 'crops2')} />
                                        </span>
                                      </span>
                                  </div>
                              ))}
                          </ReactSortable>
                        </div>
                    </div>
                    {/* Crops End */}
                    {/* {checkIfDoneButtonIsShown()} */}
                </div>
                {checkIfErrorIsShown()}
            </div>
        </div>
    );
};

export default AssignResources;
