import { GameStages } from "../../game/store/GameReducer";
import { UserRoleModel } from "../models/UserModel";

const skipControlsVisibilityArr: any = {
    player: [GameStages.CREATED],
    rm: [GameStages.CREATED],
    trainer: []
};

export const isSidebarVisible = (role: UserRoleModel, stage: GameStages) => {
    const controlOpt = skipControlsVisibilityArr[role.toLocaleLowerCase()];
    return controlOpt && controlOpt.indexOf(stage) === -1;
}
