import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminTrainingView from "../../../../../admin/components/training/AdminTrainingView";
import { SocketActionTypes } from "../../../../../socket/SocketActions";
import { AppState } from "../../../../../store/Store";
import { DialogActionTypes } from "../../store/DialogActions";
import "./styles/AdminTrainingViewDialogStyles.scss";

const AdminTrainingViewDialog = (props?: any) => {
  const gameDetailsState = (state: AppState) => state.gameState.gameDetails;
  const gameDetails: any = useSelector(gameDetailsState);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({
      type: DialogActionTypes.HIDE_ALL,
    });
    dispatch({
      type: SocketActionTypes.CURRENT_STAGE,
    });
  };

  return (
    <>
      <div
        key={gameDetails?.id}
        id="admin-panel-container"
        className={`training-view-dialog`}
      >
        <div key={gameDetails?.id} id="admin-dashboard-container">
          <AdminTrainingView
            title={`Change Training Settings`}
            key={gameDetails?.id}
            onCancel={handleClose}
            showSave={true}
            isNew={false}
            training={gameDetails}
          />
        </div>
      </div>
    </>
  );
};

export default AdminTrainingViewDialog;
