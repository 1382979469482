import { TextareaAutosize } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import "./styles/AdminDebriefStyles.scss";

type DebriefProps = {
  question?: string;
  index?: number;
  onChange?: Function;
};

const AdminDebrief = (props: DebriefProps) => {
  const handleChange = (event: ChangeEvent<{ value: string }>) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <div className={`debrief-container`} key={props?.index}>
      <TextareaAutosize
        minRows={3}
        placeholder="Enter question text"
        defaultValue={props.question || ""}
        onChange={(event) => handleChange(event)}
      />
    </div>
  );
};

export default AdminDebrief;
