import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mainDiv: {
        marginLeft: '-78px',
        marginTop: '33px'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1
    },
    container: {
        paddingTop: '5px',
        paddingBottom: theme.spacing(4),
        background: 'transparent'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    fixedMainHeight: {
        minHeight: 550
    },
    fixedHeight: {
        minHeight: 240,
    },
    gameTitle: {
        marginBottom: '20px',
        '& h1': {
            margin: 0,
            fontSize: '21px'
        },
        '& h5': {
            color: '#6d6d70',
            margin: 0,
        }
    },
    mainGrid: {
        overflow: 'auto',
        height: '550px',
        '&::-webkit-scrollbar': {
            width: '13px',
            backgroundColor: 'white'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#427d96',
          borderRadius: '2px'
        }
    },
    divQuestion: {
        marginLeft: '86px'  
    },
    pStyles: {
        textAlign: 'left',
        marginLeft: '12px',
        marginBottom: '8px',
        fontWeight: 'bold',
        marginRight: '13px'
    },
    mainButtons: {
        width: '331px',
        height: '38px',
        margin: '0 21px 8px 0',
        padding: '10px 28px 10px 18px',
        borderRadius: '5px',
        boxSizing: 'border-box',
        color: 'white'
    },
    mainDialog: {
        borderRadius: '10px;',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.5)',
        border: 'unset !important',
        height: '600px',
        backgroundColor: '#ffffff',
        minWidth: '790px',
        '&::-webkit-scrollbar': {
            width: '13px',
            backgroundColor: 'white'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#427d96',
          borderRadius: '2px'
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: '700px',
        },
    },
    drivingForcesButton: {
        backgroundColor: '#296daa',
        borderRadius: '2px'
    },
    restrainingForcesButton: {
        backgroundColor: '#0d4171',
        borderRadius: '2px'
    },
    textarea: {
        height: '105px !important',
        width: '96%;',
        overflow: 'auto !important',
        margin: '8px 21px 13px 1px',
        padding: '17px 16px 5px 14px',
        borderRadius: '3px',
        border: 'solid 2px rgb(195, 197, 199)',
        backgroundColor: '#ffffff',
        fontFamily: 'Roboto',
        fontSize: '15px',
        boxSizing: 'border-box',
        resize: 'none',
        '&::placeholder' : {
            color: '#aeaeb0',
            fontFamily: 'Roboto',
            fontSize: '13px;'
        },
    },
    facilitatorDiv1: {
        position: 'absolute',
        marginTop: '-44px',
        marginLeft: '381px',
    },
    formControl: {
        width: '222px',
        marginTop: '-7px',
        marginLeft: '12px',
        fontSize:' 13px',
        marginBottom: '15px;',
        fontFamily: 'Roboto',
        color: 'rgb(191, 191, 193)',
        '& .MuiInput-underline .MuiSelect-select.MuiSelect-selectMenu': {
            margin: '0px 0 2px -6px',
            padding:'6px 17px 3px 8px',
            borderRadius: '3px',
            border: 'solid 2px #aeaeb0',
            backgroundColor: '#fff;'
        }
    },
    formControl1: {
        width: '222px',
        marginTop: '-21px',
        marginLeft: '12px',
        fontSize:' 13px',
        marginBottom: '15px;',
        fontFamily: 'Roboto',
        color: 'rgb(191, 191, 193)',
        '& .MuiInput-underline .MuiSelect-select.MuiSelect-selectMenu': {
            margin: '0px 0 2px -6px',
            padding:'6px 17px 3px 8px',
            borderRadius: '3px',
            border: 'solid 2px #aeaeb0',
            backgroundColor: '#fff;'
        },
        '& label': {
            color: 'rgb(29, 30, 36)',
            marginTop: '-2px',
            marginLeft: '-3px',
            fontFamily: 'Roboto',
            fontSize: '15px',
        }
    },
    exampleClasses: {
        width: '339px',
        height: '45px',
        margin: '2px 0 7px 6px',
        fontFamily: 'Roboto;',
        fontSize: '13px',
        color: '#57575a'
    },
    examples: {
        fontWeight: 'bold'
    },
    doneButton: {
        width: '176px',
        height: '44px',
        margin: '0px 269px 2px',
        fontSize: '17px',
        padding: '6px 12px 6px 13px',
        borderRadius:' 5px',
        border: 'solid 2px #296daa',
        backgroundImage: 'linear-gradient(to bottom, rgb(83, 166, 213), #296daa);',
        color: 'white',
        '&:disabled': {
            opacity: '0.5',
            cursor: 'not-allowed'
        }
    }
}));

export default useStyles;