import React from 'react';
import useStyles from './styles/MapMemorizationDialogStyles';
import { Grid } from '@material-ui/core';
import MapMemorizationGrid from './mapMemorizationGrid';
import { AppState } from '../../../../../store/Store';
import { useSelector } from 'react-redux';

const MapMemorizationDialog = () => {
    const classes = useStyles();

    const selectResource = (state: AppState) => state.gameState.resourceName;
    const userResource = useSelector(selectResource);

    return (<div>
        <Grid container spacing={3} className={classes.contContainer}>
            <Grid item xs={12} className={classes.grid}>
                <span className={classes.header}>Try to remember the squares with X.</span>
            </Grid>
            <Grid item xs={12} className={classes.teamGrid}>
                <span className={classes.teamName}>{userResource}</span>
            </Grid>
        </Grid>
        <MapMemorizationGrid></MapMemorizationGrid>
    </div>)
}

export default MapMemorizationDialog;