/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": process.env.REACT_APP_WSS_URL,//"https://3v3w1kzsrc.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": process.env.REACT_APP_USER_POOL_ID,//"eu-west-1_nmr9UFAcL",
    "aws_user_pools_web_client_id": process.env.REACT_APP_WEB_CLIENT_ID,//"3q0lkihb3p951bmi2tpt1d4dql",
    "oauth": {}
};

export default awsmobile;
