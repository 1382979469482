import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/Store';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles/CountdownStyles';
import moment from 'moment';
import { SocketActionTypes } from '../../../socket/SocketActions';
import { DialogActionTypes } from '../dialogComponent/store/DialogActions';
import { GameStages } from '../../../game/store/GameReducer';

let intervalId: any = undefined;
let remainingTime = 0;
let dateSecsToEnd: number = 0;

const CountDown = () => {
    const hiddenStages = [GameStages.STAGE_FORCE_FIELD_ANALYSIS];
    const classes = useStyles();
    const [state, setState] = useState<any>();
    const [lessThanMinuteLeft, setLessThanMinuteLeft] = useState<boolean>(false);

    const selectTimeEnd = (state: AppState) => state.gameState.currentStageTimerEnd;
    const currentStage = (state: AppState) => state.gameState.stage;
    const timeEnd = useSelector(selectTimeEnd);
    const currentStageSelector = useSelector(currentStage);

    const setTime = () => {
        dateSecsToEnd = moment().add(timeEnd, 'seconds').unix() * 1000;
        intervalId = setInterval(
            () => {
                tick();
            }
            ,1000
        );
    };

    useEffect(() => {
        if (timeEnd) {
            setTime();
        } else {
            clearInt();
        }

        return () => {
            clearInt();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeEnd]);

    const locationsArr = ['game', 'service', 'final-discussion'];
    const inServiceLocation = 'service';
    const dispatch = useDispatch();

    const tick = () => {
        remainingTime = timeEnd ? (Math.floor(dateSecsToEnd - moment.now()) / 1000) : 0;
        if (timeEnd) {
            const location = window.location.href;
            const foundLocation = locationsArr.findIndex(item => location.includes(item));
            if (remainingTime !== null && remainingTime > 0 && foundLocation > -1) {
                const seconds = Math.floor(remainingTime % 60);
                const minutes = Math.floor(remainingTime / 60);
                if (minutes === 0) {
                    setLessThanMinuteLeft(true);
                } else {
                    setLessThanMinuteLeft(false);
                }
                setState({seconds, minutes});
            } else {
                if (location.includes(inServiceLocation)) {
                    dispatch({
                        type: DialogActionTypes.HIDE_ALL
                    });
                    dispatch({
                        type: SocketActionTypes.RETURN_FROM_IN_SERVICE,
                        payload: true
                    });
                }
                clearInt();
            }
        }
    }

    const getTitleText = () => {
        const location = window.location.href;
        if (location.includes('service') && lessThanMinuteLeft) {
            return `Time to decide for In-service option`;
        } else {
            return `Time left for this activity`;
        }
    }

    const clearInt = () => {
        clearInterval(intervalId);
    }

    const checkClassesCounter = () => {
        const lessThanMinute = Boolean(lessThanMinuteLeft);
        if (lessThanMinute) {
            return `${classes.counter} ${classes.counterRed}`;
        } else {
            return classes.counter;
        }
    }

    const getContainerStyles = () => {
        if (lessThanMinuteLeft) {
            return `${classes.container} ${classes.containerBigger}`;
        } else {
            return classes.container;
        }
    }

    return (hiddenStages.indexOf(currentStageSelector) >= 0 ? <></> :
    <div className={getContainerStyles()}>
        <span className={classes.text}>{getTitleText()}</span>
        {remainingTime !== null && remainingTime > 0 ?
            <span className={checkClassesCounter()}>
                <span>
                    {state && state?.minutes !== null && state?.minutes < 10 ? `0${state?.minutes}` : state?.minutes}:
                </span>
                <span>
                    {state && state?.seconds !== null && state.seconds < 10 ?
                    `0${state?.seconds}` :
                    state?.seconds}
                </span>
            </span> :
            <span className={classes.counter}>00:00</span>}
    </div>)

}

export default CountDown;