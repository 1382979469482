export enum DialogActionTypes {
  HIDE_ALL = "HIDE_ALL",
  TEXT_DIALOG = "TEXT_DIALOG",
  SHARE_SCREEN = "SHARE_SCREEN",
  OPEN_MNGMT_DIALOG_MEMO = "OPEN_MNGMT_DIALOG_MEMO_SOCKET",
  OPEN_MNGMT_DIALOG = "OPEN_MNGMT_DIALOG_MEMO",
  OPEN_FFA_DIALOG = "OPEN_FFA_DIALOG",
  OPEN_MAP_ASSIGNMENT_DIALOG = "OPEN_MAP_ASSIGNMENT_DIALOG",
  OPEN_MAP_ASSIGNMENT_DIALOG_SOCKET = "OPEN_MAP_ASSIGNMENT_DIALOG_SOCKET",
  OPEN_MAP_MEMORIZATION_DIALOG_SOCKET = "OPEN_MAP_MEMORIZATION_DIALOG_SOCKET",
  OPEN_MAP_MEMORIZATION_DIALOG = "OPEN_MAP_MEMORIZATION_DIALOG",
  OPEN_FFA_CONFIRM_DIALOG = "OPEN_FFA_CONFIRM_DIALOG",
  OPEN_IN_SERVICE_DIALOG = "OPEN_IN_SERVICE_DIALOG",
  OPEN_DEBRIEF_CONFIRM_DIALOG = "OPEN_DEBRIEF_CONFIRM_DIALOG",
  OPEN_GRID_MAP_DIALOG = "OPEN_GRID_MAP_DIALOG",
  OPEN_ASSIGN_RMS_DIALOG = "OPEN_ASSIGN_RMS_DIALOG",
  OPEN_CREATE_PLAYER_DIALOG = "OPEN_CREATE_PLAYER_DIALOG",
  OPEN_ASSIGN_PLAYERS_DIALOG = "OPEN_ASSIGN_PLAYERS_DIALOG",
  OPEN_ASSIGN_TRAINER_DIALOG = "OPEN_ASSIGN_TRAINER_DIALOG",
  OPEN_GAME_PAUSED_DIALOG = "OPEN_GAME_PAUSED_DIALOG",
  MNGT_DIALOG_MEMO = "MNGT_DIALOG_MEMO",
  SELECT_GAME_STAGE_DIALOG = "SELECT_GAME_STAGE_DIALOG",
  LEAVE_MEETING = "LEAVE_MEETING",
  SETTINGS_DIALOG = "SETTINGS_DIALOG",
  CONFIRM_GENERIC_DIALOG = "CONFIRM_GENERIC_DIALOG",
  SET_CONFIRM_BUTTON = "SET_CONFIRM_BUTTON",
}

export interface HideAllDialogsAction {
  type: DialogActionTypes.HIDE_ALL;
}

export interface OpenShareScreenDialog {
  type: DialogActionTypes.SHARE_SCREEN;
  payload: { open?: boolean };
}

export interface GamePausedDialog {
  type: DialogActionTypes.OPEN_GAME_PAUSED_DIALOG;
  payload: any;
}

export interface OpenLeaveMeetingDialogAction {
  type: DialogActionTypes.LEAVE_MEETING;
  payload: { open?: boolean };
}

export interface OpenAssignRmsDialogAction {
  type: DialogActionTypes.OPEN_ASSIGN_RMS_DIALOG;
  payload: any;
}
export interface OpenAssignPlayersDialogAction {
  type: DialogActionTypes.OPEN_ASSIGN_PLAYERS_DIALOG;
  payload: any;
}

export interface OpenCreatePlayerDialogAction {
  type: DialogActionTypes.OPEN_CREATE_PLAYER_DIALOG;
  payload: any;
}
export interface OpenAssignTrainerDialogAction {
  type: DialogActionTypes.OPEN_ASSIGN_TRAINER_DIALOG;
  payload: any;
}

export interface OpenAdminTrainingViewDialogAction {
  type: DialogActionTypes.SETTINGS_DIALOG;
  payload: { open?: boolean };
}

export interface SetConfirmButtonAction {
  type: DialogActionTypes.SET_CONFIRM_BUTTON;
  payload: any;
}

export interface ConfirmGenericDialogAction {
  type: DialogActionTypes.CONFIRM_GENERIC_DIALOG;
  payload: {
    open?: boolean;
    questionText: string;
    confirmButtonSelectedFor: string;
  };
}

export interface OpenGridMapDialogAction {
  type: DialogActionTypes.OPEN_GRID_MAP_DIALOG;
  payload: any;
}

export interface SelectGameStageDialogAction {
  type: DialogActionTypes.SELECT_GAME_STAGE_DIALOG;
  payload: any;
}

export interface OpenFFAConfirmDialogAction {
  type: DialogActionTypes.OPEN_FFA_CONFIRM_DIALOG;
  payload: any;
}

export interface OpenInServiceDialogAction {
  type: DialogActionTypes.OPEN_IN_SERVICE_DIALOG;
  payload: any;
}

export interface OpenDebriefConfirmDialogAction {
  type: DialogActionTypes.OPEN_DEBRIEF_CONFIRM_DIALOG;
  payload: any;
}
export interface OpenFFADialogAction {
  type: DialogActionTypes.OPEN_FFA_DIALOG;
  payload: { open?: boolean };
}
export interface OpenTextDialogAction {
  type: DialogActionTypes.TEXT_DIALOG;
  payload: { text?: string; open?: boolean };
}

export interface OpenSmallMap {
  type: DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG;
  payload: boolean;
}

export interface OpenManagementDialogSocketMemo {
  type: DialogActionTypes.OPEN_MNGMT_DIALOG_MEMO;
  payload: any;
}
export interface OpenMapAssignmentDialog {
  type: DialogActionTypes.OPEN_MAP_ASSIGNMENT_DIALOG;
  payload: any;
}

export interface OpenManagementDialogMemo {
  type: DialogActionTypes.OPEN_MNGMT_DIALOG;
  payload: any;
}

export interface OpenSmallMapSocketAction {
  type: DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG_SOCKET;
  payload: boolean;
}

export type DialogActions =
  | OpenTextDialogAction
  | OpenManagementDialogSocketMemo
  | OpenManagementDialogMemo
  | OpenSmallMapSocketAction
  | OpenMapAssignmentDialog
  | OpenSmallMap
  | OpenAssignPlayersDialogAction
  | OpenFFADialogAction
  | OpenAssignRmsDialogAction
  | GamePausedDialog
  | OpenAssignTrainerDialogAction
  | SetConfirmButtonAction
  | SelectGameStageDialogAction
  | ConfirmGenericDialogAction
  | OpenDebriefConfirmDialogAction
  | OpenGridMapDialogAction
  | OpenFFAConfirmDialogAction
  | OpenCreatePlayerDialogAction
  | OpenLeaveMeetingDialogAction
  | OpenInServiceDialogAction
  | HideAllDialogsAction
  | OpenAdminTrainingViewDialogAction
  | OpenShareScreenDialog;
