import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { SocketActionTypes } from "../../../../../socket/SocketActions";
import { DialogActionTypes } from "../../store/DialogActions";
import useStyles from "./styles/selectGameStyles";

const SelectGameStageDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [stage, setStage] = React.useState(1);

  const handleGoToStage = () => {
    dispatch({
      type: SocketActionTypes.NEXT_STAGE,
      stage,
    });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStage(event.target.value as number);
  };

  const fillDropdown = () => {
    let data: { id: number; txt: string }[] = [
      { id: 0, txt: "Created" },
      { id: 1, txt: "Players Introduction" },
      { id: 2, txt: "Presentation" },
      { id: 3, txt: "Regional Manager Selection" },
      { id: 4, txt: "Regional Managers Meeting" },
      { id: 5, txt: "Map Assignment" },
      { id: 6, txt: "Map Memorization" },
      { id: 7, txt: "First Quarter" },
      { id: 8, txt: "Regional Manager Switch" },
      { id: 9, txt: "First Quarter Discussion" },
      { id: 10, txt: "Second Quarter" },
      { id: 11, txt: "Second Quarter RM Meeting" },
      { id: 12, txt: "Force Field Analysis" },
      { id: 13, txt: "After FFA Quarter RM Meeting" },
      { id: 14, txt: "In Service Option" },
      { id: 15, txt: "Third/Fourth Quarter" },
      { id: 16, txt: "Results" },
      { id: 17, txt: "Final Team Discussion" },
      { id: 18, txt: "Debrief" },
      { id: 19, txt: "Game Ended" },
    ];

    let dropDown: any[] = [];

    data.forEach((item) => {
      dropDown.push({
        stageId: item.id,
        item: (
          <MenuItem value={item.id} key={item.id}>
            {item.txt}
          </MenuItem>
        ),
      });
    });

    return dropDown.map((s) => s.item);
  };

  const formControl2 = `${classes.formControl} ${classes.formControl2}`;

  const handleCancel = () => {
    dispatch({
      type: DialogActionTypes.SELECT_GAME_STAGE_DIALOG,
      payload: false,
    });
  };

  return (
    <Container>
      <Grid container className={classes.root}>
        <Grid item className={classes.header}>
          <FormControl className={formControl2}>
            <Select
              labelId="stage-select"
              id="stage-select"
              value={stage}
              classes={{
                root: "stage-selector",
                select: "select-stage",
              }}
              onChange={handleChange}
            >
              {fillDropdown()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.buttons}>
          <Button className={`cancel`} onClick={handleGoToStage}>
            Go To Stage
          </Button>
          <Button className={`confirm`} onClick={handleCancel}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SelectGameStageDialog;
