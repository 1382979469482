import { Card, CardContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store/Store";
import { TrainingModel } from "../../models/AdminTrainingsModel";
import AdminTrainingView from "../training/AdminTrainingView";
import AdminTrainingList from "../trainingList/AdminTrainingList";
import "./styles/AdminDashboardStyles.scss";
import { AdminActionTypes } from "../../store/AdminActions";
import useStyles from "../AdminPanelStyles";
import { store } from "../../..";
import { prepareTeams } from "../training/utils/adminTrainingUtils";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [selectedTraining, setSelectedTraining] = useState<
    TrainingModel | undefined
  >(undefined);
  const [items, setItems] = useState<TrainingModel[]>();
  const trainingData = (state: AppState) => state.adminState.trainings;
  const trainings = useSelector(trainingData);

  const handleCloseEdit = () => {
    setSelectedTraining(undefined);
    dispatch({
      type: AdminActionTypes.SELECT_TRAINING,
      payload: undefined,
    });
  };

  const onItemSelected = (id: number) => {
    const selTraining = trainings.find((t) => t.id === id);
    setSelectedTraining(selTraining);
    dispatch({
      type: AdminActionTypes.SELECT_TRAINING,
      payload: id,
    });
  };

  useEffect(() => {
    console.log("trainings triggered...", trainings);
    setItems(trainings);
  }, [trainings]);

  const classes = useStyles();

  return (
    <>
      <div id="admin-dashboard-container">
        <Card className="trainings-scroll">
          <CardContent>
            <span className={`trainings`}>Upcoming trainings</span>
            <AdminTrainingList items={items} itemSelected={onItemSelected} />
            TRAINING HERE
          </CardContent>
        </Card>
        <span className={classes.arrow}></span>
        <Card>
          <CardContent key={selectedTraining?.id}>
            <AdminTrainingView
              showSave={true}
              showStart={true}
              key={selectedTraining?.id}
              training={selectedTraining}
              isNew={!selectedTraining}
              onCancel={handleCloseEdit}
            />
          </CardContent>
        </Card>
      </div>
    </>
  );
};
export default AdminDashboard;
