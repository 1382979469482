import { Container, Radio } from "@material-ui/core";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { ADMIN_TRAINING_CONSTANTS } from "../../constants/AdminConstants";
import { TrainingModel } from "../../models/AdminTrainingsModel";
import "react-datepicker/dist/react-datepicker.css";
import "./AdminTrainingView.scss";
import Accordion from "../../../shared/components/adminAccordion/AdminAccordion";
import AdminDebrief from "../../../shared/components/debrief/AdminDebrief";
import moment from "moment";
import { SocketActionTypes } from "../../../socket/SocketActions";
import { useDispatch, useSelector } from "react-redux";
import PlayersSelector from "../addPlayers/PlayersSelector";
import { AppState } from "../../../store/Store";
import {
  prepareTeams,
  getUpdStatusParams,
  delay,
  clearGameDetails,
  clearGameEdited,
  et,
  getGameDetails,
  listUsersAction,
  reloadTrainings,
  modifyTeamsValue,
  getAvailableUsers,
  getEmptyTeamsValue,
  getSelectedUsers,
  getTeamMembers,
} from "./utils/adminTrainingUtils";
import { GameActionTypes } from "../../../game/store/GameActions";
import { DialogActionTypes } from "../../../shared/components/dialogComponent/store/DialogActions";
import { AdminActionTypes } from "../../store/AdminActions";

type PropsDef = {
  isNew?: boolean;
  training?: TrainingModel;
  onCancel?: () => void;
  showStart?: boolean;
  showSave?: boolean;
  title?: string;
};

const AdminTrainingView = (props: PropsDef) => {
  const dispatch = useDispatch();
  const [dateState, setDateState] = useState<any>(undefined);
  const [debriefQuestions, setDebriefQuestions] = useState<any[]>([]);
  const [dateOpen, setDateOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(Object.assign({}, et));
  const today = new Date();
  const usersData = (state: AppState) => state.adminState.availableUsers;
  const trainingData = (state: AppState) => state.gameState.gameDetails;
  const gameEdited = (state: AppState) => state.gameState.gameEdited;

  const users = useSelector(usersData);
  const trainingDetails = useSelector(trainingData);
  const gameIdState = (state: AppState) => state.gameState.gameId;
  const gameId: any = useSelector(gameIdState);
  const gameEditStatus = useSelector(gameEdited);

  var selId = selectedValues.id;
  var tid = props.training?.id;
  var isNotAdminPanel = window.location.pathname !== "/admin-panel";

  if (!tid && isNotAdminPanel) tid = gameId;

  useEffect(() => {
    if (trainingDetails.id === selId) return;

    const { teamsMap } = prepareTeams(trainingDetails);
    setSelectedValues({
      id: trainingDetails.id ? trainingDetails.id : null,
      name: trainingDetails.name,
      teams: trainingDetails.teams || "1",
      usersData: teamsMap,
    });

    if (trainingDetails.debrief) setDebriefQuestions(trainingDetails.debrief);
    if (trainingDetails.start_date) {
      setDateState(moment(trainingDetails.start_date).toDate());
    }
    if (tid) {
      getGameDetails(dispatch, tid);
    }
  }, [trainingDetails, selId, tid, dispatch]);

  useEffect(() => {
    if (!gameEditStatus) return;
    reloadTrainings(dispatch);
  }, [dispatch, gameEditStatus]);

  useEffect(() => {
    if (props.isNew) {
      listUsersAction(dispatch);
    }
  }, [props.isNew, dispatch]);

  const groupsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("# of groups did change!!!");

    const teams: any = event.target.value;
    let ud = modifyTeamsValue(Number(teams), selectedValues.usersData);
    setSelectedValues({ ...selectedValues, teams, usersData: ud });
  };

  const handleDebriefChange = (
    event: React.ChangeEvent<{ value: string }>,
    index: number
  ) => {
    const newDebriefs = [...debriefQuestions];
    newDebriefs[index].text = event.target.value;
    setDebriefQuestions(newDebriefs);
  };

  const handleRemoveDebrief = (index: number) => {
    let debriefQArrOld = [...debriefQuestions];
    debriefQArrOld = debriefQArrOld.filter(
      (question) => question.index !== index
    );
    setDebriefQuestions(debriefQArrOld);
  };

  const handleAddDebrief = () => {
    const debriefQArr = [
      ...debriefQuestions,
      { question: "", index: debriefQuestions.length + 1 },
    ];
    setDebriefQuestions(debriefQArr);
  };

  const handleAddUsersToTeam = (emails: string[], idx: number) => {
    console.log("into handle add handleAddUsersToTeam");

    const tm = getTeamMembers(users, emails);
    const svCopy = Object.assign({}, selectedValues);
    if (!svCopy.usersData[idx]) {
      svCopy.usersData = getEmptyTeamsValue(selectedValues.teams);
    }
    svCopy.usersData[idx]["teamMembers"] = tm;
    setSelectedValues(svCopy);
  };

  const handleOnSave = () => {
    const dateFormat = moment(dateState).toDate();
    let fullObj;
    if (debriefQuestions.length > 0) {
      fullObj = {
        ...selectedValues,
        debrief: debriefQuestions,
        startDate: dateFormat,
      };
    } else {
      fullObj = { ...selectedValues, startDate: dateFormat };
    }
    if (fullObj.id !== null) {
      clearGameEdited(dispatch);
      dispatch({
        type: SocketActionTypes.UPDATE_GAME_SOCKET,
        payload: fullObj,
      });
    } else {
      dispatch({
        type: SocketActionTypes.CREATE_GAME_SOCKET,
        payload: fullObj,
      });
    }
  };

  const handleOnClose = () => {
    setSelectedValues(Object.assign({}, et));
    clearGameEdited(dispatch);
    clearGameDetails(dispatch);
    setDebriefQuestions([]);
    props.onCancel && props.onCancel();
  };

  const handleOnStart = async () => {
    console.log("onStart not implemented!");
    console.log(trainingDetails.id?.toString() || "-1");
    if (trainingDetails.id) {
      //step 1: Change the gameId from within
      dispatch({
        type: GameActionTypes.SET_GAME_ID,
        payload: trainingDetails.id,
      });
      //step 2
      dispatch({
        type: SocketActionTypes.CURRENT_STAGE,
      });

      //step 2.5 end the created stage
      dispatch({
        type: SocketActionTypes.END_STAGE,
      });
      await delay(1500);
      //step 3 kick out of the admin panel
      dispatch({
        type: AdminActionTypes.LOGIN_TO_ADMIN,
        payload: false,
      });
      dispatch({
        type: AdminActionTypes.SET_INITIAL_FLAG,
        payload: false,
      });

      dispatch({
        type: DialogActionTypes.HIDE_ALL,
      });
    }
  };

  const onChangeName = (name: string) => {
    setSelectedValues({ ...selectedValues, name });
  };

  const getUpdStatus = () => {
    let { status, styleProp } = getUpdStatusParams(gameEditStatus);
    if (isNotAdminPanel && status === "OK") {
      delay(1000).then((v) => handleOnClose());
      return <></>;
    }
    return <span style={styleProp}>{status}</span>;
  };

  return (
    <Container key={props.training?.id}>
      <h1 className={`training-header ${!props.isNew ? "edit-training" : ""}`}>
        {props.isNew ? "Create new Training" : "Edit Training"}
        <span className={`close-training`} onClick={handleOnClose}></span>
      </h1>
      <div className={`admin-form-container`}>
        <div>
          <input
            className={`input`}
            placeholder="Training name"
            onChange={(e) => onChangeName(e.target.value)}
            value={selectedValues?.name}
          />
        </div>
        <div className={`two-columns`}>
          <div className="right-align" key={props.training?.id + "_columns"}>
            <span className={`span-header`}>Number of groups</span>
          </div>
          <div key={props.training?.id + "_radio"}>
            {[...Array(ADMIN_TRAINING_CONSTANTS.GROUPS.NUMBER_OF_GROUPS)].map(
              (_, index) => {
                return (
                  <span key={index + 1}>
                    <Radio
                      key={index + 1}
                      checked={selectedValues.teams === (index + 1).toString()}
                      onChange={groupsChange}
                      value={index + 1}
                      className={`training-radio-buttons`}
                      name="radio-group"
                      inputProps={{ "aria-label": (index + 1).toString() }}
                    />
                    <span className={`index-groups`}>{index + 1}</span>
                  </span>
                );
              }
            )}
          </div>
        </div>
        {new Array(Number(selectedValues.teams)).fill(1).map((item, idx) => {
          return (
            <PlayersSelector
              teamId={idx + 1}
              key={`selector-team-${idx + 1}`}
              selectedUsers={getSelectedUsers(selectedValues, idx + 1)}
              availableUsers={getAvailableUsers(selectedValues, idx + 1, users)}
              onUsersSelected={(emails: string[]) => {
                handleAddUsersToTeam(emails, idx + 1);
              }}
            />
          );
        })}
        <div className={`two-columns1 date-container`}>
          <div className={`right-align groups`}>
            <span className={`span-header`}>Date</span>
          </div>
          <div className={`input-date`}>
            <DatePicker
              className="raise-popper"
              open={dateOpen}
              onInputClick={() => setDateOpen(!dateOpen)}
              onClickOutside={() => setDateOpen(false)}
              selected={dateState}
              minDate={today}
              onChange={(date) => {
                setDateState(date);
              }}
            />
          </div>
          <div>
            <button
              type="button"
              className={`calendar-icon`}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setDateOpen(!dateOpen);
              }}
            />
          </div>
          <div className={`input-time`}>
            Time
            <DatePicker
              selected={dateState}
              onChange={(date) => {
                setDateState(date);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />
          </div>
        </div>
        <div>
          <Accordion
            title={"Debrief Questions"}
            subtitle={debriefQuestions.length}
          >
            {debriefQuestions.map((debrief, index) => {
              return (
                <div key={index} className={`debrief-row`}>
                  <span>{index + 1}</span>
                  <AdminDebrief
                    question={debrief.question}
                    index={debrief.index}
                    key={debrief.index}
                    onChange={(event: React.ChangeEvent<{ value: string }>) =>
                      handleDebriefChange(event, index)
                    }
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveDebrief(debrief.index)}
                  ></button>
                </div>
              );
            })}
            <div>
              <button
                type="button"
                className={`debrief-add-question`}
                onClick={handleAddDebrief}
              >
                Add Question
              </button>
            </div>
          </Accordion>
        </div>
        <div className={`action-buttons-container`}>
          {!props.isNew ? <p>Update status: {getUpdStatus()}</p> : null}
          {props.showSave ? (
            <button
              onClick={handleOnSave}
              type="button"
              className={`active-button ${
                selectedValues.name.length === 0 ? "inactive-button" : ""
              }`}
              disabled={selectedValues.name.length === 0}
            >
              Save Training
            </button>
          ) : null}

          {!props.isNew ? (
            <button onClick={async () => await handleOnStart()} type="button">
              Start Training
            </button>
          ) : null}
        </div>
      </div>
    </Container>
  );
};
export default AdminTrainingView;
