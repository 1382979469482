export enum SocketTypes {
    SELECT_FIELD = 'open-box',
    READY_TO_START = 'READY_TO_START',
    UNDEFINED = 'UNDEFINED',
    REMOVE_USER_FROM_RESOURCE = 'REMOVE_USER_FROM_RESOURCE_SOCKET',
    SAVE_RULES = "SAVE_RULES",
    CREATED = "CREATED",
    NEW_STAGE = "NEW_STAGE",
    PRESENTATION = "PRESENTATION",
    DIALOG_NOT_STARTED = "DIALOG_NOT_STARTED",
    PLAYERS_INTRODUCTION = "PLAYERS_INTRODUCTION",
    REGIONAL_MANAGER_SELECTION = "REGIONAL_MANAGER_SELECTION",
    REGIONAL_MANAGERS_MEETING = "REGIONAL_MANAGERS_MEETING",
    SECOND_QUARTER_RM_MEETING = "SECOND_QUARTER_RM_MEETING",
    AFTER_FFA_QUARTER_RM_MEETING = "AFTER_FFA_QUARTER_RM_MEETING",
    REGIONAL_MANAGER_SWITCH = "REGIONAL_MANAGER_SWITCH",
    MAP_ASSIGNMENT = "MAP_ASSIGNMENT",
    MAP_MEMORIZATION = "MAP_MEMORIZATION",
    FIRST_QUARTER = "FIRST_QUARTER",
    SECOND_QUARTER = "SECOND_QUARTER",
    THIRD_FOURTH_QUARTER = "THIRD_FOURTH_QUARTER",
    FIRST_QUARTER_DISCUSSION = "FIRST_QUARTER_DISCUSSION",
    FORCE_FIELD_ANALYSIS = "FORCE_FIELD_ANALYSIS",
    FFA_FACILITATOR_UPDATED = "FFA_FACILITATOR_UPDATED",
    ASSIGNED_MAP = "ASSIGNED_MAP",
    FORCE_FIELD_UPDATED = "FORCE_FIELD_UPDATED_SOCKET",
    IN_SERVICE_USED = "IN_SERVICE_USED",
    CONNECTED = "CONNECTED",
    IN_SERVICE_OPTION = "IN_SERVICE_OPTION",
    FINAL_DISCUSSION = "FINAL_DISCUSSION",
    DEBRIEF = "DEBRIEF",
    RESULTS = "RESULTS",
    GET_STAGE_TIME = "GET_STAGE_TIME",
    FINANCIAL_RESULTS = "FINANCIAL_RESULTS",
    DEBRIEF_UPDATED = "DEBRIEF_UPDATED",
    DEBRIEF_QUESTION_CHANGED = "DEBRIEF_QUESTION_CHANGED",
    GAME_ENDED = "GAME_ENDED",
    USER_CALLING = "USER_CALLING",
    CHAT_RECEIVED = "CHAT_RECEIVED",
    SELECT_RM = "SELECT_RM",
    GET_GAME = "GET_GAME",
    CREATE_GAME = "CREATE_GAME",
    GAME_PAUSED = "GAME_PAUSED",
    GAME_RESUMED = "GAME_RESUMED",
    JOIN_TRAINER_TO_TEAM = "JOIN_TRAINER_TO_TEAM",
    MUTE = "MUTE"
}

export enum SocketStageTypes {
    PLAYERS_INTRODUCTION = 'PLAYERS_INTRODUCTION',
    PRESENTATION = 'PRESENTATION',
    REGIONAL_MANAGER_SELECTION = "REGIONAL_MANAGER_SELECTION"
}
