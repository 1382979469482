import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dragContainer: {
        position: 'fixed',
        top: '14%;',
        left: '34%',
        width: '706px',
        minHeight: '640px',
        borderRadius: '10px',
        zIndex: 999,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.5)',
        paddingTop: '75px;',
        border: 'solid 3px #ebc429',
        backgroundColor: '#ffffff',
        padding: '47px 21px',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
    },
    elementsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        height: 'fit-content'
    },
    disabledItem: {
        backgroundColor: 'rgba(237, 240, 242, 0.6) !important',
        backgroundImage: 'unset !important',
        border: 'unset',
        '& span': {
            color: '#57575a'
        }
    },
    dragIcon: {
        position: 'absolute',
        marginTop: '24px',
        left: '34px',
        transform: 'rotate(-58deg)'
    },
    divItem: {
        width: '142px',
        height: '32px',
        margin: '8px 0',
        padding: '6px 9px 6px 9px',
        borderRadius: '2px;',
        boxShadow: '1px 1px 1px 0 rgb(0 0 0 / 60%)',
        border: 'solid 1px rgb(226, 226, 226) !important',
        cursor: 'grab',
        boxSizing: 'border-box',
        backgroundColor: 'rgb(203, 239, 255)'
    },
    divItemCrops: {
        marginLeft: '-31px;'
    },
    item: {
        whiteSpace: 'nowrap',
        overflow: 'hidden;',
        display: 'inherit',
        textOverflow: 'ellipsis',
        maxWidth: '113px',
        marginLeft: '0',
        marginTop: '3px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#000000'
    },
    mainLabel: {
        position: 'absolute',
        left: '188px',
        // top: '7px',
        marginTop: '-2px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        textAlign: 'center',
        color: '#57575a'
    },
    namesContainer: {
        display: 'inline-block',
        float: 'left',
        marginTop: '46px',
        marginLeft: '45px'
    },
    containerToDrag: {
        height: 'fit-content',
        width: '215px',
        position: 'relative',
        minHeight: '55px'
    },
    teamDiv: {
        fontSize: '16px;',
        fontFamily: 'Roboto',
        color: 'rgb(0, 0, 0);',
        position: 'fixed',
        bottom: '60px',
        marginLeft: '322px;'
    },
    spanTick: {
        borderRadius:' 2px',
        position: 'absolute',
        right: '79px',
        marginTop: '0px',
        cursor: 'pointer',
        height: '12px',
        '& img': {
            maxHeight: '12px',
            maxWidth: '12px',
        }
    },
    xTick: {
       
    },
    error: {
        color: 'rgb(188, 45, 29)',
        bottom: '3px',
        left: '305px',
        marginBottom: '5px',
        fontWeight: 'bold',
        position: 'absolute'
    },
    errorImage: {
        backgroundImage: 'url("icons/shape.png")',
        width: '30px;',
        height: '30px;',
        position: 'absolute',
        marginLeft: '-26px;',
        marginTop: '-8px;'
    },
    doneButtonDiv: {
        bottom: '14px;',
        marginLeft: '66px;',
        position: 'absolute'
    },
    button: {
        width: '176px',
        height: '44px',
        padding: '6px 12px 6px 13px',
        borderRadius: '5px',
        border:' solid 2px #296daa',
        color: 'white',
        fontSize: '1.2rem',
        backgroundImage: 'linear-gradient(to bottom, rgb(83, 166, 213), #296daa);'
    },
    containerToDrag2: {
    },
    containerToDrag3: {
    },
    containerElements: {
        position: 'relative',
        height: '100px',
        width: '206px !important;',
        top: '71px',
        left: '246px',
        padding: '1px 1px 9px 8px',
        borderRadius: '5px',
        boxSizing: 'border-box',
        border: 'solid 2px #62b2cf',
        backgroundColor: '#cce8f4',
        display: 'inline-block',
    },
    containerAssignedUsers: {
        backgroundColor: '#558ea6',
        border: 'solid 2px #2a6078',
    },
    humidityContainers: {
        top: '8px',
        left: '67px;',
        padding: '1px 4px 8px 21px',
        marginTop: '10px',
        boxSizing: 'border-box',
        backgroundImage: 'url("icons/icons-tile-icons-humidity.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top'
    },
    herbsContainers: {
        top: '8px',
        left: '67px;',
        marginTop: '45px',
        width: '206px !important',
        boxSizing: 'border-box',
        overflowX: 'hidden',
        overflowY: 'hidden',
        backgroundImage: 'url("icons/icons-tile-icons-herbs.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top'
    },
    herbsContainers2: {
        left: '10px !important',
    },
    humidityContainers2: {
        left: '10px !important', 
    },
    sunContainers: {
        borderRadius: '5px',
        color: '#1b1c20',
        top: '8px',
        left: '67px;',
        // width: '92%',
        backgroundImage: 'url("icons/icons-tile-icons-sun.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        marginTop: '10px',
        '& div[class*="elementLabel"]': {
            color: '#1b1c20'
        }
    },
    cropsContainers: {
        backgroundImage: 'url("icons/icons-tile-icons-crops.png")',
    },
    sunContainers2: {
        left: '10px; !important', 
    },
    mainDiv: {
        marginTop: '30px;',
        width: '90%;'
    },
    elementLabel: {
        width: '136px',
        height: 'fit-content',
        margin: '5px 0px 0px 0',
        padding: '5px 0px 0px 0px',
        fontFamily: 'Roboto',
        fontSize: '17px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#1b1c20'
    },
    elementLabelAssigned: {
        color: '#ffffff !important'
    },
    humidityLabel: {
        marginLeft: '-10px !important',
    },
    cropsLabel: {
        width: '100%;'
    },
    elementLabelCrops: {
        marginTop: '15px',
        marginLeft: '-50px',
        padding: '5px 0 5px 56px',
        width: '255px'
    },
    tick: {
        marginLeft: '10px',
        position: 'absolute',
        color: '#ffffff',
        filter: 'invert(0%) sepia(5%) saturate(100%) hue-rotate(278deg) brightness(107%) contrast(160%)'
    },
    disableSelect: {
        cursor: 'not-allowed'
    },
    assignedTick: {
        position: 'absolute',
        marginLeft: '116px',
        marginTop: '-26px',
        '& img': {
            filter: 'invert(26%) sepia(14%) saturate(87%) hue-rotate(256deg) brightness(93%) contrast(73%)'
        }
    },
    assignedTick2: {
        position: 'absolute',
        marginLeft: '116px',
        marginTop: '-16px',
        '& img': {
            filter: 'invert(26%) sepia(14%) saturate(87%) hue-rotate(256deg) brightness(93%) contrast(73%)'
        }
    }
}));

export default useStyles;