import React, { useEffect, useState } from 'react';
import { TEAM_RULES_BTN_STATE } from './../constants/ReamRulesContants';
import { TeamRulesModel } from './../model/TeamRulesModel';
import useStyles from '../styles/TeamRulesStyles';
import { TextareaAutosize } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/Store';
import { SocketActions, SocketActionTypes } from '../../../../socket/SocketActions';
import { DETERMINE_POSITION } from '../../../../core/models/PositionModel';

let initialRules: TeamRulesModel = {
    isInEditMode: false,
    btnState: TEAM_RULES_BTN_STATE.ADD,
    rulesTxt: '',
    previousRules: ''
};

const initialTxtRules = 'No rules defined yet';

interface Type {
    isDown: string;
    isOpen: boolean;
}

const TeamRulesTextarea = (props:Type) => {
    const {isDown,isOpen} = props;

    const [rules, setRules] = useState(initialRules);
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectTeamRules = (state: AppState) => state.gameState.teamRules;
    const teamRulesSelector = useSelector(selectTeamRules);

    const handleOnSave = (value: string) => {
        const saveRulesAction: SocketActions = {
            type: SocketActionTypes.SAVE_RULES,
            rules: value
        };

        dispatch(saveRulesAction);
    }


    const onBtnClick = (e: any) => {
        e.stopPropagation();
        const newRules: any = rules.rulesTxt;
        switch (rules.btnState) {
            case TEAM_RULES_BTN_STATE.SAVE:
                setRules({
                    isInEditMode: false,
                    btnState: !!newRules.length ? 
                        TEAM_RULES_BTN_STATE.EDIT :
                        TEAM_RULES_BTN_STATE.ADD,
                    rulesTxt: newRules,
                    previousRules: newRules
                });
                handleOnSave(newRules);
            break;
            default:
                selectTextarea();
                setRules({
                    btnState: TEAM_RULES_BTN_STATE.SAVE,
                    isInEditMode: true,
                    rulesTxt: newRules,
                    previousRules: newRules
                });
            break;
        }
    };

    const toggleCancelBtn = () => {
        if (rules.isInEditMode) {
            return (<button type="button" className={classes.saveEditBtn} 
                onClick={(e) => onBtnCancelClick(e)}>
                { TEAM_RULES_BTN_STATE.CANCEL }
            </button>)
        }
        return (<></>)
    }


    const onBtnCancelClick = (e: any) => {
        e.stopPropagation();
        const prevState = {...rules };
        const prevRules = prevState.previousRules;
        setRules({...prevState, isInEditMode: false, rulesTxt: prevRules});
    };

    const handleChange = (event: any) => {
        const prevState = {...rules };
        setRules({
            ...prevState,
            rulesTxt: event.target.value
        });
        event.stopPropagation();
    };

    useEffect(() => {
        setRules({
            isInEditMode: false,
            btnState: teamRulesSelector?.length ? TEAM_RULES_BTN_STATE.EDIT : TEAM_RULES_BTN_STATE.ADD,
            rulesTxt: teamRulesSelector
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamRulesSelector]);

    const selectTextarea = () => {
        document.getElementById('rulesTextArea')?.focus();
    }

    const preventClickAction = (e: any) => {
        e.stopPropagation();
    }

    const checkIfTextAreaIsShown = () => {
        if (rules.isInEditMode) {
            return (
                <TextareaAutosize className={classes.textarea}
                    value={rules.rulesTxt}
                    onChange={handleChange}></TextareaAutosize>
            );
        } else {
            return (
                <TextareaAutosize className={classes.textarea}
                    disabled value={rules.rulesTxt ? rules.rulesTxt : initialTxtRules}
                    onChange={handleChange}></TextareaAutosize>
            );
        }
    }

    return (<div className={`textarea-container ${classes.textAreaDiv} ${isDown === DETERMINE_POSITION.BOTTOM ? 'down' : 'up'} ${isOpen ? 'open' : 'close' }`} onClick={(e) => preventClickAction(e)}>
        <div className={classes.txtDiv}>{checkIfTextAreaIsShown()}</div>
        <span className={classes.saveEditBtnContainer}>
            {toggleCancelBtn()}
            <button type="button" className={classes.saveEditBtn} 
                onClick={(e) => onBtnClick(e)}>
                { rules.btnState }
            </button>
        </span>
    </div>);
}

export default TeamRulesTextarea