import { Reducer } from "redux";
import { DialogActionTypes, DialogActions } from "./DialogActions";
import _ from "lodash";

export type boolOrNullType = undefined | boolean;
export type stringOrNullType = undefined | string;

export interface DialogState {
  type: DialogActionTypes | undefined;
  data: any;
  open: boolean;
  openedManagementDialogMemo: boolOrNullType;
  openedMapAssignmentDialog: boolOrNullType;
  typeOfDialog: string;
  openSmallMap: boolOrNullType;
  openFFADialog: boolOrNullType;
  openDebriefConfirmDialog: boolOrNullType;
  openConfirmFFADone: boolOrNullType;
  openInServiceDialog: boolOrNullType;
  textDialog: boolOrNullType;
  shareScreenDialog: boolOrNullType;
  joinStaffMeeting: boolOrNullType;
  leaveMeetingDialog: boolOrNullType;
  openedGridMapDialog: boolOrNullType;
  openedSelectGameStageDialog: boolOrNullType;
  confirmGenericDialog: boolOrNullType;
  confirmButtonSelectedFor: stringOrNullType;
  openedAssignRMsDialog: boolOrNullType;
  openedCreatePlayerDialog: boolOrNullType;
  openedAssignPlayersDialog: boolOrNullType;
  openedAssignTrainerDialog: boolOrNullType;
  openedGamePausedDialog: boolOrNullType;
  confirmQuestionText: stringOrNullType;
  confirmedText: stringOrNullType;
}

const initialDialogState: DialogState = {
  type: undefined,
  data: undefined,
  open: false,
  openedManagementDialogMemo: undefined,
  typeOfDialog: "",
  openSmallMap: undefined,
  openFFADialog: undefined,
  openConfirmFFADone: undefined,
  openDebriefConfirmDialog: undefined,
  openInServiceDialog: undefined,
  textDialog: undefined,
  shareScreenDialog: undefined,
  leaveMeetingDialog: undefined,
  joinStaffMeeting: undefined,
  openedAssignRMsDialog: undefined,
  openedAssignPlayersDialog: undefined,
  openedMapAssignmentDialog: undefined,
  openedGamePausedDialog: undefined,
  openedCreatePlayerDialog: undefined,
  openedGridMapDialog: undefined,
  openedSelectGameStageDialog: undefined,
  confirmGenericDialog: undefined,
  confirmButtonSelectedFor: undefined,
  confirmQuestionText: undefined,
  confirmedText: undefined,
  openedAssignTrainerDialog: undefined,
};

export const DialogReducer: Reducer<DialogState, DialogActions> = (
  state = initialDialogState,
  action
) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case DialogActionTypes.HIDE_ALL:
      return {
        ...newState,
        ...initialDialogState,
      };
    case DialogActionTypes.TEXT_DIALOG: {
      console.log("open up a text dialog for me");
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.TEXT_DIALOG,
        data: action.payload ? action.payload : undefined,
        open: action.payload ? !!action.payload.open : false,
      };
    }
    case DialogActionTypes.OPEN_FFA_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_FFA_DIALOG,
        openFFADialog: action.payload ? !!action.payload.open : false,
      };
    case DialogActionTypes.SELECT_GAME_STAGE_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.SELECT_GAME_STAGE_DIALOG,
        openedSelectGameStageDialog: action.payload ? action.payload : false,
      };
    case DialogActionTypes.SET_CONFIRM_BUTTON:
      return {
        ...newState,
        confirmedText: action.payload,
      };
    case DialogActionTypes.SHARE_SCREEN:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.SHARE_SCREEN,
        open: action.payload.open || false,
      };
    case DialogActionTypes.CONFIRM_GENERIC_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.CONFIRM_GENERIC_DIALOG,
        confirmButtonSelectedFor: action.payload?.confirmButtonSelectedFor,
        confirmQuestionText: action.payload ? action.payload.questionText : "",
        open: !!action.payload.open,
      };
    case DialogActionTypes.OPEN_ASSIGN_RMS_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_ASSIGN_RMS_DIALOG,
        openedAssignRMsDialog: action.payload ? !!action.payload : false,
      };
    case DialogActionTypes.OPEN_CREATE_PLAYER_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_CREATE_PLAYER_DIALOG,
        openedCreatePlayerDialog: action.payload ? !!action.payload : false,
      };
    case DialogActionTypes.OPEN_GAME_PAUSED_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_GAME_PAUSED_DIALOG,
        openedGamePausedDialog: action.payload ? !!action.payload : false,
      };
    case DialogActionTypes.OPEN_ASSIGN_PLAYERS_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_ASSIGN_PLAYERS_DIALOG,
        openedAssignPlayersDialog: action.payload ? !!action.payload : false,
      };
    case DialogActionTypes.OPEN_ASSIGN_TRAINER_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_ASSIGN_TRAINER_DIALOG,
        openedAssignTrainerDialog: action.payload ? !!action.payload : false,
      };
    case DialogActionTypes.OPEN_DEBRIEF_CONFIRM_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_DEBRIEF_CONFIRM_DIALOG,
        confirmGenericDialog: action.payload ? !!action.payload.open : false,
      };
    case DialogActionTypes.OPEN_IN_SERVICE_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_IN_SERVICE_DIALOG,
        openInServiceDialog: action.payload ? !!action.payload : false,
      };
    case DialogActionTypes.LEAVE_MEETING:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.LEAVE_MEETING,
        open: !!action.payload.open,
      };
    case DialogActionTypes.SETTINGS_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.SETTINGS_DIALOG,
        open: !!action.payload.open,
      };
    case DialogActionTypes.OPEN_GRID_MAP_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_GRID_MAP_DIALOG,
        openedGridMapDialog: action.payload ? !!action.payload : false,
      };
    case DialogActionTypes.OPEN_FFA_CONFIRM_DIALOG:
      return {
        ...newState,
        typeOfDialog: DialogActionTypes.OPEN_FFA_CONFIRM_DIALOG,
        open: !!action.payload,
      };
    case DialogActionTypes.OPEN_MNGMT_DIALOG: {
      const value = action.payload.openedManagementDialogMemo;
      return {
        ...newState,
        data: value.data,
        openedManagementDialogMemo: value,
        typeOfDialog: "MNGT_DIALOG_MEMO",
        title: "MEMO",
      };
    }
    case DialogActionTypes.OPEN_MAP_ASSIGNMENT_DIALOG: {
      let value = action.payload.mapAssignment;
      return {
        ...newState,
        openedMapAssignmentDialog: value,
        typeOfDialog: "OPEN_MAP_ASSIGNMENT_DIALOG",
        title: "Assign Resources",
      };
    }
    case DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG: {
      const payload = action.payload;
      return {
        ...newState,
        typeOfDialog: "OPEN_MAP_MEMORIZATION_DIALOG",
        title: "Small Map",
        openSmallMap: payload,
      };
    }
    default: {
      return state;
    }
  }
};
