import { Dispatch } from "react";
import { GameActionTypes } from "../../../../game/store/GameActions";
import { SocketActionTypes } from "../../../../socket/SocketActions";
import {
  FullUserData,
  SelectedTeamValues,
  TrainingModel,
} from "../../../models/AdminTrainingsModel";
import { AvailableUsers, UsersTeam } from "../../../models/AvailableUsersModel";
import { AdminActionTypes } from "../../../store/AdminActions";

const remapToAvUser = (u: FullUserData): AvailableUsers => {
  return {
    id: u.id,
    company: u.company,
    connection_id: u.connection_id,
    created_date: u.created_date,
    department: u.department,
    email: u.email,
    first_name: u.first_name,
    last_updated_date: u.last_updated_date,
    trainer: u.trainer,
    username: u.username,
  };
};
export const emptyUT: UsersTeam = { 1: { teamMembers: [] } };

export const prepareTeams = (training: TrainingModel | undefined) => {
  let teamsMap: UsersTeam = {};
  let teams: number[] = [];

  if (training && Array.isArray(training?.users)) {
    training.users.forEach((user) => {
      let teamId = user.teamid;

      if (!teamsMap[teamId]) {
        teamsMap[teamId] = { teamMembers: [] };
      }
      if (!teams.includes(teamId)) {
        teams.push(teamId);
      }

      let mapped = remapToAvUser(user);
      teamsMap[teamId].teamMembers.push(mapped);
    });
    teams = teams.sort((a, b) => a - b); //ex: [12,22,88]
  }

  let normalizedTeams: UsersTeam = {};
  teams.forEach((teamId, idx) => {
    if (!normalizedTeams[idx + 1]) {
      normalizedTeams[idx + 1] = {
        teamMembers: Object.assign([], teamsMap[teamId].teamMembers),
      };
    }
  });

  return { teamsMap: normalizedTeams };
};

export const getUpdStatusParams = (gameEditStatus: boolean | null) => {
  let status = "";
  let styleProp: React.CSSProperties = {};
  if (gameEditStatus === null) {
    status = "UP TO DATE";
    styleProp = { fontWeight: 500, color: "black" };
  }
  if (gameEditStatus === false) {
    status = "FAILED";
    styleProp = { fontWeight: 500, color: "red" };
  }
  if (gameEditStatus === true) {
    status = "OK";
    styleProp = { fontWeight: 500, color: "green" };
  }

  return { status, styleProp };
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const listUsersAction = (dispatch: Dispatch<any>) => {
  dispatch({
    type: AdminActionTypes.LIST_USERS,
    payload: [],
  });
};

export const getGameDetails = (dispatch: Dispatch<any>, id: number) => {
  dispatch &&
    dispatch({
      type: SocketActionTypes.GET_GAME_DETAILS,
      payload: id,
    });
};

export const clearGameDetails = (dispatch: Dispatch<any>) => {
  dispatch({
    type: GameActionTypes.SET_GAME_DETAILS,
    payload: { name: "" },
  });
};

export const clearGameEdited = (dispatch: Dispatch<any>) => {
  dispatch({
    type: GameActionTypes.SET_GAME_EDITED_RESPONSE,
    payload: null,
  });
};

export const reloadTrainings = (dispatch: Dispatch<any>) => {
  dispatch({
    type: SocketActionTypes.GET_UPCOMING_TRAININGS,
    payload: true,
  });
};

export const et: SelectedTeamValues = {
  id: null,
  name: "",
  teams: "1",
  usersData: emptyUT,
};

export const getEmptyTeamsValue = (teams: string) => {
  const nvn = Number(teams);
  let ud: UsersTeam = Object.assign({}, emptyUT);

  if (!isNaN(nvn)) {
    new Array(nvn).fill(1).forEach((item, idx) => {
      ud[idx + 1] = { teamMembers: [] };
    });
  }

  return ud;
};

export const modifyTeamsValue = (
  wantedTeams: number,
  teamMembers: UsersTeam
) => {
  let currentTeamState: UsersTeam = Object.assign({}, teamMembers);

  let keys = Object.keys(currentTeamState).sort(
    (a, b) => Number(a) - Number(b)
  );

  const currentTeams = keys.length;

  let maxKey = keys[keys.length - 1]; // for example: "3"
  const flag = wantedTeams > currentTeams;

  if (!isNaN(wantedTeams) && !isNaN(currentTeams)) {
    if (flag) {
      // wants 4 teams and we have 3 already => we need to add X number of empty teams
      for (let i = Number(maxKey); i < wantedTeams; i++) {
        currentTeamState[i + 1] = { teamMembers: [] };
      }
    } else if (!flag) {
      //wants 2 teams, but there are 3 teams already => remove X amount of teams
      for (let i = Number(maxKey); i > wantedTeams; i--) {
        delete currentTeamState[i];
      }
    }
  }

  return currentTeamState;
};

export const getSelectedUsers = (ud: SelectedTeamValues, idx: number) => {
  let selectedEmails: string[] = [];
  if (ud.usersData[idx] && ud.usersData[idx]["teamMembers"]) {
    const emails = ud.usersData[idx]["teamMembers"].map((u) => u.email);
    selectedEmails = selectedEmails.concat(emails);
  }

  return selectedEmails;
};

export const getAvailableUsers = (
  ud: SelectedTeamValues,
  teamId: number,
  users: AvailableUsers[]
) => {
  let unavEmails: string[] = [];
  Object.keys(ud.usersData).forEach((key) => {
    let numKey = Number(key);

    let emails: string[] = [];
    if (numKey !== teamId) {
      emails = getSelectedUsers(ud, numKey);
      unavEmails = unavEmails.concat(emails);
    }
  });
  return users.filter((u) => !unavEmails.includes(u.email));
};

export const getTeamMembers = (users: AvailableUsers[], emails: string[]) => {
  const newTeam: AvailableUsers[] = [];
  let usersMap: { [key: string]: AvailableUsers } = {};

  users.forEach((u) => {
    if (!usersMap[u.email]) {
      usersMap[u.email] = u;
    }
  });

  emails.forEach((email) => {
    const match = usersMap[email];
    if (match) {
      newTeam.push(match);
    }
  });

  return newTeam;
};
