import { UserModel } from "../../core/models/UserModel";

 export enum UserActionTypes {
    TRY_LOGIN = "TRY_LOGIN",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAIL = "LOGIN_FAIL",
    TRY_SIGNUP = "TRY_SIGNUP",
    SIGNUP_FAIL = "SIGNUP_FAIL",
    SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
    TRY_CONFIRM = "TRY_CONFIRM",
    CONFIRM_SUCCESS = "CONFIRM_SUCCESS",
    CONFIRM_FAIL = "CONFIRM_FAIL",
    CHANGE_AUTH_INTENT = "CHANGE_AUTH_INTENT",
    SET_USER_DATA = "SET_USER_DATA",
    LOGOUT = "LOGOUT"
}

export interface TryLogin {
    type: UserActionTypes.TRY_LOGIN;
}

export interface LoginSuccess {
    type: UserActionTypes.LOGIN_SUCCESS;
    username: string;
    firstName: string;
    lastName: string;
    token: string;
}

export interface SetUserDataAction {
    type: UserActionTypes.SET_USER_DATA;
    userData: UserModel
}

export interface LoginFail {
    type: UserActionTypes.LOGIN_FAIL;
    error: string;
}

export interface TrySignup {
    type: UserActionTypes.TRY_SIGNUP;
}

export interface SignupSuccess {
    type: UserActionTypes.SIGNUP_SUCCESS;
    username: string;
}

export interface SignupFail {
    type: UserActionTypes.SIGNUP_FAIL;
    error: string;
}

export interface TryConfirm {
    type: UserActionTypes.TRY_CONFIRM;
}

export interface ConfirmSuccess {
    type: UserActionTypes.CONFIRM_SUCCESS; 
}

export interface ConfirmFail {
    type: UserActionTypes.CONFIRM_FAIL;
    error: string;
}

export interface ChangeAuthIntent {
    type: UserActionTypes.CHANGE_AUTH_INTENT;
    intent: 'login' | 'signup' | 'confirm'
}

export interface Logout {
    type: UserActionTypes.LOGOUT;
}

export type UserActions = TryLogin
    | LoginSuccess
    | LoginFail
    | TrySignup
    | SignupSuccess
    | SignupFail
    | TryConfirm
    | ConfirmSuccess
    | ConfirmFail
    | ChangeAuthIntent
    | Logout
    | SetUserDataAction;
