import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        height: '110px',
        top: '44px',
        left: 0,
        padding: '5px 10px',
        boxSizing: 'border-box',
        justifyContent: 'center',
        width: '100%',
        display: 'flex',
        '& video': {
            height: '76px',
            width: '137px',
            objectFit: 'cover', // Not supported only by IE
            [theme.breakpoints.up('xl')]: {
                width: '176px !important',
                height: '99px !important'
            }
        },
        '& #video-container': {
            height: '110px',
            margin: ' 0 30px',
            padding: '5px 0',
            overflowX: 'hidden',
            overflowY: 'hidden',
            boxSizing: 'border-box',
            display: 'flex',
            [theme.breakpoints.up('xl')]: {
                height: '135px'
            },
            '& > div': {
                boxShadow: '0 2px 2px 0 rgb(0 0 0 / 40%)',
                borderRadius: '3px',
                border: '2px solid #aeaeb0',
                height: '80px !important',
                width: '141px !important',
                boxSizing: 'border-box',
                [theme.breakpoints.up('xl')]: {
                    width: '180px !important',
                    height: '103px !important'
                },
            },
            '& > div:not(:last-child)': {
                marginRight: '5px'
            }
        }
    },
    carouselPrev: {
        display: 'flex',
        position: 'absolute',
        height: '100px',
        zIndex: 999,
        left: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '41px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            transform: 'rotate(180deg)',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    carouselNext: {
        display: 'flex',
        position: 'absolute',
        height: '100px',
        zIndex: 999,
        right: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '41px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    screenShare: {
        width: '100%',
        height: '90vh',
        position: 'relative',
        // left: 'calc(50% - 605px)',
        // top: 'calc(50% - 410px)',
        '& > div': {
            width: '100% !important',
            height: '84vh !important'
        },
        '&#screen-preview-thumb, &#screen-preview': {
            backgroundColor: '#fff',
            borderRadius: '5px'
        },
        '&#screen-preview': {
            background: 'url(/icons/logo-big.svg) no-repeat center center'
        }
    }
}));

export default useStyles;