import { Button, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { SocketActionTypes } from '../../../../../socket/SocketActions';
import { DialogActionTypes } from '../../store/DialogActions';
import useStyles from './styles/confirmFFADialogStyles';

const ConfirmFFADialog = () => {
    const dispatch = useDispatch();
    const store = useStore();
    const classes = useStyles();

    const handleClose = () => {
        dispatch({
            type: DialogActionTypes.HIDE_ALL
        });
    }

    const handleDone = () => {
        store.dispatch({
            type: DialogActionTypes.HIDE_ALL
        });
        store.dispatch({
            type: SocketActionTypes.NEXT_STAGE,
            stage: 12
        });
    }

    return (<Container>
            <Grid container className={classes.container}>
                <Grid item className={classes.question1}>
                    <p>Are you sure you are done with the Force Field Analisys?</p>
                </Grid>
                <Grid className={classes.btnContainer} item>
                    <Button className={classes.button2} onClick={handleClose}>Nope, not done</Button>
                    <Button className={classes.button2} onClick={handleDone}>Done</Button>
                </Grid>
            </Grid>
    </Container>);
}

export default ConfirmFFADialog;