import { makeStyles } from "@material-ui/core";

const 
useStyles = makeStyles((theme) => ({
    root: {
        background: 'url(/icons/lobby-background.svg) no-repeat 3% 100%',
        backgroundColor: '#0d4171',
        height: '100%',
    },
    container: {
        padding: '50px 100px',
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '20px', // A place fo the button next
        '& > div': {
            borderRadius: '3px',
            border: '2px solid#558EA6 !important',
            margin: '5px !important',
            width: '235px',
            height: '135px',
            overflow: 'hidden'
        },
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'cover' // Not supported only by IE
        }
    },
    screenShare: {
        width: '1000px',
        height: '700px',
        '& > div': {
            width: '1000px !important',
            height: '700px !important',
        }
    },
    nextBtn: {
        border: 0,
        position: 'absolute',
        right: '40px',
        top: 'calc(50% - 35px)',
        background: 'url(/icons/yellow-arrow-right.svg) no-repeat center center',
        backgroundSize: '20px 70px',
        width: '20px',
        height: '70px',
        cursor: 'pointer',
        outline: 0
        
    }
}));

export default useStyles;