import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles/styles';
import GeneralMap from '../../../../../shared/components/gameMap/GeneralMap';
import { AppState } from '../../../../../store/Store';
import MapMemorizationGrid from '../mapMemorizationDialog/mapMemorizationGrid';

const InServiceDialog = () => {
    const classes = useStyles();
    const selectGameNumbers = (state: AppState) => state.gameState.selectedFields;
    const gameNumbers = useSelector(selectGameNumbers);
    const selectHints = (state: AppState) => state.gameState.userHints;
    const userHints = useSelector(selectHints);
    const selectResource = (state: AppState) => state.gameState.resourceName;
    const userResource = useSelector(selectResource);


    const checkifHintsLabel = () => {
        if (userHints.length) {
            return (<p className={classes.mainTitle}>Try to remember the squares with X.</p>)
        }
        return (<span></span>)
    }

    const checkIfTeamLabel = () => {
        if (userHints.length && userResource.length) {
            return (<span className={classes.teamName}>{userResource}</span>)
        }
        return (<span></span>)
    }

    return (<Container>
            <Grid container className={classes.container}>
                <Grid item>
                    {checkifHintsLabel()}
                </Grid>
                <Grid className={classes.gameContainer} item>
                    <div className={classes.gameDiv}>
                        <GeneralMap numbers={gameNumbers} inServiceDialog={true} ></GeneralMap>
                    </div>
                    <div>
                        <Grid item xs={12} className={classes.teamGrid}>
                            {checkIfTeamLabel()}
                        </Grid>
                        <div className={`${classes.mapCont} map-memorization-grid-container`}>
                            <MapMemorizationGrid inServiceOption={true}></MapMemorizationGrid>
                        </div>
                    </div>
                </Grid>
            </Grid>
    </Container>);
}

export default InServiceDialog;