import { ICreateBulkUser } from "../shared/components/dialogComponent/dialogs/createPlayerDialog/createPlayerDialog";

export const validateData = (csvData: any[]) => {
  let parsed: ICreateBulkUser[] = [];
  let result = { error: "", data: parsed };
  csvData.forEach((item, idx) => {
    let keysLen = Object.keys(item).length;
    let check1 = item["email"] && item["name"];
    let check2 = item["password"] && keysLen === 3;
    if (check1 && check2) {
      let newEntry: ICreateBulkUser = {
        email: item["email"],
        name: item["name"],
        password: item["password"],
      };
      result.data.push(newEntry);
    }
    if (keysLen > 3) {
      result.error += `Illegal clumns found and filtered at row ${idx +
        1}; \r\n`;
    }
    if (keysLen < 3) {
      result.error += `Less than minimum required clumns found at row ${idx +
        1}; \r\n`;
    }
  });

  if (!result.data.length) {
    result.error += `No valid data found in this file! \r\n`;
  }

  return result;
};
