const startLoading = () => {
    document.getElementById("loading-indicator")?.classList.add('loading');
};

const stopLoading = () => {
    document.getElementById("loading-indicator")?.classList.remove('loading');
};

const loading = (showLoading: boolean = false) => {
    const element: any = document.getElementById("loading-indicator")?.classList;
    const method: any = showLoading ? 'add' : 'remove';
    element[method]('loading');
};

export const LoadingIndicatorService = {
    startLoading,
    stopLoading,
    loading
};