import React from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { AppState } from "./store/Store";
import GameContainer from "./game/components/GameContainer";
import VideoComponent from "./video/components/VideoComponent";
import { withRouter, RouteComponentProps } from "react-router";

const App = (props: RouteComponentProps) => {
  const selectGameNumbers = (state: AppState) => state.gameState.selectedFields;
  const gameNumbers = useSelector(selectGameNumbers);

  const showMainTitle = () => {
    const location: any = props.location;
    let pageTitle = "";
    if (!location.state?.mapDisabled) {
      pageTitle = "Discuss Your Strategy";
    }
    return <h1 className={`page-title`}>{pageTitle}</h1>;
  };

  return (
    <div className="App">
      <VideoComponent></VideoComponent>
      {showMainTitle()}
      <GameContainer numbers={gameNumbers}></GameContainer>
    </div>
  );
};

export default withRouter(App);
