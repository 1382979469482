import React, { useEffect } from "react";
import useStyles from "./AdminPanelStyles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "./styles/AdminPanel.scss";
import AdminDashboard from "./dashboard/AdminDashboard";
import AdminTrainingHistory from "./trainingsHistory/AdminTrainingsHistory";
import { userService } from "../../core/services/UserService";
import { useDispatch } from "react-redux";
import { DialogActionTypes } from "../../shared/components/dialogComponent/store/DialogActions";
import { AdminActionTypes } from "../store/AdminActions";
import { SocketActionTypes } from "../../socket/SocketActions";
import CurrentTrainings from "./currentTrainings/CurrentTrainings";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const setTabProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const AdminPanel = () => {
  const classes = useStyles();
  const [selectedTabId, setSelectedTabId] = React.useState(0);
  const dispatch = useDispatch();

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabId(newValue);
  };

  useEffect(() => {
    dispatch({
      type: DialogActionTypes.HIDE_ALL,
    });
  }, [dispatch]);

  const loadTrainings = (tabId: number, flag: boolean) => {
    setTabProps(tabId);
    const action = flag
      ? SocketActionTypes.GET_UPCOMING_TRAININGS
      : SocketActionTypes.GET_PREVIOUS_TRAININGS;
    dispatch({
      type: action,
      payload: flag,
    });
  };
  const loadCurrentTrainings = (tabId: number, flag: boolean) => {
    setTabProps(tabId);
    const action = flag
      ? SocketActionTypes.GET_CURRENT_TRAININGS
      : SocketActionTypes.GET_PREVIOUS_TRAININGS;
    dispatch({
      type: action,
      payload: flag,
    });
  };

  const handleLogout = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch({
      type: DialogActionTypes.LEAVE_MEETING,
      payload: {
        open: true,
      },
    });
  };

  const backToGame = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("YES");

    // dispatch({
    //   type: AdminActionTypes.LOGIN_TO_ADMIN,
    //   payload: false,
    // });
    // dispatch({
    //   type: AdminActionTypes.SET_INITIAL_FLAG,
    //   payload: false,
    // });

    // dispatch({
    //   type: DialogActionTypes.HIDE_ALL,
    // });
  };

  return (
    <div id="admin-panel-container" className={classes.mainBackground}>
      <AppBar position="static">
        <Tabs
          value={selectedTabId}
          onChange={handleChange}
          aria-label="Admin Tabs"
        >
          <Tab label="Dashboard" onClick={() => loadTrainings(0, true)} />
          <Tab
            label="Current Trainings"
            onClick={() => loadCurrentTrainings(1, true)}
          />
          <Tab
            label="Trainings History"
            onClick={() => loadTrainings(2, false)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTabId} index={0}>
        <AdminDashboard />
      </TabPanel>
      <TabPanel value={selectedTabId} index={1}>
        <CurrentTrainings />
        THIS
      </TabPanel>
      <TabPanel value={selectedTabId} index={2}>
        <AdminTrainingHistory />
      </TabPanel>
      <div id="admin-panel-user-info">
        <span>{userService.getUser()?.name}</span>
        <span className="separator"></span>
        <span className="btn btn-back" onClick={(event) => backToGame(event)}>
          Back To Game
        </span>
        <span className="separator"></span>
        <span onClick={(event) => handleLogout(event)}>Log out</span>
      </div>
    </div>
  );
};

export default AdminPanel;
