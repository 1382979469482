import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
    },
    mainTitle: {
        position: 'absolute',
        marginTop: '-8px;',
        marginLeft: '442px',
        fontSize: '21px',
        fontWeight: 'bold',
   
    },
    gameContainer: {
        position: 'relative',
        height: '610px',
        left: '52px',
        marginTop: '42px',
        width: 'auto',
        display:'grid',
        gridTemplateColumns: 'auto auto'
    },
    gameDiv: {
        marginLeft: '-154px',
        [theme.breakpoints.up('xl')]: {
            marginLeft: '-120px',
        }
    },
    teamGrid: {
        marginTop: '62px',
        fontWeight: 'bold',
        marginLeft: '70px',
        position: 'absolute'
    },
    teamName: {
        fontFamily: 'Roboto',
        fontSize: '19px'
    },
    mapCont: {
        marginTop: '125px',
        width: 'fit-content',
    }
}));

export default useStyles;