import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'url(/icons/main-background-new-design-rvm.png) no-repeat bottom center, linear-gradient(to bottom, rgb(0, 68, 130), rgb(25, 113, 162) 100%)',
        backgroundSize: 'contain',
        height: '100%'
    },
    videoContainer: {
        height: '110px',
        position: 'fixed',
        bottom: '44px',
        left: 0,
        padding: '5px 10px',
        // backgroundColor: '#0d4171',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        '& video': {
            height: '96px',
            width: '159px',
            objectFit: 'cover' // Not supported only by IE
        },
        '& #video-container': {
            height: '110px',
            position: 'fixed',
            bottom: '44px',
            left: 0,
            margin: '0 2%',
            padding: '5px 0',
            // backgroundColor: '#0d4171',
            overflowX: 'hidden',
            overflowY: 'hidden',
            boxSizing: 'border-box',
            width: '96%',
            display: 'flex',
            '& > div': {
                borderRadius: '3px',
                border: '2px solid#558EA6 !important',
                height: '100px !important',
                width: '163px !important',
                boxSizing: 'border-box'
            },
            '& > div:not(:last-child)': {
                marginRight: '5px'
            }
        }
    },
    carouselPrev: {
        display: 'flex',
        position: 'absolute',
        height: '100px',
        zIndex: 999,
        left: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '41px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            transform: 'rotate(180deg)',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    carouselNext: {
        display: 'flex',
        position: 'absolute',
        height: '100px',
        zIndex: 999,
        right: 0,
        '& button': {
            cursor: 'pointer',
            border: 0,
            outline: 0,
            width: '41px',
            background: 'url(icons/yellow-arrow-right.svg) no-repeat center center',
            '&:hover': {
                background: 'url(icons/yellow-arrow-right-hover.png) no-repeat center center'
            }
        }
    },
    screenShare: {
        width: '100%',
        height: '90vh',
        position: 'relative',
        // left: 'calc(50% - 605px)',
        // top: 'calc(50% - 410px)',
        '& > div': {
            width: '100% !important',
            height: '84vh !important'
        },
        '&#screen-preview-thumb, &#screen-preview': {
            backgroundColor: '#fff',
            borderRadius: '5px'
        },
        '&#screen-preview': {
            background: 'url(/icons/logo-big.svg) no-repeat center center'
        }
    }
}));

export default useStyles;