/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GameActions, GameActionTypes } from "../../../game/store/GameActions";
import { AppState } from "../../../store/Store";
import useStyles from "./styles/GeneralMapStyles";
import "./styles/generalMap.scss";

const rowMap: any = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
};

const getKeyByValue = (object: any, value: string) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const GeneralMap = (props: any) => {
  const classes = useStyles();

  const generalMap: Array<any> = [];
  const cellNum = Object.keys(rowMap).length;
  const numbersArr: string[] = props.numbers;
  const dispatch = useDispatch();

  for (let row = 0; row < cellNum; row++) {
    generalMap[row] = [];
    for (let col = 0; col < cellNum; col++) {
      let foundSomeKey = false;
      for (let index = 0; index < numbersArr.length; index++) {
        const letter = numbersArr[index][0];
        const number = Number(numbersArr[index].split(/(\d+)/)[1]) - 1;
        const foundKey = Number(getKeyByValue(rowMap, letter));
        if (foundKey === row && number === col) {
          generalMap[row].push({ name: col, selected: false, value: true });
          foundSomeKey = true;
        }
      }
      if (!foundSomeKey) {
        generalMap[row].push({ name: col, selected: false, value: undefined });
      }
    }
  }

  const selectGameNumbers = (state: AppState) => state.gameState.grid;
  const gridMap = useSelector(selectGameNumbers);

  const dispatchGrid = () => {
    const gridCreated: GameActions = {
      type: GameActionTypes.GRID_CREATED,
      payload: generalMap,
    };
    dispatch(gridCreated);
  };

  if (!gridMap?.length && generalMap?.length === 12) {
    dispatchGrid();
  }

  const actionOnClick = (rowsIndex: string, colIndex: number) => {
    if (props?.handleOnClick) {
      props.handleOnClick(rowsIndex, colIndex);
    }
  };

  const getGridItemBasedOnState = (
    cell: any,
    rowsIndex: number,
    colIndex: number
  ) => {
    const classToUse = cell.selected
      ? cell.value
        ? `${classes.paperMatch} game-match`
        : `${classes.paperNoMatch} game-no-match`
      : `${classes.paperInside} game-inside`;

    return (
      <Grid key={`${colIndex}-${rowsIndex}`} className="grid-item" item>
        <Paper
          className={classToUse}
          onClick={() => actionOnClick(rowMap[colIndex], rowsIndex + 1)}
          elevation={0}
        />
      </Grid>
    );
  };

  let numTop = `${classes.paperNumTop} game-num-top`;
  let numLeft = `${classes.paperNumLeft} game-num-left`;
  if (props.inServiceDialog) {
    numTop += ` ${classes.paperNumTopServ}`;
    numLeft += ` ${classes.paperNumTopServ}`;
  }

  const gridName = `${classes.root} main-root-grid`;
  let matrixCont = `${classes.matrixContainer}`;
  if (props.inServiceDialog) {
    matrixCont = ` matrix-inservice`;
  }

  return (
    <Grid container className={gridName} spacing={0}>
      <Grid item xs={12}>
        {/* Top Alphabetic cell */}
        <Grid
          container
          className="root-grid1"
          justifyContent="center"
          spacing={0}
        >
          {Object.keys(rowMap).map((colKey, colIndex) => {
            return (
              <Grid item key={`colKey${colIndex}`} className={classes.bold}>
                <Paper className={numTop} elevation={0}>
                  {rowMap[colKey]}
                </Paper>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          container
          justifyContent="center"
          className={matrixCont}
          spacing={0}
        >
          <Grid item>
            <Grid container direction="column">
              {Object.keys(rowMap).map((colKey, colIndex) => {
                return (
                  <Grid
                    key={`cell${colKey}-${colIndex}`}
                    item
                    className={classes.bold}
                  >
                    <Paper
                      className={numLeft}
                      elevation={0}
                      key={`${colIndex + 1}-${rowMap[colKey]}`}
                    >
                      {colIndex + 1}
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {/* Grid values */}
          <Grid
            item
            className={`${classes.paperInsideContainer} game-inside-container`}
          >
            {gridMap.map((row, rowsIndex) => {
              return (
                <Grid
                  item
                  container
                  justifyContent="center"
                  className="row-grid"
                  spacing={0}
                  key={`${rowsIndex + 1}`}
                >
                  {row.map((cell: any, colIndex: number) => {
                    return getGridItemBasedOnState(cell, rowsIndex, colIndex);
                  })}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralMap;
