import { makeStyles } from "@material-ui/core";

const 
useStyles = makeStyles((theme) => ({
    container: {
        width: '250px',
        height: '40px',
        padding: '0 15px',
        boxSizing: 'border-box',
        position: 'relative',
        overflow: 'hidden',
        borderTopRightRadius: '2px',
        borderTopLeftRadius: '2px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.4)',
        backgroundColor: 'rgb(203, 239, 255)',
        cursor: 'pointer',
        transition: 'all 0.5s',
        '&::after': {
            content: '""',
            position: 'absolute',
            right: '15px',
            top: '12px;',
            transition: 'all 0.2s',
            display: 'inline-block',
            backgroundImage: 'url(/icons/arrow-right.svg)',
            width: '7px',
            height: '16px'
        },
    },
    txtDiv: {
    },
    textAreaDiv: {
        width: '341px',
        height: 'auto',
        padding: '22px 4px 10px 15px',
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
        borderBottomLeftRadius: '2px',
        zIndex: 999,
        boxShadow: '0 2px 2px 0 rgb(0 0 0 / 40%)',
        position: 'relative',
        marginLeft: '0px',
        backgroundColor: 'rgb(237, 240, 242)',
        '&.up': {
            transform: "translateY(-232px)",
            transition: 'none',
            boxShadow: '0 -2px 2px 0 rgb(0 0 0 / 40%)',
            borderBottomLeftRadius: '0',
        }
    },
    textarea: {
        overflow: 'initial !important',
        height: '170px !important',
        maxHeight: '250px',
        fontFamily: 'Roboto',
        lineHeight: '1.92',
        fontSize: '13px',
        width: '335px',
        marginLeft: '-9px',
        resize: 'none',
        '&:disabled': {
            backgroundColor: 'transparent',
            border: 'unset !important'
        },
        '&::-webkit-scrollbar': {
            width: '13px',
            backgroundColor: 'white'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#427d96',
          borderRadius: '2px'
        },
        [theme.breakpoints.down('lg')]: {
            height: '120px !important',
        },
    },
    header: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        margin: 0,
        padding: '11px 0 11px 2px',
        textAlign: 'left',
        color: '#1b1c20'
    },
    saveEditBtnContainer: {
        display: 'inline-block',
        width: '100%',
        textAlign: 'left',
        position: 'relative',
        marginTop: '-6px',
        marginLeft: '-16px'
    },
    saveEditBtn: {
        color: '#0d4171',
        border: 'none',
        fontSize: '13px;',
        marginTop: '10px',
        cursor: 'pointer',
        fontFamily: 'Roboto',
        borderRadius: '2px',
        lineHeight: 1.75,
        fontWeight: 'bold',
        marginRight: '6px',
        padding: '3px 10px 3px 13px',
        backgroundColor: 'rgb(195, 197, 199)',
        '&:focus': {
            outline: 'none'
        }
    }
}));

export default useStyles;