import { Auth } from "aws-amplify";
import {
  UserSignupData,
  UserConfirmData,
  UserLoginData,
} from "./../store/UserReducer";

async function signup(req: UserSignupData) {
  try {
    const authResp = await Auth.signUp({
      username: req.email,
      password: req.password,
      attributes: {
        email: req.email,
        name: req.name,
      },
    });

    if (authResp.user) {
      return { success: true, error: undefined };
    }

    return { success: false, error: "Signup Failed" };
  } catch (e) {
    return { success: false, error: (e as any).message };
  }
}

async function confirm(req: UserConfirmData) {
  try {
    const confirmResp = await Auth.confirmSignUp(
      req.username,
      req.confirmationCode
    );

    if (confirmResp) {
      return { success: true, error: undefined };
    } else return { success: false, error: "Could not confirm the code" };
  } catch (e) {
    return { success: false, error: (e as any).message };
  }
}

async function login(
  req: UserLoginData
): Promise<{ success: boolean; error: string | undefined; userData: any }> {
  try {
    const signinResp = await Auth.signIn(req.email, req.password);
    localStorage.setItem("username", signinResp.attributes.email);
    return { success: true, error: undefined, userData: signinResp };
  } catch (e) {
    return { success: true, error: (e as any).message, userData: undefined };
  }
}

export const authService = { signup, confirm, login };
