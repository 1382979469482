import { Reducer } from "redux";
import { UserModel } from "../../core/models/UserModel";
import { UserActions, UserActionTypes } from "./UserActions";

export interface UserLoginData {
  email: string;
  password: string;
} //maybe change this.

export type UserSignupData = {
  name: string;
  password: string;
  email: string;
};

export type UserConfirmData = {
  username: string;
  confirmationCode: string;
};

export interface UserState {
  username: string | undefined;
  token: string | undefined;
  online: boolean;
  isLoading: boolean;
  connected: boolean; // Will be set to true once the socket is connected to the server
  isError: boolean;
  isSuccess: boolean;
  userData: UserModel;
  error: string | undefined;
  authIntent: "login" | "signup" | "confirm";
}

const initialUserState: UserState = {
  username: undefined,
  token: undefined,
  online: true,
  isLoading: false,
  isError: false,
  isSuccess: false,
  userData: {},
  error: undefined,
  authIntent: "login",
  connected: false,
};

export const userReducer: Reducer<UserState, UserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case UserActionTypes.TRY_LOGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UserActionTypes.SET_USER_DATA: {
      console.log("SET USER DATA...");
      console.log(action.userData);

      return {
        ...state,
        userData: action.userData,
      };
    }
    case UserActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        token: action.token,
      };
    }
    case UserActionTypes.LOGIN_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    }
    case UserActionTypes.TRY_SIGNUP: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    }
    case UserActionTypes.SIGNUP_SUCCESS: {
      return {
        ...state,
        username: action.username,
        isLoading: false,
        isError: false,
        error: undefined,
        isSuccess: true,
      };
    }
    case UserActionTypes.SIGNUP_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
        isSuccess: false,
      };
    }
    case UserActionTypes.TRY_CONFIRM: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    }
    case UserActionTypes.CONFIRM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: undefined,
      };
    }
    case UserActionTypes.CONFIRM_FAIL: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: action.error,
      };
    }
    case UserActionTypes.CHANGE_AUTH_INTENT: {
      return {
        ...state,
        authIntent: action.intent,
        isLoading: false,
        isError: false,
        error: undefined,
      };
    }
    case UserActionTypes.LOGOUT: {
      return initialUserState;
    }
    default: {
      return state;
    }
  }
};
