import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";

import { Store } from "redux";
import { Provider } from "react-redux";
import configureStore, { AppState } from "./store/Store";

import awsconfig from "./aws-exports";
import { BrowserRouter } from "react-router-dom";
import Routes from "./core/routes/Routes";
import DialogComponent from "./shared/components/dialogComponent/DialogComponent";
import { ToastProvider } from "react-toast-notifications";
import ParticipantsList from "./shared/sidebar/components/participantsList/ParticipantsList";
import VideoControls from "./shared/components/videoControls/VideoControls";
import Chat from "./shared/sidebar/components/chat/Chat";
// TODO: Test this again for performance issues
// var {registerObserver} = require('react-perf-devtool')
// registerObserver()

Amplify.configure(awsconfig);

interface AppProps {
  store: Store<AppState>;
}

const Root: React.FunctionComponent<AppProps> = (props) => {
  return (
    <ToastProvider>
      <Provider store={props.store}>
        <DialogComponent />
        <ParticipantsList />
        <Chat />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <VideoControls />
      </Provider>
    </ToastProvider>
  );
};

export const store = configureStore();

ReactDOM.render(
  <Root store={store} />,
  document.getElementById("root") as HTMLElement
);
serviceWorker.unregister();
