import { Button, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { DialogActionTypes } from '../../store/DialogActions';
import useStyles from './styles/confirmGenericStyles';

const ConfirmGenericDialog = (props?: any) => {
    const dispatch = useDispatch();
    const store = useStore();
    const classes = useStyles();

    const handleClose = () => {
        dispatch({
            type: DialogActionTypes.HIDE_ALL
        });
    }

    const handleDone = () => {
        store.dispatch({
            type: DialogActionTypes.HIDE_ALL
        });
        store.dispatch({
            type: DialogActionTypes.SET_CONFIRM_BUTTON,
            payload: props.actionAfterDone
        });
    }

    return (<Container>
            <Grid container className={classes.container}>
                <Grid item className={classes.question1}>
                    <p>{props.textQuestion}</p>
                </Grid>
                <Grid className={classes.btnContainer} item>
                    <Button className={classes.button1} onClick={handleClose}>Nope, not sure</Button>
                    <Button className={classes.button2} onClick={handleDone}>Confirm</Button>
                </Grid>
            </Grid>
    </Container>);
}

export default ConfirmGenericDialog;