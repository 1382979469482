import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useStore } from 'react-redux';
// import { LoadingIndicatorService } from '../../../../../core/loadingIndicator/LoadingIndicatorService';
import { stageManagement } from '../../../../../core/services/StageManagement';
import { GameStages } from '../../../../../game/store/GameReducer';
import useStyles from './styles/managamentStyles';

const ManagementDialogComponent = (props: {openTokConfig: any}) => {
    const classes = useStyles();
    const store = useStore();

    const handleJoin = () => {
        const currentStage = store.getState().gameState.stage;
        stageManagement.goToStage(store, props.openTokConfig, GameStages.PRESENTATION, currentStage);
    };

    // useEffect(() => {
    //     LoadingIndicatorService.stopLoading();
    //     return () => {
    //         LoadingIndicatorService.startLoading();
    //     }
    // }, []);

    return (<div>
            <Grid container spacing={3} className={classes.contContainer}>
                <Grid item xs={1} className={classes.grid}>
                    <span>To:</span>
                </Grid>
                <Grid item xs={11} className={classes.grid}>
                    <span className={classes.to}>
                        Regional Management
                    </span>
                </Grid>
                <Grid item xs={1} className={classes.grid}>
                    <span>From:</span>
                </Grid>
                <Grid item xs={11} className={classes.grid}>
                    <span className={classes.from}>
                        Corporate Headquarters
                    </span>
                </Grid>
                <Grid item xs={1} className={classes.grid}>
                    <span>Re:</span>
                </Grid>
                <Grid item xs={11} className={classes.grid}>
                    <span className={classes.subject}>
                        Staff Meeting
                    </span>
                </Grid>
            </Grid>
            <div className={classes.content}>  
                Please report to Corporate Headquarters at once for a staff meeting.
            </div>
            <Button variant="outlined"className={classes.button} color="primary" onClick={handleJoin}>
                Join Staff Meeting
            </Button>
     </div>)


}

export default ManagementDialogComponent