import { Button, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserRoleModel } from '../../../../../core/models/UserModel';
import { SocketActions, SocketActionTypes } from '../../../../../socket/SocketActions';
import { AppState } from '../../../../../store/Store';
import { DialogActionTypes } from '../../store/DialogActions';
import './styles/assignTrainerStyles.scss';

const AssignTrainerDialog = (props?: any) => {
    const dispatch = useDispatch();
    const [rmId, setRmId] = React.useState(-1);
    const [teamId, setTeamId] = React.useState(1);
    const [isAdmin, setAdminState] = React.useState(false);
    const [teamsList, setTeamsList] = React.useState([]);
    const currentUserData = (state: AppState) => state.userState.userData;
    const usersData = (state: AppState) => state.gameState.users;
    const gameStage = (state: AppState) => state.gameState.stage;
    const usersSelector = useSelector(usersData);
    const currentUserDataSelector = useSelector(currentUserData);
    const gameStageSelector = useSelector(gameStage);
    const [usersState, setUsersState] = React.useState({} as any);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const numValue = Number(event.target.value);
        setRmId(numValue);
    };

    const handleTeamChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const numValue = Number(event.target.value);
        setTeamId(numValue);
    };

    useEffect(() => {
        setAdminState(currentUserDataSelector.role === UserRoleModel.Trainer);
        let teams: any = JSON.parse(localStorage.getItem('temp-teams-list') as any);
        teams = teams == null ?  [] : teams;
        setTeamsList(teams);

        const newUsersState: any = {};
        usersSelector.forEach(user => {
            newUsersState[user.id] = [UserRoleModel.Trainer, UserRoleModel.RM].indexOf(user.role) > -1;
        });
        setUsersState({...newUsersState});
    }, [usersSelector, currentUserDataSelector, gameStageSelector]);

    const handleAddTrainer = () => {
        let user = _.find(usersSelector, { id: rmId })
        let userObj:any = {};
        userObj[user.id] = [UserRoleModel.Trainer, UserRoleModel.RM].indexOf(user.role) > -1
        setUsersState({
            ...usersState,
            ...userObj
        });
        const switchTrainer: SocketActions = {
            type: SocketActionTypes.SWITCH_TRAINER,
            userId: user.id,
            teamId: teamId
        };
        let team: any = _.find(teamsList, { id: teamId });
        team.rm = rmId;
        setTeamsList([
            ...teamsList,
            ...[team]
        ] as any);
        dispatch(switchTrainer);
    }

    const getTeamName = (userId: any) => {
        let result = '';
        const team: any = _.find(teamsList, {rm: userId});
        if (team) {
            result = ` - ${team.name}`;
        }
        return result;
    }

    const handleClose = () => {
        dispatch({
            type: DialogActionTypes.HIDE_ALL
        });
    }

    return (isAdmin && usersSelector.length > -1 ?
        <div>
            <div className='container-rm'>
                Add Trainer: 
                <FormControl className='form-control-dummy-rm'>
                    <Select
                        labelId="stage-select"
                        id="stage-select"
                        value={rmId}
                        onChange={handleChange}
                        >
                        <MenuItem value='-1' key={-1}>
                            Please Select
                        </MenuItem>
                        {usersSelector.map((user, index) => {
                            return (<MenuItem value={user.id} key={index}>
                                {user.name}{getTeamName(user.id)}
                            </MenuItem>)
                        })}
                    </Select>
                </FormControl>

                <FormControl className='form-control-dummy-rm'>
                    <Select
                        labelId="teams-select"
                        id="teams-select"
                        value={teamId}
                        onChange={handleTeamChange}
                        >
                        <MenuItem value='-1' key={-1}>
                            Please Select
                        </MenuItem>
                        {teamsList.map((team: any, index) => {
                            return (<MenuItem value={team.id} key={index}>
                                {team.name}
                            </MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <Button className='button-add' onClick={handleAddTrainer} disabled={teamId === -1 || rmId === -1}>Add</Button>
            </div>
            <Grid className='btn-container' item>
                <Button className='button1' onClick={handleClose}>Close</Button>
            </Grid>
        </div> : <></>);
}

export default AssignTrainerDialog;