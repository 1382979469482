export enum ChatActionTypes {
    ADD_MESSAGE = "ADD_MESSAGE",
    SEND_MESSAGE = "SEND_MESSAGE",
    RESET_MESSAGES = "RESET_MESSAGES"
}
export interface AddChatMessageAction {
    type: ChatActionTypes.ADD_MESSAGE,
    payload: any;
}

export interface SendChatMessageAction {
    type: ChatActionTypes.SEND_MESSAGE,
    payload: any;
}

export interface ResetMessagesAction {
    type: ChatActionTypes.RESET_MESSAGES,
    payload: boolean;
}

export type ChatActions = 
   | AddChatMessageAction
   | ResetMessagesAction
   | SendChatMessageAction;
