import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Subject, Subscription } from 'rxjs';
import { LoadingIndicatorService } from '../../core/loadingIndicator/LoadingIndicatorService';
import { scrollService } from '../../core/services/ScrollService';
import { VideoConfig } from '../../game/store/GameReducer';
import { AppState } from '../../store/Store';
import { opentokService } from '../../video/services/opentok.service';
import useStyles from './styles/PresentationPageStyles';

const VIDEO_CONTAINER_ID = 'video-container';
const unsubscribe$ = new Subject();
let subscription1: Subscription, subscr2: Subscription;

const PresentationPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showNavBtns, setShowNavBtns] = useState(false);
    const selectReadyToStart = (state: AppState) => state.gameState.videoConfig;
    const videoConfig = useSelector(selectReadyToStart) as VideoConfig;
    const pixelsPerScroll = 672;
    const { addToast } = useToasts();

    useEffect(() => {
        let mounted = true;
        LoadingIndicatorService.stopLoading();

        if (videoConfig.sessionId && videoConfig.apiKey && mounted) {
            opentokService.initializeOpenTok(videoConfig, VIDEO_CONTAINER_ID);
        }

        if (mounted) {
            subscription1 = opentokService.connectedUsers$.subscribe(_ => {
                updateNavBtnsState();
            });
            if (subscr2) {
                subscr2.unsubscribe();
            }
            subscr2 = opentokService.errorsByOpenTok$.subscribe((error: string | number) => {
                if (error === 1500) {
                    addToast(`Error: Please allow access to your camera and microphone in order to be able to talk.`, 
                        {appearance: 'error', autoDismiss: true});
                } else if (error === 1004) {
                    addToast(`Authentication Error: The token does not match the session ID.`, {appearance: 'error', autoDismiss: true});
                } else  {
                    if (error) {
                        addToast(`Error: ${error}`, {appearance: 'error', autoDismiss: true});
                    }
                }
                if (error) {
                    opentokService.errorsByOpenTok$.next(undefined);
                }
            });
        }
        
        return () => {
            if (mounted) {
                LoadingIndicatorService.startLoading();
                unsubscribe$.complete();
                if (subscription1) {
                    subscription1.unsubscribe();
                }
            }
            if (subscr2) {
                subscr2.unsubscribe();
            }
            mounted = false;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, videoConfig])

    const updateNavBtnsState = () => {
        const winWidth = window.innerWidth;
        const elemWidth = document.querySelector(`#${VIDEO_CONTAINER_ID}`)?.scrollWidth || 0;
        setShowNavBtns(elemWidth + 25 > winWidth);
    }
    window.addEventListener('resize', updateNavBtnsState);
    window.addEventListener('load', updateNavBtnsState);

    const handlePrevClick = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
        scrollService.scrollLeft(elem, -pixelsPerScroll, 1000 );
    };
    
    const handleNextClick = (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const elem = document.querySelector(`#${VIDEO_CONTAINER_ID}`);
        scrollService.scrollLeft(elem, pixelsPerScroll, 1000 );
    };

    return (<div className={classes.root}>
        <div className={`${classes.screenShare}`} id="screen-preview"></div>
        <div className={`${classes.screenShare} hidden`} id="screen-preview-thumb"></div>
        <div className={`${classes.videoContainer}`}>
            <div className={`${classes.carouselPrev} ${showNavBtns ? '' : 'hidden'}`}>
                <button onClick={handlePrevClick}></button>
            </div>
            <div id={`${VIDEO_CONTAINER_ID}`}></div>
            <div className={`${classes.carouselNext} ${showNavBtns ? '' : 'hidden'}`}>
                <button onClick={handleNextClick}></button>
            </div>
        </div>
    </div>);
}

export default PresentationPage;