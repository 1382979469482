import { GameStages, VideoConfig } from "../../game/store/GameReducer";
import * as OT from "@opentok/client";
import { PublisherProperties, SubscriberProperties } from "@opentok/client";
import { Subject, BehaviorSubject } from "rxjs";
import { UserRoleModel } from "../../core/models/UserModel";
import { OpentokDataModel } from "../model/VideoModel";
import _ from "lodash";
import { DialogActionTypes } from "../../shared/components/dialogComponent/store/DialogActions";
import { store } from "../..";

const streamDestroyed$ = new Subject<boolean>();
const connectedUsers$ = new BehaviorSubject<number>(0);
const userVideos$ = new BehaviorSubject<any[]>([]);
let listOfUsers$ = new BehaviorSubject<OpentokDataModel[]>([]);
const errorsByOpenTok$: BehaviorSubject<any> = new BehaviorSubject<any>(
  undefined
);
const audioDevices$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
let _isSessionInitialized = false;

const handleVideoError = (error: any) => {
  if (error) {
    // errorsByOpenTok$.next(error?.code);
  }
};

let session: OT.Session;
let publisher: OT.Publisher;
let subscriber: any = {};
let subscriberActivity: any = {};
let userRoleMap: any = {};
let currentConnectionId: string;

let sessionsConnectedSet = new Set();
let sessionsConnectedVideoSet = new Set();
let isMicMuted$ = new BehaviorSubject(false);
let isVideoDisabled$ = new BehaviorSubject(false);

const publisherOptions: PublisherProperties = {
  insertMode: "append",
  insertDefaultUI: false,
  frameRate: 7,
  resolution: "320x180",
  audioBitrate: 20000,
};

const subscriberProperties: SubscriberProperties = {
  insertDefaultUI: false,
  preferredFrameRate: 7,
  preferredResolution: { width: 320, height: 180 },
};

const getListOfUsers = (event: any): any[] => {
  const userObj: OpentokDataModel = JSON.parse(event.connection.data).userObj;
  const filteredUsers = _.filter(listOfUsers$.value, (val) => {
    return val.id !== userObj.id;
  });
  return filteredUsers;
};

let publisherScreen: OT.Publisher;
const publishScreenOptions: PublisherProperties = {
  videoSource: "screen",
  insertMode: "append",
  fitMode: "contain",
  frameRate: 1,
};

const toggleVideoPlaceholder = (
  data: any,
  isDisabled: boolean,
  currentUser: boolean
) => {
  let el;
  const currUser = localStorage.getItem("username");
  const sessionId = "a" + data;
  if (!currentUser && sessionId.length > 10) {
    el = document.getElementById(sessionId);
  } else if (currUser && currentUser) {
    el = document.getElementById(currUser)?.parentElement;
  }
  if (el) {
    if (!isDisabled) {
      el.classList.add("disabled-video");
    } else {
      el.classList.remove("disabled-video");
    }
  }
};

const toggleAudio = (muted: boolean) => {
  if (publisher) {
    isMicMuted$.next(muted);
    publisher.publishAudio(!muted);
  }
};

const toggleVideo = (hideVideo: boolean) => {
  if (publisher) {
    isVideoDisabled$.next(hideVideo);
    publisher.publishVideo(!hideVideo);
    toggleVideoPlaceholder(publisher, !hideVideo, true);
  }
};

const endSession = () => {
  let hasDestroyedSomething = false;

  if (publisherScreen) {
    hasDestroyedSomething = true;
    session.unpublish(publisherScreen);
  }

  if (publisher) {
    hasDestroyedSomething = true;
    publisher.destroy();
  }
  if (session && (session as any)?.currentState === "connected") {
    hasDestroyedSomething = true;
    session.disconnect();
  }
  connectedUsers$.next(0);
  if (!hasDestroyedSomething) {
    // No session has been started
    setTimeout(() => {
      streamDestroyed$.next(true);
    }, 300);
  }
};

const isSessionInitialized = () => {
  return _isSessionInitialized;
};

const initializeOpenTok = (
  { apiKey = "", token = "", sessionId = "" }: VideoConfig,
  vidContainerId: string
) => {
  sessionsConnectedSet = new Set();
  sessionsConnectedVideoSet = new Set();

  const initializeSession = () => {
    if (_isSessionInitialized) {
      return;
    }

    _isSessionInitialized = true;
    if (sessionId) {
      session = OT.initSession(apiKey, sessionId);
    }

    // Subscribe to a newly created stream
    session.on("streamCreated", (event: any) => {
      const streams = event.streams;
      const isScreen = event.stream.videoType === "screen" ? true : false;
      const boxId = isScreen ? "screen-preview" : vidContainerId;
      if (isScreen) {
        for (let i = 0; i < streams.length; i++) {
          if (
            streams[i].connection.connectionId !==
            (session.connection as any).connectionId
          ) {
            if (store.getState().gameState.stage !== GameStages.PRESENTATION) {
              store.dispatch({
                type: DialogActionTypes.SHARE_SCREEN,
                payload: {
                  open: true,
                },
              });
              session.subscribe(
                event.stream,
                boxId,
                {
                  insertMode: "append",
                },
                handleVideoError
              );
            } else {
              session.subscribe(
                event.stream,
                boxId,
                {
                  insertMode: "append",
                },
                handleVideoError
              );
            }
          }
        }
      } else {
        for (let i = 0; i < streams.length; i++) {
          const conId = streams[i].connection.connectionId;
          const userData = JSON.parse(streams[i].connection.data).userObj;
          let finalRole: string = userRoleMap[conId]
            ? userRoleMap[conId].role
            : userData.role;
          finalRole = finalRole.toLowerCase();
        }
        for (let i = 0; i < streams.length; i++) {
          const conId = streams[i].connection.connectionId;
          if (
            !sessionsConnectedVideoSet.has(conId) &&
            conId !== (session.connection as any).connectionId
          ) {
            const id = "a" + Date.now();
            sessionsConnectedVideoSet.add(conId);
            toggleAudio(isMicMuted$.value);
            subscriber[id] = session.subscribe(
              event.stream,
              undefined,
              subscriberProperties,
              handleVideoError
            );
            subscriber[id].on("videoElementCreated", function(event: any) {
              const userData = JSON.parse(streams[i].connection.data).userObj;
              const container = getVideoNameRole(
                userData,
                streams[i].connection.connectionId
              );
              container.appendChild(event.element);
              userVideos$.next([...userVideos$.value, event.element]);
              if (
                userData.role === "Trainer" ||
                userData.role.toUpperCase() === "RM"
              ) {
                document.getElementById(vidContainerId)?.prepend(container);
              } else {
                document.getElementById(vidContainerId)?.appendChild(container);
              }
            });
            subscriberActivity[id] = null;
            subscriber[id].on("audioLevelUpdated", function(event: any) {
              showAudioIndicator(event, id);
            });
            subscriber[id].on("videoDisabled", (event: any) => {
              toggleVideoPlaceholder(
                event.target.stream.connection.connectionId,
                false,
                false
              );
            });
            subscriber[id].on("videoEnabled", (event: any) => {
              toggleVideoPlaceholder(
                event.target.stream.connection.connectionId,
                true,
                false
              );
            });
          }
        }
      }
    });

    session.on("streamDestroyed", function(event) {
      if (event.stream.videoType === "screen") {
        if (store.getState().gameState.stage !== GameStages.PRESENTATION) {
          store.dispatch({
            type: DialogActionTypes.SHARE_SCREEN,
            payload: {
              open: false,
            },
          });
        }
      }
    });

    session.on("connectionCreated", (event: any) => {
      if (!sessionsConnectedSet.has(event.connection.connectionId)) {
        sessionsConnectedSet.add(event.connection.connectionId);
        const userObj: OpentokDataModel = JSON.parse(event.connection.data)
          .userObj;
        const listOfUsrs = getListOfUsers(event);
        listOfUsers$.next([...getListOfUsers(event), userObj]);
        connectedUsers$.next(listOfUsrs.length + 1);
      }
    });

    session.on("connectionDestroyed", (event: any) => {
      setTimeout(() => {
        document.querySelector(`#a${event.connection.connectionId}`)?.remove();
      }, 100);

      const listOfUsrs = getListOfUsers(event);
      listOfUsers$.next(listOfUsrs);
      connectedUsers$.next(listOfUsrs.length);

      sessionsConnectedSet.delete(event.connection.connectionId);
      sessionsConnectedVideoSet.delete(event.connection.connectionId);
    });

    session.on("sessionDisconnected", (_event: any) => {
      streamDestroyed$.next(true);
      session = undefined as any;
      publisher = undefined as any;
      _isSessionInitialized = false;
      connectedUsers$.next(0);
      listOfUsers$.next([]);
      setTimeout(() => {
        removeBlankVideoBoxes();
        document.querySelector(`#a${currentConnectionId}`)?.remove();
        currentConnectionId = "";
      }, 100);
    });

    session.connect(token, (error: any) => {
      if (error) {
        handleVideoError(error);
      } else {
        publisher = OT.initPublisher(
          undefined,
          publisherOptions,
          handleVideoError
        );
        publisher.on("videoElementCreated", (event: any) => {
          const publisherConnection: any = session.connection;
          const userData: OpentokDataModel = JSON.parse(
            publisherConnection.data
          ).userObj;
          const container = getVideoNameRole(
            userData,
            publisherConnection.connectionId
          );
          container.appendChild(event.element);
          userVideos$.next([...userVideos$.value, event.element]);

          OT.getUserMedia(publisherOptions)
            .then((data) => {
              const clonedMediaStream = data.clone();
              publisherOptions.videoSource = clonedMediaStream.getVideoTracks()[0];
              publisherOptions.audioSource = clonedMediaStream.getAudioTracks()[0];
            })
            .catch((err) => {});

          if (vidContainerId) {
            document.getElementById(vidContainerId)?.appendChild(container);
            if (isVideoDisabled$.value) {
              toggleVideoPlaceholder(publisher, false, true);
              publisher.publishVideo(false);
            }
          }
        });
        publisher.on("audioLevelUpdated", (event: any) => {
          showAudioIndicator(event, "publisher");
        });

        session.publish(publisher);
      }
    });
  };

  initializeSession();
};

const showAudioIndicator = (event: any, id: any) => {
  var now = Date.now();
  if (event.audioLevel > 0.2) {
    if (!subscriberActivity[id]) {
      subscriberActivity[id] = { timestamp: now, talking: false };
    } else if (subscriberActivity[id].talking) {
      subscriberActivity[id].timestamp = now;
    } else if (now - subscriberActivity[id].timestamp > 100) {
      // detected audio activity for more than 100ms for the first time.
      subscriberActivity[id].talking = true;
      var inConn = !!event.target.stream && !!event.target.stream.connection;
      var c = event.target.stream.connection;

      document
        .querySelector(`#a${inConn && c.connectionId}`)
        ?.classList.add("talking");
    }
  } else if (
    subscriberActivity[id] &&
    now - subscriberActivity[id].timestamp > 1000
  ) {
    // detected low audio activity for more than 1s
    if (subscriberActivity[id].talking) {
      var inConnection = !!event.target.stream.connection;
      var conn = event.target.stream.connection;
      document
        .querySelector(`#a${inConnection && conn.connectionId}`)
        ?.classList.remove("talking");
    }
    subscriberActivity[id] = null;
  }
};

const removeBlankVideoBoxes = () => {
  document.querySelectorAll('div[class^="video-box-"]').forEach((elem) => {
    if (!elem.querySelector("video")) {
      elem.remove();
    }
  });
};

const getVideoNameRole = (userData: any, subscriberId: string) => {
  const containerOuter = document.createElement("div");
  const container = document.createElement("div");
  container.setAttribute("id", `a${subscriberId}`); // Sets an ID with initial char so we could have a valid ID

  let userName = userData.name;
  if (userRoleMap[subscriberId]) {
    userName = userRoleMap[subscriberId].name;
  } else {
    userRoleMap[subscriberId] = {
      name: "" + userName,
      role: "",
    };
  }

  const nameBox = document.createElement("span");
  nameBox.innerHTML = userName;
  nameBox.setAttribute("title", userName);
  nameBox.setAttribute("id", userName);
  nameBox.classList.add("name-box");
  container.append(nameBox);

  let userRole = userData.role.toLowerCase();
  if (userRoleMap[subscriberId] && userRoleMap[subscriberId].role) {
    userRole = userRoleMap[subscriberId].role;
  } else {
    userRoleMap[subscriberId].role = "" + userRole;
  }

  const finalRole = userRoleMap[subscriberId]
    ? userRoleMap[subscriberId].role
    : userData.role;
  if (userData.role !== UserRoleModel.Player) {
    const roleBox = document.createElement("span");
    roleBox.innerHTML = finalRole;
    roleBox.classList.add(userData.role.toLowerCase());
    container.append(roleBox);
  }

  const divShadow = document.createElement("dd");
  divShadow.innerHTML = "";
  divShadow.classList.add("div-shadow");
  container.append(divShadow);

  // Helps identify the element so they can be positioned properly on the UI
  const containerHelperCssClass = `video-box-${userData.placement || 0}`;
  container.setAttribute("class", containerHelperCssClass);

  containerOuter.append(container);

  return container;
};

const stopShareScreen = (force = false) => {
  if (force) {
    session.unpublish(publisherScreen);
  } else if (publisherScreen) {
    session.unpublish(publisherScreen);
  }
};

/**
 * Shares the screen using the already created session/key/token
 */
const shareScreen = () => {
  OT.checkScreenSharingCapability((response) => {
    if (!response.supported || response.extensionRegistered === false) {
      // This browser does not support screen sharing.
    } else if (response.extensionInstalled === false) {
      // Prompt to install the extension.
    } else {
      // Screen sharing is available. Publish the screen.
      publisherScreen = OT.initPublisher(
        "screen-preview",
        publishScreenOptions,
        (error) => {
          if (error) {
            // Look at error.message to see what went wrong.
          } else {
            session.publish(publisherScreen, (error: any) => {
              if (error) {
                // Look error.message to see what went wrong.
              }
            });
          }
        }
      );
    }
  });
};

const setAudioSource = (deviceId: string) => {
  if (publisher) {
    publisher.setAudioSource(deviceId).then((data: any) => {
      // Intentionally left blank
    });
  }
};

const getAudioDevices = () => {
  let audioInputDevices;
  OT.getDevices((_error, devices = []) => {
    audioInputDevices = devices.filter((element) => {
      return element.kind === "audioInput";
    });
    audioDevices$.next(audioInputDevices);
  });
};

export const opentokService = {
  initializeOpenTok,
  shareScreen,
  stopShareScreen,
  toggleAudio,
  toggleVideo,
  setAudioSource,
  getAudioDevices,
  endSession,
  isSessionInitialized,
  audioDevices$,
  streamDestroyed$,
  connectedUsers$,
  userVideos$,
  isMicMuted$,
  isVideoDisabled$,
  listOfUsers$,
  errorsByOpenTok$,
};
