import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Roboto',
        fontSize: '17px',
        fontWeight: 500,
        textAlign: 'center',
        display: 'block',
        color: '#000000'
    },
    header: {
        marginBottom: '50px',
        fontWeight: 'bold'
    },
    buttons: {
        '& .cancel': {
            padding: '6px 12px 6px 13px',
            borderRadius: '4px',
            fontFamily: 'Roboto',
            fontSize: '19px',
            color: '#fff',
            marginRight: '30px',
            backgroundColor: '#0d4171',
            // '&:hover': {
            //     background: 'linear-gradient(to bottom, #296daa, #53a6d5)'
            // }
            '& .MuiButton-label': {
                fontFamily: 'Roboto',
                fontSize: '19px',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.5',
                textTransform: 'none'
            }
        },
        '& .confirm': {
            padding: '6px 6px 5px 8px',
            fontFamily: 'Roboto',
            fontSize: '14px',
            borderRadius: '2px',
            backgroundColor: 'rgb(195, 197, 199)',
            color: '1b1c20',
            '& .MuiButton-label': {
                fontFamily: 'Roboto',
                fontSize: '14px;',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '1.5',
                color: 'rgb(0, 0, 0)',
                textTransform: 'none'
            }
            // '&:hover': {
            //     background: 'linear-gradient(to bottom, #aeaeb0, #edf0f2)'
            // }
        }
    }
}));

export default useStyles;