import { GameStages } from "../store/GameReducer";

const subStageToGameTitle: { [key in GameStages]?: { [key: string]: string } } | any = {};
subStageToGameTitle.MAP_ASSIGNMENT = {
    admin: '<h1>Assign resources to each member</h1>',
    player: '<h1>Assigning resources</h1>'
};
subStageToGameTitle.MAP_MEMORIZATION = {
    admin: '<h1>Try remembering the matches </h1>',
    player: '<h1>Try remembering the matches </h1>'
};
subStageToGameTitle.SUBSTAGE_MAP_MEMORIZATION = {
    admin: '<h1>Try remembering the matches </h1>',
    player: '<h1>Try remembering the matches </h1>'
};

subStageToGameTitle.SUBSTAGE_MAP_DISCUSS_STRATEGY = {
    admin: '<h1>Discuss your strategy</h1>',
    player: '<h1>Discuss your strategy</h1>'
};
subStageToGameTitle.SUBSTAGE_FIRST_QUARTER = {
    admin: '<h1>Quarter 1 in on!</h1> <h5>Time to open fields.</h5>',
    player: '<h1>Quarter 1 in on!</h1> <h5>Time to open fields.</h5>'
};
subStageToGameTitle.SECOND_QUARTER = {
    admin: '<h1>Quarter 2 in on!</h1> <h5>Time to open fields.</h5>',
    player: '<h1>Quarter 2 in on!</h1> <h5>Time to open fields.</h5>'
};
subStageToGameTitle.THIRD_FOURTH_QUARTER = {
    admin: '<h1>Quarters 3 and 4 in on!</h1> <h5>Time to open fields.</h5>',
    player: '<h1>Quarters 3 and 4 in on!</h1> <h5>Time to open fields.</h5>'
};
subStageToGameTitle.SUBSTAGE_FIRST_QUARTER_DISCUSSION = {
    admin: `<h1>Discuss your performance during Q1</h1>`,
    player: `<h1>Discuss your performance during Q1</h1>`
};
subStageToGameTitle.SUBSTAGE_MAP_ASSIGNMENT = {
    admin: '<h1>Assign resources to each member</h1>',
    player: '<h1>Assigning resources</h1>'
};
subStageToGameTitle.SUBSTAGE_ROOM_START = {
    admin: '<h1>Discuss Your Strategy</h1>',
    player: '<h1>Discuss Your Strategy</h1>'
};
subStageToGameTitle.REGIONAL_MANAGER_SELECTION = {
    admin: '<h1>Discuss Your Strategy</h1>',
    player: '<h1>Discuss Your Strategy</h1>'
};
subStageToGameTitle.REGIONAL_MANAGERS_MEETING = {
    admin: '<h1>Discuss Your Strategy</h1>',
    player: '<h1>HQ meeting</h1>'
};
subStageToGameTitle.STAGE_FORCE_FIELD_ANALYSIS = {
    admin: '<h1>Force Field Analysis</h1><div class="facilitator">Choose facilitator</div>',
    player: '<h1>Force Field Analysis</h1><div class="facilitator">Facilitator</div>'
};
subStageToGameTitle.REGIONAL_MANAGER_SWITCH = {
    admin: '<h1>Discuss Your Strategy</h1>',
    player: '<h1>HQ meeting</h1>'
};
subStageToGameTitle.NONE = {
    admin: '',
    player: ''
};

const getSubStageTitle = (SubStage: GameStages, isAdmin = false) => {
    const playerAdminStr = isAdmin ? 'admin' : 'player';
    if (isAdmin) {
        let result: any;
        switch (SubStage) {
            case GameStages.SUBSTAGE_MAP_DISCUSS_STRATEGY:
                result += `<button class="button-blue">Assign Resources</button>`;
                return result;
            case GameStages.SUBSTAGE_FIRST_QUARTER_DISCUSSION:
                result = subStageToGameTitle[GameStages.SUBSTAGE_FIRST_QUARTER_DISCUSSION][playerAdminStr];
                // result += `<button class="button-blue analyse-button">Start Analysis</button>`;
                return result;
            case GameStages.SECOND_QUARTER:
                result = subStageToGameTitle[GameStages.SECOND_QUARTER][playerAdminStr];
                return result;
            case GameStages.THIRD_FOURTH_QUARTER:
                result = subStageToGameTitle[GameStages.THIRD_FOURTH_QUARTER][playerAdminStr];
                return result;
            case GameStages.STAGE_FORCE_FIELD_ANALYSIS:
                result = subStageToGameTitle[GameStages.STAGE_FORCE_FIELD_ANALYSIS][playerAdminStr];
                result += `<select><option value="1">1</option></select>`;
                return result;
            default:
                return subStageToGameTitle[SubStage][playerAdminStr];
        }

    }

    return subStageToGameTitle[SubStage][playerAdminStr];
};

export const GameService = { getSubStageTitle };
