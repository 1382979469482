import { CreateUsersResponse } from "../models/AdminCreateUsersModel";
import { AvailableUsers } from "../models/AvailableUsersModel";

export enum AdminActionTypes {
  LOGIN_TO_ADMIN = "LOGIN_TO_ADMIN",
  SET_INITIAL_FLAG = "SET_INITIAL_FLAG",
  TRY_GET_GAMES = "TRY_GET_GAMES",
  TRY_GET_ASSIGNED_USERS = "TRY_GET_ASSIGNED_USERS",
  TRY_CREATE_GAME = "TRY_CREATE_GAME",
  SELECT_TRAINING = "SELECT_TRAINING",
  GET_TRAINING_DETAILS = "GET_TRAINING_DETAILS",
  GET_UPCOMING_TRAININGS = "GET_UPCOMING_TRAININGS",
  GET_PREVIOUS_TRAININGS = "GET_PREVIOUS_TRAININGS",
  GET_CURRENT_TRAININGS = "GET_CURRENT_TRAININGS",
  CREATE_GAME = "CREATE_GAME",
  LIST_USERS = "LIST_USERS",
  SET_AVAILABLE_USERS = "SET_AVAILABLE_USERS",
  SET_BULK_CREATE_RESPONSE = "SET_BULK_CREATE_RESPONSE",
}
export interface LoginToAdmin {
  type: AdminActionTypes.LOGIN_TO_ADMIN;
  payload: any;
}

export interface SetInitialFlag {
  type: AdminActionTypes.SET_INITIAL_FLAG;
  payload: any;
}

export interface TryCreateGame {
  type: AdminActionTypes.TRY_CREATE_GAME;
}

export interface ListPlayersAction {
  type: AdminActionTypes.LIST_USERS;
  payload: any;
}

export interface SetAvailableUsersAction {
  type: AdminActionTypes.SET_AVAILABLE_USERS;
  payload: AvailableUsers[];
}

export interface SetBulkCreateUsersResponseAction {
  type: AdminActionTypes.SET_BULK_CREATE_RESPONSE;
  payload: CreateUsersResponse;
}

export interface CreateGameAction {
  type: AdminActionTypes.TRY_CREATE_GAME;
  payload: any;
}

export interface GetUpcomingTrainingsAction {
  type: AdminActionTypes.GET_UPCOMING_TRAININGS;
  payload: any;
}

export interface GetTrainingDetails {
  type: AdminActionTypes.GET_TRAINING_DETAILS;
  payload: any;
}

export interface GetPreviousTrainingsAction {
  type: AdminActionTypes.GET_PREVIOUS_TRAININGS;
  payload: any;
}

export interface GetCurrentTrainings {
  type: AdminActionTypes.GET_CURRENT_TRAININGS;
  payload: any;
}

export interface TryGetAssignedUsers {
  type: AdminActionTypes.TRY_GET_ASSIGNED_USERS;
  gameId: string;
}

export interface SelectTrainingAction {
  type: AdminActionTypes.SELECT_TRAINING;
  payload: any;
}

export interface TryGetGames {
  type: AdminActionTypes.TRY_GET_GAMES;
}

export type AdminActions =
  | LoginToAdmin
  | SetInitialFlag
  | TryCreateGame
  | CreateGameAction
  | TryGetAssignedUsers
  | TryGetGames
  | GetUpcomingTrainingsAction
  | GetTrainingDetails
  | GetPreviousTrainingsAction
  | SelectTrainingAction
  | ListPlayersAction
  | SetAvailableUsersAction
  | SetBulkCreateUsersResponseAction
  | GetCurrentTrainings;
