import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import App from "../../App";
import LoginSignup from "../../authentication/components/LoginSignup";
import { GameStages } from "../../game/store/GameReducer";
import DebriefPage from "../../pages/debriefPage/DebriefPage";
import FinalTeamDiscussionPage from "../../pages/finalTeamDiscussionPage/FinalTeamDiscussionPage";
import InServicePage from "../../pages/inServicePage/InServicePage";
import LobbyPage from "../../pages/lobbyPage/LobbyPage";
import GameNotStartedPage from "../../pages/gameNotStartedPage/GameNotStartedPage";
import PresentationPage from "../../pages/presentationPage/PresentationPage";
import ResultPage from "../../pages/resultPage/ResultPage";
import ForceFieldDialog from "../../shared/components/dialogComponent/dialogs/forcefieldAnalysisDialog/ForceFieldAnalysisDialog";
import { AppState } from "../../store/Store";
import { ROUTE_NAMES } from "./constants/RouteContstants";
import AdminPanel from "../../admin/components/AdminPanel";

const Routes = () => {
  const selectGameStage = (s: AppState) => s.gameState.stage;
  const gameStage = useSelector(selectGameStage);
  const selectAdminState = (s: AppState) => {
    return {
      adminPanelActive: s.adminState.adminPanelActive,
      isAfterLogin: s.adminState.isAfterLogin,
    };
  };

  const adminState = useSelector(selectAdminState);
  let redirectTo = `/${ROUTE_NAMES.PAGES.LOGIN}`;
  let state = {};

  switch (gameStage) {
    case GameStages.AUTHENTICATING:
      redirectTo = `/${ROUTE_NAMES.PAGES.LOGIN}`;
      break;
    case GameStages.PLAYERS_INTRODUCTION:
      redirectTo = `/${ROUTE_NAMES.PAGES.PLAYERS_INTRODUCTION}`;
      break;
    case GameStages.PRESENTATION:
      redirectTo = `/${ROUTE_NAMES.PAGES.PRESENTATION}`;
      break;
    case GameStages.STAGE_FORCE_FIELD_ANALYSIS:
      redirectTo = `/${ROUTE_NAMES.PAGES.FFA}`;
      break;
    case GameStages.MAP_ASSIGNMENT:
    case GameStages.MAP_MEMORIZATION:
    case GameStages.FIRST_QUARTER:
    case GameStages.SECOND_QUARTER:
    case GameStages.THIRD_FOURTH_QUARTER:
    case GameStages.REGIONAL_MANAGER_SELECTION:
      redirectTo = `/${ROUTE_NAMES.PAGES.GAME}`;
      state = { mapDisabled: true };
      break;
    case GameStages.IN_SERVICE_OPTION:
      redirectTo = `/${ROUTE_NAMES.PAGES.IN_SERVICE_OPTION}`;
      break;
    case GameStages.RESULTS:
      redirectTo = `/${ROUTE_NAMES.PAGES.RESULT}`;
      break;
    case GameStages.DEBRIEF:
      redirectTo = `/${ROUTE_NAMES.PAGES.DEBRIEF}`;
      break;
    case GameStages.FINAL_DISCUSSION:
      redirectTo = `/${ROUTE_NAMES.PAGES.FINAL_DISCUSSION}`;
      break;
    case GameStages.CREATED:
      redirectTo = `/${ROUTE_NAMES.PAGES.NOT_STARTED}`;
      break;
    default:
      break;
  }

  // regardless of what has happened, if these are true, drop the user into admin panel!
  if (adminState.adminPanelActive && adminState.isAfterLogin) {
    redirectTo = `/${ROUTE_NAMES.PAGES.ADMIN_PANEL}`;
  }

  return (
    <>
      <Switch>
        <Route
          path={`/${ROUTE_NAMES.PAGES.PLAYERS_INTRODUCTION}`}
          component={LobbyPage}
        />
        <Route
          path={`/${ROUTE_NAMES.PAGES.PRESENTATION}`}
          component={PresentationPage}
        />
        <Route path={`/${ROUTE_NAMES.PAGES.GAME}`} component={App} />
        <Route path={`/${ROUTE_NAMES.PAGES.LOGIN}`} component={LoginSignup} />
        <Route
          path={`/${ROUTE_NAMES.PAGES.FFA}`}
          component={ForceFieldDialog}
        />
        <Route
          path={`/${ROUTE_NAMES.PAGES.IN_SERVICE_OPTION}`}
          component={InServicePage}
        />
        <Route path={`/${ROUTE_NAMES.PAGES.RESULT}`} component={ResultPage} />
        <Route
          path={`/${ROUTE_NAMES.PAGES.FINAL_DISCUSSION}`}
          component={FinalTeamDiscussionPage}
        />
        <Route path={`/${ROUTE_NAMES.PAGES.DEBRIEF}`} component={DebriefPage} />
        <Route
          path={`/${ROUTE_NAMES.PAGES.NOT_STARTED}`}
          component={GameNotStartedPage}
        />
        <Route
          path={`/${ROUTE_NAMES.PAGES.ADMIN_PANEL}`}
          component={AdminPanel}
        />
      </Switch>
      {
        <Redirect
          to={{
            pathname: redirectTo,
            state: state,
          }}
        />
      }
    </>
  );
};

export default withRouter(Routes as any);
