import { makeStyles } from "@material-ui/core";

const drawerWidth = 620;

const useStyles = makeStyles((theme) => ({
    arrow: {
      width: '53px',
      height: '138px',
      marginLeft: '-42px',
      marginRight: '-12px',
      marginTop: '281px',
      background: 'url(/icons/icons-big-arrow-right.png) no-repeat 3% 100%',
      border: 'unset !important',
      boxShadow: 'unset !important',
      flex: 'unset !important;'
    },
    mainBackground: {
      background: 'url(/icons/main-background.png) no-repeat 3% 100%',
      height: '100%'
    },
    root: {
      flexGrow: 1,
    },
    toolbar: {
        paddingRight: 24,
    },
    adminMenuName: {
      marginTop: '100px',
      marginLeft: '25px',
      position: 'absolute',
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Roboto'
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(3),
        },
    }
}));

export default useStyles;