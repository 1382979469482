/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import GeneralMap from "../../shared/components/gameMap/GeneralMap";
import useStyles from "./styles/gameStyles";
import TeamRules from "../../shared/components/teamRules/TeamRules";
import FinancialReport from "../../shared/components/financialReport/FinancialReport";
import { useDispatch, useSelector, useStore } from "react-redux";
import { SocketActions, SocketActionTypes } from "../../socket/SocketActions";
import { DialogActionTypes } from "../../shared/components/dialogComponent/store/DialogActions";
import { withRouter, RouteComponentProps } from "react-router";
import { AppState } from "../../store/Store";
import { GameStages } from "../store/GameReducer";
import { GameActionTypes } from "../store/GameActions";
import { LoadingIndicatorService } from "../../core/loadingIndicator/LoadingIndicatorService";
import { UserModel, UserRoleModel } from "../../core/models/UserModel";
import { GameService } from "../services/GameService";
import { Subscription } from "rxjs/internal/Subscription";
import CountDown from "../../shared/components/countDown/CountDown";
import { Box } from "@material-ui/core";
import "./styles/gameContainerStyles.scss";
import TeamName from "../../shared/components/teamName/TeamName";

let subscr1: Subscription;

const GameContainer = (props: RouteComponentProps | any) => {
  let [mapState, setMapState] = useState({ mapDisabled: false });
  let [gameTitleState, setGameTitleState] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();
  let gameTitleCallbackArr: any[] = [];

  const fixedContentHeight = clsx(classes.paper, classes.fixedMainHeight);
  const fixedHeightPaperLeft = clsx(classes.paper, classes.fixedHeightLeft);

  const subStage = (state: AppState) => state.gameState.subStage;
  const subStageSelector = useSelector(subStage);
  const userData = (state: AppState) => state.userState.userData;
  const userDataSelector: UserModel | any = useSelector(userData);

  const initializeSubStage = (subStageName: any) => {
    switch (subStageName) {
      case GameActionTypes.SUBSTAGE_MAP_ASSIGNMENT:
        if (
          [UserRoleModel.RM, UserRoleModel.Trainer].indexOf(
            userDataSelector.role
          ) > -1
        ) {
          setTimeout(() => {
            store.dispatch({
              type: DialogActionTypes.OPEN_MAP_ASSIGNMENT_DIALOG,
              payload: { mapAssignment: true },
            });
          }, 500); // Give it some time so the other component(if it exists) can finish work
        } else {
          store.dispatch({
            type: DialogActionTypes.TEXT_DIALOG,
            payload: {
              text: "Wait until you are assigned to a resource ...",
              open: true,
            },
          });
        }
        break;
      case GameActionTypes.SUBSTAGE_MAP_MEMORIZATION:
        store.dispatch({
          type: DialogActionTypes.OPEN_MAP_MEMORIZATION_DIALOG,
          payload: true,
        });
        break;
      case GameActionTypes.SUBSTAGE_FIRST_QUARTER:
      case GameActionTypes.SUBSTAGE_FIRST_QUARTER_DISCUSSION:
        store.dispatch({
          type: DialogActionTypes.HIDE_ALL,
        });
        break;
      case GameActionTypes.STAGE_FORCE_FIELD_ANALYSIS:
        break;
    }
  };

  const handleGridSelect = (col: string, row: number) => {
    if (!mapState.mapDisabled) {
      const colName = col.toLowerCase();
      const selectFieldAction: SocketActions = {
        type: SocketActionTypes.SELECT_FIELD,
        payload: `${colName}${row}`,
        originalField: `${colName}-${row}`,
      };
      dispatch(selectFieldAction);
    }
  };

  const onChangeStage = (stageId: number) => {
    const nextStageAction: SocketActions = {
      type: SocketActionTypes.NEXT_STAGE,
      stage: stageId,
    };
    dispatch(nextStageAction);
  };

  const quartersArr = [
    GameStages.SUBSTAGE_FIRST_QUARTER,
    GameStages.SECOND_QUARTER,
    GameStages.THIRD_FOURTH_QUARTER,
  ];

  useEffect(() => {
    LoadingIndicatorService.stopLoading();

    store.dispatch({
      type: DialogActionTypes.HIDE_ALL,
    });

    const isDisabled = !quartersArr.includes(subStageSelector);
    setMapState({ mapDisabled: isDisabled });

    if (subStageSelector !== GameStages.NONE) {
      initializeSubStage(subStageSelector);
    }

    const isUserTrainer =
      [UserRoleModel.Trainer, UserRoleModel.RM].indexOf(userDataSelector.role) >
      -1;
    setGameTitleState(
      GameService.getSubStageTitle(subStageSelector, isUserTrainer)
    );
    return () => {
      LoadingIndicatorService.startLoading();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subStageSelector]);

  useEffect(() => {
    let mounted = true;

    return () => {
      if (subscr1) {
        subscr1.unsubscribe();
      }
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOnClick = () => {
    let foundStage = false;
    switch (subStageSelector) {
      case GameStages.SUBSTAGE_MAP_DISCUSS_STRATEGY:
        gameTitleCallbackArr[0] = () => onChangeStage(5);
        foundStage = true;
        break;
      case GameStages.SUBSTAGE_FIRST_QUARTER_DISCUSSION:
        gameTitleCallbackArr[0] = () => onChangeStage(11);
        foundStage = true;
        break;
    }
    if (foundStage) {
      return gameTitleCallbackArr[0];
    }
    return undefined;
  };

  const ReactDOMServer = require("react-dom/server");
  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  let reactElement: any = "<></>";
  let reactHTML: any;
  if (gameTitleState) {
    reactElement = htmlToReactParser.parse(gameTitleState);
    reactHTML = ReactDOMServer.renderToStaticMarkup(reactElement);
  }

  let classForFixedHeight = `${fixedContentHeight} main-game-container`;
  if (props.inServicePage) {
    classForFixedHeight += ` ${classes.fixedHeight1}`;
  }

  const subscribeForOpenTokService = () => {
    if (subscr1) {
      subscr1.unsubscribe();
    }
    return null;
  };

  return (
    <div className={`${classes.root} game-component-container`}>
      {subscribeForOpenTokService()}
      <main className={classes.content}>
        <Paper className={fixedHeightPaperLeft} elevation={0}>
          <Box className={`countdown-container`}>
            {/* <Button onClick={(e) => onClose()}>test</Button> */}
            <CountDown></CountDown>
          </Box>
          <Box>
            <div
              className={classes.gameTitle}
              onClick={setOnClick()}
              dangerouslySetInnerHTML={{ __html: reactHTML }}
            ></div>
          </Box>
          <Box>
            <TeamName inServicePage={props.inServicePage}></TeamName>
          </Box>
          <FinancialReport />
          <TeamRules />
        </Paper>
        <Paper className={`${classForFixedHeight} game-wrapper`} elevation={0}>
          <GeneralMap
            handleOnClick={handleGridSelect}
            numbers={props.numbers}
          ></GeneralMap>
        </Paper>
      </main>
    </div>
  );
};

export default withRouter(GameContainer);
