import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { UserActions, UserActionTypes } from "../store/UserActions";
import { AppState } from "../../store/Store";
import { authService } from "../services/auth.service";
import useStyles from "../styles/styleClasses";
import { UserConfirmData } from "../store/UserReducer";

const initialUserConfirmData: UserConfirmData = {
  username: "",
  confirmationCode: "",
};

export default function Confirm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmValues, setConfirmValues] = useState(initialUserConfirmData);

  const selectUsername = (state: AppState) => state.userState.username;
  const selectIsLoading = (state: AppState) => state.userState.isLoading;

  const initialUsername = useSelector(selectUsername);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (initialUsername) {
      setConfirmValues((prevState) => ({
        ...prevState,
        username: initialUsername,
      }));
    }
  }, [initialUsername]);

  const changeValue = (e: any) => {
    const { name, value } = e.target;
    setConfirmValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changeIntent = () => {
    const changeIntentAction: UserActions = {
      type: UserActionTypes.CHANGE_AUTH_INTENT,
      intent: "login",
    };

    dispatch(changeIntentAction);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const tryConfirmAction: UserActions = {
      type: UserActionTypes.TRY_CONFIRM,
    };

    dispatch(tryConfirmAction);

    const confirmResult = await authService.confirm(confirmValues);

    if (confirmResult.success) {
      const confirmSuccessAction: UserActions = {
        type: UserActionTypes.CONFIRM_SUCCESS,
      };

      dispatch(confirmSuccessAction);
      changeIntent();
    } else if (confirmResult.error) {
      const confirmFailAction: UserActions = {
        type: UserActionTypes.CONFIRM_FAIL,
        error: confirmResult.error,
      };
      dispatch(confirmFailAction);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Please confirm your account
        </Typography>
        <Typography variant="body1">
          We've sent you a code at the email you provided. Please enter the code
          in the field bellow!
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="off"
            value={confirmValues.username}
            onChange={changeValue}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirmationCode"
            label="Confirmation Code"
            name="confirmationCode"
            autoComplete="off"
            autoFocus
            onChange={changeValue}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.login}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Verify
          </Button>
        </form>
      </div>
    </Container>
  );
}
