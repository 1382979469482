import _ from "lodash";
import moment from "moment";
import { AnyAction, Store } from "redux";
import { delay, first } from "rxjs/operators";
import { TrainingModel } from "../../admin/models/AdminTrainingsModel";
import { AdminActionTypes } from "../../admin/store/AdminActions";
import { UserActionTypes } from "../../authentication/store/UserActions";
import { UserModel, UserRoleModel } from "../../core/models/UserModel";
import { userService } from "../../core/services/UserService";
import { GameVisibilityService } from "../../game/services/GameVisibilityService";
import {
  GameActions,
  GameActionTypes,
  GameStagesAsIndeces,
} from "../../game/store/GameActions";
import { GameStages } from "../../game/store/GameReducer";
import { QuestionModel } from "../../pages/finalTeamDiscussionPage/question.model";
import {
  DialogActions,
  DialogActionTypes,
} from "../../shared/components/dialogComponent/store/DialogActions";
import {
  ChatActions,
  ChatActionTypes,
} from "../../shared/sidebar/components/chat/store/ChatActions";
import { opentokService } from "../../video/services/opentok.service";
import { AssignedUserBackend } from "../models/assigned-user-backend.model";
import { SocketStageTypes, SocketTypes } from "../models/SocketModel";
import {
  commonEndStageActions,
  convertAssignedUserResponse,
  convertUsersFromBackend,
  getPrevioslyOpenedFields,
  setTeamResource,
  showFirstQuarterDiscussion,
  showMapAssignmentDialog,
} from "../socket-in";
import { SocketActionTypes } from "../SocketActions";

export const cStgCheck = (payload: { action: string; game: any }) =>
  (payload?.action === "current-stage" || payload?.action === "new-stage") &&
  payload?.game;

interface IPayload {
  financialResult: any;
  financials: {
    resQ1: number | undefined;
    resQ2: number | undefined;
    resQ3: number | undefined;
    resFinal: number | undefined;
  };
  game: {
    ff_driving: any;
    ff_restraining: any;
    ff_editor_id: any;
    teams: any;
    rules: any;
  };
  financialsAll: any;
}

export const getFRDev = (stage: GameStages, payload: IPayload) => {
  let financeReportDev = 0;
  if (!payload.financialResult) return financeReportDev;

  if (stage === GameStages.FIRST_QUARTER) {
    if (payload.financials.resQ1 !== undefined) {
      financeReportDev = payload?.financials.resQ1;
    }
  } else if (stage === GameStages.SECOND_QUARTER) {
    if (payload.financials.resQ2 !== undefined) {
      financeReportDev = payload?.financials.resQ2;
    }
  } else if (stage === GameStages.THIRD_FOURTH_QUARTER) {
    if (payload.financials.resQ3 !== undefined) {
      financeReportDev = payload?.financials.resQ3;
    }
  } else {
    if (payload.financials.resFinal !== undefined) {
      financeReportDev = payload.financials.resFinal;
    }
  }

  return financeReportDev;
};

export const formInitialPayload = (
  stage: GameStages,
  payload: IPayload,
  newGrid: any,
  financeReportDev: number
) => {
  let drivingForces,
    restrainingForces,
    facilitatorId = undefined;

  if (stage === GameStages.STAGE_FORCE_FIELD_ANALYSIS) {
    drivingForces = payload?.game?.ff_driving;
    restrainingForces = payload?.game?.ff_restraining;
    facilitatorId = payload?.game?.ff_editor_id;
    if (facilitatorId === null) {
      facilitatorId = -1;
    }
  }
  const finResult1 = payload?.financials;
  const financialsAll = payload?.financialsAll;
  const roomTeams = payload?.game?.teams;
  const rules = payload?.game?.rules;

  return {
    grid: newGrid,
    subStage: GameStages.NONE,
    clearPreviouslySelectedGridFlds: true,
    rules,
    financialRes: financeReportDev,
    finResult: finResult1,
    financialsAll,
    roomTeams,
    drivingForces: drivingForces !== undefined ? drivingForces : undefined,
    restrainingForces:
      restrainingForces !== undefined ? restrainingForces : undefined,
    facilitatorId: facilitatorId,
    textUpdated: false,
  };
};

const teamsMap = {
  "0": {
    t: { teamName: "herbs", team: 1, availableTeam: "herbs" },
    f: { teamName: "herbs2", team: 2, availableTeam: "herbs" },
  },
  "1": {
    t: { teamName: "humidity", team: 3, availableTeam: "humidity" },
    f: { teamName: "humidity2", team: 4, availableTeam: "humidity" },
  },
  "2": {
    t: { teamName: "sun", team: 5, availableTeam: "sun" },
    f: { teamName: "sun2", team: 6, availableTeam: "sun" },
  },
  "3": {
    t: { teamName: "crops", team: 7, availableTeam: "crops" },
    f: { teamName: "crops2", team: 8, availableTeam: "crops" },
  },
};

const getSP = (
  assignedUser: AssignedUserBackend,
  assignFromBackend?: boolean
) => (!assignFromBackend ? assignedUser.hint : assignedUser.type.toString());

export const casHelper = (
  assignedUser: AssignedUserBackend,
  assignFromBackend?: boolean
) => {
  const switchProperty = getSP(assignedUser, assignFromBackend);
  let trigger = assignedUser.leftHint || assignedUser.left;

  switch (switchProperty) {
    case "0": {
      let map = teamsMap["0"];
      return trigger ? map.t : map.f;
    }
    case "1": {
      let map = teamsMap["1"];
      return trigger ? map.t : map.f;
    }
    case "2": {
      let map = teamsMap["2"];
      return trigger ? map.t : map.f;
    }
    case "3": {
      let map = teamsMap["3"];
      return trigger ? map.t : map.f;
    }
    default:
      return { team: 0, teamName: "", availableTeam: "" };
  }
};

export const dispatchDataAndDetails = (
  payload: any,
  uService: { setUser: (arg0: any) => void },
  store: Store<AnyAction>,
  gameId: number | undefined
) => {
  if (payload?.user && typeof payload?.user === "object") {
    console.log(`WILL SET THE USER DATA`, payload);

    uService.setUser(payload.user);
    store.dispatch({
      type: UserActionTypes.SET_USER_DATA,
      userData: payload.user,
    });

    if (gameId && payload.user.role === "Trainer") {
      store.dispatch({
        type: SocketActionTypes.GET_GAME_DETAILS,
        payload: gameId,
      });
    }
  }
};

export const dispatchEditGame = (payload: any, store: Store<AnyAction>) => {
  if (payload?.action === "edit-game") {
    store.dispatch({
      type: GameActionTypes.SET_GAME_EDITED_RESPONSE,
      payload: payload?.editGameStatus === "OK",
    });
  }
};

export const dispatchSetRoomTeams = (payload: any, store: Store<AnyAction>) => {
  if (payload?.game && payload?.game.teams) {
    store.dispatch({
      type: GameActionTypes.SET_ROOM_TEAMS,
      payload: payload.game.teams,
    });
  }
};

export const dispatchGetGame = (payload: any, store: Store<AnyAction>) => {
  if (payload?.action === "get-game" && payload.name) {
    store.dispatch({
      type: GameActionTypes.SET_GAME_DETAILS,
      payload: payload,
    });

    // Set financial report for admin panel
    if (store.getState().adminState?.adminPanelActive === true) {
      if (payload?.financials) {
        const financialResult = payload?.financials;
        store.dispatch({
          type: GameActionTypes.SET_FINANCIAL_RESULT,
          payload: { finAllResult: financialResult },
        });
      }
    }
  }
};

export const dispatchSetStage = (
  payload: any,
  store: Store<AnyAction>,
  gid: number | undefined
) => {
  let gameId: number | undefined = gid;
  if (payload && Object.keys(payload).length === 1 && payload.gameId) {
    store.dispatch({
      type: GameActionTypes.SET_GAME_ID,
      payload: payload.gameId,
    });

    gameId = payload.gameId;

    store.dispatch({
      type: SocketActionTypes.CURRENT_STAGE,
    });
  }

  return gameId;
};

export const dispatchUserActions = (payload: any, store: Store<AnyAction>) => {
  if (payload?.users) {
    store.dispatch({
      type: GameActionTypes.SET_USERS,
      payload: payload.users || [],
    });
  }

  if (payload?.game?.users) {
    store.dispatch({
      type: GameActionTypes.SET_GAME_USERS,
      payload: payload.game.users || [],
    });
  }

  if (payload?.teamUsers) {
    store.dispatch({
      type: AdminActionTypes.SET_AVAILABLE_USERS,
      payload: payload.teamUsers,
    });
  }
};

export const dispatchTrainings = (payload: any, store: Store<AnyAction>) => {
  console.log("socket-in triggered");
  console.log("payload is...", payload);

  if (payload?.trainings) {
    if (payload.trainings.length) {
      let currentTrainngs: TrainingModel[] = [];
      let upcomingTrainngs: TrainingModel[] = [];
      let pastTrainngs: TrainingModel[] = [];

      if (Array.isArray(payload.trainings)) {
        payload.trainings.forEach((training: TrainingModel) => {
          const firstTrainingDate = moment(
            payload.trainings[0]?.start_date,
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          );
          const duration = moment.duration(
            firstTrainingDate.diff(moment.now())
          );
          const minutes = duration.asMinutes();
          console.log("upcoming training minutes are...", minutes);

          if (training.stage !== undefined) {
            if (training.stage === 0) {
              if (minutes > 0) upcomingTrainngs.push(training);
              else pastTrainngs.push(training); // TODO: perhaps push that to current trainings?
            }
            if (training.stage !== 0 && training.stage < 19) {
              currentTrainngs.push(training);
            }
            if (training.stage === 19) {
              pastTrainngs.push(training);
            }
          } else {
            console.log(
              "ALERT <- no training stage present for this training!",
              training
            );
            if (minutes > 0) upcomingTrainngs.push(training);
            else pastTrainngs.push(training);
          }
        });
      }

      console.log(`full on trainings report: \r\n 
        ====== upcoming: ${upcomingTrainngs.length} ======\r\n
        ====== current: ${currentTrainngs.length} ======\r\n
        ====== past: ${pastTrainngs.length} ======
      `);

      if (currentTrainngs.length > 0) {
        console.log("These are your current trainings...", currentTrainngs);

        store.dispatch({
          type: AdminActionTypes.GET_CURRENT_TRAININGS,
          payload: currentTrainngs,
        });
      }

      if (upcomingTrainngs.length > 0) {
        store.dispatch({
          type: AdminActionTypes.GET_UPCOMING_TRAININGS,
          payload: upcomingTrainngs,
        });
      }

      if (pastTrainngs.length > 0) {
        store.dispatch({
          type: AdminActionTypes.GET_PREVIOUS_TRAININGS,
          payload: pastTrainngs,
        });
      }
    }
  }
};

export const dispatchBulkCreate = (payload: any, store: Store<AnyAction>) => {
  if (payload?.bulkCreateResult) {
    store.dispatch({
      type: AdminActionTypes.SET_BULK_CREATE_RESPONSE,
      payload: payload.bulkCreateResult,
    });
  }
};

export const dispatchTeamNameId = (payload: any, store: Store<AnyAction>) => {
  const teamName: string = payload?.game?.team_name;
  if (teamName) {
    store.dispatch({
      type: GameActionTypes.SET_TEAM_NAME,
      payload: teamName,
    });
  }

  const newTeamId: string = payload?.game?.team_id;
  if (newTeamId) {
    store.dispatch({
      type: GameActionTypes.SET_TEAM_ID,
      payload: newTeamId,
    });
  }
};

export const dispatchSetTimer = (payload: any, store: Store<AnyAction>) => {
  if (payload.timeRemaining) {
    let currTime = store.getState().gameState.currentStageTimerEnd;
    if (currTime === payload.timeRemaining) {
      currTime = payload.timeRemaining + 1;
    } else {
      currTime = payload.timeRemaining;
    }
    store.dispatch({
      type: GameActionTypes.SET_TIMER,
      payload: { timeRemaining: currTime },
    });
  }
};

export const dispatchSetOpenTok = (payload: any, store: Store<AnyAction>) => {
  if (payload && payload.av) {
    store.dispatch({
      type: GameActionTypes.SAVE_OPENTOK_CONFIG,
      payload: {
        apiKey: payload.av.apiKey,
        sessionId: payload.av.session,
        token: payload.av.token,
      },
    });
  }
};

export const newStageMiddleware = (payload: any, store: Store<AnyAction>) => {
  console.log("into new stage MW!");
  console.log(payload);
  const stage = GameStagesAsIndeces[payload.stage];
  console.log(
    payload.stage,
    "AND SAME AS INDEX...",
    GameStagesAsIndeces[payload.stage]
  );

  if (payload.game && payload.user.id) {
    store.dispatch({
      type: SocketActionTypes.TRACK_USER_ATTENDANCE,
      payload: {
        gameId: payload.game.id,
        userId: payload.user.id,
        stage: stage || -1,
      },
    });
  }

  switch (payload.stage) {
    case SocketTypes.FIRST_QUARTER_DISCUSSION:
      showFirstQuarterDiscussion(store, payload);
      break;
    case SocketTypes.FIRST_QUARTER:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          const newGrid = getPrevioslyOpenedFields(
            payload?.game?.map_fields,
            store.getState()?.gameState
          );
          const financialReport = payload?.financials?.resQ1;
          const financialsAll = payload?.financialsAll;
          const finResult = payload?.financials;
          const roomTeams = payload?.game?.teams;
          store.dispatch({
            type: GameActionTypes.SUBSTAGE_FIRST_QUARTER,
            payload: {
              grid: newGrid,
              q1Res: financialReport,
              finResult,
              financialsAll,
              roomTeams,
            },
          });
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.SECOND_QUARTER:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          dispatchSecndQtr(payload, store);
        });

      commonEndStageActions(store);
      break;
    case SocketTypes.THIRD_FOURTH_QUARTER:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          dispatchSTQtr(payload, store);
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.IN_SERVICE_OPTION:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          dispatchInSvcNS(payload, store);
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.GAME_ENDED: {
      dispatchGameEnded(store);
      break;
    }

    case SocketTypes.FINAL_DISCUSSION:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          const questionsArr: QuestionModel[] = payload?.game?.debrief;
          store.dispatch({
            type: GameActionTypes.FINAL_DISCUSSION,
            payload: { questions: questionsArr, load: true },
          });
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.DEBRIEF:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          const questionsArrDebr: QuestionModel[] = payload?.game?.debrief;
          const teams: any[] = payload?.game?.teams;
          const financialsAll5 = payload?.financialsAll;
          store.dispatch({
            type: GameActionTypes.DEBRIEF,
            payload: {
              questions: questionsArrDebr,
              load: true,
              teams,
              financialsAll: financialsAll5,
            },
          });
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.RESULTS:
      opentokService.streamDestroyed$
        .pipe(first(), delay(300))
        .subscribe(() => {
          dispatchResults(payload, store);
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.FORCE_FIELD_ANALYSIS:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          const financialReportFinal1 = payload?.financials?.resQ2;
          const finResultFinal1 = payload?.financials;
          const allFinancials1 = payload?.financialsAll;
          store.dispatch({
            type: DialogActionTypes.HIDE_ALL,
          });
          store.dispatch({
            type: GameActionTypes.STAGE_FORCE_FIELD_ANALYSIS,
            payload: {
              finalRes: financialReportFinal1,
              finResult: finResultFinal1,
              allFinancials: allFinancials1,
            },
          });
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.MAP_MEMORIZATION:
      const username = payload.user
        ? payload.user.id
        : userService.getUser().id;
      const userHints1: any[] = _.filter(payload.game.hintsGiven, {
        userId: username,
      });
      let userResource = "";
      if (userHints1.length) {
        userResource = setTeamResource(userHints1[0]);
      }

      store.dispatch({
        type: GameActionTypes.SUBSTAGE_MAP_MEMORIZATION,
        payload: { hints: userHints1, resourceName: userResource },
      });
      break;
    case SocketTypes.MAP_ASSIGNMENT:
      const usersArr = payload?.game?.users;
      const assignedUsersBackend: any[] = payload?.game?.hintsGiven;
      store.dispatch({
        type: GameActionTypes.RESET_TEAMS,
        payload: true,
      });
      convertUsersFromBackend(assignedUsersBackend, store, usersArr);
      showMapAssignmentDialog(store, payload);
      break;
    case SocketStageTypes.PLAYERS_INTRODUCTION:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          dispatchPlrIntro(store);
        });
      commonEndStageActions(store);
      break;
    case SocketStageTypes.PRESENTATION:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          store.dispatch({
            type: GameActionTypes.PRESENTATION,
          });
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.REGIONAL_MANAGER_SELECTION:
      opentokService.streamDestroyed$
        .pipe(first(), delay(100))
        .subscribe(() => {
          dispatchMgrSelect(payload, store);
        });
      commonEndStageActions(store);
      break;
    case SocketTypes.REGIONAL_MANAGERS_MEETING:
    case SocketTypes.SECOND_QUARTER_RM_MEETING:
    case SocketTypes.REGIONAL_MANAGER_SWITCH:
    case SocketTypes.AFTER_FFA_QUARTER_RM_MEETING:
      const userRole =
        payload.user && payload.user.role
          ? payload.user.role
          : userService.getUser().role;
      if ([UserRoleModel.RM, UserRoleModel.Trainer].indexOf(userRole) > -1) {
        store.dispatch({
          type: DialogActionTypes.OPEN_MNGMT_DIALOG_MEMO,
          payload: {
            open: true,
            data: payload,
          },
        });
      } else {
        store.dispatch({
          type: DialogActionTypes.TEXT_DIALOG,
          payload: {
            text: "Wait until the HQ meeting is over …",
            open: true,
          },
        });
      }
      break;
    default:
      break;
  }
};

export const dispatchCreated = (payload: any, store: Store<AnyAction>) => {
  const startDate = payload?.start_date;
  const changeStageToCreated: GameActions = {
    type: GameActionTypes.SET_CREATED_STAGE,
    payload: { startDate },
  };
  store.dispatch(changeStageToCreated);
};

export const dispatchAssignedMap = (payload: any, store: Store<AnyAction>) => {
  const assignedUserBackend: AssignedUserBackend = payload;
  const convertedUser = convertAssignedUserResponse(
    assignedUserBackend,
    store,
    "assign"
  );
  if (convertedUser) {
    const setUsersAction: GameActions = {
      type: GameActionTypes.ASSIGN_USER_TO_TEAM,
      payload: { user: convertedUser },
    };

    store.dispatch(setUsersAction);
  }
};

export const dispatchDebriefUpd = (payload: any, store: Store<AnyAction>) => {
  const qPayload = payload;
  const userDataDebrief: UserModel = store.getState().userState.userData;
  const facilitatorIdDebrief: number = store.getState().gameState.facilitatorId;
  if (facilitatorIdDebrief && userDataDebrief?.id !== facilitatorIdDebrief) {
    const debriefUpdated: GameActions = {
      type: GameActionTypes.DEBRIEF_UPDATED,
      payload: qPayload,
    };
    store.dispatch(debriefUpdated);
  }
};

export const dispatchDQChanged = (payload: any, store: Store<AnyAction>) => {
  const qPayload1 = payload?.questionIndex;
  const debriefQUpdated: GameActions = {
    type: GameActionTypes.SELECT_QUESTION_ID,
    payload: qPayload1,
  };
  store.dispatch(debriefQUpdated);
};

export const dispatchGameEnded = (store: Store<AnyAction>) => {
  const isTrainer = userService.getUser()?.role === UserRoleModel.Trainer;
  if (isTrainer) {
    store.dispatch({
      type: AdminActionTypes.LOGIN_TO_ADMIN,
      payload: true,
    });
    store.dispatch({
      type: AdminActionTypes.SET_INITIAL_FLAG,
      payload: true,
    });

    store.dispatch({
      type: DialogActionTypes.HIDE_ALL,
    });
  } else {
    // store.dispatch({
    //   type: DialogActionTypes.TEXT_DIALOG,
    //   payload: {
    //     text: "The game has ended!",
    //     open: true,
    //   },
    // });
    window.location.reload();
  }
};

export const dispatchFinRes = (payload: any, store: Store<AnyAction>) => {
  const finAll = payload?.financials;
  if (finAll) {
    store.dispatch({
      type: GameActionTypes.SET_SHARED_FINANCIALS,
      payload: { financials: finAll },
    });
  }
};

export const dispatchInSvc = (payload: any, store: Store<AnyAction>) => {
  const financialResult = Number(payload?.financial);
  const currentFinancialRes = Number(payload?.financial?.resQ2);
  const setFinancialRes: GameActions = {
    type: GameActionTypes.SET_FINANCIAL_RESULT,
    payload: {
      finAllResult: financialResult,
      finResult: currentFinancialRes,
    },
  };
  store.dispatch(setFinancialRes);
  store.dispatch({
    type: DialogActionTypes.OPEN_IN_SERVICE_DIALOG,
    payload: true,
  });
  store.dispatch({
    type: GameActionTypes.USE_INSERVICE,
    payload: true,
  });
  const timer1 = 60;
  store.dispatch({
    type: GameActionTypes.SET_TIMER,
    payload: { timeRemaining: timer1 },
  });
};

export const dispatchFinalDisc = (payload: any, store: Store<AnyAction>) => {
  commonEndStageActions(store);
  const questionsArr: QuestionModel[] = payload?.game?.debrief;
  const teamId3: number = payload?.game?.team_id;
  store.dispatch({
    type: GameActionTypes.FINAL_DISCUSSION,
    payload: { questions: questionsArr, load: true, teamId: teamId3 },
  });
};

export const dispatchDebrief = (payload: any, store: Store<AnyAction>) => {
  const questionsArrDebr: QuestionModel[] = payload?.game?.debrief;
  const teamId2: number = payload?.game?.team_id;
  const teams: any[] = payload?.game?.teams;
  const financialsAll5 = payload?.financialsAll;
  store.dispatch({
    type: GameActionTypes.DEBRIEF,
    payload: {
      questions: questionsArrDebr,
      load: true,
      teamId: teamId2,
      teams,
      financialsAll: financialsAll5,
    },
  });
};

export const dispatchResults = (payload: any, store: Store<AnyAction>) => {
  const financialReportFinal = payload?.financials?.resFinal;
  const finResultFinal = payload?.financials;
  const allFinancials = payload?.financialsAll;
  store.dispatch({
    type: GameActionTypes.RESULTS,
    payload: {
      finalRes: financialReportFinal,
      finResult: finResultFinal,
      allFinancials,
    },
  });
};

export const dispatchFacilUpd = (payload: any, store: Store<AnyAction>) => {
  const assignedFacilitator: number = payload?.editor;
  if (assignedFacilitator) {
    const setFacilitatorAction: GameActions = {
      type: GameActionTypes.SET_FACILITATOR,
      payload: assignedFacilitator,
    };
    store.dispatch(setFacilitatorAction);
  }
};

export const dispatchInSvcOpt = (payload: any, store: Store<AnyAction>) => {
  const financialReport1 = payload?.financials;
  const timer = payload?.timeRemaining;
  const financialRepQ2 = payload?.financials?.resQ2;
  const newGrid1 = getPrevioslyOpenedFields(
    payload?.game?.map_fields,
    store.getState()?.gameState
  );
  const financialsAll4 = payload?.financialsAll;
  const username = payload.user ? payload.user.id : userService.getUser().id;
  const userHints1: any[] = _.filter(payload.game.hintsGiven, {
    userId: username,
  });
  let userResource = "";
  if (userHints1.length) {
    userResource = setTeamResource(userHints1[0]);
  }
  store.dispatch({
    type: GameActionTypes.SET_IN_SERVICE,
    payload: {
      financialResult: financialReport1,
      timer,
      grid: newGrid1,
      q2Res: financialRepQ2,
      financialsAll: financialsAll4,
      hints: userHints1,
      resourceName: userResource,
    },
  });
};

export const dispatchReadyToS = (payload: any, store: Store<AnyAction>) => {
  const readyToStartAction: GameActions = {
    type: GameActionTypes.READY_TO_START,
    payload: {
      apiKey: payload.apiKey,
      sessionId: payload.session,
      token: payload.token,
    },
  };
  const openTextDialogReadyAction: DialogActions = {
    type: DialogActionTypes.TEXT_DIALOG,
    payload: {
      text: "Joining the game ...",
      open: true,
    },
  };

  store.dispatch(openTextDialogReadyAction);
  store.dispatch(readyToStartAction);
};

export const dispatchSelField = (payload: any, store: Store<AnyAction>) => {
  const selectActionMessage: GameActions = {
    type: GameActionTypes.FIELD_SELECTED,
    payload,
  };
  store.dispatch(selectActionMessage);
};

export const dispatchChatRecd = (payload: any, store: Store<AnyAction>) => {
  const chatMessage: ChatActions = {
    type: ChatActionTypes.ADD_MESSAGE,
    payload,
  };
  store.dispatch(chatMessage);
  const hasNewMsg = userService.getUser().id !== payload.from.id;
  GameVisibilityService.hasNewMessage$.next(hasNewMsg);
};

export const dispatchForceFld = (payload: any, store: Store<AnyAction>) => {
  store.dispatch({
    type: DialogActionTypes.HIDE_ALL,
  });
  const financialReportFinal1 = payload?.financials?.resQ2;
  const finResultFinal1 = payload?.financials;
  const allFinancials1 = payload?.financialsAll;
  store.dispatch({
    type: DialogActionTypes.HIDE_ALL,
  });
  store.dispatch({
    type: GameActionTypes.STAGE_FORCE_FIELD_ANALYSIS,
    payload: {
      finalRes: financialReportFinal1,
      finResult: finResultFinal1,
      allFinancials: allFinancials1,
    },
  });
};

export const dispatchRmvUsrFR = (payload: any, store: Store<AnyAction>) => {
  const removedUserBackend: AssignedUserBackend = payload;
  const convUser = convertAssignedUserResponse(
    removedUserBackend,
    store,
    "remove"
  );
  if (convUser) {
    const removeUsersAction: GameActions = {
      type: GameActionTypes.REMOVE_USER_FROM_TEAM,
      payload: { user: convUser },
    };
    store.dispatch(removeUsersAction);
  }
};

export const dispatchFrceFUpd = (payload: any, store: Store<AnyAction>) => {
  const facilitatorId = store.getState().gameState.facilitatorId;
  const isUserTrainer =
    [UserRoleModel.Trainer, UserRoleModel.RM].indexOf(
      store.getState().userState.userData.role
    ) > -1;
  if (facilitatorId || isUserTrainer) {
    const forceFieldAction: GameActions = {
      type: GameActionTypes.FORCE_FIELD_UPDATED,
      payload,
    };
    store.dispatch(forceFieldAction);
  }
};

export const dispatchGameRsmd = (payload: any, store: Store<AnyAction>) => {
  store.dispatch({
    type: GameActionTypes.SET_TIMER,
    payload: { timeRemaining: payload?.remainingTime },
  });
  store.dispatch({
    type: DialogActionTypes.OPEN_GAME_PAUSED_DIALOG,
    payload: false,
  });
};

export const dispatchCreateG = (payload: any, store: Store<AnyAction>) => {
  store.dispatch({
    type: SocketActionTypes.GET_UPCOMING_TRAININGS,
    payload: true,
  });
};

export const dispatchStageTime = (payload: any, store: Store<AnyAction>) => {
  const time = payload?.remainingTime;
  store.dispatch({
    type: GameActionTypes.SET_TIMER,
    payload: { timeRemaining: time },
  });
};

export const dispatchGamePause = (store: Store<AnyAction>) => {
  store.dispatch({
    type: GameActionTypes.SET_TIMER,
    payload: { timeRemaining: 1 },
  });
  store.dispatch({
    type: DialogActionTypes.OPEN_GAME_PAUSED_DIALOG,
    payload: {
      open: true,
    },
  });
};

export const dispatchSaveRules = (payload: any, store: Store<AnyAction>) => {
  const saveRulesAction: GameActions = {
    type: GameActionTypes.SAVE_RULES,
    rules: payload.rules,
  };
  store.dispatch(saveRulesAction);
};

export const dispatchPlrIntro = (store: Store<AnyAction>) => {
  store.dispatch({
    type: GameActionTypes.PLAYERS_INTRODUCTION,
  });
};

export const dispatchPrsntation = (payload: any, store: Store<AnyAction>) => {
  const presentationAction: GameActions = {
    type: GameActionTypes.PRESENTATION,
  };
  store.dispatch(presentationAction);
};

export const dispatchMgrSelect = (payload: any, store: Store<AnyAction>) => {
  store.dispatch({
    type: GameActionTypes.REGIONAL_MANAGER_SELECTION,
  });
};

export const dispatchMgrMeet = (payload: any, store: Store<AnyAction>) => {
  if (
    [UserRoleModel.RM, UserRoleModel.Trainer].indexOf(payload.user.role) > -1
  ) {
    store.dispatch({
      type: DialogActionTypes.OPEN_MNGMT_DIALOG_MEMO,
      payload: {
        open: true,
        data: payload,
      },
    });
  } else {
    store.dispatch({
      type: DialogActionTypes.TEXT_DIALOG,
      payload: {
        text: "Wait until the HQ meeting is over ...",
        open: true,
      },
    });
  }
};

export const dispatchMapAssign = (payload: any, store: Store<AnyAction>) => {
  // opentokService.streamDestroyed$.pipe(first(), delay(300)).subscribe(() => {
  const usersArr = payload?.game?.users;
  const assignedUsersBackend: any[] = payload?.game?.hintsGiven;
  store.dispatch({
    type: GameActionTypes.RESET_TEAMS,
    payload: true,
  });
  convertUsersFromBackend(assignedUsersBackend, store, usersArr);
  showMapAssignmentDialog(store, payload);
  // });
  // commonEndStageActions(store);
};

export const dispatchMapMemoiz = (payload: any, store: Store<AnyAction>) => {
  // opentokService.streamDestroyed$.pipe(first(), delay(300)).subscribe(() => {
  const username2 = payload.user ? payload.user.id : userService.getUser().id;
  const userHints2: any[] = _.filter(payload.game.hintsGiven, {
    userId: username2,
  });
  let userResource2 = "";
  if (userHints2.length) {
    userResource2 = setTeamResource(userHints2[0]);
  }
  store.dispatch({
    type: GameActionTypes.SUBSTAGE_MAP_MEMORIZATION,
    payload: { hints: userHints2, resourceName: userResource2 },
  });
  // });
  // commonEndStageActions(store);
};

export const dispatchFirstQtr = (payload: any, store: Store<AnyAction>) => {
  const newGrid = getPrevioslyOpenedFields(
    payload?.game?.map_fields,
    store.getState()?.gameState
  );
  const financialReport = payload?.financials?.resQ1;
  const finResult1 = payload?.financials;
  const financialsAll = payload?.financialsAll;
  const roomTeams = payload?.game?.teams;
  store.dispatch({
    type: GameActionTypes.SUBSTAGE_FIRST_QUARTER,
    payload: {
      grid: newGrid,
      q1Res: financialReport,
      finResult: finResult1,
      financialsAll,
      roomTeams,
    },
  });
};

export const dispatchSecndQtr = (payload: any, store: Store<AnyAction>) => {
  const newGrid2 = getPrevioslyOpenedFields(
    payload?.game?.map_fields,
    store.getState()?.gameState
  );
  const financialReport2 = payload?.financials?.resQ2;
  const finResult2 = payload?.financials;
  const financialsAll2 = payload?.financialsAll;
  store.dispatch({
    type: GameActionTypes.SECOND_QUARTER,
    payload: {
      grid: newGrid2,
      q2Res: financialReport2,
      finResult: finResult2,
      financialsAll: financialsAll2,
    },
  });
};

export const dispatchSTQtr = (payload: any, store: Store<AnyAction>) => {
  const newGridq3 = getPrevioslyOpenedFields(
    payload?.game?.map_fields,
    store.getState()?.gameState
  );
  const financialReportq3 = payload?.financials?.resQ3;
  const finResult3 = payload?.financials;
  const financialsAll3 = payload?.financialsAll;
  store.dispatch({
    type: GameActionTypes.SUBSTAGE_THIRD_FOURTH_QUARTER,
    payload: {
      grid: newGridq3,
      q3Res: financialReportq3,
      finResult: finResult3,
      financialsAll: financialsAll3,
    },
  });
};

export const dispatchUserCling = (payload: any, store: Store<AnyAction>) => {
  const teamId = payload?.team?.id;
  store.dispatch({
    type: GameActionTypes.USER_CALLING,
    payload: teamId,
  });
};

export const dispatchDialogNS = (payload: any, store: Store<AnyAction>) => {
  const openTextDialogAction: DialogActions = {
    type: DialogActionTypes.TEXT_DIALOG,
    payload: payload.error,
  };
  store.dispatch(openTextDialogAction);
};

export const dispatchInSvcNS = (payload: any, store: Store<AnyAction>) => {
  const financialReport1 = payload?.financials;
  const timer = payload?.timeRemaining;
  const financialRepQ2 = payload?.financials?.resQ2;
  const newGrid1 = getPrevioslyOpenedFields(
    payload?.game?.map_fields,
    store.getState()?.gameState
  );
  const financialsAll4 = payload?.financialsAll;
  const un = payload.user ? payload.user.id : userService.getUser().id;
  const uHints1: any[] = _.filter(payload.game.hintsGiven, {
    userId: un,
  });
  let uRes = "";
  if (uHints1.length) {
    uRes = setTeamResource(uHints1[0]);
  }
  store.dispatch({
    type: GameActionTypes.SET_IN_SERVICE,
    payload: {
      financialResult: financialReport1,
      timer,
      grid: newGrid1,
      q2Res: financialRepQ2,
      financialsAll: financialsAll4,
      hints: uHints1,
      resourceName: uRes,
    },
  });
};
