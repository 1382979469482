import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SocketActions,
  SocketActionTypes,
} from "../../../socket/SocketActions";
import { AppState } from "../../../store/Store";
import useStyles from "./styles/FinancialReportStyles";
import "./styles/FinancialStyles.scss";

const FinancialReport = (props?: any) => {
  const classes = useStyles();
  const selectFinancialReport = (state: AppState) =>
    state.gameState.financialReport;
  const financialReportSelector = useSelector(selectFinancialReport);
  const selectTeamName = (state: AppState) => state.gameState.teamName;
  const teamName = useSelector(selectTeamName);
  const selectAllTeamsReportObj = (state: AppState) =>
    state.gameState.financialsAll;
  const allTeamsFinancials = useSelector(selectAllTeamsReportObj);
  const selectOpenFullReport = (state: AppState) =>
    state.gameState.openedFullReport;
  const openFullReportSelector = useSelector(selectOpenFullReport);
  const dispatch = useDispatch();

  const getCurrentFinancialReport = () => {
    if (!props?.adminReport) {
      return financialReportSelector !== undefined
        ? financialReportSelector > 0
          ? `${financialReportSelector}`
          : financialReportSelector
        : "(None Yet)";
    } else {
      return props.currentFinancialResult;
    }
  };

  const openFullReport = () => {
    if (financialReportSelector !== undefined) {
      const open = openFullReportSelector ? false : true;
      const openFullReportAction: SocketActions = {
        type: SocketActionTypes.OPEN_FULL_REPORT,
        payload: open,
      };
      if (!props?.finalReport && !props?.adminReport) {
        dispatch(openFullReportAction);
      }
    } else {
      const openFullReportAction: SocketActions = {
        type: SocketActionTypes.OPEN_FULL_REPORT,
        payload: false,
      };
      dispatch(openFullReportAction);
    }
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#87c9e5",
      fontWeight: "bold",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
      borderBottom: "unset !important",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "rgb(237, 240, 242)",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#dee1e3",
      },
    },
  }))(TableRow);

  const createData = (
    teamName: string,
    q1Val?: number,
    q2Val?: number,
    q3Val?: number,
    totalVal?: number,
    place?: number
  ) => {
    const q1 = !!q1Val ? q1Val : 0;
    const q2 = !!q2Val ? q2Val : 0;
    const q3 = !!q3Val ? q3Val : 0;
    const total = !!totalVal ? totalVal : 0;
    const placeVal = place ? place : 0;

    return {
      teamName,
      q1Val: q1,
      q2Val: q2,
      q3Val: q3,
      total,
      place: placeVal,
    };
  };

  const rows: any[] = [];
  if (allTeamsFinancials?.length) {
    const sortedArr = _.orderBy(
      allTeamsFinancials,
      ["resFinal", "teamName"],
      ["desc", "asc"]
    );
    let place = 1;

    sortedArr.forEach((team) => {
      rows.push(
        createData(
          team.teamName,
          team?.resQ1,
          team?.resQ2,
          team?.resQ3,
          team?.resFinal,
          place
        )
      );
      place++;
    });
  }

  const checkIfCellPositive = (value: number | undefined, team: string) => {
    if (value && value > 0) {
      return (
        <span
          className={`${classes.positive} ${
            team === teamName ? classes.currentTeam : ""
          }`}
        >
          {value}
        </span>
      );
    } else if (value && value < 0) {
      return (
        <span
          className={`${classes.negative} ${
            team === teamName ? classes.currentTeam : ""
          }`}
        >
          {value}
        </span>
      );
    } else {
      return (
        <span className={`${team === teamName ? classes.currentTeam : ""}`}>
          {value}
        </span>
      );
    }
  };

  const returnBadges = (place: number) => {
    if (place === 1) {
      return (
        <img
          src="/icons/icons-badge-1.svg"
          className={classes.team1Badge}
          alt="Winner"
        />
      );
    } else if (place === 2) {
      return (
        <img
          src="/icons/icons-badge-2.svg"
          className={classes.team2Badge}
          alt="Second Place"
        />
      );
    } else if (place === 3) {
      return (
        <img
          src="/icons/icons-badge-3.svg"
          className={classes.team3Badge}
          alt="Third Place"
        />
      );
    }
    return <span></span>;
  };

  const checkIfCurrentTeam = (team: string) => {
    return (
      <span className={team === teamName ? classes.currentTeam : ""}>
        {team}
      </span>
    );
  };

  const checkIfArrowIsShown = () => {
    if (financialReportSelector !== undefined) {
      return <span className={classes.iconFinancialExpand}></span>;
    }
    return <></>;
  };

  let openContainer = openFullReportSelector
    ? "container-financial-st open"
    : "container-financial-st";
  let tableClasses = `table-container `;
  let headerClasses = openFullReportSelector
    ? `${classes.header} ${classes.headerOpen} header-open`
    : `${classes.header}`;
  let totalClasses = ``;
  let classesFinancialRow = `${classes.last}`;
  if (props.finalReport) {
    openContainer += ` ${classes.finalReport}`;
    tableClasses += ` table-final`;
    totalClasses += ` ${classes.totalClasses}`;
    classesFinancialRow += ` bottom-row-final`;
  } else {
    classesFinancialRow += ` bottom-row-financial`;
  }
  if (financialReportSelector === undefined) {
    openContainer += " container-disabled";
    headerClasses += ` ${classes.noOpen}`;
  }
  if (props.inServicePage) {
    openContainer += ` in-service-cont`;
  }
  if (props.finalDiscussionPage) {
    openContainer += ` final-team-container`;
  }
  if (openFullReportSelector) {
    tableClasses += ` table-container-open`;
  }

  const checkIfFinalPage = () => {
    if (!props?.finalReport) {
      return (
        <div className={`fin-header-div`} onClick={openFullReport}>
          <h5 className={headerClasses}>
            Financial Report:
            <span className={classes.financialResult}>
              {getCurrentFinancialReport()}
            </span>
            {checkIfArrowIsShown()}
          </h5>
        </div>
      );
    }
    return <></>;
  };

  const checkIfFinalRowIsShown = () => {
    if (openFullReportSelector) {
      return (
        <StyledTableRow
          key="last"
          id="final-text"
          className={`${classesFinancialRow}`}
        >
          <TableCell className={classes.tableCell} align="right" colSpan={6}>
            <h3 className={`${classes.bottomText} final-text-row`}>
              All figures are accumulative!
            </h3>
          </TableCell>
        </StyledTableRow>
      );
    }
    return <></>;
  };

  const iconExpandTop = `${classes.iconFinancialExpand} ${classes.iconExpandTop}`;

  return (
    <div className={`${openContainer} fin-report`}>
      {checkIfFinalPage()}
      <div className={tableClasses}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              {props.finalReport ?? (
                <TableRow className={classes.tableRow1}>
                  <StyledTableCell>
                    <span
                      onClick={openFullReport}
                      className={classes.topRowDiv}
                    >
                      <span className={iconExpandTop}></span> Collapse
                    </span>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  {props.finalReport ? (
                    <StyledTableCell
                      component="th"
                      scope="row"
                    ></StyledTableCell>
                  ) : (
                    <></>
                  )}
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              )}
              <TableRow className={classes.tableRow}>
                <StyledTableCell></StyledTableCell>
                {props.finalReport ? (
                  <StyledTableCell align="right"></StyledTableCell>
                ) : (
                  <></>
                )}
                <StyledTableCell
                  className={props.finalReport ? "final-th" : ""}
                  align="right"
                >
                  Q1
                </StyledTableCell>
                <StyledTableCell
                  className={props.finalReport ? "final-th" : ""}
                  align="right"
                >
                  Q2
                </StyledTableCell>
                <StyledTableCell
                  className={props.finalReport ? "final-th" : ""}
                  align="right"
                >
                  Q3/Q4
                </StyledTableCell>
                <StyledTableCell
                  className={props.finalReport ? "final-th final-total" : ""}
                  align="right"
                >
                  Total
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.teamName}>
                  <StyledTableCell component="th" scope="row">
                    {checkIfCurrentTeam(row.teamName)}
                  </StyledTableCell>
                  {props.finalReport ? (
                    <StyledTableCell component="th" scope="row">
                      {returnBadges(row.place)}
                    </StyledTableCell>
                  ) : (
                    <></>
                  )}
                  <StyledTableCell align="right">
                    {checkIfCellPositive(row.q1Val, row.teamName)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {checkIfCellPositive(row.q2Val, row.teamName)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {checkIfCellPositive(row.q3Val, row.teamName)}
                  </StyledTableCell>
                  <StyledTableCell align="right" className={totalClasses}>
                    {checkIfCellPositive(row.total, row.teamName)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {checkIfFinalRowIsShown()}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default FinancialReport;
