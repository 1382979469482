import React, { useState } from 'react';
import './styles/AdminAccordionStyles.scss';

const Accordion = (props: any) => {
    const [state, setstate] = useState(false)

    return (
        <div className={`accordion-container`}>
            <div className={`accordion-header ${state ? 'open' : ''}`} onClick={() => setstate(!state)}>
                <span className={'acc-title'}>{props.title}</span>
                <span className={'acc-subtitle'}>({props.subtitle})</span>
            </div>
            <div className={`accordion-content ${state ? 'open' : ''}`}>
                {props.children}
            </div>
        </div>
    );
}

export default Accordion;