import { makeStyles } from "@material-ui/core";

const 
useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      background: 'transparent'
    },
    paperNumTopServ: {
        color: 'black !important'
    },
    paper: {
        height: '48px',
        width: '48px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: 'transparent'
    },
    paperNumTop: {
        height: '30px',
        width: '46px',
        fontWeight: 'bold',
        paddingBottom: '5px',
        display: "flex",
        color: '#fff',
        flexDirection: "column",
        justifyContent: "center",
        background: 'transparent'
    },
    paperNumLeft: {
        height: '46px',
        width: '30px',
        color: '#fff',
        paddingLeft: '18px',
        paddingRight: '5px',
        fontWeight: 'bold',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: 'transparent'
    },
    paperInsideContainer: {
        borderRadius: '3px',
        backgroundImage: 'linear-gradient(to bottom, #ffffff, rgb(213, 213, 213))'
    },
    paperMatch: {
        boxSizing: 'border-box',
        height: '40px',
        width: '40px',
        transition: 'all .2s',
        border: 'solid 1px rgb(56, 126, 152)',
        backgroundColor: '#fff',
        background: 'no-repeat center center url("icons/flower.svg");'
    },
    paperNoMatch: {
        boxSizing: 'border-box',
        height: '40px',
        width: '40px',
        border: 'solid 1px rgb(56, 126, 152)',
        transition: 'all .2s',
        background: '#fff'
    },
    paperInside: {
        boxSizing: 'border-box',
        height: '40px',
        width: '40px',
        borderRadius: '2px',
        border: 'solid 1px rgb(56, 126, 152)',
        backgroundColor: '#62b2cf',
        background: '#62b2cf',
        transition: 'all .2s',
        '&:hover': {
            cursor: 'pointer',
            background: 'linear-gradient(135deg, #62b2cf 7%, #87c9e5);',
            zIndex: '99',
            position: 'relative'
        }
    },
    control: {
        padding: theme.spacing(2),
    },
    matrixContainer: {
        marginLeft: '-22.5px',
        alignItems: 'flex-start'
        
    },
    bold: {
        fontWeight: 'bold'
    }
}));

export default useStyles;