import React, { useEffect, useState } from 'react';
import '../../App.css';
import { useDispatch, useSelector } from "react-redux";
import { AppState } from '../../store/Store';
import GameContainer from '../../game/components/GameContainer';
import VideoComponent from '../../video/components/VideoComponent';
import useStyles from './styles/inServiceStyles';
import { withRouter, RouteComponentProps } from 'react-router';
import { SocketActionTypes } from '../../socket/SocketActions';
import './styles/inServiceStyles.scss';

const InServicePage = (props: RouteComponentProps) => {
    const classes = useStyles();
    const selectGameNumbers = (state: AppState) => state.gameState.selectedFields;
    const [hideButtonsState, setHideButtonsState] = useState(false);
    const gameNumbers = useSelector(selectGameNumbers);
    const selectInService = (state: AppState) => state.gameState.inServiceUsed;
    const inServiceUsed = useSelector(selectInService);
    const dispatch = useDispatch();

    const selectInServiceOption = () => {
        dispatch({
            type: SocketActionTypes.SET_IN_SERVICE
        });
        setHideButtonsState(true);
    }

    const hideButtons = () => {
        setHideButtonsState(true);
    }
    const button1 = `${classes.buttons} ${classes.button1}`;
    const button2 = `${classes.buttons}`;

    useEffect(() => {
        if (inServiceUsed) {
            hideButtons();
        }
    }, [inServiceUsed]);

    const showOrHideButtons = () => {
        if (!hideButtonsState) {
            return (<div className='main-div-1'>
                <button className={button1} type="button" onClick={() => selectInServiceOption()}>In-Service Option ($2,000)</button>
                <button className={button2} type="button" onClick={() => hideButtons()}>Don’t use in-service option</button>
            </div>)
        }
        return (<div className={classes.mainDiv}>Discuss Your Strategy</div>)
    }

    const showMainTitle = () => {
        return (<div>
            {showOrHideButtons()}
        </div>);
    }

    return (
        <div className="App App-container in-service-page">
            <VideoComponent></VideoComponent>
            {showMainTitle()}
            <GameContainer numbers={gameNumbers} inServicePage={true} ></GameContainer>
        </div>
    );
}

export default withRouter(InServicePage);
