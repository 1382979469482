import { VideoConfig } from "./GameReducer";

export enum GameActionTypes {
  BATCH_UPDATE_STORE = "BATCH_UPDATE_STORE",
  USER_AUTHENTICATED_SUCCESSFULLY = "USER_AUTHENTICATED_SUCCESSFULLY",
  SOCKET_CONNECTED = "SOCKET_CONNECTED",
  PLAYERS_INTRODUCTION = "PLAYERS_INTRODUCTION",
  MAP_ASSIGNMENT = "MAP_ASSIGNMENT",
  SUBSTAGE_MAP_ASSIGNMENT = "SUBSTAGE_MAP_ASSIGNMENT",
  SUBSTAGE_MAP_MEMORIZATION = "SUBSTAGE_MAP_MEMORIZATION",
  SUBSTAGE_FIRST_QUARTER = "SUBSTAGE_FIRST_QUARTER",
  SUBSTAGE_THIRD_FOURTH_QUARTER = "SUBSTAGE_THIRD_FOURTH_QUARTER",
  SUBSTAGE_FIRST_QUARTER_DISCUSSION = "SUBSTAGE_FIRST_QUARTER_DISCUSSION",
  STAGE_FORCE_FIELD_ANALYSIS = "SUBSTAGE_FORCE_FIELD_ANALYSIS",
  PRESENTATION = "PRESENTATION",
  SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED",
  SELECT_TILE = "SELECT_TILE",
  TEST_ACTION = "TEST_ACTION",
  FIELD_SELECTED = "FIELD_SELECTED",
  RESET_GAME_MATRIX = "RESET_GAME_MATRIX",
  ADD_FIELD_TO_SELECTED = "ADD_FIELD_TO_SELECTED",
  READY_TO_START = "READY_TO_START",
  FIRST_QUARTER = "FIRST_QUARTER",
  SECOND_QUARTER = "SECOND_QUARTER",
  SAVE_OPENTOK_CONFIG = "SAVE_OPENTOK_CONFIG",
  DESTROY_OT_SESSION = "DESTROY_OT_SESSION",
  GRID_CREATED = "GRID_CREATED",
  OPEN_FULL_REPORT = "OPEN_FULL_REPORT",
  OPEN_FULL_TEAM_RULES = "OPEN_FULL_TEAM_RULES",
  SAVE_RULES = "SAVE_RULES",
  REGIONAL_MANAGER_SELECTION = "REGIONAL_MANAGER_SELECTION",
  SET_TIMER = "SET_TIMER",
  SET_RULES = "SET_RULES",
  SET_GAME_ID = "SET_GAME_ID",
  SET_TEAM_NAME = "SET_TEAM_NAME",
  SET_TEAM_ID = "SET_TEAM_ID",
  SET_GAME = "SET_GAME",
  SET_USERS = "SET_USERS",
  SET_GAME_USERS = "SET_GAME_USERS",
  SET_FACILITATOR = "SET_FACILITATOR",
  SET_RESULTS = "SET_RESULTS",
  SET_TEAMS = "SET_TEAMS",
  SET_GAME_DETAILS = "SET_GAME_DETAILS",
  CHANGE_STAGE = "CHANGE_STAGE",
  CHANGE_USERS = "CHANGE_USERS",
  USE_INSERVICE = "USE_INSERVICE",
  SELECT_QUESTION_ID = "SELECT_QUESTION_ID",
  ASSIGN_USER_TO_RESOURCE = "ASSIGN_USER_TO_RESOURCE",
  ASSIGN_USER_TO_TEAM = "ASSIGN_USER_TO_TEAM",
  REMOVE_USER_FROM_TEAM = "REMOVE_USER_TO_TEAM",
  FORCE_FIELD_UPDATED = "FORCE_FIELD_UPDATED",
  SET_IN_SERVICE = "SET_IN_SERVICE",
  DEBRIEF_UPDATED = "DEBRIEF_UPDATED",
  FFA_TEXT_UPDATED = "FFA_TEXT_UPDATED",
  SET_IN_SERVICE_OPTION = "SET_IN_SERVICE_OPTION",
  FINAL_DISCUSSION = "FINAL_DISCUSSION",
  SET_FINANCIAL_RESULT = "SET_FINANCIAL_RESULT",
  SET_SHARED_FINANCIALS = "SET_SHARED_FINANCIALS",
  DEBRIEF = "DEBRIEF",
  RESULTS = "RESULTS",
  SET_CREATED_STAGE = "SET_CREATED_STAGE",
  OPEN_RIGHT_CHEVRON = "OPEN_RIGHT_CHEVRON",
  SET_ROOM_TEAMS = "SET_ROOM_TEAMS",
  USER_CALLING = "USER_CALLING_GAME",
  REMOVE_USER_CALLING = "REMOVE_USER_CALLING",
  DISABLE_NEXT_STAGE_BTN = "DISABLE_NEXT_STAGE_BTN",
  DISABLE_ROOMS_BTNS = "DISABLE_NEXT_STAGE_BTNS",
  RESET_TEAMS = "RESET_TEAMS",
  SET_GAME_EDITED_RESPONSE = "SET_GAME_EDITED_RESPONSE",
}

export enum GameStagesAsIndeces {
  CREATED = 0,
  PLAYERS_INTRODUCTION = 1,
  PRESENTATION = 2,
  REGIONAL_MANAGER_SELECTION = 3,
  REGIONAL_MANAGERS_MEETING = 4,
  MAP_ASSIGNMENT = 5,
  MAP_MEMORIZATION = 6,
  FIRST_QUARTER = 7,
  REGIONAL_MANAGER_SWITCH = 8,
  FIRST_QUARTER_DISCUSSION = 9,
  SECOND_QUARTER = 10,
  SECOND_QUARTER_RM_MEETING = 11,
  FORCE_FIELD_ANALYSIS = 12,
  AFTER_FFA_RM_MEETING = 13,
  IN_SERVICE_OPTION = 14,
  THIRD_FOURTH_QUARTER = 15,
  RESULTS = 16,
  FINAL_TEAM_DISCUSSION = 17,
  DEBRIEF = 18,
  GAME_ENDED = 19,
}

export interface UserAuthenticatedSuccessfully {
  type: GameActionTypes.USER_AUTHENTICATED_SUCCESSFULLY;
}

export interface SetInServiceAction {
  type: GameActionTypes.SET_IN_SERVICE;
  payload?: any;
}

export interface GameEditedAction {
  type: GameActionTypes.SET_GAME_EDITED_RESPONSE;
  payload: any;
}

export interface ResetTeamsAction {
  type: GameActionTypes.RESET_TEAMS;
  payload?: any;
}

export interface SetGameAction {
  type: GameActionTypes.SET_GAME;
  payload?: any;
}

export interface UserCallingAction {
  type: GameActionTypes.USER_CALLING;
  payload?: any;
}

export interface UserCallingRemoveAction {
  type: GameActionTypes.REMOVE_USER_CALLING;
  payload?: any;
}

export interface OpenRightChevronAction {
  type: GameActionTypes.OPEN_RIGHT_CHEVRON;
  payload?: boolean;
}

export interface SetResultsAction {
  type: GameActionTypes.SET_RESULTS;
  payload?: any;
}

export interface SetRoomTeams {
  type: GameActionTypes.SET_ROOM_TEAMS;
  payload?: any;
}

export interface SetCreatedStageAction {
  type: GameActionTypes.SET_CREATED_STAGE;
  payload?: any;
}

export interface SetSharedFinancialsAction {
  type: GameActionTypes.SET_SHARED_FINANCIALS;
  payload?: any;
}

export interface DebriefUpdatedAction {
  type: GameActionTypes.DEBRIEF_UPDATED;
  payload?: any;
}

export interface AddTeamsAction {
  type: GameActionTypes.SET_TEAMS;
  payload?: any;
}

export interface SetGameDetailsAction {
  type: GameActionTypes.SET_GAME_DETAILS;
  payload?: any;
}

export interface UseInServiceAction {
  type: GameActionTypes.USE_INSERVICE;
  payload?: any;
}

export interface SetFinancialResult {
  type: GameActionTypes.SET_FINANCIAL_RESULT;
  payload: any;
}

export interface ResultsAction {
  type: GameActionTypes.RESULTS;
  payload?: any;
}

export interface DebriefAction {
  type: GameActionTypes.DEBRIEF;
  payload?: any;
}

export interface SetRulesAction {
  type: GameActionTypes.SET_RULES;
  payload?: any;
}

export interface FinalDiscussionAction {
  type: GameActionTypes.FINAL_DISCUSSION;
  payload?: any;
}

export interface DisableNextStageBtnAction {
  type: GameActionTypes.DISABLE_NEXT_STAGE_BTN;
  payload?: boolean;
}

export interface DisableRoomsBtnsAction {
  type: GameActionTypes.DISABLE_ROOMS_BTNS;
  payload?: boolean;
}

export interface SetThirdFourthQuarterAction {
  type: GameActionTypes.SUBSTAGE_THIRD_FOURTH_QUARTER;
  payload?: any;
}

export interface SetInServiceOptionAction {
  type: GameActionTypes.SET_IN_SERVICE_OPTION;
  payload?: any;
}

export interface RegionalManagerSelection {
  type: GameActionTypes.REGIONAL_MANAGER_SELECTION;
  payload?: any;
}

export interface FFATextUpdated {
  type: GameActionTypes.FFA_TEXT_UPDATED;
  payload?: any;
}
export interface ForceFieldUpdatedAction {
  type: GameActionTypes.FORCE_FIELD_UPDATED;
  payload?: any;
}

export interface SubPageMapAssignmentAction {
  type: GameActionTypes.SUBSTAGE_MAP_ASSIGNMENT;
  payload?: any;
}

export interface SubPageMapMemorizationAction {
  type: GameActionTypes.SUBSTAGE_MAP_MEMORIZATION;
  payload?: any;
}

export interface SetFacilitatorAction {
  type: GameActionTypes.SET_FACILITATOR;
  payload?: any;
}

export interface SubPageFirstQuarterAction {
  type: GameActionTypes.SUBSTAGE_FIRST_QUARTER;
  payload?: any;
}

export interface BatchUpdateStoreAction {
  type: GameActionTypes.BATCH_UPDATE_STORE;
  payload?: any;
}

export interface SubPageForceFieldAnalysis {
  type: GameActionTypes.STAGE_FORCE_FIELD_ANALYSIS;
  payload?: any;
}

export interface SelectQuestionId {
  type: GameActionTypes.SELECT_QUESTION_ID;
  payload?: any;
}

export interface SubPageFirstQuarterDiscussionAction {
  type: GameActionTypes.SUBSTAGE_FIRST_QUARTER_DISCUSSION;
  payload?: any;
}

export interface RemoveUserFromTeam {
  type: GameActionTypes.REMOVE_USER_FROM_TEAM;
  payload?: any;
}

export interface PageMapAssignmentAction {
  type: GameActionTypes.MAP_ASSIGNMENT;
  payload?: any;
}

export interface AssignUserToTeam {
  type: GameActionTypes.ASSIGN_USER_TO_TEAM;
  payload: any;
}

export interface SetTimer {
  type: GameActionTypes.SET_TIMER;
  payload?: any;
}

export interface SetGameId {
  type: GameActionTypes.SET_GAME_ID;
  payload?: any;
}

export interface ChangeUsers {
  type: GameActionTypes.CHANGE_USERS;
  payload?: any;
}

export interface ChangeState {
  type: GameActionTypes.CHANGE_STAGE;
  payload?: any;
}

export interface AssignUserToResource {
  type: GameActionTypes.ASSIGN_USER_TO_RESOURCE;
  payload?: any;
}

export interface SetUsersAction {
  type: GameActionTypes.SET_USERS;
  payload?: any;
}

export interface SetGameUsersAction {
  type: GameActionTypes.SET_GAME_USERS;
  payload?: any;
}

export interface SetTeamNameAction {
  type: GameActionTypes.SET_TEAM_NAME;
  payload?: string;
}

export interface SetTeamIdAction {
  type: GameActionTypes.SET_TEAM_ID;
  payload?: any;
}

export interface SocketConnected {
  type: GameActionTypes.SOCKET_CONNECTED;
  payload?: any;
}

export interface SocketPlayerIntroductionAction {
  type: GameActionTypes.PLAYERS_INTRODUCTION;
  payload?: any;
}

export interface SocketPresentationAction {
  type: GameActionTypes.PRESENTATION;
  payload?: any;
}

export interface DestroyOtSession {
  type: GameActionTypes.DESTROY_OT_SESSION;
  payload?: any;
}

export interface SaveRules {
  type: GameActionTypes.SAVE_RULES;
  rules: any;
  payload?: any;
}

export interface AddFieldToSelected {
  type: GameActionTypes.ADD_FIELD_TO_SELECTED;
  payload: any;
}

export interface SocketDisconnected {
  type: GameActionTypes.SOCKET_DISCONNECTED;
  payload?: any;
}

export interface GridCreated {
  type: GameActionTypes.GRID_CREATED;
  payload: any;
}

export interface FieldSelected {
  type: GameActionTypes.FIELD_SELECTED;
  payload: any;
}

export interface ResetGameMatrixAction {
  type: GameActionTypes.RESET_GAME_MATRIX;
}

export interface ReadyToStartAction {
  type: GameActionTypes.READY_TO_START;
  payload: VideoConfig;
}

export interface FirstQuarterAction {
  type: GameActionTypes.FIRST_QUARTER;
  payload: VideoConfig;
}

export interface SecondQuarterAction {
  type: GameActionTypes.SECOND_QUARTER;
  payload: any;
}

export interface SaveOpentokConfigAction {
  type: GameActionTypes.SAVE_OPENTOK_CONFIG;
  payload: VideoConfig;
}

export interface OpenFullReport {
  type: GameActionTypes.OPEN_FULL_REPORT;
  payload: any;
}
export interface OpenFullTeamRules {
  type: GameActionTypes.OPEN_FULL_TEAM_RULES;
  payload: any;
}

export interface SelectTile {
  type: GameActionTypes.SELECT_TILE;
  coords: number[];
  payload?: any;
}

export interface TestAction {
  type: GameActionTypes.TEST_ACTION;
  payload: any;
}

export type GameActions =
  | GameEditedAction
  | UserAuthenticatedSuccessfully
  | SocketConnected
  | SocketDisconnected
  | SelectTile
  | TestAction
  | FieldSelected
  | GridCreated
  | OpenFullReport
  | OpenFullTeamRules
  | ReadyToStartAction
  | SaveRules
  | AddFieldToSelected
  | DestroyOtSession
  | SetTimer
  | FFATextUpdated
  | AddTeamsAction
  | SocketPlayerIntroductionAction
  | RegionalManagerSelection
  | SocketPresentationAction
  | SetRoomTeams
  | SaveOpentokConfigAction
  | SetUsersAction
  | UserCallingAction
  | SetTeamNameAction
  | ChangeState
  | SetFinancialResult
  | SetInServiceOptionAction
  | SubPageMapAssignmentAction
  | SubPageFirstQuarterAction
  | SubPageMapMemorizationAction
  | SubPageForceFieldAnalysis
  | PageMapAssignmentAction
  | AssignUserToResource
  | AssignUserToTeam
  | SetRulesAction
  | UseInServiceAction
  | SetCreatedStageAction
  | SetGameAction
  | SetSharedFinancialsAction
  | ChangeUsers
  | OpenRightChevronAction
  | SetGameUsersAction
  | ResetTeamsAction
  | SetResultsAction
  | ResultsAction
  | DebriefUpdatedAction
  | FinalDiscussionAction
  | DebriefAction
  | DisableNextStageBtnAction
  | DisableRoomsBtnsAction
  | ForceFieldUpdatedAction
  | SetFacilitatorAction
  | SetInServiceAction
  | FirstQuarterAction
  | SecondQuarterAction
  | RemoveUserFromTeam
  | ResetGameMatrixAction
  | SetThirdFourthQuarterAction
  | SelectQuestionId
  | SubPageFirstQuarterDiscussionAction
  | SetGameId
  | SetTeamIdAction
  | SetGameDetailsAction
  | BatchUpdateStoreAction
  | UserCallingRemoveAction;
