import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        position: 'relative',
        width: '100%'
    },
    container: {
        paddingTop: '5px',
        background: 'transparent'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
    },
    fixedMainHeight: {
       
    },
    fixedHeight: {
        minHeight: 240,
    },
    fixedHeight1: {
        top: '20px'
    },
    fixedHeightLeft: {
        marginTop: '50px',
        minHeight: 776,
        '& >*:not(.countdown-container)': {
            marginLeft: '28px',
            maxWidth: '265px'
        },
    },
    buttonSmallMap: {
        marginLeft: '11px'
    },
    gameTitle: {
        textAlign: 'left',
        color: 'white',
        '& h1': {
            margin: 0,
            fontSize: '38px',
            [theme.breakpoints.down('lg')]: {
                fontSize: '28px',
            },
        },
        '& h5': {
            color: 'white',
            margin: 0,
            fontWeight: 'normal'
        }
    }
}));

export default useStyles;