import { makeStyles } from "@material-ui/core";

const 
useStyles = makeStyles((theme) => ({
    container: {
        width: '0px',
        height: '100%',
        position: 'fixed',
        top: '0',
        overflow: 'hidden',
        zIndex: 999999,
        background: '#013d6d',
        color: '#fff',
        right: '0px',
        transition: 'all 0.5s',
        fontSize: '15px',
        '&.participants-visible': {
            right: 0,
            width: '280px'
        },
        '& ul': {
            width: '280px',
            listStyle: 'none',
            margin: 0,
            padding: '15px',
            '& li': {
                position: 'relative',
                paddingBottom: '10px',
                '& button': {
                    position: 'absolute',
                    right: '30px',
                    top: '3px',
                    cursor: 'pointer',
                    width: '16px',
                    border: 0,
                    outline: 0,
                    height: '16px',
                    display: 'inline-block',
                    background: 'url(/icons/mic-enabled.svg) no-repeat center center',
                    backgroundSize: '16px 16px',
                    '&:hover': {
                        background: 'url(/icons/mic-enabled-hover.svg) no-repeat center center',
                        backgroundSize: '16px 16px'
                    },
                    '&.mutted': {
                        background: 'url(/icons/mic-disabled-hover.svg) no-repeat center center',
                        backgroundSize: '16px 16px'
                    }
                }
            }
        },
        '& span': {
            color: '#caccce'
        },
        '& > div': {
            width: '280px',
            textAlign: 'center',
            margin: '7px'
        }
    }
}));

export default useStyles;