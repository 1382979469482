export interface HintModel {
    id: number,
    type: HintFieldType,
    userId: number,
    left: boolean,
    hint_fields: HintFieldModel[]
}

export interface HintFieldModel {
    a1notation: string,
    suitable: HintFieldType
}

export enum HintFieldType {
    FILLED = 0,
    EMPTY,
    MATCH
}

export enum HintType {
    HERBS = 0,
    HUMIDITY,
    SUN,
    NO_CROPS
}

