export enum SocketActionTypes {
  READY_TO_START = "READY_TO_START_SOCKET",
  CURRENT_STAGE = "CURRENT_STAGE_SOCKET",
  GET_GAME_DETAILS = "GET_GAME_DETAILS",
  TRY_CONNECT_SOCKET = "TRY_CONNECT_SOCKET",
  REFRESH_CONNECTION = "REFRESH_CONNECTION",
  END_STAGE = "END_STAGE",
  NEXT_STAGE = "NEXT_STAGE",
  ASSIGN_USER_RM = "ASSIGN_USER_RM",
  ASSIGN_PLAYER = "ASSIGN_PLAYER",
  CALL_TRAINER = "CALL_TRAINER",
  SWITCH_TEAM = "SWITCH_TEAM",
  GET_GAME_ID = "GET_GAME_ID",
  SWITCH_TRAINER = "SWITCH_TRAINER",
  RESET_STAGE = "RESET_STAGE",
  SOCKET_CONNECTED = "SOCKET_CONNECTED_SOCKET",
  SOCKET_FAILED = "SOCKET_FAILED",
  SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED",
  NEW_MESSAGE = "NEW_MESSAGE",
  INIT_VIDEO = "INIT_VIDEO",
  SELECT_FIELD = "SELECT_FIELD",
  FIRST_QUARTER_DISCUSSION = "FIRST_QUARTER_DISCUSSION",
  SAVE_RULES = "SAVE_RULES_SOCKET",
  OPEN_FULL_REPORT = "OPEN_FULL_REPORT_SOCKET",
  RETURN_FROM_IN_SERVICE = "RETURN_FROM_IN_SERVICE_SOCKET",
  OPEN_FULL_TEAM_RULES = "OPEN_FULL_TEAM_RULES_SOCKET",
  ASSIGN_USER_TO_RESOURCE = "ASSIGN_USER_TO_RESOURCE_SOCKET",
  REMOVE_USER_FROM_RESOURCE = "REMOVE_USER_FROM_RESOURCE_SOCKET",
  SET_FACILITATOR = "SET_FACILITATOR_SOCKET",
  SET_IN_SERVICE = "SET_IN_SERVICE_SOCKET",
  SET_FORCES = "SET_DRIVING_FORCES_SOCKET",
  SET_DEBRIEF = "SET_DEBRIEF",
  REFRESH_FINANCIALS = "REFRESH_FINANCIALS_SOCKET",
  SET_DEBRIEF_QUESTION = "SET_DEBRIEF_QUESTION_SOCKET",
  PING_ACTION = "PING_ACTION",
  MUTE_ALL = "MUTE_ALL",
  GET_UPCOMING_TRAININGS = "GET_UPCOMING_TRAININGS_SOCKET",
  GET_TRAINING_DETAILS = "GET_TRAINING_DETAILS_SOCKET",
  GET_PREVIOUS_TRAININGS = "GET_PREVIOUS_TRAININGS_SOCKET",
  SEND_MESSAGE = "SEND_MESSAGE_SOCKET",
  CREATE_GAME_SOCKET = "CREATE_GAME_SOCKET",
  UPDATE_GAME_SOCKET = "UPDATE_GAME_SOCKET",
  BULK_CREATE_USERS = "BULK_CREATE_USERS_SOCKET",
  PAUSE_GAME_SOCKET = "PAUSE_GAME_SOCKET",
  RESUME_GAME_SOCKET = "RESUME_GAME_SOCKET",
  START_GAME_SOCKET = "START_GAME_SOCKET",
  TRACK_USER_ATTENDANCE = "TRACK_USER_ATTENDANCE",
  GET_CURRENT_TRAININGS = "GET_CURRENT_TRAININGS",
}

export interface TrackUserAttendance {
  type: SocketActionTypes.TRACK_USER_ATTENDANCE;
  payload: { userId: string; gameId: string; stage: number };
}

export interface ResetStageSocketAction {
  type: SocketActionTypes.RESET_STAGE;
}

export interface GetGameDetailsSocketAction {
  type: SocketActionTypes.GET_GAME_DETAILS;
  payload: any;
}

export interface PauseGameSocketAction {
  type: SocketActionTypes.PAUSE_GAME_SOCKET;
  payload: any;
}

export interface ResumeGameSocketAction {
  type: SocketActionTypes.RESUME_GAME_SOCKET;
  payload: any;
}

export interface GetUpcomingTraningsAction {
  type: SocketActionTypes.GET_UPCOMING_TRAININGS;
  payload: any;
}

export interface GetCurrentTraningsAction {
  type: SocketActionTypes.GET_CURRENT_TRAININGS;
  payload: any;
}

export interface BulkCreateUsersAction {
  type: SocketActionTypes.BULK_CREATE_USERS;
  payload: any;
}

export interface GetTrainingDetailsAction {
  type: SocketActionTypes.GET_TRAINING_DETAILS;
  payload: any;
}

export interface SendMessageAction {
  type: SocketActionTypes.SEND_MESSAGE;
  payload: any;
}

export interface CreateGameSocketAction {
  type: SocketActionTypes.CREATE_GAME_SOCKET;
  payload: any;
}

export interface UpdateGameSocketAction {
  type: SocketActionTypes.UPDATE_GAME_SOCKET;
  payload: any;
}

export interface GetPreviousTraningsAction {
  type: SocketActionTypes.GET_PREVIOUS_TRAININGS;
  payload: any;
}
export interface SwitchTeamSocketAction {
  type: SocketActionTypes.SWITCH_TEAM;
  payload: any;
}

export interface MuteAllSocketAction {
  type: SocketActionTypes.MUTE_ALL;
}

export interface PingAction {
  type: SocketActionTypes.PING_ACTION;
  payload: any;
}
export interface FirstQuarterDiscussionAction {
  type: SocketActionTypes.FIRST_QUARTER_DISCUSSION;
}

export interface SetDebriefAction {
  type: SocketActionTypes.SET_DEBRIEF;
  payload?: any;
}

export interface SetDebriefQuestionAction {
  type: SocketActionTypes.SET_DEBRIEF_QUESTION;
  payload?: any;
}

export interface SetInServiceAction {
  type: SocketActionTypes.SET_IN_SERVICE;
  payload?: any;
}

export interface SetFacilitatorAction {
  type: SocketActionTypes.SET_FACILITATOR;
  payload: any;
}

export interface ReturnFromInServiceAction {
  type: SocketActionTypes.RETURN_FROM_IN_SERVICE;
  payload: any;
}

export interface EndStageSocketAction {
  type: SocketActionTypes.END_STAGE;
}

export interface RefreshFinancialsSocketAction {
  type: SocketActionTypes.REFRESH_FINANCIALS;
}

export interface TryConnectSocket {
  type: SocketActionTypes.TRY_CONNECT_SOCKET;
  idToken: string;
}
export interface RefreshConnectionAction {
  type: SocketActionTypes.REFRESH_CONNECTION;
  idToken: string;
}

export interface SetForcesSocket {
  type: SocketActionTypes.SET_FORCES;
  payload: any;
}

export interface ReadyToStartSocketAction {
  type: SocketActionTypes.READY_TO_START;
  name: string;
}

export interface CurrentStageSocketAction {
  type: SocketActionTypes.CURRENT_STAGE;
}

export interface SaveRuleSocketAction {
  type: SocketActionTypes.SAVE_RULES;
  rules: string;
}

export interface AssignUserToResource {
  type: SocketActionTypes.ASSIGN_USER_TO_RESOURCE;
  payload: any;
}
export interface RemoveUserFromResource {
  type: SocketActionTypes.REMOVE_USER_FROM_RESOURCE;
  payload: any;
}
export interface NextStageAction {
  type: SocketActionTypes.NEXT_STAGE;
  stage: number;
  timeRemaining?: number;
}

export interface AssignUserRMAction {
  type: SocketActionTypes.ASSIGN_USER_RM;
  userId: number;
  teamId: number;
}

export interface AssignPlayerToTeamAction {
  type: SocketActionTypes.ASSIGN_PLAYER;
  userId: number;
  teamId: number;
}

export interface SwitchTrainerction {
  type: SocketActionTypes.SWITCH_TRAINER;
  userId: number;
  teamId: number;
}
export interface GetGameId {
  type: SocketActionTypes.GET_GAME_ID;
}

export interface InitVideoSocketAction {
  type: SocketActionTypes.INIT_VIDEO;
  name: string;
}

export interface SocketConnected {
  type: SocketActionTypes.SOCKET_CONNECTED;
}

export interface CallTrainerSocketAction {
  type: SocketActionTypes.CALL_TRAINER;
  payload: boolean;
}

export interface SocketFailed {
  type: SocketActionTypes.SOCKET_FAILED;
  error: string;
}

export interface SocketDisconnected {
  type: SocketActionTypes.SOCKET_DISCONNECTED;
}

export interface NewMessage {
  type: SocketActionTypes.NEW_MESSAGE;
  message: string;
}
export interface OpenFullReport {
  type: SocketActionTypes.OPEN_FULL_REPORT;
  payload: any;
}
export interface OpenFullTeamRules {
  type: SocketActionTypes.OPEN_FULL_TEAM_RULES;
  payload: any;
}
export interface SelectField {
  type: SocketActionTypes.SELECT_FIELD;
  payload: string;
  originalField: string;
}

export type SocketActions =
  | TrackUserAttendance
  | TryConnectSocket
  | SocketConnected
  | SocketFailed
  | SocketDisconnected
  | NewMessage
  | InitVideoSocketAction
  | SetFacilitatorAction
  | OpenFullReport
  | ResumeGameSocketAction
  | PauseGameSocketAction
  | SetDebriefQuestionAction
  | OpenFullTeamRules
  | GetPreviousTraningsAction
  | GetTrainingDetailsAction
  | GetUpcomingTraningsAction
  | GetCurrentTraningsAction
  | BulkCreateUsersAction
  | SelectField
  | SetInServiceAction
  | SaveRuleSocketAction
  | ReadyToStartSocketAction
  | CreateGameSocketAction
  | UpdateGameSocketAction
  | SendMessageAction
  | EndStageSocketAction
  | ResetStageSocketAction
  | CurrentStageSocketAction
  | AssignUserToResource
  | RemoveUserFromResource
  | SetForcesSocket
  | PingAction
  | SetDebriefAction
  | NextStageAction
  | AssignPlayerToTeamAction
  | ReturnFromInServiceAction
  | AssignUserRMAction
  | FirstQuarterDiscussionAction
  | RefreshFinancialsSocketAction
  | SwitchTrainerction
  | GetGameId
  | CallTrainerSocketAction
  | MuteAllSocketAction
  | GetGameDetailsSocketAction
  | SwitchTeamSocketAction
  | RefreshConnectionAction;
