import moment from "moment";
import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { TrainingModel } from "../../models/AdminTrainingsModel";
import FinancialReport from "../../../shared/components/financialReport/FinancialReport";
import Accordion from "../../../shared/components/adminAccordion/AdminAccordion";
import { AppState } from "../../../store/Store";
import { useSelector } from "react-redux";
import "./AdminTrainingsHistoryDetails.scss";

let trainingComp: TrainingModel;

const convertTraining = (training: TrainingModel) => {
  if (training && training.start_date?.length === 24) {
    training.start_date = moment(
      training?.start_date,
      "YYYY-MM-DDTHH:mm:ss.SSSZ"
    ).format("DD MMM YYYY, HH:mm");
  }
  trainingComp = { ...training };
};

const returnDebriefQuestions = (props: any, trainingDetails: TrainingModel) => {
  if (props.training?.id === trainingDetails.id) {
    return (
      <div className={`displayTable`}>
        {trainingDetails?.debrief?.length ? (
          trainingDetails.debrief.map((debriefQ, index) => {
            return (
              <span key={`dd-${index}`}>
                <div key={`display-${index}`} className={`displayRowRect`}>
                  <div key={`display1-${index}`} className={`displayCell`}>
                    <span
                      key={index}
                      className={`trainDebriefQuestion trainingDebriefQuestionRact`}
                    >
                      Question {debriefQ.index}
                    </span>
                    <span className={`trainingDebriefQuestionRact`}>
                      {debriefQ.question}
                    </span>
                  </div>
                </div>
                <div key={`display2-${index}`} className={`displayRow`}>
                  <div className={`displayCell`}>
                    <span className={`trainDebriefTeam`}>
                      {debriefQ.teamName}
                    </span>
                    <span className={`trainDebriefTeam`}>
                      {/* <img src='/icons/icons-badge-1.svg' alt="FirstPlace" className={`team1Badge`} /> */}
                      {/* <img src='/icons/icons-badge-2.svg' alt="FirstPlace" className={`team2Badge`} /> */}
                      {/* <img src='/icons/icons-badge-3.svg' alt="FirstPlace" className={`team3Badge`} /> */}
                    </span>
                    <span className={`trainDebriefAnswer`}>
                      {debriefQ.answer}
                    </span>
                  </div>
                </div>
                <hr className={`questionSeparator`} />
              </span>
            );
          })
        ) : (
          <></>
        )}
      </div>
    );
  }
};

const returnParticipants = (props: any, trainingDetails: TrainingModel) => {
  console.log("go build the participants...", trainingDetails?.users);
  if (props.training?.id === trainingDetails.id) {
    return (
      <table className={`team`}>
        <tr>
          <th className="tal">Display name</th>
          <th className="tal">Team id</th>
          <th className="tal">Has attended</th>
          <th className="tal">Current stage</th>
          <th className="tal">Last seen</th>
        </tr>
        {trainingDetails?.users?.length ? (
          trainingDetails.users
            .sort((a, b) => a.teamid - b.teamid)
            .map((user, index) => {
              console.log("user.name is: ", user.display_name);

              return (
                <tr
                  key={`user-${index}`}
                  className={
                    user.hasAttended !== -1
                      ? `userParticipated`
                      : "userNotParticipated"
                  }
                >
                  <td className={`teamMembers`}> {user.display_name} </td>
                  <td className={`teamMembers`}> {user.teamid} </td>
                  <td className={`teamMembers`}>
                    {user.hasAttended !== -1 ? "YES" : "NO"}
                  </td>
                  <td className={`teamMembers`}>
                    {user.gameStage !== -1 ? user.gameStage : 0}
                    {"/19 "}
                  </td>
                  <td className={`teamMembers`}>
                    {user.lastSeen !== null
                      ? moment(
                          user.lastSeen,
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        ).format("DD MMM YYYY, HH:mm")
                      : "NEVER"}
                  </td>
                </tr>
              );
            })
        ) : (
          <tr>
            <td>No users to show in this training...</td>
          </tr>
        )}
      </table>
    );
  }
};

const checkIfFinancialReportAvailable = (
  props: any,
  trainingDetails: TrainingModel
) => {
  if (
    props.training?.id === trainingDetails.id &&
    trainingDetails?.financials?.length
  ) {
    return (
      <FinancialReport
        adminReport={true}
        finalReport={true}
        currentFinancialResult={1000}
      ></FinancialReport>
    );
  } else {
    return (
      <div className={`no-fin-report`}>No Financial Report Available!</div>
    );
  }
};

const checkIfTrainingsDetailsAreShown = (
  props: any,
  trainingDetails: TrainingModel
) => {
  if (props.training?.name && props.training?.id === trainingDetails.id) {
    convertTraining(props.training);
    return (
      <div className={`training-details-history`}>
        <Card>
          <CardContent key={`key-content-${trainingComp.id}`}>
            <span
              key={`key-details-${trainingComp.id}`}
              className={`trainings`}
            >
              Training Details
            </span>
          </CardContent>
        </Card>
        <div className={`admin-form-container`}>
          <div key={`keys-${trainingComp.id}`} className={"training-div"}>
            <span className={"training-name"}>{trainingComp.name}</span>
          </div>
          <div key={`keys1-${trainingComp.id}`} className={`two-columns`}>
            <div>
              <span className={`span-time`}>{trainingComp?.start_date}</span>
            </div>
          </div>
          <div key={`keys2-${trainingComp.id}`} className={`two-columns`}>
            {/* Financial Report */}
            {checkIfFinancialReportAvailable(props, trainingDetails)}
            {/* Financial Report */}
          </div>
          <div className={`accordion-1`}>
            {/* Debrief Questions */}
            <Accordion
              key={`key-acc-${trainingComp.id}`}
              title={"Debrief Questions"}
              subtitle={trainingDetails?.debrief?.length}
            >
              {returnDebriefQuestions(props, trainingDetails)}
            </Accordion>
            {/* Debrief Questions */}
          </div>
          <div className={`accordion-2`}>
            <Accordion
              key={`key-acc2-${trainingComp.id}`}
              title={"Participants"}
              subtitle={trainingDetails?.users?.length}
            >
              {/* Participants */}
              <div className={`displayTeams`}>
                {returnParticipants(props, trainingDetails)}
              </div>
              {/* Participants */}
            </Accordion>
          </div>
          {/* <div>
            <button
              onClick={() =>
                console.log("user wants to go here...", trainingDetails.id)
              }
            >
              Go to this training
            </button>
          </div> */}
        </div>
      </div>
    );
  }
  return <></>;
};

const AdminTrainingHistoryDetails = (props: any) => {
  const trainingData = (state: AppState) => state.gameState.gameDetails;
  const trainingDetails = useSelector(trainingData);
  return checkIfTrainingsDetailsAreShown(props, trainingDetails);
};

export default AdminTrainingHistoryDetails;
