const scrollLeft = (element: any, change: any, duration: any) => {
    const calcAnimation = (t: any, b: any, c: any, d: any) => {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };
    let start = element.scrollLeft,
        currentTime = 0,
        increment = 20;
        
    let animateScroll = () => {        
        currentTime += increment;
        var val = calcAnimation(currentTime, start, change, duration);
        element.scrollLeft = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

export const scrollService = { scrollLeft };