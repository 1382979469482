import { makeStyles } from "@material-ui/core";

const 
useStyles = makeStyles((theme) => ({
    totalClasses: {
        fontSize: '17px !important',
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    finalReport: {
        marginTop: '0',
        '&.open': {
            height: '308px'
        }
    },
    iconFinancialExpand: {
        top: '12px',
        right: '15px',
        width: '7px',
        height: '16px',
        position: 'absolute',
        display: 'inline-block',
        transition: 'transform 0.5s',
        backgroundImage: 'url(/icons/arrow-right.svg)'
    },
    iconExpandTop: {
        position: 'relative',
        marginLeft: '20px',
        top: '4px',
    },
    topRowDiv: {
        cursor: 'pointer'
    },
    header: {
        fontSize: '15px',
        fontWeight: 'normal',
        margin: 0,
        textAlign: 'left',
        color: '#1b1c20',
        marginLeft: '12px',
        cursor: 'pointer',
    },
    noOpen: {
        cursor: 'auto',
        borderRadius: '3px',
        color: '#57575a',
    },
    headerOpen: {
    },
    financialResult: {
        fontSize: '15px',
        fontWeight: 'bold',
        marginLeft: '5px',
    },
    positive: {
        color: 'black'
    },
    negative: {
        color: '#d0021b !important'
    },
    currentTeam: {
        color: '#1b1c20',
        fontWeight: 'bold'
    },
    tableRow: {
        backgroundColor: '#296daa',
    },
    tableRow1: {
        backgroundColor: '#ebc429',
        borderRadius: '2px;',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.4)',
        '& th': {
            backgroundColor: '#ebc429 !important',
        }
    },
    team1Badge: {
        width: '29px',
        height: '29px',
        display: 'block'
    },
    team2Badge: {
        width: '29px',
        height: '29px',
        display: 'block'
    },
    team3Badge: {
        width: '29px',
        height: '29px',
        display: 'block'
    },
    bottomText: {
        fontSize: '12px',
        right: '-104px',
        fontWeight: 500,
        color: '#57575a',
    },
    last: {
        padding: '0 !important',
        backgroundColor: '#fff !important'
    },
    tableCell: {
        lineHeight: '0px',
        borderBottom: 'unset !important'
    }
}));

export default useStyles;