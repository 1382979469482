import { Box, Button, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GameActionTypes } from "../../../game/store/GameActions";
import { SocketActionTypes } from "../../../socket/SocketActions";
import { AppState } from "../../../store/Store";
import "./styles/AdminRooms.scss";

const AdminRooms = () => {
  const [roomsFound, setRoomsFound] = useState(false);
  const roomTeams = (state: AppState) => state.gameState.roomTeams;
  const rooms = useSelector(roomTeams);
  const currentTeamId = (state: AppState) => state.gameState.teamId;
  const currentTeamIdSelector = useSelector(currentTeamId);
  const userCallingSel = (state: AppState) =>
    state.gameState.userCallingFromTeam;
  const userCalling = useSelector(userCallingSel);
  const dispatch = useDispatch();

  const showRooms = () => {
    console.log(`will show rooms now...`);
    console.log(rooms);
    console.log(`will show rooms now...`);

    let roomsArr: any[] = [];
    rooms.forEach((team, index) => {
      roomsArr.push(
        <Tooltip
          key={`room-${team.id}`}
          title={team.name ? team.name : index + 1}
          placement="left"
          classes={{
            popper: `rooms-tooltip`,
          }}
        >
          <Button
            key={`room-${team.id}`}
            className={checkIfCurrentTeam(team.id)}
            onClick={(event) => handleSwitchTeam(team.id)}
          >
            {index + 1}
          </Button>
        </Tooltip>
      );
    });
    if (roomsArr.length) {
      if (!roomsFound) {
        setRoomsFound(true);
      }
      return (
        <>
          <h4>Rooms</h4>
          {roomsArr}
        </>
      );
    }
    if (roomsFound) {
      setRoomsFound(false);
    }
    return <></>;
  };

  const checkIfCurrentTeam = (teamId: any) => {
    let classForButton = "";
    if (currentTeamIdSelector === teamId) {
      classForButton = "active";
    }
    if (userCalling.indexOf(teamId) > -1) {
      classForButton += " calling";
    }
    return classForButton;
  };

  const handleSwitchTeam = (teamId: any) => {
    dispatch({
      type: SocketActionTypes.SWITCH_TEAM,
      payload: teamId,
    });

    // Removes the team ID of the list of teams which are calling the trainer
    dispatch({
      type: GameActionTypes.REMOVE_USER_CALLING,
      payload: teamId,
    });
  };

  const getAdminRooms = () => {
    if (!roomsFound) {
      return "not-found";
    }
  };

  return (
    <Box id={`admin-rooms-container`} className={getAdminRooms()}>
      {showRooms()}
    </Box>
  );
};

export default AdminRooms;
