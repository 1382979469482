import { Button, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import CSVReader, { IFileInfo } from "react-csv-reader";
import { useDispatch, useSelector } from "react-redux";
import { AdminActionTypes } from "../../../../../admin/store/AdminActions";
import { UserRoleModel } from "../../../../../core/models/UserModel";
import { SocketActionTypes } from "../../../../../socket/SocketActions";
import { AppState } from "../../../../../store/Store";
import { validateData } from "../../../../../utils/parsing";
import { DialogActionTypes } from "../../store/DialogActions";
import "./styles/createPlayerStyles.scss";
const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, ""),
};

export interface ICreateBulkUser {
  email: string;
  name: string;
  password: string;
}

const CreatePlayerDialog = () => {
  const dispatch = useDispatch();
  const [isAdmin, setAdminState] = React.useState(false);
  const [canShow, setCanShow] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [users, setUsers] = React.useState<ICreateBulkUser[]>([]);
  const [warnings, setWarnings] = React.useState("");

  const currentUserData = (state: AppState) => state.userState.userData;
  const usersData = (state: AppState) => state.gameState.users;
  const gameStage = (state: AppState) => state.gameState.stage;
  const addUsersResponse = (state: AppState) =>
    state.adminState.addUsersResponse;

  const usersSelector = useSelector(usersData);
  const currentUserDataSelector = useSelector(currentUserData);
  const gameStageSelector = useSelector(gameStage);
  const usersResponse = useSelector(addUsersResponse);

  useEffect(() => {
    setCanShow(true);
    if (currentUserDataSelector) {
      setAdminState(currentUserDataSelector.role === UserRoleModel.Trainer);
    }

    const newUsersState: any = {};
    usersSelector.forEach((user) => {
      newUsersState[user.id] =
        [UserRoleModel.Trainer, UserRoleModel.RM].indexOf(user.role) > -1;
    });
  }, [usersSelector, currentUserDataSelector, gameStageSelector]);

  const handleBulkAddPlayers = () => {
    dispatch({
      type: SocketActionTypes.BULK_CREATE_USERS,
      payload: JSON.stringify(users),
    });
  };

  const handleClose = () => {
    dispatch({
      type: DialogActionTypes.HIDE_ALL,
    });
    dispatch({
      type: AdminActionTypes.SET_BULK_CREATE_RESPONSE,
      payload: { errors: null, createdUsers: null },
    });
  };

  const handleFile = (data: any[], fileInfo: IFileInfo) => {
    dispatch({
      type: AdminActionTypes.SET_BULK_CREATE_RESPONSE,
      payload: { errors: null, createdUsers: null },
    });

    setWarnings("");
    setUsers([]);
    setFileName(fileInfo.name);
    const result = validateData(data);
    if (result.error.length) {
      setWarnings(result.error);
    }
    setUsers(result.data);
  };
  const handleFileError = (error: Error) => {
    setFileName(error.message);
    setUsers([]);
  };

  return canShow && isAdmin && usersSelector.length > -1 ? (
    <div>
      <div className="label-create">Bulk Create Users:</div>
      <CSVReader
        cssClass="csv-reader-input"
        cssInputClass="csv-input"
        cssLabelClass="csv-label"
        label="Select a CSV"
        onFileLoaded={handleFile}
        onError={handleFileError}
        parserOptions={papaparseOptions}
        inputId="csv-input"
        inputName="csv-file"
      />
      <p>Selected file: {fileName || "No file selected"}</p>
      {warnings.length > 0 && (
        <p style={{ color: "orange", fontWeight: 500 }}>Warnings: {warnings}</p>
      )}
      {users.length > 0 && (
        <p style={{ color: "green", fontWeight: 500 }}>
          Will try to create the following users: [
          {users
            .map((u) => u.email)
            .join(", ")
            .trim()}
          ]
        </p>
      )}

      {usersResponse.createdUsers && usersResponse.createdUsers.length > 0 && (
        <p style={{ color: "green", fontWeight: 500 }}>
          The following users were created: [
          {usersResponse.createdUsers.substring(
            0,
            usersResponse.createdUsers.length - 1
          )}
          ]
        </p>
      )}
      {usersResponse.errors && usersResponse.errors.length > 0 && (
        <p style={{ color: "red", fontWeight: 500 }}>
          The following errors were encountered: [
          {usersResponse.errors.split(",").join("\r\n")}]
        </p>
      )}

      <Grid className="btn-container" item>
        <Button
          className="button1 button-create"
          onClick={handleBulkAddPlayers}
          disabled={!users.length}
        >
          Create
        </Button>
        <Button className="button1" onClick={handleClose}>
          Close
        </Button>
      </Grid>
    </div>
  ) : null;
};

export default CreatePlayerDialog;
