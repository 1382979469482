import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import FinancialReport from '../../shared/components/financialReport/FinancialReport';
import TeamRules from '../../shared/components/teamRules/TeamRules';
import useStyles from './styles/DebriefPageStyles';
import clsx from 'clsx';
import VideoComponent from '../../video/components/VideoComponent';
import CountDown from '../../shared/components/countDown/CountDown';
import { RouteComponentProps, withRouter } from 'react-router';
import { SocketActions, SocketActionTypes } from '../../socket/SocketActions';
import { UserRoleModel } from '../../core/models/UserModel';
import { DialogActions, DialogActionTypes } from '../../shared/components/dialogComponent/store/DialogActions';
import { AppState } from '../../store/Store';
import { QuestionModel } from './question.model';
import { LoadingIndicatorService } from '../../core/loadingIndicator/LoadingIndicatorService';
import _ from 'lodash';
import './styles/finalDiscussion.scss';

const changedTimes: number[] = [];
let isInitialRun: boolean = true;
const FinalTeamDiscussionPage = (props: RouteComponentProps) => {
    const classes = useStyles();
    const [facilitatorId, setfacilitatorId] = React.useState('-1');
    const [questions, setQuestions]: any[] = React.useState([]);
    const dispatch = useDispatch();
    const usersData = (state: AppState) => state.gameState.users;
    const usersDataSelector = useSelector(usersData);
    const userData = (state: AppState) => state.userState.userData;
    const userSelector: any = useSelector(userData);
    const [isAdmin, setAdminState] = React.useState(false);
    const facilitatorData = (state: AppState) => state.gameState.facilitatorId;
    const facilitatorSelector = useSelector(facilitatorData);
    const questionsData = (state: AppState) => state.gameState.questionsArr;
    const questionsSelector: QuestionModel[] = useSelector(questionsData);
    const textUpdatedData = (state: AppState) => state.gameState.textUpdated;
    const updatedSel = useSelector(textUpdatedData);
    const selectTeamName = (state: AppState) => state.gameState.teamName;
    const teamNameSel = useSelector(selectTeamName);
    const fixedContentHeight = clsx(classes.paper, classes.fixedMainHeight);

    const sendQuestion = (question: QuestionModel) => {
        dispatch({
            type: SocketActionTypes.SET_DEBRIEF,
            payload: question
        });
    }

    useEffect(() => {
        LoadingIndicatorService.stopLoading();
        if (userSelector) {
            setAdminState([UserRoleModel.Trainer, UserRoleModel.RM].indexOf(userSelector.role) >= 0);
        }
        if (facilitatorSelector) {
            setfacilitatorId(facilitatorSelector);
        }
        if (questionsSelector) {
            const questionsState: any[] = [];
            if (questionsSelector.length) {
                questionsSelector.forEach(teamQuestions => {
                    questionsState.push(teamQuestions);
                });
                setQuestions(questionsState);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSelector, facilitatorSelector, questionsSelector, updatedSel]);

    const handleChangeFacilitator = (event: any) => {
        const userId = event.target.value;
        if (userId !== '-1') {
            setfacilitatorId(usersDataSelector.filter(user => user.id === userId)[0]?.id);
            const setFacilitatorAction: SocketActions = {
                type: SocketActionTypes.SET_FACILITATOR,
                payload: userId
            };
            dispatch(setFacilitatorAction);
        } else {
            setfacilitatorId('-1');
        }
    }

    const showDialogComplete = () => {
        dispatch({
            type: DialogActionTypes.OPEN_DEBRIEF_CONFIRM_DIALOG,
            payload: true 
        });
    }

    const doneBtn = () => {
        const userIsFacilitator = facilitatorId ? userSelector.id === Number(facilitatorId) : undefined;
        const userCanWrite = isAdmin || userIsFacilitator;
        const allQuestionsAnswered = questions.filter((q: any) => q.answer?.length < 3);
        if (userCanWrite) {
            return (<button className={classes.doneButton} 
                        onClick={() => showDialogComplete()}
                        disabled={allQuestionsAnswered.length > 0}>
                        Done
                    </button>)
        } else {
            return (<></>);
        }
    }

    const labelFacilitatorDivs = `facilitator-div user ${classes.facilitatorDiv1}`;

    const facilitatorDropdown = () => {
        if (isAdmin) {
             return (<div className='facilitator-div' >Choose Facilitator: 
                <FormControl className={`${classes.formControl} drop`}>
                    <Select
                        labelId="facilitator-id"
                        id="facilitator-select"
                        value={facilitatorId}
                        onChange={handleChangeFacilitator}
                        >
                        {returnUsers()}
                    </Select>
                </FormControl>
            </div>)
        } else {
            let facilitatorName;
            if (facilitatorId !== '-1') {
                facilitatorName = usersDataSelector.filter((user: any) => user.id === facilitatorId)[0].name;
            }
            return (<div className={labelFacilitatorDivs}>Facilitator: 
                <FormControl className={`${classes.formControl1} label`}>
                    <InputLabel id="facilitator-label">{facilitatorName}</InputLabel>
                </FormControl>
            </div>);
        }
    }

    const showMainTitle = () => {
        const location: any = props.location;
        let pageTitle = '';
        if (!location.state?.mapDisabled) {
            pageTitle = 'Please answer the questions bellow as a team.';
        }
        return (<h1 className={`page-title page-title1`}>{pageTitle}</h1>);
    }

    const returnUsers = () => {
        let menuItemsArr = [<MenuItem key={-1} value={'-1'}>
            Select who edits the fields
        </MenuItem>];
        for (let index = 0; index < usersDataSelector.length; index++) {
            const user = usersDataSelector[index];
            if (user.id === userSelector.id && !user?.name.includes('(Me)')) {
                user.name = user.name + ' (Me)';
            }
            menuItemsArr.push(<MenuItem 
                key={user?.id}
                value={user?.id}
                >{user?.name}
            </MenuItem>);
        }
        return menuItemsArr;
    }

    const showTeamInfo = () => {
        let teamName: string | undefined;
        return (teamName ?? <div className={'bottom-div2'}>
            <p className={`team-name`}>You are in team {teamNameSel}</p>
        </div>);
    }

    const updateQuestions = (textTyped: string, teamQuestion: QuestionModel) => {
        let questCopy: any = {};
        changedTimes[teamQuestion.index]++;
        const foundIndex = questions.findIndex((quest: QuestionModel) => quest.index === teamQuestion.index);
        questCopy = _.cloneDeep(questions[foundIndex]);
        questCopy.answer = textTyped;
        const questionsCopy = _.cloneDeep(questions);
        questionsCopy[foundIndex] = questCopy;
        setQuestions(questionsCopy);
        if (changedTimes[teamQuestion.index] === 3) {
            sendQuestion(questCopy);
            changedTimes[teamQuestion.index] = 0;
        }
    }

    const sendFinalQuestion = (textTyped: string, teamQuestion: QuestionModel) => {
        let questCopy: any = {};
        const foundIndex = questions.findIndex((quest: QuestionModel) => quest.index === teamQuestion.index);
        questCopy = _.cloneDeep(questions[foundIndex]);
        questCopy.answer = textTyped;
        const questionsCopy = _.cloneDeep(questions);
        questionsCopy[foundIndex] = questCopy;
        sendQuestion(questCopy);
    }

    const returnQuestions = () => {
        const userIsFacilitator = facilitatorId ? userSelector.id === Number(facilitatorId) : undefined;
        const userCanWrite = isAdmin || userIsFacilitator;
        return (<div className={classes.mainDiv}>
                {questions.map((teamQuestion: QuestionModel) => {
                    if (isInitialRun) {
                        changedTimes[teamQuestion.index] = 0;
                    }
                    return <div key={teamQuestion.index} className={classes.divQuestion}>
                        <p className={classes.pStyles}>
                            {teamQuestion.index}. {teamQuestion.question}
                        </p>
                        <TextareaAutosize className={classes.textarea}
                            disabled={!userCanWrite} 
                            value={teamQuestion.answer !== null ? teamQuestion.answer : ''}
                            onBlur={(event) => sendFinalQuestion(event.target.value, teamQuestion)}
                            onChange={(event) => {
                            const textTyped = event.target.value;
                            updateQuestions(textTyped, teamQuestion);
                        }}></TextareaAutosize>
                    </div>
                })}
                {  questions.length ? isInitialRun = false : '' }
            </div>);
    }

    const showMapBtn = () => {
        const openGridMap: DialogActions = {
            type: DialogActionTypes.OPEN_GRID_MAP_DIALOG,
            payload: true
        };
        dispatch(openGridMap);
    }

    return (
        <div className="App">
            <VideoComponent></VideoComponent>
            <CountDown></CountDown>
            <div className="final-discussion-row">
                <div className="left">
                    {showMainTitle()}
                    {showTeamInfo()}
                    {facilitatorDropdown()}
                    <Button className='button-show-map' onClick={showMapBtn}>Show Map</Button>
                    <FinancialReport finalDiscussionPage={true}  className='fin-report'></FinancialReport>
                    <TeamRules finalDiscussionPage={true} className='team-rules'></TeamRules>
                </div>
                <div className="right">
                    <div className={classes.root}>
                        <main className={classes.content}>
                            <Container maxWidth="xl" className={classes.container} disableGutters={true}>
                                <Grid container spacing={1}>
                                    <Paper className={`${fixedContentHeight}`} elevation={0}>
                                        <div className={classes.gameTitle}></div>
                                        <div className={`${classes.mainDialog} questions-wrapper`}>
                                            <div className={classes.mainGrid}>
                                                {returnQuestions()}
                                            </div>
                                            {doneBtn()}
                                        </div>
                                    </Paper>
                                </Grid>
                            </Container>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(FinalTeamDiscussionPage);