import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        paddingLeft: '36%',
    },
    titleh2: {
        fontSize: '36px',
        fontWeight: 'bolder',
        fontFamily: 'Roboto'
    },
    contentContainer: {
        border: '0 !important'
    },
    contContainer: {
        paddingLeft: '30px'
    },
    from : {
        fontSize: '17px',
        fontFamily: 'Roboto',
        marginTop: '-3px',
        position: 'absolute'
    },
    to: {
        fontSize: '17px',
        fontFamily: 'Roboto',
        marginTop: '-3px',
        position: 'absolute'
    },
    subject: {
        fontSize: '17px',
        fontFamily: 'Roboto',
        marginTop: '-3px',
        position: 'absolute'
    },
    content: {
        marginTop: '44px',
        fontSize: '19px',
        fontFamily: 'Roboto',
        textAlign: 'center'
    },
    grid: {
        padding: '0 !important'
    },
    button: {
        width: '200px',
        height: '44px',
        color: '#fff',
        borderRadius: '4px;',
        backgroundColor: '#0d4171',
        marginTop: '46px',
        fontSize: '19px;',
        textTransform: 'none',
        marginLeft: 'calc(50% - 105px)'
    }
}));

export default useStyles;