import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mainDiv: {
        position: 'absolute',
        left: '19px',
        width: '200px',
        top: '160px',
        marginBottom: '68px',
        height: '58px',
        color: 'white',
        paddingTop: '10px',
        fontWeight: 'bold',
        fontSize: '19px',
    },
    mainDiv1: {
        position: 'relative',
        top: '44px',
        marginBottom: '68px',
        height: '58px',
        color: 'black',
        paddingTop: '10px',
        fontWeight: 'bold',
        fontSize: '19px',
    },
    buttons: {
        width: '244px',
        height: '32px',
        padding: '5px 6px 5px 8px',
        borderRadius: '3px',
        cursor: 'pointer',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '14px',
        backgroundColor: 'white',
        border: 'unset',
        lineHeight: '1.5',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#1b1c20'
    },
    button1: {
        marginRight: '15px'
    },
}));

export default useStyles;